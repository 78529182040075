import React, { useCallback, useContext, useMemo, useState } from "react";
import { styled } from "twin.macro";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { AccountMenu } from "./AccountMenu/AccountMenu";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";
import AppContext from "../../../contexts/AppContext";
import { UserBusinessRoles } from "../../../utilities/types";
import ManufacturersContext from "../../../contexts/ManufacturersContext";
import { WHITE } from "../../rxLibrary/colors";
import { IconDown } from "../../rxLibrary/icons";

const AccountName = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${WHITE};
  width: 194px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const AccountAndSettings = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: ${WHITE};
`;

export function NavBarAccountMenu() {
  const { userHasRole } = useContext(AppContext);
  const { manufacturer } = useContext(ManufacturersContext);
  const [isOpen, setIsOpen] = useState(false);
  const { currentBuyingPharmacy } = useBuyingPharmacy()
  const pharmacyName = currentBuyingPharmacy?.name

  const accountName = useMemo(() => {
    if (!userHasRole) return;

    if (userHasRole(UserBusinessRoles.MANUFACTURING)) {
      return manufacturer?.name;
    }

    if (userHasRole(UserBusinessRoles.BUYING)) {
      return pharmacyName;
    }
  }, [manufacturer, pharmacyName, userHasRole]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <ClickAwayListener onClickAway={close}>
      <AccountMenu onClose={close} open={isOpen}>
        <button tw="flex flex-col text-left space-y-[2px]" onClick={open}>
          <AccountName>{accountName}</AccountName>

          <div tw="flex items-center space-x-[5px]">
            <AccountAndSettings>ACCOUNT AND SETTINGS</AccountAndSettings>
            <IconDown tw="opacity-70" color="white" />
          </div>
        </button>
      </AccountMenu>
    </ClickAwayListener>
  );
}
