import React, { ButtonHTMLAttributes, ReactNode, useMemo } from "react";
import tw, { css } from "twin.macro";
import { BLACK, BLUE_1, GREY_1, GREY_2, GREY_3, WHITE } from "../colors";
import { Icon3Dots, IconNote, IconTrash } from "../icons";
import IconShoppingList from "./icon-shopping-list.svg";

export { BackButton } from "./BackButton";
export { EnhancedShowSupplierButton } from "./EnhancedShowSupplierButton";

type IButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonType =
  | "primary"
  | "secondary"
  | "tertiary"
  | "large"
  | "text-1"
  | "text-2";

export function Button({
  variant,
  block,
  fullWidth,
  icon,
  children,
  type = "button",
  ...props
}: {
  icon?: ReactNode;
  variant?: ButtonType;
  block?: boolean;
  fullWidth?: boolean;
} & IButtonProps) {
  const css = useMemo(
    () => [
      BASE_BUTTON_CSS,
      ...getVariantStyle(variant),
      block ? tw`flex` : tw`inline-flex`,
      fullWidth ? tw`w-full` : undefined,
    ],
    [variant, block]
  );

  return (
    <button css={css} type={type} {...props}>
      {!!icon && <span>{icon}</span>}
      <span>{children}</span>
    </button>
  );
}

export function IconButton({ type = "button", ...props }: IButtonProps) {
  return <button css={ICON_BUTTON_CSS} type={type} {...props} />;
}

export function MenuButton(props: Omit<IButtonProps, "children">) {
  return (
    <IconButton {...props}>
      <Icon3Dots />
    </IconButton>
  );
}

export function NoteButton(props: Omit<IButtonProps, "children">) {
  return (
    <IconButton {...props}>
      <IconNote />
    </IconButton>
  );
}

export function TrashButton(props: IButtonProps) {
  return (
    <IconButton {...props}>
      <IconTrash />
    </IconButton>
  );
}

export function ShoppingListButton(props: IButtonProps) {
  return (
    <Button {...props} icon={<IconShoppingList />} tw="gap-x-[7px] h-[54px]" />
  );
}

function getVariantStyle(variant?: ButtonType) {
  switch (variant) {
    case "secondary":
      return [BUTTON_SHADOW_CSS, BUTTON_SECONDARY_CSS];
    case "tertiary":
      return [BUTTON_SHADOW_CSS, BUTTON_TERTIARY_CSS];
    case "large":
      return [BUTTON_LARGE_CSS];
    case "text-1":
      return [BUTTON_TEXT_1_CSS];
    case "text-2":
      return [BUTTON_TEXT_2_CSS];
    default:
      return [BUTTON_SHADOW_CSS, BUTTON_PRIMARY_CSS];
  }
}

const BASE_BUTTON_CSS = css`
  font-family: "Archivo Narrow Variable", sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  column-gap: 3px;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms, background-color 300ms, color 300ms,
    box-shadow 300ms;

  &:disabled {
    opacity: 0.35;
    cursor: not-allowed;
    box-shadow: none;
  }
`;

export const BUTTON_SHADOW_CSS = css`
  &:hover {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  }

  &:active {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
  }
`;

const ICON_BUTTON_CSS = [
  BASE_BUTTON_CSS,
  css`
    line-height: 1.57;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    color: ${BLACK};

    &:hover {
      background-color: ${GREY_3};
    }

    &:active {
      background-color: ${GREY_2};
    }
  `,
];

const BUTTON_PRIMARY_CSS = css`
  height: 40px;
  padding: 0 20px;
  border-radius: 50px;
  font-size: 14px;
  font-stretch: normal;
  line-height: 1.29;
  color: ${WHITE};
  background-color: ${BLUE_1};

  &:hover {
    background-color: ${BLUE_1}E6;
  }
`;

const BUTTON_SECONDARY_CSS = css`
  height: 40px;
  padding: 0 20px;
  border-radius: 50px;
  font-size: 14px;
  font-stretch: normal;
  line-height: 1.29;
  color: ${BLUE_1};
  border: solid 1px ${GREY_2};
  background-color: ${WHITE};
`;

const BUTTON_TERTIARY_CSS = css`
  height: 28px;
  padding: 0 15px;
  border-radius: 30px;
  border: solid 1px ${BLUE_1};
  background-color: ${WHITE};
  font-size: 14px;
  font-stretch: normal;
  line-height: 1.29;
  color: ${BLUE_1};
`;

const BUTTON_LARGE_CSS = css`
  font-size: 14px;
  font-stretch: normal;
  line-height: 1.29;
  color: ${BLUE_1};
  width: 250px;
  height: 48px;
  border-radius: 30px;
  border: solid 1px ${BLUE_1};
  background-color: ${WHITE};

  &:hover,
  &:active {
    color: ${WHITE};
    background-color: ${BLUE_1};
  }

  &:disabled {
    background-color: ${WHITE};
  }
`;

const BUTTON_TEXT_1_CSS = css`
  height: 24px;
  padding: 0 12px;
  border-radius: 8px;
  font-size: 14px;
  font-stretch: normal;
  line-height: 1.57;
  color: ${BLUE_1};

  &:hover {
    background-color: ${GREY_3};
  }

  &:active {
    background-color: ${GREY_2};
  }
`;

const BUTTON_TEXT_2_CSS = css`
  height: 24px;
  padding: 0 12px;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 12px;
  font-stretch: condensed;
  line-height: 2;
  color: ${GREY_1};

  &:hover {
    background-color: ${GREY_3};
  }

  &:active {
    background-color: ${GREY_2};
  }
`;
