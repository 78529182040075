import { useContext, useEffect, useState } from "react";
import keyBy from "lodash/keyBy";
import { useShoppingState } from "../../../contexts/ShoppingContext/ShoppingContext";
import BuyingPharmacyContext from "../../../contexts/BuyingPharmacyContext";
import { PrescriptionDetail, Supplier } from "../../../utilities/types";
import {
  SupplierOrder_All, SupplierOrderItemRecommendation_All
} from "../../../services/types";

export type ShoppingItemSupplierOrderItem = SupplierOrderItemRecommendation_All & {
  id: string;
  prescriptionDetails: PrescriptionDetail[];
};

export type ShoppingItemSupplierOrder = Omit<SupplierOrder_All, "items"> & {
  items: ShoppingItemSupplierOrderItem[];
};

export type ShoppingItem = {
  supplier: Supplier;
  supplierOrder: ShoppingItemSupplierOrder;
};

export function useShoppingList() {
  const [shoppingList, setShoppingList] = useState<{
    shoppingItems: ShoppingItem[];
    unusedSuppliers: Supplier[];
  }>({
    shoppingItems: [],
    unusedSuppliers: [],
  });

  const { getSupplierById, currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const { optimizeCartResponse, getPrescriptionsWithDetails } =
    useShoppingState();

  useEffect(() => {
    if (!currentBuyingPharmacy) return;

    const { requestData } = optimizeCartResponse.data ?? {};
    const allowedSupplierIds = requestData?.allowedSupplierIds ?? currentBuyingPharmacy.suppliers.map((s) => s.id);

    const { supplierOrders } = optimizeCartResponse.data?.selections ?? {};
    if (!supplierOrders?.length || !allowedSupplierIds.length) {
      setShoppingList({ shoppingItems: [], unusedSuppliers: [] });
      return;
    }

    const suppliersWithOrders: {
      supplier: Supplier;
      supplierOrder: SupplierOrder_All;
    }[] = [];
    const unusedSuppliers: Supplier[] = [];
    const supplierOrdersById = keyBy(supplierOrders, "supplierId");
    allowedSupplierIds.forEach((id ) => {
      const supplier = getSupplierById(id);
      if (!supplier) return;

      const supplierOrder: SupplierOrder_All | undefined = supplierOrdersById[id];
      const hasSupplierOrderItems = !!supplierOrder?.items?.length;

      if (hasSupplierOrderItems) {
        suppliersWithOrders.push({ supplier, supplierOrder });
      } else {
        unusedSuppliers.push(supplier);
      }
    });

    const shoppingItems = suppliersWithOrders.map((supplierWithOrder) => {
      const { supplierOrder } = supplierWithOrder;

      const newItems = supplierOrder.items.map((item, i) => {
        const id = i + "-" + item.rxNumbers.join("");
        const prescriptionDetails = getPrescriptionsWithDetails(item.rxNumbers);
        return { ...item, id, prescriptionDetails };
      });

      const newSupplierOrder = { ...supplierOrder, items: newItems };

      return { ...supplierWithOrder, supplierOrder: newSupplierOrder };
    });

    setShoppingList({ shoppingItems, unusedSuppliers });
  }, [
    optimizeCartResponse,
    getSupplierById,
    getPrescriptionsWithDetails,
    currentBuyingPharmacy,
  ]);

  return shoppingList;
}
