import React, { useEffect, useState } from "react";
import { useShoppingState } from "../../../../../contexts/ShoppingContext/ShoppingContext";
import { PrescriptionDetail } from "../../../../../utilities/types";
import {
  UnfilledFuturePrescriptionGroup_All
} from "../../../../../services/types";

export type BuyLaterItem = UnfilledFuturePrescriptionGroup_All & {
  id: string;
  prescriptionDetails: PrescriptionDetail[];
};

export function useBuyLaterTableItems() {
  const [buyLaterTableItems, setBuyLaterTableItems] = useState<BuyLaterItem[]>(
    []
  );

  const { optimizeCartResponse, getPrescriptionsWithDetails } =
    useShoppingState();
  const { unfilledFuturePrescriptionGroups } =
    optimizeCartResponse?.data?.selections ?? {};

  useEffect(() => {
    if (!unfilledFuturePrescriptionGroups) {
      setBuyLaterTableItems([]);
      return;
    }

    const newBuyLaterTableItems = unfilledFuturePrescriptionGroups.map(
      (item) => {
        const id = item.rxNumbers.join("_");
        const prescriptionDetails = getPrescriptionsWithDetails(item.rxNumbers);
        const newItem = { ...item, id, prescriptionDetails };
        return newItem;
      }
    );

    setBuyLaterTableItems(newBuyLaterTableItems);
  }, [unfilledFuturePrescriptionGroups, getPrescriptionsWithDetails]);

  return buyLaterTableItems;
}
