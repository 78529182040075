import React, {useContext} from "react";
import semver from "semver";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
const arrowWestIcon = new URL('svgurl:../../assets/icon-arrow-west.svg', import.meta.url).toString();
const homeIcon = new URL('svgurl:../../assets/icon-home.svg', import.meta.url).toString();
const downIcon = new URL('svgurl:../../assets/down.svg', import.meta.url).toString();
import {EnhancedSupplierNavBarDisplayConfig} from "../EnhancedSupplierNavBar";
import EnhancedClientCommandContext from "../EnhancedClientCommandContext";
import EnhancedClientConfigContext from "../EnhancedClientConfigContext";
import EnhancedClientNavBarCommandContext from "../EnhancedClientNavBarCommandContext";

export function NavBarHeader({ displayConfig, hideHome } : {
  displayConfig: EnhancedSupplierNavBarDisplayConfig, hideHome?: boolean }
) {
  const { showMain, reloadSupplier, resetSupplier} = useContext(EnhancedClientCommandContext);
  const { setNavBarHeight, requestCancelSupplierLogin} = React.useContext(EnhancedClientNavBarCommandContext);
  const { enhancedClientActive, enhancedClientVersion } = useContext(EnhancedClientConfigContext);
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMoreActionsClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setMoreActionsAnchorEl(event.currentTarget);
  };
  const handleMoreActionsClose = () => {
    setMoreActionsAnchorEl(null);
  };
  const moreActionsOpen = Boolean(moreActionsAnchorEl);
  const isLogin = displayConfig.useCase === "login";
  return <div className="row">
    <div className="breadcrumbs">
      {(isLogin ? (
        <h5 className="breadcrumb">DaylightRx</h5>
          ) : (
        <>
          <img src={arrowWestIcon} className="hover-pointer backIco"
               alt="Back" onClick={showMain}/>
          <h5 className="breadcrumb hover-pointer"
          onClick={showMain}>DaylightRx</h5>
        </>
      ))}
      <h5 className="breadcrumb">/</h5>
      <h5
        className="current">{displayConfig.supplierDisplay}</h5>
    </div>
    <div className="actions">
      {(!hideHome && !isLogin && (!enhancedClientActive || (enhancedClientActive && semver.gte(enhancedClientVersion, "0.2.150")))) && (
        <>
          <h5 className="sep">|</h5>
          <img id="more-actions-button" src={downIcon}
               className="hover-pointer moreActionsIcon"
               alt="down"
               onClick={(e) => {
                 handleMoreActionsClick(e);
               }}
               aria-controls={moreActionsOpen ? "more-actions-menu" : undefined}
               aria-haspopup="true"
               aria-expanded={moreActionsOpen ? "true" : undefined}
          />
          <Menu
            id="more-actions-menu"
            anchorEl={moreActionsAnchorEl}
            open={moreActionsOpen}
            onClose={() => {
              handleMoreActionsClose()
            }}
            MenuListProps={{
              "aria-labelledby": "more-actions-button",
            }}
            transitionDuration={1000}
          >
            <MenuItem onClick={() => {
              setNavBarHeight(displayConfig.supplier, -1);
              resetSupplier(displayConfig.supplier);
              handleMoreActionsClose()
            }}>Reset</MenuItem>
          </Menu>
        </>
      )}
      {((enhancedClientVersion && semver.gte(enhancedClientVersion, "0.2.170")) && isLogin && (
        <>
          <h5 className="sep">|</h5>
          <h5 className="hover-pointer homeLabel" onClick={() => { requestCancelSupplierLogin(displayConfig.supplier); showMain(); }}>Cancel</h5>
        </>
      ))}
      {(!hideHome && (
          <>
            <h5 className="sep">|</h5>
            <img src={homeIcon} className="hover-pointer homeIcon"
                 alt="refresh" onClick={() => reloadSupplier(displayConfig.supplier)}/>
            <h5 className="hover-pointer homeLabel" onClick={() => reloadSupplier(displayConfig.supplier)}>{displayConfig.supplierDisplay} Home</h5>
          </>
      ))}
      {(!isLogin && (
        <>
          <h5 className="sep">|</h5>
          <h5 className="hover-pointer homeLabel" onClick={showMain}>Close</h5>
        </>
      ))}
      <h5 className="sep">|</h5>
    </div>
  </div>;
}
