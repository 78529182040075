import React, { useContext, useMemo } from "react";
import { styled } from "twin.macro";
import isUndefined from "lodash/isUndefined";
import isString from "lodash/isString";
import { formatWithUnit } from "../../../../../utilities/formatWithUnit";
import { ItemInCart } from "../../../../../utilities/types";
import { Text } from "../../../../../components/rxLibrary/typography";
import { GREY_2 } from "../../../../../components/rxLibrary/colors";
import BuyingPharmacyContext from "../../../../../contexts/BuyingPharmacyContext";
import { PmsEnum } from "../../../../../utilities/pms";

const TextWrapper = styled.div`
  padding: 0 12px;
  border-bottom: 1px solid ${GREY_2};
`;

function useFormatDispensedQuantity(prescription: ItemInCart): string {
  const { dispensedQuantity, packQuantity = 0 } = prescription;
  const { unitSize, unitQuantity, uom } = prescription.drug;

  const formattedDispensedQuantity = useMemo(() => {
    if (isString(dispensedQuantity)) return dispensedQuantity;

    const quantity = isUndefined(dispensedQuantity)
      ? packQuantity * unitSize * unitQuantity
      : dispensedQuantity;

    const newDispensedQuantity = formatWithUnit(quantity, uom);
    return newDispensedQuantity;
  }, [dispensedQuantity, packQuantity, unitSize, unitQuantity, uom]);

  return formattedDispensedQuantity;
}

function useFormatValue(value: number) {
  const newValue = useMemo(() => {
    let newValue = value;
    if (newValue < -100) {
      newValue = Math.round(newValue);
    }
    if (newValue <= -10000) {
      newValue = -9999;
    }
    if (Math.abs(newValue) >= 1000) {
      newValue = Math.ceil(newValue);
    }

    return newValue;
  }, [value]);

  return newValue;
}

export function ShoppingListColumnDispensedQuantity({
  prescription,
  hideDispensedQuantity,
}: {
  prescription: ItemInCart;
  hideDispensedQuantity?: boolean;
}) {
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const { pms, keepsInventory: pharmacyKeepsInventory } =
    currentBuyingPharmacy ?? {};
  const isLiberty = pms === PmsEnum.Liberty;

  const dispensedQuantity = useFormatDispensedQuantity(prescription);
  const boh = useFormatValue(prescription.boh);
  const eoh = useFormatValue(prescription.eoh);

  if (!pharmacyKeepsInventory) {
    if (hideDispensedQuantity) return null;
    return <Text weight="bold">{dispensedQuantity}</Text>;
  }

  return (
    <div tw="flex flex-col items-center">
      {!hideDispensedQuantity && (
        <TextWrapper>
          <Text weight="bold">{dispensedQuantity}</Text>
        </TextWrapper>
      )}

      <div>
        <Text color="grey-2">BOH: </Text>
        <Text weight="bold">{boh} </Text>

        {!isLiberty && (
          <>
            <Text color="grey-2">EOH </Text>
            <Text weight="bold">{eoh}</Text>
          </>
        )}
      </div>
    </div>
  );
}
