import React, { useCallback, useMemo, useState } from "react";
import "twin.macro";
import p from "pluralize";
import { useHistory } from "react-router-dom";
import intersectionBy from "lodash/intersectionBy";
import type { ShippingInfo } from "../../../../../utilities/shipping/shipping.constants";
import type { ManufacturerStoreItem } from "../Catalog.constants";
import { Text } from "../../../../../components/rxLibrary/typography";
import { IconClock } from "../../../../../components/rxLibrary/icons";
import { Button } from "../../../../../components/rxLibrary/buttons";
import { FullPageLoader } from "../../../../../components/loaders/FullPageLoader";
import { ShoppingCartWithNumber } from "../../../../../components/ShoppingCartWithNumber";
import { Box } from "../../../../../components/rxLibrary/box";
import {
  Popover,
  useIsPopoverOpen,
} from "../../../../../components/rxLibrary/popover";
import { displayErrorModal } from "../../../../../utilities/errorModals/displayErrorModal";
import {
  currencyWithCentsAtAllTimes,
  currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits,
} from "../../../../../utilities/numbers/currency";
import { MAYNE_MIN_FOAM_ITEMS_QTY } from "../../../../../utilities/config";
import { ManufacturerStoreSubmitOrder } from "../useManufacturerStoreItems/useManufacturerStoreItems";
import { useCatalog } from "../useCatalog";
import { useCatalogCartHeaderWarnings } from "./useCatalogCartHeaderWarnings/useCatalogCartHeaderWarnings";
import { CatalogCartOrderConfirmationPopover } from "./CatalogCartOrderConfirmationPopover";
import { useStickyShoppingCart } from "./CatalogCart.useStickyShoppingCart";
import { CatalogCartItem } from "./CatalogCartItem";

export function CatalogCart({
  cartItems,
  shippingInfo,
  submitOrder,
  removeLocalStorageCartCatalog,
}: {
  cartItems: ManufacturerStoreItem[];
  shippingInfo?: ShippingInfo;
  submitOrder: ManufacturerStoreSubmitOrder;
  removeLocalStorageCartCatalog: () => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [{ popoverMsg, popoverOnContinue }, setPopoverProps] = useState<{
    popoverMsg?: string;
    popoverOnContinue?: () => Promise<void>;
  }>({});

  const {
    isPopoverOpen,
    openPopover: _openPopover,
    closePopover: _closePopover,
    handleOpenPopoverChange,
  } = useIsPopoverOpen();

  const history = useHistory();

  const catalogHeaderWarnings = useCatalogCartHeaderWarnings();

  const { cartRef, itemsWrapperRef, hasScrollOverlay, updateHasScrollOverlay } =
    useStickyShoppingCart(cartItems);

  const {
    pharmacy,
    supplier,
    itemsByType,
    isMayneSupplier,
    isJourneySupplier,
  } = useCatalog();
  const { id: supplierId, shippingConfig, defaultShippingMethod } = supplier;
  const minOrder = shippingConfig?.minOrder;
  const cartItemsCount = cartItems.length;
  const { shippable, minFoamQtyError, itemsTotals } = shippingInfo ?? {};
  const isSubmitDisabled = isPopoverOpen || !shippable;
  const { total = 0 } = itemsTotals ?? {};
  const pharmacyName = pharmacy.name;
  const { featuredItems } = itemsByType;

  const totalFormatted = useMemo(() => {
    return currencyWithCentsAtAllTimes(total);
  }, [total]);

  const minOrderFormatted = useMemo(() => {
    if (!minOrder) return;
    return currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(minOrder);
  }, [minOrder]);

  const minShipWarning = useMemo(() => {
    if (!minOrder) return;

    const hasMinShip = total >= minOrder;
    const min = currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(minOrder);

    if (minFoamQtyError) {
      if (hasMinShip) {
        return `Minimum of ${MAYNE_MIN_FOAM_ITEMS_QTY} foam units required to submit order`;
      }
      return `${min} minimum + min. of ${MAYNE_MIN_FOAM_ITEMS_QTY} foam units required to submit order`;
    }

    if (!hasMinShip) return `${min} minimum to submit order`;
  }, [total, minOrder, minFoamQtyError]);

  const featuredItemsNotInCartCount = useMemo(() => {
    const featuredItemsInCart = intersectionBy(featuredItems, cartItems, "ndc");
    return featuredItems.length - featuredItemsInCart.length;
  }, [featuredItems, cartItems]);

  const openPopover = useCallback(
    (popoverMsg: string, popoverOnContinue?: () => Promise<void>) => {
      setPopoverProps({ popoverMsg, popoverOnContinue });
      _openPopover();
    },
    [_openPopover]
  );

  const closePopover = useCallback(() => {
    setPopoverProps({});
    _closePopover();
  }, [_closePopover]);

  const handleSubmitOrder = useCallback(
    async ({
      skipDuplicateCheck = false,
    }: {
      skipDuplicateCheck?: boolean;
    } = {}) => {
      setIsSubmitting(true);

      try {
        const response = await submitOrder({ skipDuplicateCheck });
        const data = response?.data?.data;
        if (!data) return;

        const { purchaseOrder } = data;
        const purchaseOrderNumbers =
          purchaseOrder.purchaseOrderNumbers?.join(",") ??
          purchaseOrder.purchaseOrderNumber;

        removeLocalStorageCartCatalog();

        history.push(
          `/manufacturer-store/${supplierId}/completed/${purchaseOrderNumbers}`
        );
      } catch (error: any) {
        if (error?.response?.data?.error?.errorCode === "duplicate_order") {
          console.warn("Duplicate order error", error);
          setIsSubmitting(false);
          openPopover(
            error?.response?.data?.error?.detail ??
              "Same order submitted recently",
            async () => await handleSubmitOrder({ skipDuplicateCheck: true })
          );
          return;
        }

        const email = "ecommerce-support@daylightrx.com";
        const requestStatus = error?.response?.status;

        let content;
        if (requestStatus) {
          // 503 is a server overload error
          if (requestStatus < 500 || requestStatus === 503) {
            content = (
              <Text>
                The order was not submitted. Please try again or contact us via
                the orange chat widget or at{" "}
                <Text tw="underline" weight="bold" color="blue-1">
                  <a href={`mailto:${email}`}>{email}</a>
                </Text>
              </Text>
            );
          }
        }

        displayErrorModal({
          title: "There was a problem submitting your order",
          email,
          content,
        });
        setIsSubmitting(false);
        console.error("Error in manufacture store page", error);
      }
    },
    [supplierId, submitOrder, openPopover, removeLocalStorageCartCatalog]
  );

  const preHandleSubmitOrder = useCallback(async () => {
    if (featuredItemsNotInCartCount === 0) {
      return await handleSubmitOrder();
    }

    const c = featuredItemsNotInCartCount;
    const msg = `${c} list ` + `${p("item", c)} ${p("is", c)} not in cart!`;
    openPopover(msg, async () => await handleSubmitOrder());
  }, [featuredItemsNotInCartCount, openPopover, handleSubmitOrder]);

  return (
    <div tw="flex-1">
      <Box
        ref={cartRef}
        bgColor="white"
        borderColor="blue-2"
        tw="border rounded-lg overflow-y-auto flex flex-col sticky top-[20px] transition-[max-height] duration-200"
      >
        <Box tw="pt-[20px] pb-[9px] space-y-[9px]" bgColor="grey-5">
          <div tw="flex space-x-2 ml-[21px] mr-[50px]">
            <ShoppingCartWithNumber value={cartItemsCount} />
            <div tw="pt-[4px]">
              <Text weight="bold" size="large" block>
                Shopping Cart - {pharmacyName}
              </Text>

              {!!defaultShippingMethod && (
                <Text block>
                  {defaultShippingMethod}
                  {isJourneySupplier && " (Refrigerated items: Overnight)"}
                </Text>
              )}
            </div>
          </div>

          {!!catalogHeaderWarnings && (
            <div tw="flex mx-[32px] space-x-[4px]">
              <div>
                <IconClock />
              </div>

              <div tw="space-y-[5px]">
                {catalogHeaderWarnings.map((text) => (
                  <Text key={text} block>
                    {text}
                  </Text>
                ))}
              </div>
            </div>
          )}

          {!!minOrderFormatted && (
            <Box tw="border-t mx-[32px] pt-[9px]" borderColor="grey-2">
              <Text block>
                <Text weight="bold">Order Minimum: </Text>
                {minOrderFormatted}

                {isMayneSupplier && (
                  <>
                    <Text weight="bold"> | Foam products: </Text>
                    Min. of {MAYNE_MIN_FOAM_ITEMS_QTY} units required
                  </>
                )}
              </Text>
            </Box>
          )}
        </Box>

        {cartItemsCount === 0 ? (
          <div tw="py-[50px]">
            <Text weight="bold" color="grey-1" center block>
              Your Shopping Cart is Empty
            </Text>
          </div>
        ) : (
          <>
            <div
              tw="overflow-y-auto flex-1 mx-[18px]"
              ref={itemsWrapperRef}
              onScroll={updateHasScrollOverlay}
            >
              {cartItems.map((item) => (
                <Box
                  key={item.ndc}
                  borderColor="grey-2"
                  tw="border-b py-[20px]"
                >
                  <CatalogCartItem item={item} />
                </Box>
              ))}
            </div>

            {hasScrollOverlay && (
              <div tw="relative">
                <div
                  tw="absolute bottom-0 w-full h-[99px] pointer-events-none"
                  style={{
                    backgroundImage:
                      "linear-gradient(to bottom, #0000, #00000049)",
                  }}
                />
              </div>
            )}

            <div tw="text-right mt-[10px] mb-[20px] mx-[39px]">
              <Text tw="mb-[10px]" block>
                Subtotal ({cartItemsCount} {p("item", cartItemsCount)}
                ): <Text weight="bold">{totalFormatted}</Text>
              </Text>

              <Popover
                open={isPopoverOpen}
                trigger={["contextMenu"]}
                onOpenChange={handleOpenPopoverChange}
                content={() => (
                  <CatalogCartOrderConfirmationPopover
                    msg={popoverMsg}
                    onClose={closePopover}
                    onContinue={popoverOnContinue}
                  />
                )}
              >
                <Button
                  disabled={isSubmitDisabled}
                  onClick={preHandleSubmitOrder}
                >
                  Submit Order
                </Button>
              </Popover>

              {!!minShipWarning && (
                <Text tw="mt-[10px]" weight="bold" color="red" block>
                  {minShipWarning}
                </Text>
              )}
            </div>
          </>
        )}
      </Box>

      {isSubmitting && <FullPageLoader text="Submitting Order" />}
    </div>
  );
}
