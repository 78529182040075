import React from "react";
import "twin.macro";
import { Link } from "react-router-dom";
import { Text } from "../../../components/rxLibrary/typography";
import { Button } from "../../../components/rxLibrary/buttons";
import { Card } from "./Card";

export function DailyPurchaseCard() {
  return (
    <Card>
      <div tw="flex flex-col items-center">
        <img tw="mb-[28px]" src="/assets/pages/home/daily-purchase.svg" />

        <Text tw="mb-[18px]" weight="bold" size={21}>
          DAILY PURCHASE
        </Text>

        <Text tw="w-[295px] mx-auto mb-[24px]" size="medium" center>
          Add to inventory items throughout the day and make your consolidated,
          end-of-day orders.
        </Text>

        <Link to="/shoppingList">
          <Button>Go to List</Button>
        </Link>
      </div>
    </Card>
  );
}
