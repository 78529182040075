import React, { ComponentProps } from "react";
import { styled } from "twin.macro";
import { Switch as BaseSwitch } from "antd";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import { GREEN_1, GREY_1 } from "./colors";

const SwitchStyled = styled(BaseSwitch)`
  &.ant-switch {
    &.ant-switch-checked {
      background-color: ${GREEN_1};

      &:hover {
        opacity: 0.8;
        background-color: ${GREEN_1};
      }

      &:active {
        opacity: 1;
        background-color: ${GREY_1};
      }

      .anticon.anticon-check {
        display: block;
        position: absolute;
        right: 0;
        color: ${GREEN_1};
        top: 0;
        margin-top: 4px;
        margin-right: 3px;
        font-size: 9px;
      }
    }

    &:not(.ant-switch-checked) {
      background-color: #d8d5d5;

      &:hover {
        background-color: ${GREY_1};
      }

      &:active {
        background-color: ${GREEN_1};
      }

      .anticon.anticon-check {
        display: none;
      }
    }
  }
`;

type ISwitchProps = Omit<
  ComponentProps<typeof BaseSwitch>,
  "size" | "checkedChildren"
>;


export function Switch(props: ISwitchProps) {
  return (
    <SwitchStyled
      {...props}
      checkedChildren={<CheckOutlined rev={undefined} />}
      size="small"
    />
  );
}
