import React, { ComponentProps, useCallback, useMemo } from "react";
import { styled } from "twin.macro";
import p from "pluralize";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { BLUE_1 } from "../../../../../../components/rxLibrary/colors";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import { CutOffTime } from "../../../../../../components/CutOffTime";
import { currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits } from "../../../../../../utilities/numbers/currency";
import { ShoppingItem } from "../../../useShoppingList";
import { TableHeaderCurrency } from "../../table/tableHeader/TableHeaderCurrency";
import { TableHeaderDescription } from "../../table/tableHeader/TableHeaderDescription";
import { ShoppingListTableHeaderWarnings } from "./ShoppingListTableHeaderWarnings";
import { ShoppingListTableHeaderShippingInfo } from "./ShoppingListTableHeaderShippingInfo";
import { ShoppingListTableHeaderVisitedItems } from "./ShoppingListTableHeaderVisitedItems";
import {
  SupplierOrderItemRecommendation_All
} from "../../../../../../services/types";
import {tryWriteClipboard} from "../../../../../../utilities/clipboard";

type ButtonHandleClick = NonNullable<ComponentProps<typeof Button>["onClick"]>;

const BlueColumn = styled.div`
  background-color: ${BLUE_1}12;
`;

export function ShoppingListTableHeader({
  shoppingItem,
  openSupplierPage,
  getVisitedItemsCount,
}: {
  shoppingItem: ShoppingItem;
  openSupplierPage: (shoppingItem: ShoppingItem) => void;
  getVisitedItemsCount: (items: SupplierOrderItemRecommendation_All[]) => number;
}) {
  const { supplier, supplierOrder } = shoppingItem;
  const { buyingCost, shippingCost, items } = supplierOrder;
  const { displayName, shippingConfig } = supplier;
  const cutoffTimes = shippingConfig?.cutoffTimes ?? "0:0";
  const itemsCount = items.length;

  const visitedItemsCount = useMemo(() => {
    return getVisitedItemsCount(items);
  }, [items, getVisitedItemsCount]);

  const total = useMemo(() => {
    const value = buyingCost + shippingCost;
    return currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(value, true);
  }, [buyingCost + shippingCost]);

  const handleOpenSupplierClick: ButtonHandleClick = useCallback(
    (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      if (shoppingItem.supplierOrder.items.length > 0) {
        tryWriteClipboard(shoppingItem.supplierOrder.items[0].referenceData.drugInfo.ndc)
      }
      openSupplierPage(shoppingItem);
    },
    [openSupplierPage, shoppingItem]
  );

  return (
    <div tw="flex h-full">
      <div tw="h-full flex-1 space-y-[11px] py-[20px] px-[10px]">
        <div tw="flex flex-1 pr-[13px]">
          <div tw="w-[371px] pl-[6px] mr-[16px]">
            <div tw="flex items-baseline mb-[6px]">
              <Text block color="blue-1" size={21}>
                {displayName} Shopping List
              </Text>

              <Text tw="ml-[7px]" color="blue-1">
                <CutOffTime cutoffTimes={cutoffTimes} cutoffOnly />
              </Text>
            </div>

            <ShoppingListTableHeaderShippingInfo supplier={supplier} />
          </div>

          <div tw="text-center w-[39px] space-y-[6px] mr-[30px]">
            <Text weight="bold" size={20} block center>
              {itemsCount}
            </Text>
            <TableHeaderDescription>
              {p("ITEM", itemsCount)}
            </TableHeaderDescription>
          </div>

          <div tw="text-center w-[94px] space-y-[6px]">
            <TableHeaderCurrency value={total} />
            <TableHeaderDescription>TOTAL</TableHeaderDescription>
          </div>
        </div>

        <ShoppingListTableHeaderWarnings shoppingItem={shoppingItem} />
      </div>

      <BlueColumn tw="h-full w-[494px] py-[20px] px-[40px]">
        <div tw="flex space-x-[30px]">
          <div>
            <Button variant="large" onClick={handleOpenSupplierClick}>
              Shop list at {displayName}
            </Button>
          </div>

          <div tw="flex justify-center items-center">
            <ShoppingListTableHeaderVisitedItems
              visitedItemsCount={visitedItemsCount}
              itemsCount={itemsCount}
            />
          </div>
        </div>
      </BlueColumn>
    </div>
  );
}
