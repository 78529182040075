import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { css } from "twin.macro";
import type { ShoppingSupplierCheck } from "../../../utilities/types";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";
import { useShoppingUpdater } from "../../../contexts/ShoppingContext/ShoppingContext";
import { Text } from "../../rxLibrary/typography";
import { Switch } from "../../rxLibrary/switch";
import { Button } from "../../rxLibrary/buttons";
import EnhancedClientConfigContext from "../../../enhanced/EnhancedClientConfigContext";
import { supplierIsDisabledInEnhancedClient } from "../../../enhanced/util";
import { GREY_3, GREY_5 } from "../../rxLibrary/colors";
import { Box } from "../../rxLibrary/box";
import { CutOffTime } from "../../CutOffTime";

const ROW_BORDER_CSS = css`
  border-top: 1px solid ${GREY_3};
  border-bottom: 1px solid ${GREY_3};
`;

const ROW_HOVER_CSS = css`
  cursor: pointer;

  &:hover {
    background-color: ${GREY_5};
  }
`;

const SHADOW_TOP_CSS = css`
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.16);
`;

export function SupplierModal({
  selectedSuppliers,
  onClose,
  onContinue,
}: {
  selectedSuppliers?: ShoppingSupplierCheck[];
  onClose: () => void;
  onContinue: (suppliersList: ShoppingSupplierCheck[]) => void;
}) {
  const { currentBuyingPharmacy, getSupplierById } = useBuyingPharmacy();
  const { enhancedClientVersion } = useContext(EnhancedClientConfigContext);
  const { addOrgSuppliersList } = useShoppingUpdater();
  const [suppliersList, setSuppliersList] = useState<ShoppingSupplierCheck[]>(
    []
  );
  const [showError, setShowError] = useState(false);
  const { suppliers } = currentBuyingPharmacy || {};

  const suppliersCount = suppliersList.length;
  const suppliersCheckCount = useMemo(() => {
    return suppliersList.filter((item) => item.check).length;
  }, [suppliersList]);

  const selectAllSuppliers = useCallback(() => {
    const newSupplierList = suppliers?.map(
      (supplier): ShoppingSupplierCheck => ({ id: supplier.id, check: true })
    );
    setSuppliersList(newSupplierList ?? []);
  }, [suppliers]);

  const selectNoneSuppliers = useCallback(() => {
    const newSupplierList = suppliers?.map(
      (supplier): ShoppingSupplierCheck => ({ id: supplier.id, check: false })
    );
    setSuppliersList(newSupplierList ?? []);
  }, [suppliers]);

  useEffect(() => {
    const newSupplierList = suppliers?.map(
      (supplier): ShoppingSupplierCheck => {
        const { id } = supplier;
        const check = selectedSuppliers
          ? !!selectedSuppliers.find((s) => s.id === id)?.check
          : true;

        return { id, check };
      }
    );
    setSuppliersList(newSupplierList ?? []);
  }, [enhancedClientVersion]);

  useEffect(() => {
    setShowError(suppliersCheckCount <= 0);
  }, [suppliersCheckCount]);

  return (
    <div tw="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <Box
        tw="flex flex-col w-full rounded-lg pt-10 w-[820px] max-h-[90dvh]"
        bgColor="white"
      >
        <div tw="flex justify-between items-center px-8">
          <Text tw="pl-6" size="large" weight="bold" block>
            Select Suppliers for Pricing Comparison
          </Text>

          <Text block>
            Selected {suppliersCheckCount} of {suppliersCount}
          </Text>

          <div tw="flex items-center">
            <Button variant="text-2" onClick={selectAllSuppliers}>
              Select all Suppliers
            </Button>
            <Text color="grey-2">|</Text>
            <Button variant="text-2" onClick={selectNoneSuppliers}>
              Select None
            </Button>
          </div>
        </div>

        <div tw="flex-1 px-8 my-4 overflow-auto">
          <table tw="w-full">
            {suppliersList.map(({ id, check }, suppliersIndex) => {
              const supplier = getSupplierById(id);
              if (!supplier) return null;

              const { name, displayName, shippingConfig } = supplier;
              const cutoffTimes = shippingConfig?.cutoffTimes || "0:0";

              // TODO: validate if this is doing something
              const isDisabled = supplierIsDisabledInEnhancedClient(
                name,
                enhancedClientVersion
              );

              function updateSupplierCheck(checked: boolean) {
                if (isDisabled) return;

                const newSupplierList = [...suppliersList];
                newSupplierList[suppliersIndex] = {
                  ...newSupplierList[suppliersIndex],
                  check: checked,
                };
                setSuppliersList(newSupplierList);
              }

              return (
                <tr
                  key={id}
                  css={[ROW_BORDER_CSS, !isDisabled && ROW_HOVER_CSS]}
                  onClick={() => updateSupplierCheck(!check)}
                >
                  <td tw="p-1 pl-6 w-[200px]">
                    <Text weight="bold">{displayName}</Text>
                  </td>

                  <td tw="p-1 min-w-[270px]">
                    <Text>
                      {isDisabled ? (
                        "Temporarily disabled on DaylightRx"
                      ) : (
                        <CutOffTime cutoffTimes={cutoffTimes} />
                      )}
                    </Text>
                  </td>

                  <td tw="p-1 w-[110px]">
                    <Switch
                      checked={check && !isDisabled}
                      disabled={isDisabled}
                      onChange={updateSupplierCheck}
                    />
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        <div tw="py-6 px-20" css={SHADOW_TOP_CSS}>
          <div tw="flex items-center h-full">
            {showError && (
              <Text color="red">Please select at least one supplier</Text>
            )}

            <div tw="flex items-center ml-auto space-x-2">
              <Button variant="text-2" onClick={onClose}>
                Cancel
              </Button>

              <Button
                disabled={showError}
                onClick={() => {
                  setShowError(showError);

                  if (!showError) {
                    addOrgSuppliersList(suppliersList);
                    onContinue(suppliersList);
                  }
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}
