import React, { useContext, useMemo } from "react";
import "twin.macro";
import ManufacturersContext from "../../../contexts/ManufacturersContext";
import { useManufacturerReport } from "../useManufacturerReport/useManufacturerReport";
import { ManufacturerReportType } from "../Manufacturer.constants";
import { ManufacturerReportModal } from "./ManufacturerReportModal";
import { ManufacturerReportGrid } from "./ManufacturerReportGrid";
import { ManufacturerReportWrapper } from "./ManufacturerReportWrapper";
import { ManufacturerReportError } from "./ManufacturerReportError";

export function ManufacturerReports() {
  const { manufacturer } = useContext(ManufacturersContext);
  const { report, reportDates } = useManufacturerReport();
  const { reportId, reportType, iframeProps } = report;

  const [startDate, endDate] = useMemo(() => {
    return reportDates.map((date) => date.format("YYYY-MM-DD"));
  }, [reportDates]);

  if (!manufacturer) {
    return <ManufacturerReportError />;
  }

  if (reportType === ManufacturerReportType.COMING_SOON) {
    return <ManufacturerReportWrapper>Coming Soon</ManufacturerReportWrapper>;
  }

  if (reportType === ManufacturerReportType.MODE) {
    return (
      <ManufacturerReportModal
        endDate={endDate}
        reportId={reportId}
        startDate={startDate}
        iframeProps={iframeProps}
        manufacturerId={manufacturer.id}
      />
    );
  }

  if (
    reportType === ManufacturerReportType.AG_GRID &&
    "Report" in report &&
    report.Report
  ) {
    return (
      <ManufacturerReportGrid
        reportId={reportId}
        startDate={startDate}
        endDate={endDate}
        Report={report.Report}
        manufacturerId={manufacturer.id}
      />
    );
  }

  return <ManufacturerReportError />;
}
