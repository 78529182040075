import { FC } from "react";
import { PharmacyReport } from "./ManufacturerReports/reports/PharmacyReport/PharmacyReport";
import { FillSummary } from "./ManufacturerReports/reports/FillSummary/FillSummary";
// import { CouponAsPrimaryWithPreviousDetail } from "./ManufacturerReports/reports/CouponAsPrimaryWithPreviousDetail/CouponAsPrimaryWithPreviousDetail";
import { Occ8CouponContribution } from "./ManufacturerReports/reports/Occ8CouponContribution/Occ8CouponContribution";
import { AllClaimWithFillSummaryDispensedInProgress } from "./ManufacturerReports/reports/AllClaimWithFillSummaryDispensedInProgress/AllClaimWithFillSummaryDispensedInProgress";
import { AllFillsByStatusQuantityPayerDispensedInProgress } from "./ManufacturerReports/reports/AllFillsByPayerDispensedInProgress/AllFillsByStatusQuantityPayerDispensedInProgress/AllFillsByStatusQuantityPayerDispensedInProgress";
import { AllFillsByPayerStatusQuantityDispensedInProgress } from "./ManufacturerReports/reports/AllFillsByPayerDispensedInProgress/AllFillsByPayerStatusQuantityDispensedInProgress/AllFillsByPayerStatusQuantityDispensedInProgress";
import { AllFillsByPayerQuantityStatusDispensedInProgress } from "./ManufacturerReports/reports/AllFillsByPayerDispensedInProgress/AllFillsByPayerQuantityStatusDispensedInProgress/AllFillsByPayerQuantityStatusDispensedInProgress";
import { AllFillsByQuantityStatusPayerDispensedInProgress } from "./ManufacturerReports/reports/AllFillsByPayerDispensedInProgress/AllFillsByQuantityStatusPayerDispensedInProgress/AllFillsByQuantityStatusPayerDispensedInProgress";
import { AllClaimWithFillSummaryAbandonedTransferred } from "./ManufacturerReports/reports/AllClaimWithFillSummaryAbandonedTransferred/AllClaimWithFillSummaryAbandonedTransferred";
import { AllFillsByStatusQuantityPayerAbandonedTransferred } from "./ManufacturerReports/reports/AllFillsByPayerAbandonedTransferred/AllFillsByStatusQuantityPayerAbandonedTransferred/AllFillsByStatusQuantityPayerAbandonedTransferred";
import { AllFillsByPayerStatusQuantityAbandonedTransferred } from "./ManufacturerReports/reports/AllFillsByPayerAbandonedTransferred/AllFillsByPayerStatusQuantityAbandonedTransferred/AllFillsByPayerStatusQuantityAbandonedTransferred";
import { AllFillsByPayerQuantityStatusAbandonedTransferred } from "./ManufacturerReports/reports/AllFillsByPayerAbandonedTransferred/AllFillsByPayerQuantityStatusAbandonedTransferred/AllFillsByPayerQuantityStatusAbandonedTransferred";
import { AllFillsByQuantityStatusPayerAbandonedTransferred } from "./ManufacturerReports/reports/AllFillsByPayerAbandonedTransferred/AllFillsByQuantityStatusPayerAbandonedTransferred/AllFillsByQuantityStatusPayerAbandonedTransferred";
import { NonAdherentRefillOutreachReport } from "./ManufacturerReports/reports/NonAdherentRefillOutreach/NonAdherentRefillOutreachReport";

export enum ManufacturerReportType {
  AG_GRID = "AG_GRID",
  MODE = "MODE",
  COMING_SOON = "COMING SOON",
}

export type ManufacturerReportComponent =
  | FC<{ data: never[] }>
  | FC<{
      data: never[];
      exportCsvData: never[];
      exportAllCsvData: never[];
    }>;

export type ManufacturerReportItem = {
  key: string;
  label: string;
  reportId: number;
  reportType: ManufacturerReportType;
  Report?: ManufacturerReportComponent;
  iframeProps?: Record<string, any>;
};

type ManufacturerReportGroup = {
  key: string;
  label: string;
  children: (ManufacturerReportGroup | ManufacturerReportItem)[];
};

export type ManufacturerReport =
  | ManufacturerReportItem
  | ManufacturerReportGroup;

type BaseManufacturerReportItem = Omit<ManufacturerReportItem, "key" | "label">;
type BaseManufacturerReportGroup = Omit<
  ManufacturerReportGroup,
  "key" | "children"
> & {
  children: (BaseManufacturerReportItem | BaseManufacturerReportGroup)[];
};
export type BaseManufacturerReport =
  | BaseManufacturerReportItem
  | BaseManufacturerReportGroup;

export const MANUFACTURER_REPORTS: BaseManufacturerReport[] = [
  {
    reportId: 12, // Summary
    reportType: ManufacturerReportType.MODE,
  },
  {
    label: "All Fills",
    children: [
      {
        reportId: 13, // All Claims, Grouped by Fill for Dispensed and In Progress Fills
        reportType: ManufacturerReportType.AG_GRID,
        Report: AllClaimWithFillSummaryDispensedInProgress,
      },
      {
        reportId: 25, // All Claims, Grouped by Fill for Abandoned and Transferred Fills
        reportType: ManufacturerReportType.AG_GRID,
        Report: AllClaimWithFillSummaryAbandonedTransferred,
      },
      {
        reportId: 10, // Basic Data (Last Claim Only)
        reportType: ManufacturerReportType.AG_GRID,
        Report: FillSummary,
      },
    ],
  },
  {
    label: "All Fills by Payer",
    children: [
      {
        label: "Dispensed and In Progress Fills",
        children: [
          {
            reportId: 22, // By Dispensed Quantity \u2192 Dispensed Status \u2192 Payer
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByQuantityStatusPayerDispensedInProgress,
          },
          {
            reportId: 14, // By Dispensed Status \u2192 Dispensed Quantity \u2192 Payer
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByStatusQuantityPayerDispensedInProgress,
          },
          {
            reportId: 20, // By Payer \u2192 Dispensed Status \u2192 Dispensed Quantity
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByPayerStatusQuantityDispensedInProgress,
          },
          {
            reportId: 21, // By Payer \u2192 Dispensed Quantity \u2192 Dispensed Status
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByPayerQuantityStatusDispensedInProgress,
          },
        ],
      },
      {
        label: "Transferred and Abandoned Fills",
        children: [
          {
            reportId: 29, // By Dispensed Quantity \u2192 Dispensed Status \u2192 Payer
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByQuantityStatusPayerAbandonedTransferred,
          },
          {
            reportId: 26, // By Dispensed Status \u2192 Dispensed Quantity \u2192 Payer
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByStatusQuantityPayerAbandonedTransferred,
          },
          {
            reportId: 27, // By Payer \u2192 Dispensed Status \u2192 Dispensed Quantity
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByPayerStatusQuantityAbandonedTransferred,
          },
          {
            reportId: 28, // By Payer \u2192 Dispensed Quantity \u2192 Dispensed Status
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByPayerQuantityStatusAbandonedTransferred,
          },
        ],
      },
    ],
  },
  {
    label: "Coupon Card",
    children: [
      {
        reportId: 15, // OCC 8 Fills with Coupon Contribution - Dispensed Fills
        reportType: ManufacturerReportType.AG_GRID,
        Report: Occ8CouponContribution,
      },
      // report not generated after 2024-01-09 due to long processing time
      // {
      //   reportId: 23, // Coupon Card Used as Primary Payer - Dispensed Fills
      //   reportType: ManufacturerReportType.AG_GRID,
      //   Report: CouponAsPrimaryWithPreviousDetail,
      // },
    ],
  },
  {
    label: "Pharmacy",
    children: [
      {
        reportId: 31, // Summary
        reportType: ManufacturerReportType.MODE,
      },
      {
        reportId: 24, // Pharmacy Detail
        reportType: ManufacturerReportType.AG_GRID,
        Report: PharmacyReport,
      },
    ],
  },
  {
    reportId: 32, // Non-Adherent Covered Refill Outreach, by Pharmacy
    reportType: ManufacturerReportType.AG_GRID,
    Report: NonAdherentRefillOutreachReport,
  },
  {
    reportId: 33, // (Coming Soon) Refill Program Summary
    reportType: ManufacturerReportType.COMING_SOON,
  },
  {
    reportId: 34, // (Coming Soon)
    reportType: ManufacturerReportType.COMING_SOON,
  },
  {
    reportId: 38, // (Coming Soon)
    reportType: ManufacturerReportType.COMING_SOON,
  },
  {
    reportId: 39, // (Coming Soon)
    reportType: ManufacturerReportType.COMING_SOON,
  },
];
