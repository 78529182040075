import { useContext, useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import { useShoppingState } from "../../../contexts/ShoppingContext/ShoppingContext";
import EnhancedClientCommandContext, { EnhancedCartUpdate } from "../../../enhanced/EnhancedClientCommandContext";
import { SupplierItemChangeOperation } from "../../../utilities/shoppingListTypes";

export function useP4EnhancedCart(
  processCartChangeOperations: (
    operations: SupplierItemChangeOperation[]
  ) => void
) {
  const {
    addEnhancedShoppingCartChangeListener,
    removeEnhancedShoppingCartChangeListener,
    pushCompletedShoppingCartChange,
  } = useContext(EnhancedClientCommandContext);
  const { optimizeCartResponse } = useShoppingState();
  const [enhancedCartUpdate, setEnhancedCartUpdate] =
    useState<EnhancedCartUpdate | null>(null);

  useEffect(() => {
    if (
      enhancedCartUpdate &&
      enhancedCartUpdate.status === "waiting" &&
      !isEqual(enhancedCartUpdate.currState, optimizeCartResponse)
    ) {
      // forwarding markProcessed operations back to p4 results in an infinite loop
      if (enhancedCartUpdate.operations.filter((op) => op.type !== "markProcessed").length > 0) {
        pushCompletedShoppingCartChange({
          ...enhancedCartUpdate,
          status: "complete",
          currState: optimizeCartResponse,
        });
      }
      setEnhancedCartUpdate(null);
    }
  }, [enhancedCartUpdate, optimizeCartResponse]);

  useEffect(() => {
    console.log("Adding event listeners");
    const changeListener = (
      supplier: string,
      operations: SupplierItemChangeOperation[]
    ) => {
      setEnhancedCartUpdate({
        supplierName: supplier,
        operations: operations,
        status: "new",
      });
    };
    addEnhancedShoppingCartChangeListener(changeListener);

    return () => {
      removeEnhancedShoppingCartChangeListener(changeListener);
    };
  }, []);

  useEffect(() => {
    if (enhancedCartUpdate && enhancedCartUpdate.status === "new") {
      processCartChangeOperations(enhancedCartUpdate.operations);
      setEnhancedCartUpdate({
        ...enhancedCartUpdate,
        status: "waiting",
        currState: optimizeCartResponse,
      });
    }
  }, [enhancedCartUpdate, optimizeCartResponse]);
}
