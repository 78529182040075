import { css } from "twin.macro";

export const BLUE_1 = "#324DC7" as const;
export const BLUE_2 = "#0A1E79" as const;
export const BLUE_3 = "#B2F5ED" as const;
export const BLACK = "#202020" as const;
export const GREY_1 = "#726C66" as const;
export const GREY_2 = "#C7C7C7" as const;
export const GREY_3 = "#E8E6E6" as const;
export const GREY_4 = "#F5F5F5" as const;
export const GREY_5 = "#F1F4FB" as const;
export const GREEN_1 = "#49A900" as const;
export const GREEN_2 = "#D0EE1F" as const;
export const GREEN_3 = "#DBEECC" as const;
export const GREEN_4 = "#6A8575" as const;
export const GREEN_5 = "#259452" as const;
export const ORANGE_1 = "#FF6600" as const;
export const ORANGE_2 = "#FFA604" as const;
export const RED = "#C73232" as const;
export const WHITE = "#FFF" as const;
export const YELLOW = "#FFC009" as const;

export const COLORS = {
  "blue-1": BLUE_1,
  "blue-2": BLUE_2,
  "blue-3": BLUE_3,
  black: BLACK,
  "grey-1": GREY_1,
  "grey-2": GREY_2,
  "grey-3": GREY_3,
  "grey-4": GREY_4,
  "grey-5": GREY_5,
  "green-1": GREEN_1,
  "green-2": GREEN_2,
  "green-3": GREEN_3,
  "green-4": GREEN_4,
  "green-5": GREEN_5,
  "orange-1": ORANGE_1,
  "orange-2": ORANGE_2,
  red: RED,
  white: WHITE,
  yellow: YELLOW,
} as const;

export type RxColor = keyof typeof COLORS;

export function getColorCss(color?: RxColor) {
  const colorCode = color ? COLORS[color] : undefined;
  return css`
    color: ${colorCode ?? BLACK};
  `;
}

export function getBorderColorCss(color?: RxColor) {
  const colorCode = color ? COLORS[color] : undefined;
  if (!colorCode) return;
  return css`
    border-color: ${colorCode};
  `;
}

export function getBackgroundColorCss(color?: RxColor) {
  const colorCode = color ? COLORS[color] : undefined;
  if (!colorCode) return;
  return css`
    background-color: ${colorCode};
  `;
}
