import { useEffect, useState } from "react";
import "twin.macro";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";
import type { CatalogItemsMostRecentPurchase } from "utilities/types";
import type { ManufacturerStoreItem } from "../../Catalog.constants";
import { formatDrugNameWithDetails } from "utilities/drugInfo/formatDrugNameWithDetails";
import { Text } from "components/rxLibrary/typography";
import { Box } from "components/rxLibrary/box";
import { CatalogItem } from "../CatalogItem/CatalogItem";

type BuyItAgainItem = {
  item: ManufacturerStoreItem;
  originalInvoiceDate: string;
};

export function CatalogItemsBuyItAgain({
  shoppingListItems,
  mostRecentPurchases,
}: {
  shoppingListItems: ManufacturerStoreItem[];
  mostRecentPurchases?: CatalogItemsMostRecentPurchase[];
}) {
  const [items, setItems] = useState<BuyItAgainItem[]>();

  useEffect(() => {
    if (!mostRecentPurchases?.length || !shoppingListItems?.length) {
      setItems(undefined);
      return;
    }

    const itemsBySupplierItemNumber = keyBy(
      shoppingListItems,
      "catalogInfo.supplierItemNumber"
    );
    const newItems = mostRecentPurchases.map<BuyItAgainItem>((purchase) => {
      const { supplierItemNumber, originalInvoiceDate } = purchase;
      const item = itemsBySupplierItemNumber[supplierItemNumber];
      return {
        item,
        originalInvoiceDate,
      };
    });
    const sortedItems = sortBy(newItems, (item) => {
      return formatDrugNameWithDetails(item.item.drug);
    });
    setItems(sortedItems);
  }, [shoppingListItems, mostRecentPurchases]);

  if (!items?.length) return null;
  return (
    <Box bgColor="white" tw="rounded-lg px-[26px] pt-[33px] pb-[21px]">
      <Text tw="mb-[17px]" size={21} block>
        Buy It Again
      </Text>

      {items?.map(({ item, originalInvoiceDate }) => (
        <CatalogItem
          key={item.ndc}
          item={item}
          originalInvoiceDate={originalInvoiceDate}
        />
      ))}
    </Box>
  );
}
