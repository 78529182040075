import React, { useEffect, useMemo, useState } from "react";
import "twin.macro";
import keyBy from "lodash/keyBy";
import type { RefillItemDrugItem } from "./AcquisitionCost.utils";
import { getPharmacyRefillItemsDrugs } from "../../../../services/pharmacies";
import { useRequest } from "../../../../services/request/useRequest";
import { PharmacyRefillItem } from "../../../../utilities/types";
import { AcquisitionCostRefillItem } from "./AcquisitionCostRefillItem";
import { AcquisitionCostError } from "./AcquisitionCostError";
import { AcquisitionCostSkeleton } from "./AcquisitionCostSkeleton";

export function AcquisitionCostSupplier({
  supplierId,
  pharmacyId,
  refillItems,
  enableRefetchOnClose,
}: {
  supplierId: number;
  pharmacyId: number;
  refillItems?: PharmacyRefillItem[];
  enableRefetchOnClose: () => void;
}) {
  const [drugItems, setDrugItems] = useState<RefillItemDrugItem[]>();
  const config = useMemo(() => ({ supplierId }), [supplierId]);
  const { data, error, isLoading } = useRequest({
    config,
    dataKey: "drugs",
    request: getPharmacyRefillItemsDrugs,
  });

  useEffect(() => {
    if (!data) return setDrugItems(undefined);

    const refillItemByNdc = keyBy(refillItems ?? [], "ndc");
    const items = data.map<RefillItemDrugItem>((drug) => {
      const { ndc } = drug;
      const refillItem = refillItemByNdc[ndc];
      return { ndc, drug, hasRefillItem: !!refillItem, cost: refillItem?.cost };
    });

    setDrugItems(items);
  }, [data, refillItems]);

  if (error) {
    return <AcquisitionCostError />;
  }

  if (!drugItems?.length || isLoading) {
    return <AcquisitionCostSkeleton />;
  }

  return (
    <div tw="grid gap-4 xl:grid-cols-2">
      {drugItems?.map((item) => (
        <AcquisitionCostRefillItem
          key={item.ndc}
          drugItem={item}
          supplierId={supplierId}
          pharmacyId={pharmacyId}
          enableRefetchOnClose={enableRefetchOnClose}
        />
      ))}
    </div>
  );
}
