"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.endsWith = endsWith;
exports.matches = matches;
exports.startsWith = startsWith;
function matches(search, str) {
  return str.toLowerCase().indexOf(search.toLowerCase()) > -1;
}
function startsWith(str, search) {
  return str.indexOf(search) === 0;
}
function endsWith(str, search) {
  return str.indexOf(search, str.length - search.length) !== -1;
}
