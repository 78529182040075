import type {
  BlobPayloadData,
  CatalogInfo,
  ComparisonSummaryType,
  ComparisonType,
  DrugInfo,
  DrugInfoReference,
  EntityId, EntityReference, Prescription, PrescriptionGroupForOptimization,
  User
} from "../utilities/types";
import type {ShippingInfo} from "../utilities/shipping/shipping.constants";

export type OptimizationSelections = {
  comparisonSummary?: ComparisonSummaryType;
  supplierOrders: SupplierOrder[];
  unavailable: Unavailable[];
  unfilledFuturePrescriptionGroups: UnfilledFuturePrescriptionGroup[];
  visitedItemsIds?: string[];
}
export type OptimizationSelections_All = {
  comparisonSummary?: ComparisonSummaryType;
  supplierOrders: SupplierOrder_All[];
  unavailable: Unavailable_All[];
  unfilledFuturePrescriptionGroups: UnfilledFuturePrescriptionGroup_All[];
  visitedItemsIds: Record<string, boolean>;
}
export type OptimizeCartCustomerStatus =
  "pending"
  | "created"
  | "canceled"
  | "done";

export type OptimizeCartStatus = "pending" | "complete";
export type OptimizationReferenceData = {
  drugs: Record<EntityId, DrugInfo>;
  catalogItems: Record<EntityId, CatalogInfo>;
}

export type OptimizedCartResponse_All = {
  error?: {
    error?: {
      errorCode: string;
    };
  };
  data?: OptimizedCart_All
}

export type OptimizeCartRequestData = {
  allowedSupplierIds: number[];
  prescriptionGroups: PrescriptionGroupForOptimization[];
}

export type OptimizedCart = {
  id: number;
  schemaVersionNumber: number;
  createdBy: User;
  createdAt: string;
  updatedAt: string;
  status: OptimizeCartStatus;
  customerStatus: OptimizeCartCustomerStatus;
  cartSnapshot: BlobPayloadData,
  referenceData: OptimizationReferenceData,
  selections: OptimizationSelections,
  requestData: OptimizeCartRequestData,
}

export type OptimizedCart_All = {
  id: number;
  editVersion: number;
  schemaVersion: number;
  createdBy: User;
  createdAt: string;
  updatedAt: string;
  status: OptimizeCartStatus;
  customerStatus: OptimizeCartCustomerStatus;
  cartSnapshot: BlobPayloadData,
  referenceData: OptimizationReferenceData,
  selections: OptimizationSelections_All,
  requestData: OptimizeCartRequestData,

  prescriptions: Prescription[];
};

export type RecentOptimization = {
  id: number;
  createdBy: User;
  createdAt: string;
  updatedAt: string;
  customerStatus: OptimizeCartCustomerStatus;
  selections?: OptimizationSelections,
}
export type RecentOptimizationsRequest = {
  cartOptimizations: RecentOptimization[]
}
type CartOptimizationStatus = "pending" | "complete";
export type AsyncCartOptimizationRequestResponse = {
  data: {
    cartOptimization: {
      id: number;
      status: CartOptimizationStatus;
    };
  };
};
export type CartOptimizationRequest = {
  cartOptimization: OptimizedCart;
};

export type CatalogDrug = {
  numPackages: number;
  // TODO: check if we can remove this fields
  supplierId?: number;
  manufacturerChanged?: boolean;
  packSizeChanged?: boolean;
  drug: EntityReference,
  catalogInfo: EntityReference
}
export type CatalogDrug_All = CatalogDrug & {
  referenceData: {
    drugInfo: DrugInfo;
    catalogInfo: CatalogInfo;
  }
}

export const uniqueCatalogDrugID = (
  item: CatalogDrug_All | CatalogDrug
): string => {
  return `${item.drug.id}_${item.catalogInfo.id}`;
};

export type SupplierOrderItemRecommendation = CatalogDrug & {
  rxNumbers: string[];
  buyingReason?: "meet_min_ship" | "requested";
  numPackagesIncreased?: boolean;
  numPackagesIncreasedTo?: number;
  originalNumPackages?: number;
  alternatives: CatalogDrug[];
  originalDrugs?: { [key: string]: EntityReference};
  comparison?: ComparisonType;
};
export type SupplierOrderItemRecommendation_All = Omit<SupplierOrderItemRecommendation, "alternatives" | "originalDrugs"> &
  CatalogDrug_All & {
  alternatives: CatalogDrug_All[];
  originalDrugs?: { [key: string]: DrugInfoReference };
}

/**
 * shippingInfo: Detailed information on shipping conditions, which is updated not immediately after item data is updated
 */
export type SupplierOrder = {
  supplierId: number;
  shippingCost: number;
  buyingCost: number;
  shippingInfo?: ShippingInfo;
  items: SupplierOrderItemRecommendation[];
  increasedBasketWarnings?: {
    drugsWithAvailabilityLimitations: EntityReference[];
  };
}
export type SupplierOrder_All = Omit<SupplierOrder, "items" | "increasedBasketWarnings"> & {
  items: SupplierOrderItemRecommendation_All[];
  increasedBasketWarnings?: {
    drugsWithAvailabilityLimitations: DrugInfoReference[]
  }
}

export type UnfilledFuturePrescriptionGroup = {
  purchaseBy: string;
  numPackages: number;
  rxNumbers: string[];
  alternatives: CatalogDrug[];
  drug: EntityReference;
};

export type UnfilledFuturePrescriptionGroup_All = Omit<UnfilledFuturePrescriptionGroup, 'alternatives'> & {
  alternatives: CatalogDrug_All[];
  referenceData: { drugInfo: DrugInfo };
}
export type Unavailable = {
  purchaseBy: string | null;
  rxNumbers: string[];
  drug: EntityReference;
};
export type Unavailable_All = Unavailable & {
  referenceData: { drugInfo: DrugInfo };
}
