import React, { useCallback } from "react";
import "twin.macro";
import { useShoppingUpdater } from "../../../../../../contexts/ShoppingContext/ShoppingContext";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { Switch } from "../../../../../../components/rxLibrary/switch";
import { ItemInCart } from "../../../../../../utilities/types";

export function PrescriptionManufacturerSubstitution({
  prescription,
}: {
  prescription: ItemInCart;
}) {
  const checked = prescription.manufactutrer;

  const { updatePrescriptionManufacturer } = useShoppingUpdater();

  const updateManufacturer = useCallback(
    (newManufacturer: boolean) => {
      updatePrescriptionManufacturer(prescription, newManufacturer);
    },
    [prescription, updatePrescriptionManufacturer]
  );

  return (
    <label tw="flex items-center">
      <Switch checked={checked} onChange={updateManufacturer} />
      <Text tw="ml-1" weight="bold">
        Manufacturer
      </Text>
    </label>
  );
}
