import React from "react";
import tw, { styled } from "twin.macro";
import isNil from "lodash/isNil";
import { currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits } from "../../../utilities/numbers/currency";
import { DEFAULT_TEXT_CSS } from "../../rxLibrary/typography";
import { GREY_2 } from "../../rxLibrary/colors";
import { Box } from "../../rxLibrary/box";
import { Text } from "../../rxLibrary/typography";
import { IconCheck } from "../../rxLibrary/icons";
import { DATE_WITH_YEAR } from "../../../utilities/dates/dates.constants";
import { formatDateToFormat } from "../../../utilities/dates/formatDateToFormat";
import { useRefillOpportunities } from "../RefillOpportunitiesContext";

function Coverage({ coverage }: { coverage: string | null }) {
  if (isNil(coverage)) return null;

  if (coverage === "Yes") {
    return (
      <div tw="flex space-x-1">
        <IconCheck color="green-1" />
        <span>Covered</span>
      </div>
    );
  }
  return <span>Not covered</span>;
}

const Table = styled.table`
  width: 100%;
  margin-bottom: 90px;
  border-collapse: collapse;

  thead {
    box-shadow: 0 2px 4px 0 #00000030;
  }

  tr,
  th,
  td {
    ${DEFAULT_TEXT_CSS}
    border-collapse: collapse;
  }

  th {
    line-height: 1.14;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  td {
    vertical-align: top;
    border-top: 1px solid ${GREY_2};
    border-bottom: 1px solid ${GREY_2};
  }

  // Trick to make the box full height
  // reference: https://stackoverflow.com/questions/3215553/make-a-div-fill-an-entire-table-cell
  tr {
    height: 1px; // ignored
    td {
      height: inherit;

      ${Box} {
        height: 100%;
      }
    }
  }
`;

const Label = tw(
  Box
)`inline-flex rounded items-center justify-center w-[16px] h-[16px] mr-1`;

export function RefillOpportunitiesReportTable() {
  const { refillOpportunities } = useRefillOpportunities();

  return (
    <Table>
      <thead>
        <tr>
          <th tw="w-[259px] pr-[10px] pl-[20px]">Item</th>
          <th tw="w-[106px] px-[10px]">Manufacturer</th>
          <th tw="w-[64px] px-[10px]">Rx #</th>
          <th tw="w-[55px] px-[10px]">Patient</th>
          <th tw="w-[100px] px-[10px]">Est. Profit</th>
          <th tw="w-[100px] px-[10px]">Est. Revenue</th>
          <th tw="w-[100px] px-[10px]">Est. Cost</th>
          <th tw="w-[74px]  px-[10px]">Paid Last By Patient</th>
          <th tw="w-[90px] px-[10px]">Refill Due Date</th>
          <th tw="w-[116px] px-[10px]">Primary Payer</th>
          <th tw="w-[96px] px-[10px]">Coverage</th>
        </tr>
      </thead>

      <tbody>
        {refillOpportunities?.map((refillOpportunity) => {
          const {
            rxNumber,
            patient,
            primaryPayer,
            refillDueDate,
            estimatedCost,
            estimatedProfit,
            estimatedRevenue,
            lastPatientPaidAmt,
            lastDispensedDrugName,
            lastDispensedManufacturer,
            coveredByPrimaryWNonzeroRemit,
          } = refillOpportunity;

          const refillDueDateFormatted = formatDateToFormat(
            refillDueDate,
            DATE_WITH_YEAR
          );
          const estProfit =
            currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(
              estimatedProfit
            );
          const estRevenue =
            currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(
              estimatedRevenue
            );
          const estCost =
            currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(
              estimatedCost
            );
          const paidLastByPatient =
            currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(
              lastPatientPaidAmt
            );

          return (
            <tr key={rxNumber}>
              <td tw="py-[9px] pl-[10px] pr-[0px]">
                <Box
                  bgColor="grey-5"
                  tw="py-[6px] pl-[10px] pr-[10px] rounded-l"
                >
                  <Text weight="bold">{lastDispensedDrugName}</Text>
                </Box>
              </td>
              <td tw="px-[0px] py-[9px]">
                <Box bgColor="grey-5" tw="py-[6px] px-[10px]">
                  <Text weight="bold">{lastDispensedManufacturer}</Text>
                </Box>
              </td>
              <td tw="px-[0px] py-[9px]">
                <Box bgColor="grey-5" tw="py-[6px] px-[10px]">
                  {rxNumber}
                </Box>
              </td>
              <td tw="px-[0px] py-[9px]">
                <Box bgColor="grey-5" tw="py-[6px] px-[10px]">
                  {patient}
                </Box>
              </td>
              <td tw="pl-[0px] pr-[5px] py-[9px]">
                <Box
                  bgColor="grey-5"
                  tw="py-[6px] pl-[10px] pr-[10px] rounded-r"
                >
                  <Label bgColor="yellow">P</Label>
                  <span>{estProfit}</span>
                </Box>
              </td>
              <td tw="py-[15px] px-[10px]">
                <Label bgColor="blue-3">R</Label>
                <span>{estRevenue}</span>
              </td>
              <td tw="py-[15px] px-[10px]">
                <Label bgColor="green-3">C</Label>
                <span>{estCost}</span>
              </td>
              <td tw="py-[15px] px-[10px]">{paidLastByPatient}</td>
              <td tw="py-[15px] px-[10px]">{refillDueDateFormatted || ""}</td>
              <td tw="py-[15px] px-[10px]">{primaryPayer}</td>
              <td tw="py-[15px] px-[10px]">
                <Coverage coverage={coveredByPrimaryWNonzeroRemit} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
