import dayjs from "dayjs";
import { CatalogInfo } from "../types";

export function formatDrugExpirationDate(catalogInfo?: CatalogInfo) {
  const { expirationDate } = catalogInfo ?? {};
  if (!expirationDate) return;

  const currentTime = dayjs();
  const expirationDateObj = dayjs(expirationDate);
  const timeBetween = expirationDateObj.diff(currentTime, "days");

  if (timeBetween > 365) return;
  const result =
    expirationDateObj.format("M") + "/" + expirationDateObj.format("YY");

  return result;
}
