import React, {FC, useMemo} from "react";
import {css, styled} from "twin.macro";
import cn from "classnames";
import pluralize from "pluralize";
import dayjs, {Dayjs} from "dayjs";
import {DispensesStatsData, DrugWithStats} from "../../../../../utilities/types";
import {getDispensesStatsGroup} from "../../../../../utilities/drugInfo/drugInfo";
import {dayjsWithMinMax} from "../../../../../utilities/dates/dayjsWithMinMax";
import {dayjsWithDuration} from "../../../../../utilities/dates/dayjsWithDuration";

export const CancelBtn = styled.button`
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.07px;
  color: #1d1d1d;
  text-decoration: underline;
`;

export const ModalContent = styled.div(
  ({ screen }: { screen: number }) => css`
    width: 1200px;
    margin: 16px auto;
    height: calc(100vh - 32px);
    overflow-y: auto;
    background-color: ${screen === 1 ? "#fff" : "#f5f5f5"};
  `
);

export const MediumParagraph: FC = ({ children }) => (
  <p className="font-500 ml-4">{children}</p>
);

export function DispensesStatsRow({
  prescription,
}: {
  prescription: DrugWithStats;
}) {
  const { unitsNum, rxNum, unit, type } = useMemo(() => {
    let unit = "";
    let type = "";
    let states: DispensesStatsData = {
      numberOfPrescriptions: 0,
      numberOfUnits: 0,
    };
    const states30 = getDispensesStatsGroup(prescription, "last30Days");
    const states90 = getDispensesStatsGroup(prescription, "last90Days");
    const states180 = getDispensesStatsGroup(prescription, "last180Days");
    if (states30.numberOfPrescriptions !== 0) {
      type = "last30Days";
      states = states30;
    } else if (states90.numberOfPrescriptions !== 0) {
      type = "last90Days";
      states = states90;
    } else if (states180.numberOfPrescriptions === 0) {
      type = "last180Days";
      states = states180;
    }

    if (prescription.drug.uom === "Each") {
      unit = prescription.drug.form;
    } else if (prescription.drug.packageDescription === "Box") {
      if (prescription.drug.form === "Granules") {
        unit = "Box";
      } else {
        unit = prescription.drug.form;
      }
    } else {
      unit = prescription.drug.packageDescription;
    }

    const unitsNum = states.numberOfUnits;
    const rxNum = states.numberOfPrescriptions;

    return { unitsNum, rxNum, unit, type };
  }, [prescription]);

  if (rxNum === 0) return <p>Not dispensed in last 6 months</p>;
  return (
    <>
      <p className="text-sm">
        {" "}
        <span className="font-500">
          {rxNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          {rxNum > 1 ? ` Rxs` : ` Rx`} (
          {unitsNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          {pluralize(unit, unitsNum)}){" "}
        </span>
      </p>
      <p>Dispensed in the past {type.match(/\d+/)} days</p>
    </>
  );
}

function LastDispensesDate({ items }: { items: DrugWithStats[] }) {
  const text = useMemo(() => {
    const mostDistantDate = items.reduce<Dayjs | undefined>((date, item) => {
      const newDateStr = item.mostRecentlyDispensedDate;
      if (!newDateStr) return date;

      const newDate = dayjs(newDateStr);
      if (!date) return newDate;

      const maxDate = dayjsWithMinMax.max(newDate, date);
      return maxDate;
    }, undefined);

    if (!mostDistantDate) return;
    const days = dayjsWithDuration
      .duration(dayjs().diff(mostDistantDate))
      .days();

    let daysAgo = `${days} days ago`;
    if (days === 0) daysAgo = "today";
    else if (days === 1) daysAgo = "yesterday";

    return ` - Last dispensed ${daysAgo}`;
  }, [items]);

  if (!text) return null;
  return <span>{text}</span>;
}

export function DispensesStatsTitle({
  items,
  type,
  tbl = false,
}: {
  items: DrugWithStats[];
  type: string;
  tbl?: boolean;
}) {
  const { rxNum, unitsNum, unit } = useMemo(() => {
    let rxNum = 0;
    let unitsNum = 0;
    items.forEach((item) => {
      const states = getDispensesStatsGroup(item, type);
      rxNum += states.numberOfPrescriptions;
      unitsNum += states.numberOfUnits;
    });

    let unit = "";
    if (items[0].drug.uom === "Each") {
      unit = items[0].drug.form;
    } else if (items[0].drug.packageDescription === "Box") {
      if (items[0].drug.form === "Granules") {
        unit = "Box";
      } else {
        unit = items[0].drug.form;
      }
    } else {
      unit = items[0].drug.packageDescription;
    }

    return { rxNum, unitsNum, unit };
  }, [items, type]);

  if (rxNum === 0) return <p />;
  return (
    <>
      <p className="text-sm">
        {" "}
        <span className={cn(tbl && "font-500")}>
          {rxNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          {rxNum > 1 ? ` Rxs` : ` Rx`} (
          {unitsNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          {pluralize(unit, unitsNum)}){" "}
        </span>
        {!tbl && `dispensed in the past ${type.match(/\d+/)} days`}
        {!tbl && <LastDispensesDate items={items} />}
      </p>
      {tbl && <p>Dispensed in the past {type.match(/\d+/)} days</p>}
    </>
  );
}
