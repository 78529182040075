"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchSSOData = fetchSSOData;
var _web_api = _interopRequireDefault(require("../web_api"));
var _cache = _interopRequireDefault(require("../../utils/cache"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var cache = new _cache.default(function () {
  return _web_api.default.getSSOData.apply(_web_api.default, arguments);
});
function fetchSSOData(id) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }
  cache.get.apply(cache, [id].concat(args));
}
