import "twin.macro";
import { Collapse, Form, Tooltip } from "antd";
import MinusOutlined from "@ant-design/icons/MinusOutlined";
import type { ShippingConfig } from "../../../../../../../utilities/types";
import { Button } from "../../../../../../../components/rxLibrary/buttons";
import { Text } from "../../../../../../../components/rxLibrary/typography";
import { IconPlus } from "../../../../../../../components/rxLibrary/icons";
import { DefaultShippingConfig } from "./DefaultShippingConfig";
import { ShippingConfigFormCategory } from "./ShippingConfigFormCategory";
import React, { useCallback } from "react";
import {
  CLEAN_FROM_SHIPPING_CONFIG,
  formatFormShippingConfig,
} from "../AdminSupplierMappingForm.utils";

export function AdminSupplierMappingFormShippingConfig({
  supplierId,
  timezoneWarning,
  defaultShippingConfig,
  shippingConfigOverrideIsVisible,
  showShippingConfigOverride,
  hideShippingConfigOverride,
}: {
  supplierId?: number;
  timezoneWarning?: string;
  defaultShippingConfig?: ShippingConfig;
  shippingConfigOverrideIsVisible: boolean;
  showShippingConfigOverride: () => void;
  hideShippingConfigOverride: () => void;
}) {
  const form = Form.useFormInstance();

  const addShippingConfigOverride = useCallback(() => {
    const newShippingConfig = defaultShippingConfig
      ? formatFormShippingConfig(defaultShippingConfig)
      : CLEAN_FROM_SHIPPING_CONFIG;
    form.setFieldValue("shippingConfig", newShippingConfig);
    showShippingConfigOverride();
  }, [form, defaultShippingConfig, showShippingConfigOverride]);

  const removeShippingConfigOverride = useCallback(() => {
    form.setFieldValue("shippingConfig", CLEAN_FROM_SHIPPING_CONFIG);
    hideShippingConfigOverride();
  }, [form, hideShippingConfigOverride]);

  if (shippingConfigOverrideIsVisible) {
    return (
      <>
        <ShippingConfigFormCategory timezoneWarning={timezoneWarning} />

        <Collapse
          ghost
          size="small"
          tw="mb-4"
          items={[
            {
              key: "defaultShippingConfig",
              label: "Default Shipping Config",
              children: (
                <>
                  <Text tw="mb-4" color="yellow" block>
                    Warning: <strong>Shipping Config Override</strong> complete
                    overrides all the{" "}
                    <strong>Default Supplier Shipping Config</strong> fields.
                  </Text>

                  <DefaultShippingConfig
                    timezoneWarning={timezoneWarning}
                    defaultShippingConfig={defaultShippingConfig}
                  />
                </>
              ),
            },
          ]}
        />

        <Button
          variant="secondary"
          icon={<MinusOutlined rev={undefined} />}
          onClick={removeShippingConfigOverride}
        >
          Remove Shipping Config Override
        </Button>
      </>
    );
  }

  return (
    <>
      <DefaultShippingConfig
        timezoneWarning={timezoneWarning}
        defaultShippingConfig={defaultShippingConfig}
      />

      <Tooltip
        title={
          !supplierId &&
          "Please select a supplier to add a shipping config override"
        }
      >
        <Button
          variant="secondary"
          icon={<IconPlus />}
          onClick={addShippingConfigOverride}
          disabled={!supplierId}
        >
          Add Shipping Config Override
        </Button>
      </Tooltip>
    </>
  );
}
