import cloneDeep from "lodash/cloneDeep";
import {
  Prescription,
  ItemPurchaseDetails,
  ItemInCart, EntityId, DrugInfo,
} from "../../../utilities/types";
import {isInventoryId} from "../../../utilities/prescriptions/isInventoryId";

export function mergePmsDataWithCartData(
  prescriptions: Prescription[],
  cartData: ItemPurchaseDetails[],
  referenceData?: Record<EntityId, DrugInfo>): ItemInCart[] {
  const seenRxNumbers = new Set<string>();
  const newItems = prescriptions.map((prescription) => {
    seenRxNumbers.add(prescription.rxNumber);

    const clonedPrescription = cloneDeep(prescription);
    const cartObj = cartData.find(
      (item) => item.id === clonedPrescription.rxNumber
    );
    const eoh = clonedPrescription.eoh || 0;
    const boh = clonedPrescription.boh || 0;

    if (cartObj) {
      return {
        ...clonedPrescription,
        eoh,
        boh,
        packSize: cartObj.packSize,
        manufactutrer: cartObj.manufactutrer,
        status: cartObj.status,
        currentFillId: cartObj.currentFillId ? cartObj.currentFillId : "",
        processedAt: cartObj.processedAt,
        supplierId: cartObj.supplierId,
        note: cartObj.note,
        purchaseBy: cartObj.purchaseBy,
      };
    } else {
      const manufactutrer = !(
        clonedPrescription.drug.isBrand ||
        clonedPrescription.drug.isNarrowTherapeuticIndex
      );

      return {
        ...clonedPrescription,
        eoh,
        boh,
        status: "add",
        packSize: true,
        manufactutrer,
      };
    }
  });

  // When loading from a past snapshot, we may have items in the cart that are not in the prescriptions
  // In that case, create a Prescription object that tells the user to see the PMS for more details
  if (referenceData) {
    cartData
      .filter((item) => !isInventoryId(item.id))
      .filter((item) => !seenRxNumbers.has(item.id))
      .forEach((item) => {
        const manufactutrer = item.manufactutrer;
        const unavailablePrescription: Prescription = {
          rxNumber: item.id,
          eoh: 0,
          boh: 0,
          currentFillId: item.currentFillId ? item.currentFillId : "",
          drug: referenceData[item.ndc],
          dawCode: null,
          dispensedQuantity: 0,
          fillDate: new Date().toString(),
          patient: {
            shortName: "See PMS",
          },
          prescriber: {
            lastName: "See PMS",
          },
          workflowDate: new Date().toString(),
        };

        newItems.push({
          ...unavailablePrescription,
          rxNumber: item.id,
          eoh: 0,
          boh: 0,
          packSize: item.packSize,
          manufactutrer,
          status: "add",
          currentFillId: item.currentFillId ? item.currentFillId : "",
          processedAt: item.processedAt,
          supplierId: item.supplierId,
          note: item.note,
          purchaseBy: item.purchaseBy,
        });
      });
  }

  return newItems;
}
