import React, { ComponentProps } from "react";
import "twin.macro";
import { Dropdown as BaseDropdown, MenuProps } from "antd";
export type { MenuProps } from "antd";
import { Global, css } from "@emotion/react";
import { DEFAULT_TEXT_CSS } from "./typography";

const RX_DROPDOWN_OVERLAY_CLASS_NAME = "rx-dropdown-overlay";

export type DropdownMenuOptions = NonNullable<
  NonNullable<MenuProps["items"]>[number]
>[];

export function Dropdown(
  props: Omit<ComponentProps<typeof BaseDropdown>, "overlayClassName">
) {
  return (
    <>
      <BaseDropdown
        trigger={["click"]}
        overlayClassName={RX_DROPDOWN_OVERLAY_CLASS_NAME}
        destroyPopupOnHide
        tw="rounded-lg"
        {...props}
      />
      <Global styles={GLOBAL_CSS} />
    </>
  );
}

const GLOBAL_CSS = css`
  .${RX_DROPDOWN_OVERLAY_CLASS_NAME} {
    span {
      ${DEFAULT_TEXT_CSS}
    }
  }
`;
