import React, { ComponentProps, useMemo } from "react";
import { styled } from "twin.macro";
import { Text } from "../typography";
import { GREY_3 } from "../colors";
import { Select } from "./select";

type BaseSelectProps = ComponentProps<typeof Select>;

type QtyOption = Omit<
  NonNullable<BaseSelectProps["options"]>[number],
  "value"
> & {
  value: number;
};

type QuantitiesConfig = {
  maxQty?: number;
  bundleSize?: number;
  includeZero?: boolean;
  zeroLabel?: string;
  multiplesOfBundleSize?: boolean;
  directDealLabel?: boolean;
};

type SelectProps =
  | Omit<BaseSelectProps, "value" | "options" | "onChange" | "mode"> & {
      value?: number;
      vertical?: boolean;
      options?: QtyOption[];
      quantitiesConfig?: QuantitiesConfig;
      onChange?: (value: number) => void;
    };

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: col;
  align-items: center;
  padding: 10px 6px;
  border-radius: 6px;
  column-gap: 7px;
  background-color: ${GREY_3};
`;

function getQtyOptions(includeZero?: boolean, zeroLabel?: string, maxQty = 120): QtyOption[] {
  const arr = [...Array(maxQty)].map((_, index) => {
    const value = index + 1;
    return { label: value.toString(), value };
  });

  if (includeZero) {
    arr.unshift({ label: zeroLabel || "0", value: 0 });
  }

  return arr;
}

function formatQuantityOptions(quantitiesConfig?: QuantitiesConfig) {
  const {
    maxQty,
    zeroLabel,
    bundleSize,
    includeZero,
    directDealLabel,
    multiplesOfBundleSize,
  } = quantitiesConfig ?? {};

  let items = getQtyOptions(includeZero, zeroLabel, maxQty);

  if (!bundleSize) return items;

  if (multiplesOfBundleSize) {
    items = items.filter((item) => {
      const value = Number(item.value);
      if (value === 0) return true;
      return value % bundleSize === 0;
    });
  }

  if (directDealLabel) {
    items = items.map((item) => {
      const value = Number(item.value);
      if (value === 0 || value % bundleSize !== 0) return item;
      return { ...item, label: `${item.label} - Manuf direct` };
    });
  }

  return items;
}

export function QtySelect({
  options,
  vertical,
  quantitiesConfig,
  ...props
}: SelectProps) {
  const onChange = props.onChange as BaseSelectProps["onChange"];

  const formattedOptions = useMemo(() => {
    if (options) return options;

    const newOptions = formatQuantityOptions(quantitiesConfig);
    return newOptions;
  }, [options, quantitiesConfig]);

  const select = (
    <Select
      tw="min-w-[64px]"
      {...props}
      options={formattedOptions}
      onChange={onChange}
    />
  );

  if (vertical) {
    return (
      <div tw="flex flex-col items-center space-y-[3px]">
        <Text weight="bold" block>
          Qty
        </Text>
        {select}
      </div>
    );
  }

  return (
    <Wrapper>
      <Text>Qty</Text>
      <div>{select}</div>
    </Wrapper>
  );
}
