import {
  ReactNode,
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import "twin.macro";
import dayjs, { Dayjs } from "dayjs";
import intersection from "lodash/intersection";
import type { Pharmacy } from "../../utilities/types";
import PharmaciesContext from "../../contexts/PharmaciesContext";
import { getLocalStorageValue } from "../../utilities/localStorage/getLocalStorageValue";
import { setLocalStorageValue } from "../../utilities/localStorage/setLocalStorageValue";
import { useBuyingPharmacy } from "../../contexts/BuyingPharmacyContext";
import { FullPageLoader } from "../../components/loaders/FullPageLoader";
import { ReportError } from "../../components/errors/ReportError";
import { ErrorMessage } from "../../components/errors/ErrorMessage";
import { EmailLink } from "../../components/rxLibrary/Link";

const PerformanceContext = createContext<
  | {
      toDate: Dayjs;
      fromDate: Dayjs;
      reportId: number;
      reports: Pharmacy["reports"];
      selectedPharmacies: number[];
      setToDate: (toDate: Dayjs) => void;
      setFromDate: (fromDate: Dayjs) => void;
      setReportId: (reportId?: number) => void;
      setSelectedPharmacies: (pharmacyId: number[]) => void;
    }
  | undefined
>(undefined);

export function PerformanceContextProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const [fromDate, setFromDate] = useState(() => dayjs().subtract(7, "day"));
  const [toDate, setToDate] = useState(() => dayjs().subtract(1, "day"));
  const [_reportId, _setReportId] = useState<number>();
  const [_selectedPharmacies, _setSelectedPharmacies] = useState<number[]>();

  const { pharmacies } = useContext(PharmaciesContext);
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const reports = currentBuyingPharmacy?.reports;

  const reportId = useMemo(() => {
    return _reportId || reports?.[0]?.id;
  }, [_reportId, reports?.[0]?.id]);

  const selectedPharmacies = useMemo(() => {
    if (_selectedPharmacies?.length) return _selectedPharmacies;
    if (pharmacies?.[0]?.id) return [pharmacies?.[0]?.id];
  }, [_selectedPharmacies, pharmacies?.[0]?.id]);

  const setReportId = useCallback((value?: number) => {
    _setReportId(value);
    setLocalStorageValue("LastReportKey", value);
  }, []);

  const setSelectedPharmacies = useCallback((value: number[]) => {
    _setSelectedPharmacies(value);
    setLocalStorageValue("LastReportPharmacies", value);
  }, []);

  useEffect(() => {
    if (!reports?.length) return;
    const reportIdLs = getLocalStorageValue<number | undefined>(
      "LastReportKey",
      undefined
    );
    const hasReportId = reportIdLs && reports.some((r) => r.id === reportIdLs);
    const initialReportId = hasReportId ? reportIdLs : undefined;
    _setReportId(initialReportId);
  }, [reports]);

  useEffect(() => {
    if (!pharmacies.length) return;

    const selectedPharmaciesLs = getLocalStorageValue<number[] | undefined>(
      "LastReportPharmacies",
      undefined
    );

    let hasPharmacies = false;
    if (selectedPharmaciesLs?.length) {
      const pharmacyIds = pharmacies.map((p) => p.id);
      hasPharmacies =
        intersection(selectedPharmaciesLs, pharmacyIds).length ===
        selectedPharmaciesLs.length;
    }

    const initialSelectedPharmacies = hasPharmacies
      ? selectedPharmaciesLs
      : undefined;
    _setSelectedPharmacies(initialSelectedPharmacies);
  }, [pharmacies]);

  if (!reports || !pharmacies) {
    return <FullPageLoader text="Loading Reports" />;
  }

  if (reports.length === 0) {
    return (
      <ErrorMessage tw="h-[100dvh]">
        Your account is not set up to show performance reports. Please contact{" "}
        <EmailLink email="support@daylightrx.com" /> to add reporting to your
        account.
      </ErrorMessage>
    );
  }

  if (!reportId || !selectedPharmacies?.length) {
    return <ReportError tw="h-[100dvh]" />;
  }

  return (
    <PerformanceContext.Provider
      value={{
        toDate,
        reports,
        fromDate,
        reportId,
        selectedPharmacies,
        setToDate,
        setFromDate,
        setReportId,
        setSelectedPharmacies,
      }}
    >
      {children}
    </PerformanceContext.Provider>
  );
}

export function usePerformance() {
  const context = useContext(PerformanceContext);
  if (!context) {
    throw new Error(
      "usePerformance must be used within a PerformanceContextProvider"
    );
  }
  return context;
}
