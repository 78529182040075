"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.displayName = displayName;
exports.url = url;
var _blueimpMd = _interopRequireDefault(require("blueimp-md5"));
var _trim = _interopRequireDefault(require("trim"));
var _jsonp_utils = _interopRequireDefault(require("../utils/jsonp_utils"));
var _email = require("../field/email");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var md5 = _blueimpMd.default.md5 || _blueimpMd.default;
function normalize(str) {
  return typeof str === 'string' ? (0, _trim.default)(str.toLowerCase()) : '';
}
function displayName(email, cb) {
  email = normalize(email);
  if (!(0, _email.validateEmail)(email)) return cb({});
  var url = "https://secure.gravatar.com/".concat(md5(email), ".json");
  _jsonp_utils.default.get(url, function (error, result) {
    if (!error && result && result.entry && result.entry[0]) {
      cb(null, result.entry[0].displayName);
    } else {
      cb({});
    }
  });
}
function url(email, cb) {
  email = normalize(email);
  if (!(0, _email.validateEmail)(email)) return cb({});
  cb(null, "https://secure.gravatar.com/avatar/".concat(md5(email), "?d=404&s=160"));
}
