import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { styled } from "twin.macro";
import "core-js/actual/set-immediate";
import { Form } from "antd";
import TextArea, { TextAreaRef } from "antd/es/input/TextArea";
import { ItemInCart, ItemNote } from "../../utilities/types";
import { formatDateToDayWithTime } from "../../utilities/dates/formatDateToDayWithTime";
import { Button, TrashButton } from "../rxLibrary/buttons";
import { Text } from "../rxLibrary/typography";
import { GREY_4, GREY_3, WHITE } from "../rxLibrary/colors";

export function PrescriptionNotePopover({
  prescription,
  close,
  updatePrescriptionNote,
}: {
  prescription: ItemInCart;
  close: () => void;
  updatePrescriptionNote: (prescription: ItemInCart, note?: ItemNote) => void;
}) {
  const {
    message,
    createdAt = new Date(),
    updatedAt,
  } = prescription.note ?? {};
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const textAreaRef = useTextAreaAutoFocus();
  const hasMessage = !!message;
  const createdAtStr = formatDateToDayWithTime(createdAt);
  const updatedAtStr = formatDateToDayWithTime(updatedAt);

  function openOverlay(e: MouseEvent) {
    e.preventDefault();
    setIsOverlayOpen(true);
  }

  function closeOverlay(e: MouseEvent) {
    e.stopPropagation();
    setIsOverlayOpen(false);
  }

  function deleteNote() {
    updatePrescriptionNote(prescription);
    close();
  }

  function updateNote({ message }: { message: string }) {
    const note: ItemNote = {
      message,
      createdAt: createdAt ?? new Date(),
      updatedAt: new Date(),
    };
    updatePrescriptionNote(prescription, note);
    close();
  }

  return (
    <Wrapper>
      <Form
        onFinish={updateNote}
        requiredMark={false}
        initialValues={{ message }}
      >
        <Header>
          <Text>
            Added: {createdAtStr}
            {!!updatedAtStr &&
              createdAtStr !== updatedAtStr &&
              `, Updated: ${updatedAtStr}`}
          </Text>
        </Header>

        <Form.Item
          name="message"
          rules={[{ required: true, whitespace: true }]}
        >
          <TextArea
            ref={textAreaRef}
            bordered={false}
            autoSize={{ minRows: 3 }}
          />
        </Form.Item>

        <Footer>
          <div>{hasMessage && <TrashButton onClick={openOverlay} />}</div>
          <div>
            <Button variant="text-1" onClick={close}>
              Cancel
            </Button>
            <Button variant="tertiary" type="submit">
              {hasMessage ? "Update" : "Apply"}
            </Button>
          </div>
        </Footer>
      </Form>

      {isOverlayOpen && (
        <Overlay>
          <Text weight="bold" size="medium" block>
            Delete note?
          </Text>
          <OverlayActions>
            <Button variant="text-1" onClick={closeOverlay}>
              Cancel
            </Button>
            <Button variant="tertiary" onClick={deleteNote}>
              Delete
            </Button>
          </OverlayActions>
        </Overlay>
      )}
    </Wrapper>
  );
}

function useTextAreaAutoFocus() {
  const textAreaRef = useRef<TextAreaRef>(null);

  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      setImmediate(() => {
        // focus at the end of the text
        const end = textArea?.resizableTextArea?.textArea?.value?.length ?? 0;
        textArea?.resizableTextArea?.textArea?.setSelectionRange(end, end);
        textArea?.resizableTextArea?.textArea?.focus();
      });
    }
  }, []);

  return textAreaRef;
}

const Wrapper = styled.div`
  width: 310px;
  position: relative;

  .ant-form-item-explain {
    margin-left: 11px;

    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

const Header = styled.div`
  padding: 12px 17px;
  background-color: ${GREY_4};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 14px;
  align-items: center;
  border-top: 1px solid ${GREY_3};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${WHITE};
  opacity: 0.9;
`;

const OverlayActions = styled.div`
  display: flex;
  padding-top: 13px;
  margin-top: 13px;
  border-top: 1px solid ${GREY_3};
`;
