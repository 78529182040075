import { useEffect, useState } from "react";
import "twin.macro";
import Highlight from "react-highlighter";
import type { CatalogItemsSearchBarItem } from "../../Catalog.constants";
import { Image } from "../../../../../../components/Image";
import { IconMagnify } from "../../../../../../components/rxLibrary/icons";
import { YELLOW } from "../../../../../../components/rxLibrary/colors";
import {
  AutoComplete,
  AutoCompleteOption,
  AutoCompleteInputSearch,
} from "../../../../../../components/antd/AutoComplete";

export function CatalogItemsSearchBar({
  filter,
  searchBarItems,
  setFilter,
  setIsSearchBarOpen,
}: {
  filter: string;
  searchBarItems?: CatalogItemsSearchBarItem[];
  setFilter: (value: string) => void;
  setIsSearchBarOpen: (value: boolean) => void;
}) {
  const [options, setOptions] = useState<AutoCompleteOption[]>([]);

  useEffect(() => {
    if (!searchBarItems?.length) {
      setOptions([]);
      return;
    }

    const newOptions = searchBarItems.map(({ drugName, imageURI }) => ({
      value: drugName,
      label: (
        <div key={drugName + imageURI} tw="flex items-center space-x-[8px]">
          <Image tw="w-[54px] object-contain" src={imageURI} />
          <Highlight search={filter} matchStyle={{ backgroundColor: YELLOW }}>
            {drugName}
          </Highlight>
        </div>
      ),
    }));
    setOptions(newOptions);
  }, [searchBarItems]);

  return (
    <AutoComplete
      tw="flex-1"
      value={filter}
      options={options}
      onChange={setFilter}
      onDropdownVisibleChange={setIsSearchBarOpen}
      popupMatchSelectWidth
    >
      <AutoCompleteInputSearch
        type="text"
        size="large"
        placeholder="Name, NDC"
        enterButton={<IconMagnify />}
        allowClear
      />
    </AutoComplete>
  );
}
