import React from "react";
import { styled } from "twin.macro";
import { BLACK } from "../../../../../../components/rxLibrary/colors";

const Visited = styled.span`
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${BLACK};
`;

const Counter = styled.span`
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${BLACK};
`;

export function ShoppingListTableHeaderVisitedItems({
  visitedItemsCount,
  itemsCount,
}: {
  visitedItemsCount?: number;
  itemsCount: number;
}) {
  if (!visitedItemsCount) return null;
  return (
    <p>
      <Visited>Visited </Visited>
      <Counter>
        {visitedItemsCount} OF {itemsCount} ITEMS
      </Counter>
    </p>
  );
}
