import React, { useCallback, useState } from "react";
import "twin.macro";
import { Button } from "../../rxLibrary/buttons";
import { IconSave } from "../../rxLibrary/icons";
import { displayErrorModal } from "../../../utilities/errorModals/displayErrorModal";
import { getReportRefillOpportunity } from "../../../services/report";
import { useRequestClient } from "../../../services/request/requestClient";
import { downloadJsonLikeCsv } from "../../../utilities/csv/downloadJsonLikeCsv";
import { useRefillOpportunities } from "../RefillOpportunitiesContext";

const CSV_HEADERS = [
  { key: "lastDispensedDrugName", label: "Item" },
  { key: "lastDispensedManufacturer", label: "Manufacturer" },
  { key: "rxNumber", label: "Rx #" },
  { key: "patient", label: "Patient" },
  { key: "estimatedProfit", label: "Est. Profit" },
  { key: "estimatedRevenue", label: "Est. Revenue" },
  { key: "estimatedCost", label: "Est. Cost" },
  { key: "lastPatientPaidAmt", label: "Paid Last By Patient" },
  { key: "refillDueDate", label: "Refill Due Date" },
  { key: "primaryPayer", label: "Primary Payer" },
  { key: "coveredByPrimaryWNonzeroRemit", label: "Covered" },
  { key: "lastDispensedQuantity", label: "Last Dispensed Quantity" },
  { key: "rxStatus", label: "Rx Status" },
  { key: "fillStatus", label: "Fill Status" },
  { key: "estimatedCost", label: "Estimated Cost" },
  { key: "autoRefillOn", label: "Auto Refill On" },
  { key: "lastSoldDate", label: "Last Sold Date" },
  { key: "daysSupply", label: "Days Supply" },
  { key: "daysSupplyEnds", label: "Days Supply Ends" },
  { key: "dateWritten", label: "Date Written" },
  { key: "lastDateFilled", label: "Last Date Filled" },
  { key: "refillsRemaining", label: "Refills Remaining" },
  { key: "secondaryPayer", label: "Secondary Payer" },
  { key: "daw", label: "DAW" },
  { key: "isCycleFill", label: "Is Cycle Fill" },
  { key: "isPrn", label: "Is PRN" },
  { key: "prescriberName", label: "Prescriber Name" },
  { key: "prescriberAddress", label: "Prescriber Address" },
  { key: "prescriberCity", label: "Prescriber City" },
  { key: "prescriberState", label: "Prescriber State" },
  { key: "prescriberZip", label: "Prescriber Zip" },
  { key: "prescriberNpi", label: "Prescriber NPI" },
];

export function DownloadCsvRefillOpportunitiesButton() {
  const {
    dates,
    pharmacyId,
    sortColumn,
    sortDirection,
    manufacturerIds,
    manufacturerFilters,
    showOnlyCoveredFills,
  } = useRefillOpportunities();
  const client = useRequestClient();
  const [isLoading, setIsLoading] = useState(false);

  const downloadCsvReport = useCallback(async () => {
    setIsLoading(true);

    try {
      const [startDate, endDate] = dates;
      const payload = {
        endDate,
        pageSize: 0,
        startDate,
        sortColumn,
        pharmacyId,
        sortDirection,
        showOnlyCoveredFills,
        manufacturerIds: manufacturerFilters?.length
          ? manufacturerFilters
          : manufacturerIds,
      };

      const response = await getReportRefillOpportunity(client, payload);
      const data = response?.data?.data?.refillOpportunities;
      if (!data) {
        throw new Error("Empty response downloading refill opportunities");
      }

      downloadJsonLikeCsv({
        data: data.records,
        columns: CSV_HEADERS,
        filename: "report-opportunities.csv",
      });
    } catch (error) {
      displayErrorModal({
        title: "Error downloading your report refill opportunities",
      });
      console.error("Error downloading report refill opportunities", error);
    } finally {
      setIsLoading(false);
    }
  }, [
    dates,
    pharmacyId,
    sortColumn,
    sortDirection,
    manufacturerIds,
    manufacturerFilters,
    showOnlyCoveredFills,
    client,
  ]);

  return (
    <Button
      variant="text-1"
      icon={<IconSave />}
      disabled={isLoading}
      onClick={downloadCsvReport}
    >
      {isLoading ? "Downloading" : "Download"} CSV File
    </Button>
  );
}
