import { useCallback, useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import type { UpdatePagination } from "../../components/rxLibrary/pagination/pagination.utils";
import {
  RequestClient,
  useRequestClient,
  RequestClientConfig,
} from "./requestClient";

/**
 * Utility hook to make a request and handle loading and error states and aborting the request when the component unmounts.
 */
export function useRequest<
  O,
  K extends string,
  C = Record<string, never>,
  OF = O
>({
  start = true,
  config,
  dataKey,
  request,
  formatter,
  updatePagination,
}: {
  dataKey: K;
  start?: boolean;
  config?: RequestClientConfig<C>;
  request: (
    client: RequestClient,
    config: RequestClientConfig<C>
  ) => Promise<void | AxiosResponse<{ data?: Record<K, O>; error?: any }>>;
  formatter?: (data: O) => OF;
  updatePagination?: UpdatePagination;
}) {
  type ResponseData = {
    data?: O;
    error?: any;
    response?: AxiosResponse<{ data?: Record<K, O>; error?: any }>;
  };

  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [formattedData, setFormattedData] = useState<OF>();

  const requestClient = useRequestClient();
  const abortControllerRef = useRef<AbortController>();

  const [isLoading, setIsLoading] = useState(false);
  const [{ data, error, response }, setResponseData] = useState<ResponseData>(
    {}
  );

  const refetch = useCallback(() => {
    setShouldRefetch(true);
  }, []);

  const abortRequest = useCallback(() => {
    if (abortControllerRef.current?.signal?.aborted !== false) return;
    abortControllerRef.current.abort();
  }, []);

  useEffect(() => {
    if (shouldRefetch) {
      setShouldRefetch(false);
      return;
    }

    if (!start) {
      setIsLoading(false);
      return;
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;
    init();

    // cancel request on unmount
    return abortRequest;

    async function init() {
      setIsLoading(true);

      const responseData: ResponseData = {};
      try {
        const response = await request(requestClient, {
          ...config,
          signal: controller.signal,
        } as RequestClientConfig<C>);
        if (!response?.data) return;

        responseData.response = response;
        responseData.data = response.data.data?.[dataKey];
      } catch (error) {
        responseData.error = error;
      }

      if (responseData.error) {
        console.error("Error in use page request", config, responseData);
      }

      setResponseData(responseData);
      setIsLoading(false);
    }
  }, [
    start,
    config,
    dataKey,
    request,
    abortRequest,
    requestClient,
    shouldRefetch,
  ]);

  useEffect(() => {
    if (updatePagination && data) updatePagination(data);
  }, [data, updatePagination]);

  useEffect(() => {
    if (!data || !formatter) {
      setFormattedData(undefined);
      return;
    }
    const newFormattedData = formatter(data);
    setFormattedData(newFormattedData);
  }, [data, formatter]);

  return {
    data,
    error,
    response,
    isLoading,
    formattedData,
    refetch,
    abortRequest,
  };
}
