import React, { useEffect, useState } from "react";
import "twin.macro";
import cn from "classnames";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "../../../../../../scss/base.scss";
import "../../../../../../scss/dropdown.scss";
import { IDropDownItem } from "../../../../../../components/DropDownListQty";

/** @deprecated */
export function GeneralDropDown({
  label,
  className,
  placeholder,
  name,
  defaultValue,
  dropDownItems,
  disabled = false,
  callBack,
  onOpen,
  onClose,
  openTimeMs,
}: {
  name?: string;
  label?: string;
  className?: string;
  placeholder?: string;
  defaultValue?: string;
  dropDownItems: IDropDownItem[];
  disabled?: boolean;
  callBack: (value: string, idx: number) => void;
  onOpen?: () => void;
  onClose?: () => void;
  openTimeMs?: number;
}) {
  const [sValue, setSValue] = useState<string>(defaultValue || "");

  // reset when disabled
  useEffect(() => {
    if (disabled) {
      setSValue("");
    } else {
      setSValue(defaultValue || "");
    }
  }, [defaultValue, disabled]);

  useEffect(() => {
    dropDownItems?.map((option: IDropDownItem) => {
      if (option.disabled && option.value === sValue) {
        setSValue("");
        callBack && callBack("", -1);
      }
    });
  }, [dropDownItems]);

  return (
    <div className={cn("mui-dropdown", className, disabled && "disabled")}>
      {label && <span style={{ lineHeight: "16px" }}>{label}</span>}
      <div>
        <Select
          disabled={disabled}
          name={name}
          value={placeholder ? "placeholder" : sValue}
          onChange={(e: SelectChangeEvent<string>) => {
            setSValue(e.target.value);
          }}
          onOpen={onOpen}
          onClose={onClose}
          MenuProps={{
            transitionDuration: openTimeMs,
          }}
        >
          {placeholder && (
            <MenuItem disabled value="placeholder">
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {dropDownItems?.map((option: IDropDownItem, index: number) => {
            return (
              <MenuItem
                key={`select${name}${index}`}
                value={option.value}
                disabled={option.disabled}
                onClick={() => {
                  callBack && callBack(option.value as string, index);
                }}
              >
                {option.label ?? option.value}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
}
