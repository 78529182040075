import { css, styled } from "twin.macro";
import { RxColor, getColorCss } from "../colors";
import IconCheckSvg from "./svg/icon-check.svg";
import Icon3DotsSvg from "./svg/icon-3-dots.svg";
import IconDownSvg from "./svg/icon-down.svg";
import IconFilterSvg from "./svg/icon-filter.svg";
import IconHomeSvg from "./svg/icon-home.svg";
import IconMagnifySvg from "./svg/icon-magnify.svg";
import IconNoteSvg from "./svg/icon-note.svg";
import IconPlusSvg from "./svg/icon-plus.svg";
import IconRefreshSvg from "./svg/icon-refresh.svg";
import IconSortAscendSvg from "./svg/icon-sort-ascend.svg";
import IconSortDescendSvg from "./svg/icon-sort-descend.svg";
import IconXSvg from "./svg/icon-x.svg";
import IconClockSvg from "./svg/icon-clock.svg";
import IconBellSvg from "./svg/icon-bell.svg";
import IconEyeSvg from "./svg/icon-eye.svg";
import IconEyeShutSvg from "./svg/icon-eye-shut.svg";
import IconArrowLeftSvg from "./svg/icon-arrow-left.svg";
import IconTrashSvg from "./svg/icon-trash.svg";
import IconShoppingCartSvg from "./svg/icon-shopping-cart.svg";
import IconChatBubblesSvg from "./svg/icon-chat-bubbles.svg";
import IconCombinedSvg from "./svg/icon-combined.svg";
import IconPrintSvg from "./svg/icon-print.svg";
import IconSnowflakeSvg from "./svg/icon-snowflake.svg";
import IconSaveSvg from "./svg/icon-save.svg";
import IconFoamSvg from "./svg/icon-foam.svg";
import IconOrderHistorySvg from "./svg/icon-order-history.svg";
import IconGearSvg from "./svg/icon-gear.svg";

const SvgStyled = styled.svg(({ color }: { color?: RxColor }) => [
  css`
    path,
    circle,
    rect,
    polygon,
    ellipse,
    text {
      fill: currentColor;
    }
  `,
  !!color && getColorCss(color),
]);

export type Icon = typeof SvgStyled;

export const IconCheck = SvgStyled.withComponent(IconCheckSvg);
export const IconDown = SvgStyled.withComponent(IconDownSvg);
export const IconPlus = SvgStyled.withComponent(IconPlusSvg);
export const Icon3Dots = SvgStyled.withComponent(Icon3DotsSvg);
export const IconX = SvgStyled.withComponent(IconXSvg);
export const IconRefresh = SvgStyled.withComponent(IconRefreshSvg);
export const IconFilter = SvgStyled.withComponent(IconFilterSvg);
export const IconHome = SvgStyled.withComponent(IconHomeSvg);
export const IconNote = SvgStyled.withComponent(IconNoteSvg);
export const IconSortAscend = SvgStyled.withComponent(IconSortAscendSvg);
export const IconSortDescend = SvgStyled.withComponent(IconSortDescendSvg);
export const IconMagnify = SvgStyled.withComponent(IconMagnifySvg);
export const IconClock = SvgStyled.withComponent(IconClockSvg);
export const IconBell = SvgStyled.withComponent(IconBellSvg);
export const IconEye = SvgStyled.withComponent(IconEyeSvg);
export const IconEyeShut = SvgStyled.withComponent(IconEyeShutSvg);
export const IconArrowLeft = SvgStyled.withComponent(IconArrowLeftSvg);
export const IconTrash = SvgStyled.withComponent(IconTrashSvg);
export const IconShoppingCart = SvgStyled.withComponent(IconShoppingCartSvg);
export const IconChatBubbles = SvgStyled.withComponent(IconChatBubblesSvg);
export const IconCombined = SvgStyled.withComponent(IconCombinedSvg);
export const IconPrint = SvgStyled.withComponent(IconPrintSvg);
export const IconSnowflake = SvgStyled.withComponent(IconSnowflakeSvg);
export const IconSave = SvgStyled.withComponent(IconSaveSvg);
export const IconFoam = SvgStyled.withComponent(IconFoamSvg);
export const IconOrderHistory = SvgStyled.withComponent(IconOrderHistorySvg);
export const IconGear = SvgStyled.withComponent(IconGearSvg);
