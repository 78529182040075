import "twin.macro";
import { WideContainer } from "../../../components/containers/WideContainer";
import { Box } from "../../../components/rxLibrary/box";
import { PerformanceHeaderCSVExportLinks } from "./PerformanceHeaderCSVExportLinks";
import { PerformanceHeaderDateRangePicker } from "./PerformanceHeaderDateRangePicker";
import { PerformanceHeaderReportSelector } from "./PerformanceHeaderReportSelector";
import { PerformanceHeaderPharmaciesSelect } from "./PerformanceHeaderPharmaciesSelect";

export function PerformanceHeader() {
  return (
    <Box bgColor="grey-5" tw="py-8">
      <WideContainer tw="flex justify-between">
        <PerformanceHeaderPharmaciesSelect />
        <PerformanceHeaderReportSelector />
        <PerformanceHeaderDateRangePicker />
      </WideContainer>

      <PerformanceHeaderCSVExportLinks />
    </Box>
  );
}
