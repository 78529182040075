"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPassword = setPassword;
exports.setShowPassword = setShowPassword;
exports.validatePassword = validatePassword;
var _policy = _interopRequireDefault(require("password-sheriff/lib/policy"));
var _index = require("./index");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function validatePassword(password, policy) {
  if (!password) {
    return false;
  }
  if (!policy) {
    return true;
  }
  return new _policy.default(policy.toJS()).check(password);
}
function setPassword(m, password, policy) {
  return (0, _index.setField)(m, 'password', password, validatePassword, policy);
}
function setShowPassword(m, checked) {
  return (0, _index.setField)(m, 'showPassword', checked, function () {
    return true;
  });
}
