import dayjs from "dayjs";
import { DateRangePickerValue } from "../../rxLibrary/dateRangePicker";
import type { SelectOption } from "../../rxLibrary/selects/select";

export function getDateRangesPresets(): {
  label: string;
  value: DateRangePickerValue;
}[] {
  return [
    {
      label: "Last 90 Days",
      value: [dayjs().subtract(91, "day"), dayjs().subtract(1, "day")],
    },
    {
      label: "91-180 Days Ago",
      value: [dayjs().subtract(181, "day"), dayjs().subtract(91, "day")],
    },
    {
      label: "181-270 Days Ago",
      value: [dayjs().subtract(271, "day"), dayjs().subtract(181, "day")],
    },
    {
      label: "Now to end-of-week",
      value: [dayjs(), dayjs().endOf("week")],
    },
    {
      label: "Next 7 Days",
      value: [dayjs(), dayjs().add(7, "day")],
    },
    {
      label: "Next 30 Days",
      value: [dayjs(), dayjs().add(30, "day")],
    },
  ];
}

export const REFILL_OPPORTUNITIES_SORT_OPTIONS: SelectOption[] = [
  {
    label: "Last Dispensed Drug Name",
    value: "last_dispensed_drug_name",
  },
  {
    label: "Last Dispensed Manufacturer",
    value: "last_dispensed_manufacturer",
  },
  {
    label: "Rx #",
    value: "rx_number",
  },
  {
    label: "Patient",
    value: "patient",
  },
  {
    label: "Est. Profit",
    value: "estimated_profit",
  },
  {
    label: "Est. Revenue",
    value: "estimated_revenue",
  },
  {
    label: "Est. Cost",
    value: "estimated_cost",
  },
  {
    label: "Refill Due Date",
    value: "refill_due_date",
  },
  {
    label: "Primary Payer",
    value: "primary_payer",
  },
];
