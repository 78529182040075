import { useMemo, useContext, useCallback } from "react";
import "twin.macro";
import Select from "react-select";
import keyBy from "lodash/keyBy";
import PharmaciesContext from "../../../contexts/PharmaciesContext";
import { usePerformance } from "../usePerformance";

export function PerformanceHeaderPharmaciesSelect() {
  const { pharmacies } = useContext(PharmaciesContext);
  const { selectedPharmacies, setSelectedPharmacies } = usePerformance();

  const pharmacyOptions = useMemo(() => {
    return pharmacies.map((p) => ({ value: p.id, label: p.name }));
  }, [pharmacies]);
  type Option = typeof pharmacyOptions[number];

  const pharmacySelections = useMemo(() => {
    if (!selectedPharmacies?.length || !pharmacyOptions?.length) {
      return [];
    }
    
    const pharmacyOptionsById = keyBy(pharmacyOptions, "value");
    return selectedPharmacies.reduce<Option[]>((acc, id) => {
      const option = pharmacyOptionsById[id];
      if (option) acc.push(option);
      return acc;
    }, []);
  }, [selectedPharmacies, pharmacyOptions]);

  const handleChange = useCallback(
    (selection: readonly Option[]) => {
      const values = selection.map((s) => s.value);
      setSelectedPharmacies(values);
    },
    [setSelectedPharmacies]
  );

  return (
    <div>
      <span tw="mr-2 font-light">Pharmacy:</span>

      <Select
        isMulti
        tw="inline-block text-black"
        value={pharmacySelections}
        options={pharmacyOptions}
        isLoading={!selectedPharmacies?.length}
        onChange={handleChange}
      />
    </div>
  );
}
