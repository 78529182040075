import { useCallback, useState } from "react";
import pick from "lodash/pick";
import type { PaginationConfig, UpdatePagination } from "./pagination.utils";

export function usePagination({
  initialPagination,
}: {
  initialPagination?: PaginationConfig;
} = {}) {
  const [pagination, setPagination] = useState(initialPagination);

  const updatePagination: UpdatePagination = useCallback(
    (value: Partial<PaginationConfig>) => {
      setPagination((currentPagination) => {
        const newPagination = {
          ...currentPagination,
          ...pick(value, [
            "pageSize",
            "totalRows",
            "currentPage",
            "totalPageCount",
          ]),
        } as PaginationConfig;

        return newPagination;
      });
    },
    []
  );

  return { pagination, updatePagination };
}
