import { useCallback } from "react";
import axios, { AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";
import qs from "qs";
import * as Sentry from "@sentry/react";
import { X_CLIENT_SESSION_ID } from "../../utilities/config";
import { useAuth0 } from "../../contexts/AuthenticationContext";
import { snakeCaseObjectKeys } from "../../utilities/objects/snakeCaseObjectKeys";

const { REACT_APP_BACKEND_URL } = process.env;

type RequestConfig = Omit<
  AxiosRequestConfig,
  "baseUrl" | "paramsSerializer"
> & {
  noAuth?: boolean;
  retries?: number;
  noDataWrapper?: boolean;
  noSnakeCaseParams?: boolean;
  shouldSkipSentryNotification?: (error: any) => boolean;
};

export type RequestClient = ReturnType<typeof useRequestClient>;

export type RequestClientConfig<C = Record<string, never>> = RequestConfig & C;

/**
 * Our BE don't support query params arrays with the [] syntax, so we need to serialize them manually
 * Example: {arr: [1,2], q: 3} => arr=1&arr=2&q=3
 * Example axios default no supported: {arr: [1,2], q: 3} => arr[]=1&arr[]=2&q=3
 */
function paramsSerializer(params: Record<string, any>) {
  return qs.stringify(params, { indices: false });
}

class RequestError extends Error {
  constructor(message: string) {
    super(message); // (1)
    this.name = "RequestError";
  }
}

/**
 * Hook to create a request client that automatically adds the necessary headers and snake cases the params
 */
export function useRequestClient() {
  const { getAccessTokenSilently } = useAuth0();

  const client = useCallback(
    async <RequestPayload = void>({
      retries,
      noAuth = false,
      noDataWrapper = false,
      noSnakeCaseParams = false,
      shouldSkipSentryNotification = () => false,
      ...options
    }: RequestConfig & { url: string }) => {
      const config: AxiosRequestConfig = {
        ...options,
        method: options.method ?? "GET",
        baseURL: REACT_APP_BACKEND_URL,
        paramsSerializer,
      };
      config.headers = {
        ...config.headers,
        "X-Client-Session-Id": X_CLIENT_SESSION_ID,
      };

      if (!noSnakeCaseParams) {
        config.params = snakeCaseObjectKeys(config.params);
      }

      if (!noAuth) {
        const token = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
      }

      if (!noDataWrapper && config.data) {
        config.data = { data: config.data };
      }

      const client = axios.create();
      if (retries) {
        axiosRetry(client, {
          retries,
          onRetry: (retryCount, error, requestConfig) => {
            console.warn(
              `Request ${requestConfig.method} ${requestConfig.url} failed with status ${error?.response?.status}. Retrying ${retryCount} of ${retries}, error `,
              error,
              " config ",
              config
            );
          },
        });
      }

      type Response = RequestPayload extends Blob
        ? Blob
        : RequestPayload extends void
        ? void
        : { data: RequestPayload };
      return client<Response>(config).catch((error) => {
        const isCancelled = axios.isCancel(error);
        let msg = `Request ${config.method} ${config.url} `;
        msg += isCancelled ? "cancelled" : "failed";
        if (error?.response?.status) {
          msg += ` with status ${error.response.status}`;
        }

        if (isCancelled) {
          console.warn(msg, error);
          return;
        }

        const skipSentryNotification = shouldSkipSentryNotification(error);
        if (skipSentryNotification) {
          console.warn(msg, error);
        } else {
          console.error(msg, error);
          Sentry.captureException(new RequestError(msg), {
            extra: {
              requestRetires: retries,
              requestConfig: config,
              responseData: error.response,
              response: error.response?.data,
              error,
              stringifiedError: JSON.stringify(error, null, 2),
            },
          });
        }

        throw error;
      });
    },
    [getAccessTokenSilently]
  );

  return client;
}
