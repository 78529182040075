import { useState, useEffect } from "react";
import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";
import { useShoppingState } from "../../../../../contexts/ShoppingContext/ShoppingContext";
import { isInventoryId } from "../../../../../utilities/prescriptions/isInventoryId";
import { ItemInCart } from "../../../../../utilities/types";

type UnavailableRxItem = {
  isInventory: boolean;
  prescription: ItemInCart;
  prescriptionId: string;
};

export function useUnavailableRxList() {
  const [unavailableRxList, setUnavailableRxList] =
    useState<UnavailableRxItem[]>([]);
  const {
    optimizeCartResponse,
    prescriptionItemsInCart,
    serverInventoryItemsInCart,
  } = useShoppingState();
  const { unavailable } = optimizeCartResponse?.data?.selections ?? {};

  useEffect(() => {
    if (!unavailable) {
      setUnavailableRxList([]);
      return;
    }

    const itemsById = {
      ...keyBy(serverInventoryItemsInCart, "key"),
      ...keyBy(prescriptionItemsInCart, "rxNumber"),
    };
    const prescriptionIds = uniq(unavailable.flatMap((item) => item.rxNumbers));

    const newUnavailableRxList = prescriptionIds
      .map((prescriptionId) => {
        const isInventory = isInventoryId(prescriptionId);
        const prescription = itemsById[prescriptionId];
        // TODO: Jason please remove the next line when you fix the issue with the snapshot of the cart
        if (!prescription) return;
        return { prescription, prescriptionId, isInventory };
      })
      .filter(Boolean) as UnavailableRxItem[];

    setUnavailableRxList(newUnavailableRxList);
  }, [
    unavailable,
    serverInventoryItemsInCart,
    prescriptionItemsInCart,
    optimizeCartResponse,
  ]);

  return unavailableRxList;
}
