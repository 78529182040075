/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getPageVersion() {
  const ms = Date.now();
  const url = `/version.txt?t=${ms}`;
  const response = await fetch(url, { cache: "no-store" });
  const version = (await response.text()).trim();

  return version;
}
