"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "WindowScroller", {
  enumerable: true,
  get: function get() {
    return _WindowScroller["default"];
  }
});
Object.defineProperty(exports, "IS_SCROLLING_TIMEOUT", {
  enumerable: true,
  get: function get() {
    return _WindowScroller.IS_SCROLLING_TIMEOUT;
  }
});
exports["default"] = void 0;

var _WindowScroller = _interopRequireWildcard(require("./WindowScroller"));

var _default = _WindowScroller["default"];
exports["default"] = _default;