"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCollection = getCollection;
exports.getEntity = getEntity;
exports.getState = getState;
exports.observe = observe;
exports.read = read;
exports.removeEntity = removeEntity;
exports.setEntity = setEntity;
exports.subscribe = subscribe;
exports.swap = swap;
exports.unsubscribe = unsubscribe;
exports.updateCollection = updateCollection;
exports.updateEntity = updateEntity;
var _atom = _interopRequireDefault(require("../utils/atom"));
var _immutable = require("immutable");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var store = (0, _atom.default)(new _immutable.Map({}));
function observe(key, id, f) {
  subscribe("".concat(key, "-").concat(id), function (_, oldState, newState) {
    var m = getEntity(newState, 'lock', id);
    var oldM = getEntity(oldState, 'lock', id);
    if (m && !m.equals(oldM)) {
      f(m);
    }
  });
}
function subscribe(key, f) {
  store.addWatch(key, f);
}
function unsubscribe(key) {
  store.removeWatch(key);
}
function swap() {
  return store.swap.apply(store, arguments);
}
function updateEntity(state, coll, id, f) {
  for (var _len = arguments.length, args = new Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
    args[_key - 4] = arguments[_key];
  }
  return state.updateIn([coll, id], new _immutable.Map({}), function (x) {
    return f.apply(void 0, [x].concat(args));
  });
}
function setEntity(state, coll, id, m) {
  return state.setIn([coll, id], m);
}
function read(f) {
  for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    args[_key2 - 1] = arguments[_key2];
  }
  return f.apply(void 0, [store.deref()].concat(args));
}
function getEntity(state, coll) {
  var id = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return state.getIn([coll, id]);
}
function removeEntity(state, coll) {
  var id = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return state.removeIn([coll, id]);
}
function getCollection(state, coll) {
  return state.get(coll, (0, _immutable.Map)()).toList();
}

// TODO: try to remove this fn
function updateCollection(state, coll, f) {
  for (var _len3 = arguments.length, args = new Array(_len3 > 3 ? _len3 - 3 : 0), _key3 = 3; _key3 < _len3; _key3++) {
    args[_key3 - 3] = arguments[_key3];
  }
  return state.update(coll, function (xs) {
    return f.apply(void 0, [xs].concat(args));
  });
}
function getState() {
  return store.deref();
}

// DEV
// store.addWatch("keepHistory", (key, oldState, newState) => {
//   if (!window.h) window.h = []; window.h.push(newState);
//   console.debug("something changed", newState.toJS());
// });
