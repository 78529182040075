import React, { useCallback, useContext, useEffect, useState } from "react";
import "twin.macro";
import uniq from "lodash/uniq";
import {
  RequestState,
  SuggestedNDC,
  DrugInfo,
  Pharmacy,
} from "../../utilities/types";
import {
  getDispensedText,
  disableV1EnhancementsForSupplier,
} from "../../utilities/search";
import BuyingPharmacyContext from "../../contexts/BuyingPharmacyContext";
import { EnhancedSupplierConnectionStatus} from "../../enhanced/types";
import EnhancedClientContext, {
  EnhancedStockValidationAvailability,
  RequestEnhanceItemParam,
} from "../../enhanced/EnhancedClientContext";
import { SearchResultsHeaderBar } from "../../components/SearchResultsHeaderBar/SearchResultsHeaderBar";
import { drugInfoToDrugNameUOMSizeStr } from "../../utilities/drugInfo/drugInfoToDrugNameUOMSizeStr";
import EnhancedClientCommandContext from "../../enhanced/EnhancedClientCommandContext";
import EnhancedSupplierLoginInterstitial from "../../enhanced/EnhancedSupplierLoginInterstitial";
import EnhancedClientConfigContext from "../../enhanced/EnhancedClientConfigContext";
import { QuoteState, useCompare } from "./useCompare/useCompare";
import { NDCResults } from "./NDCResults/NDCResults";
import { QuoteStateError } from "./QuoteStateError";

function toDrugNameUOMSizeStr(
  suggestedNDC: SuggestedNDC | null,
  relatedDrugsByNDC: Record<string, DrugInfo>
): string {
  if (!suggestedNDC) {
    return "";
  }
  const searchDrug = relatedDrugsByNDC[suggestedNDC.ndc];
  if (!searchDrug) return "";

  return drugInfoToDrugNameUOMSizeStr(searchDrug);
}

function toEnhancementRequestList(quoteState: QuoteState, enhancedSupplierConnectionStatuses: Record<string, EnhancedSupplierConnectionStatus>, currentBuyingPharmacy: Pharmacy | null): RequestEnhanceItemParam[] {
  if (!currentBuyingPharmacy) {
    return [];
  }

  const listsByNdc  = quoteState.itemListsByNDC;
  const suppliersRequested = new Set<string>();
  const requests: RequestEnhanceItemParam[] = listsByNdc.map((v) => {
    return v.items.map((i) => {
      suppliersRequested.add(i.catalogInfo.supplier);
      return {
        ndc: i.drugInfo.ndc,
        supplier: i.catalogInfo.supplier,
        price: i.catalogInfo.price,
        drugNameUOMSizeStr: toDrugNameUOMSizeStr(quoteState.suggestedNDC, quoteState.relatedDrugsByNDC),
        supplierItemNumbers: i.catalogInfo.supplierItemNumber ? [i.catalogInfo.supplierItemNumber] : [],
      };
    })
  }).flat().filter(item => {
    return !!enhancedSupplierConnectionStatuses[item.supplier]
      && !enhancedSupplierConnectionStatuses[item.supplier].enhancementsDisabled
      && !disableV1EnhancementsForSupplier(item.supplier)
    ;
  });

  return requests;
}

export function EnhancedCompare() {
  const { enhancedClientVersion } = useContext(EnhancedClientConfigContext);
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);

  const [ loggedInToAllSuppliers, setLoggedInToAllSuppliers ] = useState<boolean>(false);
  const [ enhancementRequestState, setEnhancementRequestState] = useState<RequestState>(RequestState.PENDING);
  const [ skippedSuppliers , setSkippedSuppliers ] = useState<string[]>([]);
  const { showMain } = useContext(EnhancedClientCommandContext);
  const {requestEnhanceItems, enhancedSupplierConnectionStatuses, currentEnhancementState } = useContext(EnhancedClientContext);

  const onStartSearch = useCallback(() => {
    setEnhancementRequestState(RequestState.UNINITIATED);
  }, []);

  const { ndc, from, drugName, quoteState } = useCompare({
    enhancedClientVersion,
    onStartSearch,
  });

  useEffect(() => {
    if (quoteState.quoteRequestState === RequestState.PENDING) {
      return;
    }
    if (!loggedInToAllSuppliers) {
      return;
    }

    if (currentEnhancementState.traceId !== quoteState.traceId) {
      const requests: RequestEnhanceItemParam[] = toEnhancementRequestList(quoteState, enhancedSupplierConnectionStatuses, currentBuyingPharmacy).filter(req => {
        return enhancedSupplierConnectionStatuses[req.supplier].loggedIn
      });

      if (requests.length > 0) {
        requestEnhanceItems(quoteState.traceId, requests);
        setEnhancementRequestState(RequestState.PENDING);
      } else {
        setEnhancementRequestState(RequestState.COMPLETED);
      }
    }
  }, [currentBuyingPharmacy, quoteState, currentEnhancementState, enhancedSupplierConnectionStatuses, loggedInToAllSuppliers]);

  useEffect(() => {
    if (!loggedInToAllSuppliers) return;
    if (quoteState.quoteRequestState === RequestState.PENDING) return;
    if (enhancementRequestState === RequestState.UNINITIATED) return;
    if (currentEnhancementState.requestBatch.length === 0) return;

    console.log("Checking enhancement state");
    console.log("connectionStatuses", enhancedSupplierConnectionStatuses);
    console.log("items", currentEnhancementState.enhancedSupplierItems);
    console.log("requestsCompleted", currentEnhancementState.requestsCompleted);
    let requestsProcessed = 0;
    currentEnhancementState.requestBatch.forEach(req => {
      console.log("Checking", req);
      if (currentEnhancementState.requestsCompleted && currentEnhancementState.requestsCompleted[req.supplier]) {
        requestsProcessed += 1;
        console.log(`ok completed; ${requestsProcessed}/${currentEnhancementState.requestBatch.length}`);
      } else {
        const supplierResults = currentEnhancementState.enhancedSupplierItems[req.supplier];
        if (!supplierResults) {
          console.log("No supplierResults");
          return;
        }
        const ndcResult = supplierResults[req.ndc];
        if (!ndcResult) {
          console.log("No ndcResult");
          return;
        }
        if (ndcResult.availability === EnhancedStockValidationAvailability.PENDING) {
          console.log("Pending availability");
          return;
        }
        console.log(`ok found; ${requestsProcessed}/${currentEnhancementState.requestBatch.length}`, ndcResult);
        requestsProcessed += 1;
      }
    });
    if (requestsProcessed === currentEnhancementState.requestBatch.length) {
      console.log("All requests processed!", quoteState.itemListsByNDC);
      console.log("SETTING COMPLETED via PROCESSED");
      setEnhancementRequestState(RequestState.COMPLETED);
    } else {
      console.log("Processed", requestsProcessed, "of", currentEnhancementState.requestBatch.length, "requests");
    }
  }, [loggedInToAllSuppliers, quoteState, enhancementRequestState, currentEnhancementState, enhancedSupplierConnectionStatuses]);

  return (
    <>
      <SearchResultsHeaderBar
          dispensed={quoteState.quoteRequestState === RequestState.COMPLETED}
          dispensedValue={
            quoteState.quoteRequestState === RequestState.COMPLETED &&
            quoteState.itemListsByNDC[0]
                ? getDispensedText(quoteState.itemListsByNDC[0].items)
                : null
          }
          textSearch = {from === "selectDrug"}
          ndc={from === "selectDrug" ? drugName || '' : ndc}
          searchDisabled={quoteState.quoteRequestState === RequestState.PENDING}
      />

      <div tw="pt-12">
        {quoteState.quoteRequestState === RequestState.ERROR || enhancementRequestState === RequestState.ERROR ? (
          <QuoteStateError ndc={ndc} errorDetail={quoteState.errorDetail} />
        ) : (
            <>
              {(enhancementRequestState === RequestState.UNINITIATED || enhancementRequestState === RequestState.PENDING || !loggedInToAllSuppliers) && (
                    <EnhancedSupplierLoginInterstitial
                        key={ndc}
                        quoteRequestState={quoteState.quoteRequestState}
                        onComplete={() => {
                          console.log("interstitial onComplete");
                        }}
                        onLoginsFinished={() => {
                            console.log("interstitial onLoginsFinished");
                          showMain();
                          if (currentEnhancementState.isFirstSearch && (currentEnhancementState.traceId === null || currentEnhancementState.traceId === quoteState.traceId)) {
                            setTimeout(() => {
                              setLoggedInToAllSuppliers(true);
                            }, 5000);
                          } else {
                            setLoggedInToAllSuppliers(true);
                          }
                        }}
                        onCancelSupplierLogin={(supplier) => {
                          setSkippedSuppliers((prev) => {
                            return [...prev, supplier];
                          });
                        }}
                        limitedTo={uniq(Object.keys(enhancedSupplierConnectionStatuses).filter(s => !skippedSuppliers.includes(s)))}
                        onTimeout={() => {
                          setEnhancementRequestState(RequestState.COMPLETED);
                        }}
                    />
              )}

              {loggedInToAllSuppliers && enhancementRequestState === RequestState.COMPLETED && (
                    <NDCResults ndc={ndc} itemsByNDC={quoteState.itemListsByNDC}
                                suggestedDrug={quoteState.suggestedDrug} relatedDrugsByNDC={quoteState.relatedDrugsByNDC}
                                enhancementExcludedSuppliers={skippedSuppliers}
                    />
              )}
            </>
        )}
      </div>
    </>
  );
}
