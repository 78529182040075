import React, { useCallback, useContext } from "react";
import "twin.macro";
import { useHistory } from "react-router-dom";
import EnhancedClientConfigContext from "../../../../enhanced/EnhancedClientConfigContext";
import { useShoppingCartServerUpdater } from "../../../../contexts/ShoppingCartServerContext";
import { useShoppingState } from "../../../../contexts/ShoppingContext/ShoppingContext";
import { useStorageState } from "../../../../contexts/StorageContext";
import AppContext from "../../../../contexts/AppContext";
import { WideContainer } from "../../../../components/containers/WideContainer";
import { ShoppingListButton } from "../../../../components/rxLibrary/buttons";
import {
  SortBySelect,
  SortDirectionEnum,
} from "../../../../components/rxLibrary/selects/SortBySelect";
import { MultiSelectCheckboxes, MultiSelectCheckboxesSelectedTags } from "../../../../components/rxLibrary/selects/MultiSelectCheckboxes";
import {
  CART_ITEMS_SORT_OPTIONS,
  CART_ITEM_FILTERS_OPTIONS,
  CartItemsSortEnum,
  ISetCartItemsSort,
  ISetCartItemsSearch,
  ISetCartItemsFilters,
  ISetCartItemsSortOrder,
} from "../useShoppingListItems/useShoppingListItems.constants";
import { SearchBarItem } from "../searchBar";
import { ShoppingListSearchBar } from "./ShoppingListSearchBar";

export function ShoppingListBar({
  searchBarItems,
  cartItemsSort,
  cartItemsFilters,
  cartItemsSortOrder,
  setCartItemsSort,
  setSearchBarFilter,
  setCartItemsFilters,
  setCartItemsSortOrder,
}: {
  searchBarItems: SearchBarItem[];
  cartItemsSort: CartItemsSortEnum;
  cartItemsFilters: string[];
  cartItemsSortOrder: SortDirectionEnum;
  setCartItemsSort: ISetCartItemsSort;
  setSearchBarFilter: ISetCartItemsSearch;
  setCartItemsFilters: ISetCartItemsFilters;
  setCartItemsSortOrder: ISetCartItemsSortOrder;
}) {
  const { user } = useContext(AppContext);
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);
  const { serverItemPurchaseDetails, activeCartItemIds } = useShoppingState();
  const { pushBlob } = useShoppingCartServerUpdater();
  const { waitButtonMode } = useStorageState();
  const history = useHistory();

  const shoppingList = serverItemPurchaseDetails.filter(
    (item) => item.status === "list" && activeCartItemIds[item.id]
  );
  const shoppingListCount = shoppingList.length;

  const handleShoppingListClick = useCallback(() => {
    if (!enhancedClientActive && !user?.is_admin) {
      window.alert(
        "Please use the DaylightRx Desktop App to complete your purchases."
      );
    } else {
      pushBlob({ second: true, force: false });
      history.push("/shoppingListTwo");
    }
  }, [user, enhancedClientActive, pushBlob, history]);

  return (
    <div tw="bg-white sticky z-20 top-0">
      <WideContainer>
        <div tw="flex justify-between items-center pt-[21px]">
          <div tw="flex justify-start gap-x-2">
            <MultiSelectCheckboxes
              title="Select Filters to Apply"
              filters={cartItemsFilters}
              setFilters={setCartItemsFilters}
              options={CART_ITEM_FILTERS_OPTIONS}
            />

            <ShoppingListSearchBar
              searchBarItems={searchBarItems}
              setSearchBarFilter={setSearchBarFilter}
            />
          </div>

          <div tw="flex justify-end gap-x-[27px]">
            <SortBySelect
              selectMinWidth={130}
              value={cartItemsSort}
              options={CART_ITEMS_SORT_OPTIONS}
              sortOrder={cartItemsSortOrder}
              onChange={setCartItemsSort}
              onSortOrderChange={setCartItemsSortOrder}
            />
            <ShoppingListButton
              disabled={shoppingListCount === 0 || waitButtonMode}
              onClick={handleShoppingListClick}
            >
              Shopping List ({shoppingListCount})
            </ShoppingListButton>
          </div>
        </div>

        <div tw="min-h-[21px] pt-[10px] pb-[27px]">
          <MultiSelectCheckboxesSelectedTags
            filters={cartItemsFilters}
            setFilters={setCartItemsFilters}
            options={CART_ITEM_FILTERS_OPTIONS}
          />
        </div>
      </WideContainer>
    </div>
  );
}
