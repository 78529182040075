import React from "react";
import "twin.macro";
import { IconShoppingCart } from "./rxLibrary/icons";
import { Text } from "./rxLibrary/typography";

export function ShoppingCartWithNumber({ value }: { value: number }) {
  return (
    <div>
      <div tw="relative inline-block">
        <IconShoppingCart color="blue-2" />
        <Text tw="absolute top-[25%] left-[50%]" color="white">
          {value}
        </Text>
      </div>
    </div>
  );
}
