import { useEffect, useState } from "react";
import "twin.macro";
import { WideContainer } from "../../../components/containers/WideContainer";
import { usePerformance } from "../usePerformance";

const TRENDS_WITH_DIR_CSV_KEY = "bf19ca34debf";
const TRENDS_WITHOUT_DIR_CSV_KEY = "b52abee3f48d";
const OVERVIEW_WITH_DIR_CSV_KEY = "ee6f5115513f";
const OVERVIEW_WITHOUT_DIR_CSV_KEY = "6450e15a814d";
const ALL_MEDICATIONS_CSV_KEY = "c03de5d75f24";
const PRESCRIBERS_CSV_KEY = "9f8885a29c82";
const ALL_MEDICATIONS_DETAILED_CSV_KEY = "cc5c49909ef3";
const CONTROLLED_DRUGS_CSV_KEY = "3817340fd996";

export function PerformanceHeaderCSVExportLinks() {
  const { toDate, fromDate, reportId, selectedPharmacies } = usePerformance();
  const [csvExportLink, setCsvExportLink] = useState<string>();

  useEffect(() => {
    setCsvExportLink(undefined);
  }, [toDate, fromDate, reportId, selectedPharmacies]);

  useEffect(() => {
    window.addEventListener("message", messageListenerCallback);

    return () => {
      window.removeEventListener("message", messageListenerCallback);
    };

    function messageListenerCallback(event: MessageEvent) {
      const modeBaseUrl = event.origin;

      const isModeExportMessage =
        modeBaseUrl === "https://app.mode.com" &&
        event.data["type"] === "reportExportPaths";
      if (!isModeExportMessage) return;

      // CSV Exports
      let newExportLink;
      [
        TRENDS_WITH_DIR_CSV_KEY,
        TRENDS_WITHOUT_DIR_CSV_KEY,
        OVERVIEW_WITH_DIR_CSV_KEY,
        OVERVIEW_WITHOUT_DIR_CSV_KEY,
        ALL_MEDICATIONS_CSV_KEY,
        PRESCRIBERS_CSV_KEY,
        ALL_MEDICATIONS_DETAILED_CSV_KEY,
        CONTROLLED_DRUGS_CSV_KEY,
      ].forEach((k) => {
        if (event.data["query_csv_export_paths"][k]) {
          newExportLink = modeBaseUrl + event.data["query_csv_export_paths"][k];
        }
      });
      setCsvExportLink(newExportLink);
    }
  }, []);

  return (
    <WideContainer tw="flex justify-between pt-4">
      {!!csvExportLink && <a href={csvExportLink}>Download as CSV</a>}
    </WideContainer>
  );
}
