import React, { ReactNode } from "react";
import "twin.macro";
import DotLoader from "../../../../components/loaders/DotLoader/DotLoader";
import { Text } from "../../../../components/rxLibrary/typography";

export function ShoppingListTableWrapper({
  loading,
  itemsCount,
  visibleItemsCount,
  emptyText,
  children,
}: {
  loading: boolean;
  itemsCount: number;
  visibleItemsCount: number;
  emptyText: string;
  children: ReactNode;
}) {
  if (loading) {
    return (
      <div tw="flex justify-center bg-white p-[40px]">
        <div tw="w-full">
          <DotLoader />
        </div>
      </div>
    );
  }

  if (itemsCount === 0) {
    return (
      <div tw="bg-white p-[40px]">
        <Text color="grey-1">{emptyText}</Text>
      </div>
    );
  }

  if (visibleItemsCount === 0) {
    return (
      <div tw="bg-white p-[40px]">
        <Text color="grey-1">
          {itemsCount === 1
            ? "There is 1 item in this list; it is filtered out."
            : `There are ${itemsCount} items in this list; they are all filtered out.`}
        </Text>
      </div>
    );
  }

  return <div tw="relative">{children}</div>;
}
