import {
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import tw from "twin.macro";
import "core-js/actual/set-immediate";
import Highlight from "react-highlighter";
import { YELLOW } from "../../../../components/rxLibrary/colors";
import { IconMagnify } from "../../../../components/rxLibrary/icons";
import BuyingPharmacyContext from "../../../../contexts/BuyingPharmacyContext";
import {
  AutoComplete,
  AutoCompleteOption,
  AutoCompleteInputSearch,
} from "../../../../components/antd/AutoComplete";
import { ISetCartItemsSearch } from "../useShoppingListItems/useShoppingListItems.constants";
import {
  SearchBarItem,
  filterSearchBarItems,
  formatSearchBarFilterObj,
} from "../searchBar";

export function ShoppingListSearchBar({
  searchBarItems,
  setSearchBarFilter,
}: {
  searchBarItems: SearchBarItem[];
  setSearchBarFilter: ISetCartItemsSearch;
}) {
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const pms = currentBuyingPharmacy?.pms;

  const [filter, setFilter] = useState("");
  const [options, setOptions] = useState<AutoCompleteOption[]>([]);
  const [hasFocus, setHasFocus] = useState(false);
  const isWide = hasFocus || !!filter;

  const placeholder = hasFocus
    ? "Search Items (Drug Name / NDC / Rx #)"
    : "Search this page";

  const handleOnFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setHasFocus(false);
  }, []);

  const handleSearch = useCallback(
    (value: string) => {
      setFilter(value);
      setImmediate(() => setSearchBarFilter(value));
    },
    [setSearchBarFilter]
  );

  useEffect(() => {
    const searchBarFilterObj = formatSearchBarFilterObj(filter, pms);
    if (!searchBarFilterObj) {
      setOptions([]);
      return;
    }

    setImmediate(() => {
      const newItems = filterSearchBarItems(searchBarFilterObj, searchBarItems);
      const newOptions = newItems.map(({ drugName }) => ({
        value: drugName,
        label: (
          <Highlight search={filter} matchStyle={{ backgroundColor: YELLOW }}>
            {drugName}
          </Highlight>
        ),
      }));

      setOptions(newOptions);
    });
  }, [pms, filter, searchBarItems]);

  return (
    <AutoComplete
      css={isWide ? tw`w-[420px]` : tw`w-[200px]`}
      value={filter}
      options={options}
      onChange={handleSearch}
      popupMatchSelectWidth
    >
      <AutoCompleteInputSearch
        type="text"
        size="large"
        placeholder={placeholder}
        enterButton={<IconMagnify />}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        allowClear
      />
    </AutoComplete>
  );
}
