import React, { useCallback, useContext } from "react";
import "twin.macro";
import "core-js/actual/set-immediate";
import { Link } from "react-router-dom";
import { WideContainer } from "../../../components/containers/WideContainer";
import {
  IconRefresh,
  IconEyeShut,
  IconEye,
  IconPrint,
} from "../../../components/rxLibrary/icons";
import { PrintWrapper } from "../../../components/rxLibrary/print";
import { Box } from "../../../components/rxLibrary/box";
import { Button } from "../../../components/rxLibrary/buttons";
import { Text } from "../../../components/rxLibrary/typography";
import EnhancedClientContext from "../../EnhancedClientContext";

// TODO: remove after fix the refresh button
const DISPLAY_REFRESH_BTN = false;

export function EnhancedSupplierAccountSummaryHeader({
  workstationName,
  shouldShowAllPasswords,
  showAllPasswords,
  hideAllPasswords,
}: {
  shouldShowAllPasswords: boolean;
  workstationName?: string;
  showAllPasswords: () => void;
  hideAllPasswords: () => void;
}) {
  const { refreshSuppliers } = useContext(EnhancedClientContext);

  const print = useCallback(() => {
    showAllPasswords();
    setImmediate(window.print);
  }, [showAllPasswords]);

  return (
    <Box tw="sticky top-0 z-20" bgColor="white">
      <WideContainer>
        <Box tw="flex justify-between items-center pt-20 pb-8">
          <div>
            <Text block size={32}>
              Supplier Management
            </Text>

            {!!workstationName && (
              <Text block color="grey-1">
                Passwords saved on {workstationName}
              </Text>
            )}
          </div>

          <PrintWrapper tw="flex space-x-2">
            {DISPLAY_REFRESH_BTN && (
              <Button
                variant="secondary"
                icon={<IconRefresh />}
                onClick={refreshSuppliers}
              >
                Refresh
              </Button>
            )}

            {shouldShowAllPasswords ? (
              <Button
                variant="secondary"
                icon={<IconEyeShut />}
                onClick={hideAllPasswords}
              >
                Hide All
              </Button>
            ) : (
              <Button
                variant="secondary"
                icon={<IconEye />}
                onClick={showAllPasswords}
              >
                Show All Passwords
              </Button>
            )}

            <Button variant="secondary" icon={<IconPrint />} onClick={print}>
              Print
            </Button>

            <Link to="/">
              <Button>Done</Button>
            </Link>
          </PrintWrapper>
        </Box>
      </WideContainer>
    </Box>
  );
}
