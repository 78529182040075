import React, { Fragment, useCallback, useMemo } from "react";
import { styled } from "twin.macro";
import isNil from "lodash/isNil";
import { OrderHistoryOrderSummary } from "../../../../../utilities/types";
import { currencyWithCentsAtAllTimes } from "../../../../../utilities/numbers/currency";
import { getShippingTrackingUrl } from "../../../../../utilities/getShippingTrackingUrl";
import { formatNdc } from "../../../../../utilities/ndc/formatNdc";
import { WHITE } from "../../../../../components/rxLibrary/colors";
import { Text } from "../../../../../components/rxLibrary/typography";
import { SimpleTable } from "../../../../../components/rxLibrary/tables";
import { Warning } from "../../../../../components/rxLibrary/warnings";

const Wrapper = styled.div`
  background-color: ${WHITE};
`;

const Table = styled(SimpleTable)`
  tr.parentErrorRow,
  tr.parentErrorRow td {
    border-bottom: 0;
  }

  tr.errorRow,
  tr.errorRow td {
    padding-top: 0;
    border-top: 0;
  }
`;

export function OrderHistoryRowSummary({
  itemSummary,
}: {
  itemSummary: OrderHistoryOrderSummary;
}) {
  const { items, shipDate, shippingId, shippingMethod, trackingNumber } =
    itemSummary;

  const title = useMemo(() => {
    return [
      shipDate
        ? `Shipped: ${shipDate}`
        : "No shipping information available",
      shippingMethod,
      !!shippingId && `Shipping ID: ${shippingId}`,
    ]
      .filter(Boolean)
      .join(" | ");
  }, [shippingId, shippingMethod, shipDate]);

  const shippingTrackingUrl = useMemo(() => {
    return getShippingTrackingUrl({
      shippingMethod,
      trackingNumber,
    });
  }, [shippingMethod, trackingNumber]);

  const openShippingTrackingPage = useCallback(() => {
    window.open(
      shippingTrackingUrl,
      "_blank",
      "noopener,noreferrer,height=570,width=570,toolbar=no,menubar=no,status=no,directories=no"
    );
  }, [shippingTrackingUrl]);

  return (
    <Wrapper tw="p-4">
      <div tw="flex items-center space-x-4 mb-2">
        <Text color="blue-1">
          {title}
          {!!shippingTrackingUrl && (
            <>
              {" | Tracking: "}
              <button tw="underline" onClick={openShippingTrackingPage}>
                {trackingNumber}
              </button>
            </>
          )}
        </Text>
      </div>

      <Table>
        <thead>
          <tr>
            <th />
            <th tw="w-[99px]">NDC</th>
            <th tw="w-[64px]">Quantity</th>
            <th tw="w-[125px]">Original Unit Price</th>
            <th tw="w-[144px]">Discounted Unit Price</th>
            <th tw="w-[148px]">Total Discounted Price</th>
          </tr>
        </thead>

        <tbody>
          {items.map((item, i) => {
            const {
              ndc,
              quantity,
              drugDescription,
              originalUnitPrice,
              discountedUnitPrice,
              totalDiscountedPrice,
              errorMessage,
            } = item;

            const ndcFormatted = !isNil(ndc) && formatNdc(ndc);

            const discountedUnitPriceFormatted =
              !isNil(discountedUnitPrice) &&
              currencyWithCentsAtAllTimes(discountedUnitPrice);

            const totalDiscountedPriceFormatted =
              !isNil(totalDiscountedPrice) &&
              currencyWithCentsAtAllTimes(totalDiscountedPrice);

            const originalUnitPriceFormatted =
              !isNil(originalUnitPrice) &&
              currencyWithCentsAtAllTimes(originalUnitPrice);

            return (
              <Fragment key={i}>
                <tr className="parentErrorRow">
                  <td>{drugDescription}</td>
                  <td>{ndcFormatted}</td>
                  <td>{quantity}</td>
                  <td>{originalUnitPriceFormatted}</td>
                  <td>{discountedUnitPriceFormatted}</td>
                  <td>{totalDiscountedPriceFormatted}</td>
                </tr>

                {errorMessage && (
                  <tr className="errorRow">
                    <td colSpan={6}>
                      <Warning type="error">{errorMessage}</Warning>
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </Wrapper>
  );
}
