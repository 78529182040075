import React, { useCallback, useState } from "react";
import { css, styled } from "twin.macro";
import { GREY_1, GREY_2, GREY_3, BLUE_1, BLACK } from "../colors";
import { IconDown, IconSortAscend, IconSortDescend } from "../icons";
import { DEFAULT_TEXT_CSS, Text } from "../typography";
import { Select, SelectProps } from "./select";

const SortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 38px;
  color: ${GREY_1};
  background-color: ${GREY_3};
  border-radius: 0 8px 8px 0;

  :hover {
    color: ${GREY_3};
    background-color: ${GREY_1};
  }
`;

const Wrapper = styled.div(
  ({ selectMinWidth }: { selectMinWidth?: number }) => [
    !!selectMinWidth &&
      css`
        .ant-select {
          min-width: ${selectMinWidth}px;
        }
      `,
    css`
      display: flex;
      border: 1px solid ${GREY_2};
      border-radius: 8px;

      :hover {
        border-color: ${BLUE_1};
      }

      .ant-select {
        border: none;
        border-radius: 8px 0 0 8px;
        height: 38px;

        :hover {
          svg {
            color: ${GREY_1};
          }
        }

        .ant-select-selector {
          border: none;
          border-radius: 8px 0 0 8px;
        }

        .ant-select-selection-item,
        .ant-select-item-option-content {
          ${DEFAULT_TEXT_CSS}
        }

        svg {
          color: ${BLACK};
        }
      }
    `,
  ]
);

export enum SortDirectionEnum {
  ASC = "ASC",
  DESC = "DESC",
}

type SortBySelectProps = Omit<
  SelectProps,
  "size" | "placeholder" | "onDropdownVisibleChange" | "suffixIcon" | "onChange"
> & {
  sortOrder: SortDirectionEnum;
  label?: string;
  selectMinWidth?: number;
  onSortOrderChange: (v: SortDirectionEnum) => void;
  onChange: (value: any) => void;
};

export type SortBySelectOptions = SortBySelectProps["options"];

export function SortBySelect({
  label = "Sort By",
  sortOrder,
  selectMinWidth,
  onChange,
  onSortOrderChange,
  ...props
}: SortBySelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const isSortOrderAsc = sortOrder === SortDirectionEnum.ASC;

  const toggleSortOrder = useCallback(() => {
    const newValue = isSortOrderAsc
      ? SortDirectionEnum.DESC
      : SortDirectionEnum.ASC;
    onSortOrderChange(newValue);
  }, [isSortOrderAsc, onSortOrderChange]);

  const onDropdownVisibleChange = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  return (
    <div tw="flex space-x-[5px] items-center">
      <Text size="medium">{label}</Text>

      <Wrapper selectMinWidth={selectMinWidth}>
        <Select
          {...props}
          suffixIcon={!isOpen && <IconDown />}
          placeholder="Sort By"
          onChange={onChange}
          onDropdownVisibleChange={onDropdownVisibleChange}
        />

        <SortButton onClick={toggleSortOrder}>
          {isSortOrderAsc ? <IconSortAscend /> : <IconSortDescend />}
        </SortButton>
      </Wrapper>
    </div>
  );
}
