import React, {
  useEffect,
  useContext,
  useState,
  createContext,
  useCallback,
  useMemo,
} from "react";
import keyBy from "lodash/keyBy";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { Dayjs } from "dayjs";
import type {
  ItemNote,
  Supplier,
  ItemInCart,
  PrescriptionDetail,
  ItemPurchaseDetails,
  ShoppingSupplierCheck,
  PurchaseQuantityMethodEnum,
  PrescriptionGroupAdjustment,
} from "../../utilities/types";
import BuyingPharmacyContext from "../BuyingPharmacyContext";
import { useStorageUpdater } from "../StorageContext";
import {
  useShoppingCartServerState,
  useShoppingCartServerUpdater,
} from "../ShoppingCartServerContext";
import { formatPurchaseByToString } from "../../utilities/dates/purchaseBy/formatPurchaseByToString";
import { isInventoryId } from "../../utilities/prescriptions/isInventoryId";
import { getPrescriptionId } from "../../utilities/prescriptions/getPrescriptionId";
import {supplierOrderItemsAreEqual} from "./ShoppingContext.utils";
import {
  getShippingInfo,
  buildShippingFeeSimulation,
} from "../../utilities/shipping/shippingInfo";
import {
  getOptimizedCartWithUpdatedBuyLaterItemPurchaseBy,
  getOptimizedCartWithMoveBuyLaterItemToShoppingList,
  getOptimizedCartWithMovedShoppingListItemToBuyLater,
} from "./ShoppingContext.optimizedCartItemOperations";
import {
  SupplierOrder_All,
  SupplierOrderItemRecommendation_All,
  CatalogDrug_All,
  OptimizedCartResponse_All,
  uniqueCatalogDrugID
} from "../../services/types";
import {
  getQueryParamFirstValue
} from "../../utilities/queryParams/getQueryParamFirstValue";
import {getOptimizedCart} from "../../services/legacy/optimizations";
import {useAuth0} from "../AuthenticationContext";
import {getQueryParams} from "../../utilities/queryParams/getQueryParams";
import {getPrescriptions} from "../../services/legacy/prescriptions";
import {
  mergePmsDataWithCartData
} from "../../pages/shopping/ShoppingList/mergePmsDataWithCartData";

type ShoppingStateContextType = {
  lookupOptimizedCartOp: {cartId: number, loaded?: boolean, processed?: boolean} | null;
  optimizeCartResponse: OptimizedCartResponse_All;
  hasPricesChanged: boolean;
  orgSuppliersList: ShoppingSupplierCheck[];
  activeCartItemIds: Record<string, boolean>;
  serverItemPurchaseDetails: ItemPurchaseDetails[];
  serverInventoryItemsInCart: ItemInCart[];
  prescriptionItemsInCart: ItemInCart[];
  serverPrescriptionGroupAdjustments: PrescriptionGroupAdjustment[];
  getShippingFeeSimulation: (
    supplier: Supplier,
    {
      itemToAdd,
      itemToRemove,
    }: { itemToAdd?: CatalogDrug_All; itemToRemove?: CatalogDrug_All }
  ) => ReturnType<typeof buildShippingFeeSimulation>;
  addOptimizeCartResponse: (items: OptimizedCartResponse_All) => void; // needs to be in state b/c it triggers undoable operations
  getPrescriptionsWithDetails: (rxNumbers: string[]) => PrescriptionDetail[];
  updateItemPurchaseDetailValue_undoable: <K extends keyof ItemPurchaseDetails>(
    id: string,
    key: K,
    value: ItemPurchaseDetails[K],
    options?: {
      undo?: {
        id: string;
        key: K;
        value: ItemPurchaseDetails[K];
      };
    }
  ) => void; // mutation
  deletePrescriptionGroupAdjustmentsByRxNumbers_undoable: (
    rxNumbers: string[]
  ) => void; // mutation
  isVisitedItem: (item: SupplierOrderItemRecommendation_All) => boolean;
  getVisitedItemsCount(items: SupplierOrderItemRecommendation_All[]): number;
  useCase: "newlyCreated" | "previouslyCreated"
};

type ShoppingUpdaterContextType = {
  setPrescriptionItemsActive: React.Dispatch<React.SetStateAction<boolean>>;
  addOrgSuppliersList: (items: ShoppingSupplierCheck[]) => void; // non-mutation
  addSupplierToOptimizeCart: (
    supplierObj?: Supplier | null,
    newSupplierOrder?: SupplierOrder_All
  ) => void; // non-mutation
  updateOptimizeCartResponse: (
    supplierObj?: Supplier | null,
    oldItem?: SupplierOrderItemRecommendation_All,
    newSupplierObj?: Supplier | null,
    newItem?: CatalogDrug_All
  ) => void; // non-mutation
  updateOptimizeCartQty: (
    newQty: number,
    item: SupplierOrderItemRecommendation_All,
    supplierObj?: Supplier | null
  ) => void; // non-mutation
  updateOptimizeCartQtyAlt: (
    newQty: number,
    currentItem: SupplierOrderItemRecommendation_All,
    altItem: CatalogDrug_All,
    supplierObj?: Supplier | null
  ) => void; // non-mutation
  removeFromCart: (id: string) => void; // mutation
  updateItemPurchaseDetails: (items: ItemPurchaseDetails[]) => void; // TODO mutation remove this, too destructive
  updateItemPurchaseDetailStatus: (
    id: string,
    status: string,
    ndc: string,
    manufactutrer: boolean,
    packSize: boolean,
    currentFillId?: string,
    packQuantity?: number,
    processedAt?: string,
    noManufacturerPreference?: boolean,
    supplierId?: number
  ) => void; // mutation
  updateItemPurchaseDetailPackQuantity: (
    id: string,
    ndc: string,
    status: string,
    manufactutrer: boolean,
    packSize: boolean,
    packQuantity: number,
    currentFillId?: string,
    noManufacturerPreference?: boolean
  ) => void;

  addInventoryItem: (item: ItemInCart) => void; // mutation
  setPrescriptionItemsInCart: (items: ItemInCart[]) => void; // TODO mutation remove this, too destructive
  setHasPricesChanged: React.Dispatch<React.SetStateAction<boolean>>;
  deletePrescription: (id: string) => void; // mutation
  updatePrescriptionPackSize: (
    prescription: ItemInCart,
    packSize: boolean
  ) => void; // mutation
  updatePrescriptionPackQuantity: (
    prescription: ItemInCart,
    packQuantity: number
  ) => void; // mutation
  updatePrescriptionManufacturer: (
    prescription: ItemInCart,
    manufactutrer: boolean
  ) => void; // mutation
  updatePrescriptionNote: (prescription: ItemInCart, note?: ItemNote) => void; // mutation
  updatePrescriptionStatus: (prescription: ItemInCart, status: string) => void; // mutation
  updatePrescriptionPurchaseBy: (id: string, purchaseBy?: Date | Dayjs) => void; // mutation
  updatePrescriptionsPurchaseBy: (
    rxNumbers: string[],
    purchaseBy?: Date | Dayjs
  ) => void; // mutation
  updatePrescriptionGroupPurchaseQuantity: (
    rxNumbers: string[],
    purchaseQuantityMethod: PurchaseQuantityMethodEnum,
    num?: string
  ) => void; // mutation
  updatePrescriptionGroupChosenSubstitution: (
    rxNumbers: string[],
    allowPackSizeSubstitution: boolean,
    allowManufacturerSubstitution: boolean
  ) => void; // mutation
  deletePrescriptionGroupAdjustments: (
    adjustments: PrescriptionGroupAdjustment[]
  ) => void; // mutation
  moveShoppingListItemToBuyLater: (v: {
    date: Date | Dayjs;
    item: SupplierOrderItemRecommendation_All;
    supplierId: number;
  }) => void; // mutation
  updateBuyLaterItemPurchaseBy: (
    rxNumbers: string[],
    date: Date | Dayjs
  ) => void; // mutation
  moveBuyLaterItemToShoppingList: (v: {
    newItem: SupplierOrderItemRecommendation_All;
    supplierId: number;
  }) => void; // mutation
  removeVisitedItem: (
    item: SupplierOrderItemRecommendation_All
  ) => void;
  addVisitedItems: (
    items: SupplierOrderItemRecommendation_All[]
  ) => void;
};

const ShoppingStateContext = createContext<
  ShoppingStateContextType | undefined
>(undefined);
const ShoppingUpdaterContext = createContext<
  ShoppingUpdaterContextType | undefined
>(undefined);

if (process.env.REACT_APP_USE_CONTEXT_DEVTOOL) {
  ShoppingStateContext.displayName = "ShoppingStateContext";
  ShoppingUpdaterContext.displayName = "ShoppingUpdateContext";
}

export const ShoppingContextProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const useCase = useMemo(() => {
    if (getQueryParamFirstValue(getQueryParams()["from"]) === "list") {
      return "previouslyCreated";
    }
    return "newlyCreated";
  }, []);

  const { getAccessTokenSilently } = useAuth0();
  const {
    itemPurchaseDetails: serverItemPurchaseDetails,
    inventoryItemsInCart: serverInventoryItemsInCart,
    prescriptionGroupAdjustments: serverPrescriptionGroupAdjustments,
  } = useShoppingCartServerState();
  const { submitShoppingCartMutation, submitOptimizationUpdate } = useShoppingCartServerUpdater();
  const { setWaitButtonMode } = useStorageUpdater();
  const { currentBuyingPharmacyId, getSupplierById } = useContext(BuyingPharmacyContext);

  const [hasPricesChanged, setHasPricesChanged] = useState(false);

  const [lookupOptimizedCartOp, setLookupOptimizedCartOp] = useState<{cartId: number, loaded?: boolean, processed?: boolean} | null>(null);
  const [loadedOptimization, setLoadedOptimization] =
    useState<OptimizedCartResponse_All | null>(null);
  const [cartReconstructedOptimization, setCartReconstructedOptimization] =
    useState<OptimizedCartResponse_All | null>(null);
  const [optimizeCartResponse, setOptimizeCartResponse] =
    useState<OptimizedCartResponse_All>({});

  const optimizationPrescriptionGroupAdjustments = useMemo(() => {
    return optimizeCartResponse?.data?.cartSnapshot.prescriptionGroupAdjustments || [];
  }, [optimizeCartResponse]);

  const optimizationItemPurchaseDetails = useMemo(() => {
    return optimizeCartResponse?.data?.cartSnapshot.cart || [];
  }, [optimizeCartResponse]);

  const [orgSuppliersList, setOrgSuppliersList] = useState<
    ShoppingSupplierCheck[]
  >([]);
  const [prescriptionItemsActive, setPrescriptionItemsActive] =
    useState<boolean>(false);
  const [prescriptionItemsInCart, setPrescriptionItemsInCart] = useState<
    ItemInCart[]
  >([]);
  const [prescriptionsById, setPrescriptionsById] = useState<
    Record<string, ItemInCart>
  >({});

  const updateOptimizeCartResponseAll = useCallback((arg: React.SetStateAction<OptimizedCartResponse_All>) => {
    setOptimizeCartResponse((prev) => {
      const newOptimizeCart = typeof arg === "function" ? arg(prev) : arg;
      if (newOptimizeCart.data) {
        newOptimizeCart.data.editVersion++;
      }
      submitOptimizationUpdate(JSON.parse(JSON.stringify(newOptimizeCart)));
      return newOptimizeCart;
    });
  }, [setOptimizeCartResponse]);

  useEffect(() => {
    if (lookupOptimizedCartOp) {
      return;
    }
    if (optimizeCartResponse && Object.keys(optimizeCartResponse).length > 0) {
      setLookupOptimizedCartOp({cartId: optimizeCartResponse.data?.id || -1, loaded: true, processed: true});
      return;
    }
    if (!currentBuyingPharmacyId) {
      return;
    }
    const cartIdStr = getQueryParamFirstValue(getQueryParams()["optimizedCartId"]);
    if (!cartIdStr) return;
    const cartId = parseInt(cartIdStr, 10);
    if (cartId) {
      setLookupOptimizedCartOp({cartId});
    } else {
      setLookupOptimizedCartOp({cartId: 0, loaded: true, processed: true});
    }
  }, [currentBuyingPharmacyId, optimizeCartResponse, lookupOptimizedCartOp, setLookupOptimizedCartOp, getAccessTokenSilently]);

  useEffect(() => {
    (async () => {
      if (
        !lookupOptimizedCartOp ||
        lookupOptimizedCartOp.loaded ||
        !currentBuyingPharmacyId
      ) {
        return;
      }
      setLookupOptimizedCartOp({...lookupOptimizedCartOp, loaded: true});
      try {
        const token = await getAccessTokenSilently();

        const { data } = await getPrescriptions(
          currentBuyingPharmacyId,
          token,
          false,
          false
        );

        // If we have data
        if (data?.prescriptions) {
          setPrescriptionItemsActive(true);
        }

        const response = await getOptimizedCart(lookupOptimizedCartOp.cartId, token);
        if (response.data) {
          response.data.prescriptions = data?.prescriptions || [];
          setLoadedOptimization(response);
        }
      }
      catch (e) {
        console.error(e);
      }
    })();
  }, [currentBuyingPharmacyId, lookupOptimizedCartOp, setLoadedOptimization, getAccessTokenSilently]);

  useEffect(() => {
    if (loadedOptimization && loadedOptimization.data) {
      const newPrescriptionsById: Record<string, ItemInCart> = {};
      const merged = mergePmsDataWithCartData(loadedOptimization.data.prescriptions, loadedOptimization.data.cartSnapshot.cart, loadedOptimization.data.referenceData.drugs);
      merged.forEach((item) => {
        if (item.rxNumber) {
          newPrescriptionsById[item.rxNumber] = item;
        }
      });

      loadedOptimization.data.cartSnapshot.inventory.forEach((item) => {
        if (item.key) {
          newPrescriptionsById[item.key] = item;
        }
      });
      setPrescriptionItemsInCart(merged.filter((item) => item.rxNumber));
      setPrescriptionsById(newPrescriptionsById);
      setCartReconstructedOptimization(loadedOptimization);
    }
  }, [loadedOptimization, setPrescriptionsById]);

  useEffect(() => {
    if (lookupOptimizedCartOp?.processed) {
      return;
    }
    if (cartReconstructedOptimization && lookupOptimizedCartOp?.cartId) {
      setOptimizeCartResponse(cartReconstructedOptimization);
      setLookupOptimizedCartOp({...lookupOptimizedCartOp, processed: true});
    }
  }, [cartReconstructedOptimization, lookupOptimizedCartOp]);

  const activeCartItemIds = useMemo(() => {
    const newActiveCartItemIds: Record<string, boolean> = {};
    serverInventoryItemsInCart.forEach((item) => {
      newActiveCartItemIds[item.key as string] = true;
    });
    if (prescriptionItemsActive) {
      prescriptionItemsInCart.forEach((item) => {
        newActiveCartItemIds[item.rxNumber as string] = true;
      });
    }
    return newActiveCartItemIds;
  }, [prescriptionItemsActive, serverInventoryItemsInCart, prescriptionItemsInCart]);

  const addOrgSuppliersList = useCallback((items: ShoppingSupplierCheck[]) => {
    const newItems = items.filter((item) => item.check);
    setOrgSuppliersList(newItems);
  }, []);

  /**
   * Simulate if we will have a shipping cost after add/remove items
   */
  const getShippingFeeSimulation = useCallback(
    (
      supplier: Supplier,
      {
        itemToAdd,
        itemToRemove,
      }: { itemToAdd?: CatalogDrug_All; itemToRemove?: CatalogDrug_All }
    ) => {
      const supplierOrders = optimizeCartResponse.data?.selections.supplierOrders;
      const shippingFeeSimulation = buildShippingFeeSimulation({
        supplier,
        itemToAdd,
        itemToRemove,
        supplierOrders,
      });
      return shippingFeeSimulation;
    },
    [optimizeCartResponse.data?.selections.supplierOrders]
  );

  const addSupplierToOptimizeCart = useCallback(
    (supplierObj?: Supplier | null, newSupplierOrder?: SupplierOrder_All) => {
      const supplierId = supplierObj?.id;
      if (!supplierId || !newSupplierOrder) return;

      updateOptimizeCartResponseAll((prevOptimizeCart) => {
        if (!prevOptimizeCart) return prevOptimizeCart;

        const optimizeCart = cloneDeep(prevOptimizeCart);
        const supplierOrders = optimizeCart?.data?.selections.supplierOrders ?? [];
        const supplierCheck = supplierOrders.filter(
          (s) => s.supplierId === supplierId
        );

        if (supplierCheck.length !== 0) {
          return prevOptimizeCart;
        }

        // supplier is not in the list
        const finalCart = JSON.parse(JSON.stringify(optimizeCart));
        if (finalCart.data) {
          finalCart.data.selections.supplierOrders = [...supplierOrders, newSupplierOrder];
        }
        return finalCart;
      });
    },
    []
  );

  const addInventoryItem = useCallback(
    (item: ItemInCart) => {
      submitShoppingCartMutation({
        name: "addInventoryItem",
        params: { item },
      });
    },
    [submitShoppingCartMutation]
  );

  const updateInventoryItem = useCallback(
    <K extends keyof ItemInCart>(
      key: string,
      field: K,
      value: ItemInCart[K]
    ) => {
      submitShoppingCartMutation({
        name: "updateInventoryItem",
        params: { key, field, value },
      });
    },
    [submitShoppingCartMutation]
  );

  const updatePmsItem = useCallback(
    <K extends keyof ItemInCart>(
      rxNumber: string,
      field: K,
      value: ItemInCart[K]
    ) => {
      const itemIndex = prescriptionItemsInCart.findIndex((item) => {
        return item.rxNumber === rxNumber;
      });
      if (itemIndex === -1) return;

      const newPrescriptionItemsInCart = [...prescriptionItemsInCart];
      newPrescriptionItemsInCart[itemIndex] = {
        ...newPrescriptionItemsInCart[itemIndex],
        [field]: value,
      };

      setPrescriptionItemsInCart(newPrescriptionItemsInCart);
    },
    [prescriptionItemsInCart]
  );

  const updateItemPurchaseDetails = useCallback(
    (items: ItemPurchaseDetails[]) => {
      // make sure we only add valid items
      submitShoppingCartMutation({
        name: "updateItemPurchaseDetails",
        params: { items },
      });
    },
    [submitShoppingCartMutation]
  );

  const removeFromCart = useCallback(
    (id: string) => {
      submitShoppingCartMutation({
        name: "removeFromCart",
        params: { id },
      });
    },
    [submitShoppingCartMutation]
  );

  const updatePrescriptionGroupPurchaseQuantity = useCallback(
    (
      rxNumbers: string[],
      purchaseQuantityMethod: PurchaseQuantityMethodEnum,
      num?: string
    ) => {
      submitShoppingCartMutation({
        name: "updatePrescriptionGroupPurchaseQuantity",
        params: {
          rxNumbers,
          purchaseQuantityMethod,
          num,
        },
      });
    },
    [submitShoppingCartMutation]
  );

  const updatePrescriptionGroupChosenSubstitution = useCallback(
    (
      rxNumbers: string[],
      allowPackSizeSubstitution: boolean,
      allowManufacturerSubstitution: boolean
    ) => {
      submitShoppingCartMutation({
        name: "updatePrescriptionGroupChosenSubstitution",
        params: {
          rxNumbers,
          allowPackSizeSubstitution,
          allowManufacturerSubstitution,
        },
      });
    },
    [submitShoppingCartMutation]
  );

  const deletePrescriptionGroupAdjustments = useCallback(
    (adjustments: PrescriptionGroupAdjustment[]) => {
      submitShoppingCartMutation({
        name: "deletePrescriptionGroupAdjustments",
        params: { adjustments },
      });
    },
    [submitShoppingCartMutation]
  );

  const deletePrescriptionGroupAdjustmentsByRxNumbers_undoable = useCallback(
    (rxNumbers: string[]) => {
      let adjustmentsToDelete = optimizationPrescriptionGroupAdjustments.filter(
        (adjustment) => {
          return rxNumbers.some((rn) => adjustment.rxNumbers.includes(rn));
        }
      );
      if (adjustmentsToDelete.length === 0) {
        adjustmentsToDelete = serverPrescriptionGroupAdjustments.filter(
          (adjustment) => {
            return rxNumbers.some((rn) => adjustment.rxNumbers.includes(rn));
          }
        );
      }
      if (adjustmentsToDelete.length > 0) {
        submitShoppingCartMutation({
          name: "deletePrescriptionGroupAdjustments",
          params: { adjustments: adjustmentsToDelete },
          options: { undo: { adjustments: adjustmentsToDelete } },
        });
      }
    },
    [submitShoppingCartMutation, optimizationPrescriptionGroupAdjustments, serverPrescriptionGroupAdjustments]
  );

  // secId is to cover similar NDC on P3
  // has to be removed once the whole flow is in place
  const updateItemPurchaseDetailValue = useCallback(
    <K extends keyof ItemPurchaseDetails>(
      id: string,
      key: K,
      value: ItemPurchaseDetails[K]
    ) => {
      submitShoppingCartMutation({
        name: "updateItemPurchaseDetailValue",
        params: { id, key, value },
      });
    },
    [submitShoppingCartMutation]
  );

  const updateItemPurchaseDetailValue_undoable = useCallback(
    <K extends keyof ItemPurchaseDetails>(
      id: string,
      key: K,
      value: ItemPurchaseDetails[K]
    ) => {
      let existingItem = optimizationItemPurchaseDetails.find((item) => {
        return item.id === id;
      });
      if (!existingItem) {
        existingItem = serverItemPurchaseDetails.find((item) => {
          return item.id === id;
        });
      }
      if (existingItem) {
        submitShoppingCartMutation({
          name: "updateItemPurchaseDetailValue",
          params: { id, key, value },
          options: {
              undo: { id, key, value: existingItem[key] },
            },
        });
      }
    },
    [optimizationItemPurchaseDetails, serverItemPurchaseDetails, submitShoppingCartMutation]
  );

  const updateItemPurchaseDetailStatus = useCallback(
    (
      id: string,
      status: string,
      ndc: string,
      manufactutrer: boolean,
      packSize: boolean,
      currentFillId?: string,
      packQuantity?: number,
      processedAt?: string,
      noManufacturerPreference?: boolean,
      supplierId?: number
    ) => {
      const obj = {
        id: id,
        status: status,
        manufactutrer: manufactutrer,
        packSize: packSize,
        ndc: ndc,
        currentFillId: currentFillId,
        packQuantity: packQuantity,
        processedAt: processedAt,
        noManufacturerPreference: noManufacturerPreference,
        supplierId: supplierId,
        active: true,
      };

      submitShoppingCartMutation({
        name: "updateItemPurchaseDetailStatus",
        params: obj,
      });
    },
    [submitShoppingCartMutation]
  );

  const updateItemPurchaseDetailPackQuantity = useCallback(
    (
      id: string,
      ndc: string,
      status: string,
      manufactutrer: boolean,
      packSize: boolean,
      packQuantity: number,
      currentFillId?: string,
      noManufacturerPreference?: boolean
    ) => {
      const obj = {
        id: id,
        status: status,
        ndc: ndc,
        manufactutrer: manufactutrer,
        packSize: packSize,
        packQuantity: packQuantity || 1, // _added_ items have min qty of 1
        currentFillId: currentFillId,
        active: true,
        noManufacturerPreference,
      };
      submitShoppingCartMutation({
        name: "updateItemPurchaseDetailPackQuantity",
        params: obj,
      });
    },
    [submitShoppingCartMutation]
  );

  const addOptimizeCartResponse = useCallback(
    (optimizedCart: OptimizedCartResponse_All) => {
      setOptimizeCartResponse(optimizedCart);

      // TODO: This could be improved to be more efficient
      const allRxNumbers: string[] = [];
      optimizedCart.data?.selections.supplierOrders.forEach(({ items, supplierId }) => {
        items.forEach((item) => {
          item.rxNumbers.forEach((rx) => {
            updateItemPurchaseDetailValue_undoable(rx, "status", "processed");
            updateItemPurchaseDetailValue_undoable(
              rx,
              "supplierId",
              supplierId
            );
            allRxNumbers.push(rx);
          });
        });
      });
      deletePrescriptionGroupAdjustmentsByRxNumbers_undoable(allRxNumbers);
    },
    [
      updateItemPurchaseDetailValue_undoable,
      deletePrescriptionGroupAdjustmentsByRxNumbers_undoable,
    ]
  );

  const updateOptimizeCartResponse = useCallback(
    (
      oldSupplierObj?: Supplier | null,
      currentItem?: SupplierOrderItemRecommendation_All,
      newSupplierObj?: Supplier | null,
      newItem?: CatalogDrug_All
    ) => {
      const oldSupplierId = oldSupplierObj?.id;
      const newSupplierId = newSupplierObj?.id;
      let referenceItem: SupplierOrderItemRecommendation_All | undefined =
        undefined;

      updateOptimizeCartResponseAll((prevState: OptimizedCartResponse_All) => {
        const theOptimizeCart: OptimizedCartResponse_All = cloneDeep(prevState);

        const supplierOrders: SupplierOrder_All[] | undefined = theOptimizeCart.data?.selections.supplierOrders;

        // get number of suppliers
        const supplierCount = supplierOrders?.length;

        // suppliers minus current and new
        let finalSupplier: SupplierOrder_All[] | undefined =
          supplierOrders?.filter(
            (s) =>
              s.supplierId !== oldSupplierId && s.supplierId !== newSupplierId
          );

        // get supplier old object
        const oldSupplier: SupplierOrder_All | undefined = supplierOrders?.find(
          (s) => s.supplierId === oldSupplierId
        );

        if (oldSupplier) {
          referenceItem =
            oldSupplier.items.find((item) =>
              supplierOrderItemsAreEqual(item, currentItem)
            ) || currentItem;
          const otherItems = oldSupplier.items.filter(
            (item) => !supplierOrderItemsAreEqual(item, currentItem)
          );
          // all items except the old one
          oldSupplier.items = otherItems;

          // Old Supplier has been updated
          finalSupplier?.push(oldSupplier);
        }

        // new supplier
        if (newItem) {
          // get new supplier
          const tmpSupplier = theOptimizeCart.data?.selections.supplierOrders.find(
            (s) => s.supplierId === newSupplierId
          );

          if (tmpSupplier) {
            const newSupplier = Object.assign({}, tmpSupplier);
            // Alternatives
            const selectedAlternative =
              referenceItem?.alternatives.find((alt: CatalogDrug_All) =>
                supplierOrderItemsAreEqual(alt, newItem)
              ) || newItem;

            const newAlternatives = referenceItem?.alternatives.filter(
              (alt) => !supplierOrderItemsAreEqual(alt, newItem)
            );

            const newItems = [...newSupplier.items];

            if (referenceItem?.referenceData.catalogInfo && referenceItem?.referenceData.drugInfo) {
              const newAlt: CatalogDrug_All = {
                drug: {id: referenceItem.drug.id},
                catalogInfo: {id: referenceItem.catalogInfo.id},
                referenceData: {
                  catalogInfo: referenceItem?.referenceData.catalogInfo,
                  drugInfo: referenceItem?.referenceData.drugInfo,
                },
                numPackages: referenceItem?.numPackages || 0,
              };
              newAlternatives?.push(newAlt);
            }

            const theItem: SupplierOrderItemRecommendation_All = {
              alternatives: newAlternatives || [],
              drug: { id: selectedAlternative.drug.id},
              catalogInfo: {id: selectedAlternative.catalogInfo.id},
              numPackages: selectedAlternative.numPackages,
              originalNumPackages: referenceItem?.originalNumPackages,
              supplierId: newSupplierId,
              manufacturerChanged: referenceItem?.manufacturerChanged,
              packSizeChanged: referenceItem?.packSizeChanged,
              rxNumbers: referenceItem?.rxNumbers || [],
              referenceData: {
                drugInfo: selectedAlternative.referenceData.drugInfo,
                catalogInfo: selectedAlternative.referenceData.catalogInfo,
              }
            };

            newItems.push(theItem);
            // add new
            newSupplier.items = newItems;

            // old and new can be the same
            if (oldSupplierId === newSupplierId) {
              finalSupplier = theOptimizeCart.data?.selections.supplierOrders.filter(
                (s: SupplierOrder_All) => s.supplierId !== oldSupplierId
              );
            }
            finalSupplier?.push(newSupplier);
          }
        }

        if (finalSupplier && finalSupplier.length === supplierCount) {
          const finalCart = JSON.parse(JSON.stringify(theOptimizeCart));
          if (finalCart.data) {
            finalCart.data.selections.supplierOrders = finalSupplier;
          }
          return finalCart;
        } else {
          return prevState;
        }
      });
    },
    [updateOptimizeCartResponseAll]
  );

  const updateOptimizeCartQty = useCallback(
    (
      newQty: number,
      currentItem: SupplierOrderItemRecommendation_All,
      supplierObj?: Supplier | null
    ) => {
      const supplierId = supplierObj?.id;
      if (!supplierId) return;

      updateOptimizeCartResponseAll((prevState) => {
        const oldOptimizeCart = cloneDeep(prevState);
        const supplierOrders = oldOptimizeCart.data?.selections.supplierOrders;
        if (!supplierOrders) {
          return prevState;
        }

        const supplier = supplierOrders.find((s) => {
          return s.supplierId === supplierId;
        });
        if (!supplier) {
          return prevState;
        }

        const supplierItemIndex = supplier.items.findIndex((item) => {
          return supplierOrderItemsAreEqual(item, currentItem);
        });

        if (supplierItemIndex === -1) {
          return prevState;
        }

        supplier.items[supplierItemIndex].numPackages = newQty;
        const newSupplierOrders = supplierOrders.filter(
          (s) => s.supplierId !== supplierId
        );

        const newOptimizedCart = {
          ...oldOptimizeCart,
          data: {
            ...oldOptimizeCart.data,
            supplierOrders: [...newSupplierOrders, supplier],
          },
        } as OptimizedCartResponse_All;

        return newOptimizedCart;
      });

      setTimeout(() => {
        setWaitButtonMode(false);
      }, 0);
    },
    [updateOptimizeCartResponseAll, setWaitButtonMode]
  );

  const removeVisitedItem = useCallback((item: SupplierOrderItemRecommendation_All) => {
    const itemId = uniqueCatalogDrugID(item);
    updateOptimizeCartResponseAll((prevState) => {
      if (!prevState.data) {
        return prevState;
      }
      const newVisitedItemsIds = { ...prevState.data.selections.visitedItemsIds };
      delete newVisitedItemsIds[itemId];
      return {
        ...prevState,
        data: {
          ...prevState.data,
          selections: {
            ...prevState.data.selections,
            visitedItemsIds: newVisitedItemsIds,
          },
        },
      };
    });
  }, [updateOptimizeCartResponseAll]);
  const addVisitedItems = useCallback((items: SupplierOrderItemRecommendation_All[]) => {
    const itemIds = items.map((item) => uniqueCatalogDrugID(item));
    updateOptimizeCartResponseAll((prevState) => {
      if (!prevState.data) {
        return prevState;
      }
      const newVisitedItemsIds = { ...prevState.data.selections.visitedItemsIds };
      itemIds.forEach((id) => {
        newVisitedItemsIds[id] = true;
      });
      return {
        ...prevState,
        data: {
          ...prevState.data,
          selections: {
            ...prevState.data.selections,
            visitedItemsIds: newVisitedItemsIds,
          },
        },
      };
    });
  }, [updateOptimizeCartResponseAll]);

  const isVisitedItem = useCallback((item: SupplierOrderItemRecommendation_All) => {
    const itemId = uniqueCatalogDrugID(item);
    return optimizeCartResponse.data?.selections.visitedItemsIds[itemId] || false;
  }, [optimizeCartResponse]);

  const getVisitedItemsCount = useCallback((items: SupplierOrderItemRecommendation_All[]) => {
    return items.reduce((acc, item) => {
      return acc + (isVisitedItem(item) ? 1 : 0);
    }, 0);
  }, [isVisitedItem]);

  const updateOptimizeCartQtyAlt = useCallback(
    (
      newQty: number,
      currentItem: SupplierOrderItemRecommendation_All,
      altItem: CatalogDrug_All,
      supplierObj?: Supplier | null
    ) => {
      const supplierId = supplierObj?.id;
      if (!supplierId) return;

      updateOptimizeCartResponseAll((prevState) => {
        const oldOptimizeCart = cloneDeep(prevState);
        const supplierOrders = prevState.data?.selections.supplierOrders;
        if (!supplierOrders) {
          return prevState;
        }

        const supplier = supplierOrders.find((s) => {
          return s.supplierId === supplierId;
        });
        if (!supplier) {
          return prevState;
        }

        const theItem = supplier.items.find((item) =>
          supplierOrderItemsAreEqual(item, currentItem)
        );
        if (!theItem) {
          return prevState;
        }

        // If we have the item, look for it's alternatives
        const newAltItem = theItem.alternatives.find((item) =>
          supplierOrderItemsAreEqual(item, altItem)
        );
        if (!newAltItem) {
          return prevState;
        }

        newAltItem.numPackages = newQty;
        const newSupplierOrders = supplierOrders.filter(
          (s) => s.supplierId !== supplierId
        );

        const newOptimizedCart = {
          ...oldOptimizeCart,
          data: {
            ...oldOptimizeCart?.data,
            supplierOrders: [...newSupplierOrders, supplier],
          },
        } as OptimizedCartResponse_All;

        return newOptimizedCart;
      });
    },
    [updateOptimizeCartResponseAll]
  );

  const updatePrescriptionStatus = useCallback(
    (prescription: ItemInCart, status: string) => {
      const id = getPrescriptionId(prescription);
      const isInventory = isInventoryId(id);

      if (isInventory) {
        updateInventoryItem(id, "status", status);
      } else {
        updatePmsItem(id, "status", status);
      }

      updateItemPurchaseDetailStatus(
        id,
        status,
        prescription.drug.ndc,
        prescription.manufactutrer,
        prescription.packSize,
        prescription.currentFillId,
        prescription.packQuantity,
        prescription.processedAt,
        prescription.noManufacturerPreference,
        prescription.supplierId
      );
    },
    [updateInventoryItem, updatePmsItem, updateItemPurchaseDetailStatus]
  );

  const updatePrescriptionNote = useCallback(
    (prescription: ItemInCart, note?: ItemNote) => {
      const id = getPrescriptionId(prescription);
      const isInventory = isInventoryId(id);

      if (isInventory) {
        updateInventoryItem(id, "note", note);
      } else {
        if (prescription.status === "add") {
          // force the prescription to be added or removed from the cart
          updatePrescriptionStatus(prescription, "add");
        }
        updatePmsItem(id, "note", note);
      }

      updateItemPurchaseDetailValue(id, "note", note);
    },
    [updateInventoryItem, updatePmsItem, updateItemPurchaseDetailValue]
  );

  const deletePrescription = useCallback(
    (id: string) => {
      const isInventory = isInventoryId(id);
      if (!isInventory) return;

      submitShoppingCartMutation({
        name: "removeFromInventory",
        params: { id },
      });
      removeFromCart(id);
    },
    [removeFromCart, submitShoppingCartMutation]
  );

  const updatePrescriptionPackQuantity = useCallback(
    (prescription: ItemInCart, packQuantity: number) => {
      const id = getPrescriptionId(prescription);

      if (packQuantity === 0) {
        deletePrescription(id);
        return;
      }

      const isInventory = isInventoryId(id);
      if (isInventory) {
        updateInventoryItem(id, "packQuantity", packQuantity);
      } else {
        updatePmsItem(id, "packQuantity", packQuantity);
      }

      updateItemPurchaseDetailValue(id, "packQuantity", packQuantity);
    },
    [updateInventoryItem, updatePmsItem, updateItemPurchaseDetailValue]
  );

  const updatePrescriptionPurchaseBy = useCallback(
    (id: string, date?: Date | Dayjs) => {
      const isInventory = isInventoryId(id);
      const purchaseBy = formatPurchaseByToString(date);

      if (isInventory) {
        updateInventoryItem(id, "purchaseBy", purchaseBy);
      } else {
        updatePmsItem(id, "purchaseBy", purchaseBy);
      }

      updateItemPurchaseDetailValue(id, "purchaseBy", purchaseBy);
    },
    [updateInventoryItem, updatePmsItem, updateItemPurchaseDetailValue]
  );

  const updatePrescriptionPackSize = useCallback(
    (prescription: ItemInCart, packSize: boolean) => {
      const id = getPrescriptionId(prescription);
      const isInventory = isInventoryId(id);

      if (isInventory) {
        updateInventoryItem(id, "packSize", packSize);
      } else {
        if (prescription.status === "add") {
          // force the prescription to be added or removed from the cart
          updatePrescriptionStatus(prescription, "add");
        }
        updatePmsItem(id, "packSize", packSize);
      }

      updateItemPurchaseDetailValue(id, "packSize", packSize);
    },
    [updateInventoryItem, updatePmsItem, updateItemPurchaseDetailValue]
  );

  const updatePrescriptionManufacturer = useCallback(
    (prescription: ItemInCart, manufacturer: boolean) => {
      const id = getPrescriptionId(prescription);
      const isInventory = isInventoryId(id);

      if (isInventory) {
        updateInventoryItem(id, "manufactutrer", manufacturer);
      } else {
        if (prescription.status === "add") {
          // force the prescription to be added or removed from the cart
          updatePrescriptionStatus(prescription, "add");
        }
        updatePmsItem(id, "manufactutrer", manufacturer);
      }

      updateItemPurchaseDetailValue(id, "manufactutrer", manufacturer);
    },
    [updateInventoryItem, updatePmsItem, updateItemPurchaseDetailValue]
  );

  const updatePrescriptionsPurchaseBy = useCallback(
    (rxNumbers: string[], date?: Date | Dayjs) => {
      rxNumbers.forEach((id) => updatePrescriptionPurchaseBy(id, date));
    },
    [updatePrescriptionPurchaseBy]
  );

  const updateBuyLaterItemPurchaseBy = useCallback(
    (rxNumbers: string[], date: Date | Dayjs) => {
      updatePrescriptionsPurchaseBy(rxNumbers, date);

      const newOptimizeCartResponseAll =
        getOptimizedCartWithUpdatedBuyLaterItemPurchaseBy({
          date,
          rxNumbers,
          optimizeCartResponseAll: optimizeCartResponse,
        });

      if (newOptimizeCartResponseAll) {
        updateOptimizeCartResponseAll(newOptimizeCartResponseAll);
      }
    },
    [optimizeCartResponse, updatePrescriptionsPurchaseBy]
  );

  const moveShoppingListItemToBuyLater = useCallback(
    ({
      date,
      item,
      supplierId,
    }: {
      date: Date | Dayjs;
      item: SupplierOrderItemRecommendation_All;
      supplierId: number;
    }) => {
      updatePrescriptionsPurchaseBy(item.rxNumbers, date);

      const newOptimizeCartResponseAll =
        getOptimizedCartWithMovedShoppingListItemToBuyLater({
          date,
          supplierId,
          itemToBuyLater: item,
          optimizeCartResponseAll: optimizeCartResponse,
        });

      if (newOptimizeCartResponseAll) {
        updateOptimizeCartResponseAll(newOptimizeCartResponseAll);
      }
    },
    [optimizeCartResponse, updatePrescriptionsPurchaseBy]
  );

  const moveBuyLaterItemToShoppingList = useCallback(
    ({
      newItem,
      supplierId,
    }: {
      newItem: SupplierOrderItemRecommendation_All;
      supplierId: number;
    }) => {
      updatePrescriptionsPurchaseBy(newItem.rxNumbers);

      const newOptimizeCartResponseAll =
        getOptimizedCartWithMoveBuyLaterItemToShoppingList({
          newItem,
          supplierId,
          optimizeCartResponseAll: optimizeCartResponse,
        });

      if (newOptimizeCartResponseAll) {
        updateOptimizeCartResponseAll(newOptimizeCartResponseAll);
      }
    },
    [optimizeCartResponse, updatePrescriptionsPurchaseBy]
  );

  const getPrescriptionsWithDetails = useCallback(
    (rxNumbers: string[]): PrescriptionDetail[] => {
      const prescriptionDetails = rxNumbers.reduce<PrescriptionDetail[]>(
        (acc, id) => {
          const prescription = prescriptionsById[id];
          if (prescription) {
            const isInventory = isInventoryId(id);
            acc.push({ id, isInventory, prescription });
          }
          return acc;
        },
        []
      );
      return prescriptionDetails;
    },
    [prescriptionsById]
  );

  useEffect(() => {
    const newPrescriptionsById = {
      ...keyBy(serverInventoryItemsInCart, "key"),
      ...keyBy(prescriptionItemsInCart, "rxNumber"),
    };
    setPrescriptionsById(newPrescriptionsById);
  }, [serverInventoryItemsInCart, prescriptionItemsInCart]);

  useEffect(() => {
    if (!optimizeCartResponse?.data) return;

    const { supplierOrders } = optimizeCartResponse.data.selections;
    const newSupplierOrders = supplierOrders.map((supplierOrder) => {
      const { supplierId, items } = supplierOrder;
      const supplier = getSupplierById(supplierId);
      const shippingInfo = getShippingInfo(items, supplier);
      const { shippingFee, itemsTotals } = shippingInfo;

      const newSupplierOrder: SupplierOrder_All = {
        ...supplierOrder,
        shippingInfo,
        shippingCost: shippingFee,
        buyingCost: itemsTotals.total,
      };
      return newSupplierOrder;
    });

    if (isEqual(supplierOrders, newSupplierOrders)) return;

    const newOptimizedCart: OptimizedCartResponse_All = {
      ...optimizeCartResponse,
      data: {
        ...optimizeCartResponse.data,
        selections: {
          ...optimizeCartResponse.data.selections,
          supplierOrders: newSupplierOrders,
        }
      },
    };
    setOptimizeCartResponse(newOptimizedCart);
  }, [getSupplierById, optimizeCartResponse]);

  return (
    <ShoppingStateContext.Provider
      value={{
        lookupOptimizedCartOp,
        orgSuppliersList,
        optimizeCartResponse,
        serverItemPurchaseDetails,
        serverInventoryItemsInCart,
        prescriptionItemsInCart,
        serverPrescriptionGroupAdjustments,
        hasPricesChanged,
        activeCartItemIds,
        addOptimizeCartResponse,
        getShippingFeeSimulation,
        getPrescriptionsWithDetails,
        updateItemPurchaseDetailValue_undoable,
        deletePrescriptionGroupAdjustmentsByRxNumbers_undoable,
        isVisitedItem,
        getVisitedItemsCount,
        useCase,
      }}
    >
      <ShoppingUpdaterContext.Provider
        value={{
          addOrgSuppliersList,
          addSupplierToOptimizeCart,
          updateOptimizeCartResponse,
          updateOptimizeCartQty,
          updateOptimizeCartQtyAlt,
          removeFromCart,
          updateItemPurchaseDetails,
          updateItemPurchaseDetailStatus,
          updateItemPurchaseDetailPackQuantity,
          addInventoryItem,
          setPrescriptionItemsInCart,
          setHasPricesChanged,
          setPrescriptionItemsActive,
          deletePrescription,
          updatePrescriptionNote,
          updatePrescriptionPackSize,
          updatePrescriptionPackQuantity,
          updatePrescriptionManufacturer,
          updatePrescriptionGroupPurchaseQuantity,
          updatePrescriptionGroupChosenSubstitution,
          deletePrescriptionGroupAdjustments,
          updatePrescriptionStatus,
          updatePrescriptionPurchaseBy,
          updatePrescriptionsPurchaseBy,
          updateBuyLaterItemPurchaseBy,
          moveShoppingListItemToBuyLater,
          moveBuyLaterItemToShoppingList,
          removeVisitedItem,
          addVisitedItems,
        }}
      >
        {children}
      </ShoppingUpdaterContext.Provider>
    </ShoppingStateContext.Provider>
  );
};

export const useShoppingState = () => {
  const context = useContext(ShoppingStateContext);
  if (context === undefined) {
    throw new Error(
      "useShoppingState must be used within a ShoppingContextProvider"
    );
  }
  return context;
};

export const useShoppingUpdater = () => {
  const context = useContext(ShoppingUpdaterContext);
  if (context === undefined) {
    throw new Error(
      "useShoppingUpdater must be used within a ShoppingContextProvider"
    );
  }
  return context;
};
