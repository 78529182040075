import React, { useMemo } from "react";
import { Text } from "../../../../../components/rxLibrary/typography";
import { ItemInCart } from "../../../../../utilities/types";
import { formatFillDate } from "../../../../../utilities/prescriptions/formatFillDate";

export function FillDateCol({ prescription }: { prescription: ItemInCart }) {
  const fillDate = useMemo(() => {
    return formatFillDate(prescription);
  }, [prescription]);

  return (
    <Text weight="bold" block>
      {fillDate || ''}
    </Text>
  );
}
