import { useEffect, useMemo, useState } from "react";
import type {
  CatalogItem,
  CatalogItemsResponse,
} from "../../../../../utilities/types";
import { getCatalogItems } from "../../../../../services/legacy/catalogItems";
import { useRequest } from "../../../../../services/request/useRequest";
import { SUPPLIER_ID_JOURNEY } from "../../../../../utilities/config";

// TODO: Remove when we implement the BE refrigerated items
const REFRIGERATED_NDCS = ["72356010303", "69489020130", "69489021230"];

function formatRefrigeratedItems(
  supplierId: number,
  catalogItems: CatalogItem[]
) {
  if (supplierId !== SUPPLIER_ID_JOURNEY) return catalogItems;

  const newCatalogItems = catalogItems.map<CatalogItem>((item) => {
    const ndc = item.drug.ndc;
    const refrigerated = REFRIGERATED_NDCS.includes(ndc);
    return { ...item, refrigerated };
  });
  return newCatalogItems;
}

export function useCatalogItems({
  supplierId,
  pharmacyId,
}: {
  supplierId: number;
  pharmacyId: number;
}) {
  const [formattedData, setFormattedData] = useState<CatalogItemsResponse>();

  const config = useMemo(() => {
    return { pharmacyId, supplierId };
  }, [pharmacyId, supplierId]);

  const { data } = useRequest({
    config,
    dataKey: "catalogItems",
    request: getCatalogItems,
  });

  useEffect(() => {
    if (!data) return;

    // TODO: Remove when we implement the BE refrigerated items
    const items = formatRefrigeratedItems(supplierId, data.items);
    setFormattedData({ ...data, items });
  }, [data, supplierId]);

  return formattedData;
}
