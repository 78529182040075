import React, {createContext, useContext, useEffect, useMemo, useRef, useState} from "react";
import EnhancedClientConfigContext from "../enhanced/EnhancedClientConfigContext";

type TimerContext = {
  refresh: boolean;
  start: (total: number) => void;
};

const defaultState = {
  refresh: false,
  start: () => {},
};

const TimerContext = createContext<TimerContext>(defaultState);

if (process.env.REACT_APP_USE_CONTEXT_DEVTOOL) {
  TimerContext.displayName = "TimerContext";
}

export default TimerContext;

type TimerContextProviderProps = {
  children?: React.ReactNode;
};

export function TimerContextProvider({
  children,
}: TimerContextProviderProps): JSX.Element {
  // Store the created interval
  let intervalId: NodeJS.Timeout;

  const ms = 20000;
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);
  const counter = useRef<number>(0)
  const [startCountDown, setStartCountDown] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const start: (total: number) => void = (total: number) => {
    counter.current = total;
    setStartCountDown(true);
  };

  useEffect(() => {
    if (!enhancedClientActive) {
      return;
    }
    if (!startCountDown) {
      return;
    }
    intervalId = setInterval(() => {
      if(startCountDown && counter.current > 0) {
        counter.current = counter.current - (ms / 1000);
        // set refresh true when counter is done
        // console.log(counter.current);
        if (counter.current <= 1) {
          setRefresh(true);
        }
      }
    }, ms);

    // Clear interval when unmount
    return () => clearInterval(intervalId);
  }, [startCountDown, counter, enhancedClientActive]);

  const value = useMemo(() => ({
      start,
      refresh
  }), [refresh, start]);
  return (
    <TimerContext.Provider
      value={value}
    >
      {children}
    </TimerContext.Provider>
  );
}
