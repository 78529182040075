import { useMemo } from "react";
import "twin.macro";
import { WideContainer } from "../../components/containers/WideContainer";
import { FullPageLoader } from "../../components/loaders/FullPageLoader";
import { ErrorMessage } from "../../components/errors/ErrorMessage";
import { Box } from "../../components/rxLibrary/box";
import { useRequest } from "../../services/request/useRequest";
import { getModeUrlReport } from "../../services/report";
import { usePerformance } from "./usePerformance";

const HEIGHT_STYLE = { height: "calc(100dvh - 236px)" };

export function PerformanceReport() {
  const { toDate, fromDate, reportId, selectedPharmacies } = usePerformance();

  const config = useMemo(() => {
    return {
      toDate: toDate.format("YYYY-MM-DD"),
      fromDate: fromDate.format("YYYY-MM-DD"),
      reportId,
      pharmacyId: selectedPharmacies,
    };
  }, [reportId, toDate, fromDate, selectedPharmacies]);

  const {
    data: modeUrl,
    error,
    isLoading,
  } = useRequest({ config, dataKey: "signedUrl", request: getModeUrlReport });

  if (isLoading) return <FullPageLoader text="Loading Analytics" />;

  if (error || !modeUrl) {
    return (
      <ErrorMessage style={HEIGHT_STYLE}>
        There was a problem loading this report. Very sorry! We have been
        notified and we will look into this right away.
      </ErrorMessage>
    );
  }

  return (
    <Box bgColor="grey-4">
      <WideContainer style={HEIGHT_STYLE}>
        <iframe tw="w-full h-full" src={modeUrl} />
      </WideContainer>
    </Box>
  );
}
