import React, { useCallback, useState } from "react";
import tw, { css, styled } from "twin.macro";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import PrinterOutlined from "@ant-design/icons/PrinterOutlined";
import { useStorageState } from "../../../../contexts/StorageContext";
import { useShoppingCartServerUpdater } from "../../../../contexts/ShoppingCartServerContext";
import { Title } from "../../../../components/rxLibrary/typography";
import { GREY_4 } from "../../../../components/rxLibrary/colors";
import { Button } from "../../../../components/rxLibrary/buttons";
import { useShoppingListPrintBtnProps } from "../useShoppingListPrintBtnProps";

const BtnWrapper = styled.div(() => [
  tw`mb-2 py-6 px-10 rounded-lg w-[360px]`,
  css`
    background-color: ${GREY_4};
  `,
]);

export function ShoppingBarDoneModalBtn() {
  const history = useHistory();
  const { waitButtonMode } = useStorageState();
  const { pushBlob, setUseBlob } = useShoppingCartServerUpdater();
  const [isOpen, setIsOpen] = useState(false);
  const printBtnProps = useShoppingListPrintBtnProps();

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleComplete = useCallback(async () => {
    await pushBlob({
      force: true,
      cb: () => {
        setUseBlob(true);
        history.push("/shoppingList");
      },
    });
  }, [pushBlob, setUseBlob]);

  return (
    <>
      <Button disabled={waitButtonMode} onClick={openModal}>
        Done Purchasing
      </Button>

      {isOpen && (
        <Modal open footer={null} onCancel={closeModal}>
          <div tw="flex flex-col justify-center items-center py-8">
            <Title tw="mb-10" block>
              Done Purchasing?
            </Title>

            <BtnWrapper tw="flex flex-col items-center">
              <FileTextOutlined tw="mb-2" style={{ fontSize: 100 }} />

              <Button className="print-shopping-list" {...printBtnProps} fullWidth icon={<PrinterOutlined />}>
                Print Shopping List for your records
              </Button>
            </BtnWrapper>

            <BtnWrapper tw="flex justify-center">
              <Button fullWidth variant="secondary" onClick={handleComplete}>
                Go to shopping list main page
              </Button>
            </BtnWrapper>

            <Button variant="text-2" onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}
