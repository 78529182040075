export const ROUTES: Readonly<
  {
    to: string;
    image: string;
    text: string;
    enhancedClientOnly: boolean;
  }[]
> = [
  {
    to: "/supplierAccounts",
    image: "/assets/icons/icon-suppliers-and-passwords.svg",
    text: "MY SUPPLIERS & PASSWORDS",
    enhancedClientOnly: true,
  },
  {
    to: "/refill-opportunities",
    image: "/assets/icons/icon-refill-opportunities.svg",
    text: "MY REFILL OPPORTUNITIES",
    enhancedClientOnly: false,
  },
  {
    to: "invoice-history",
    image: "/assets/icons/icon-invoice-history.svg",
    text: "MY INVOICES",
    enhancedClientOnly: false,
  },
  {
    to: "/performance",
    image: "/assets/icons/icon-performance-reports.svg",
    text: "MY PERFORMANCE REPORTS",
    enhancedClientOnly: false,
  },
  {
    to: "/previousShoppingLists",
    image: "/assets/icons/icon-latest-shopping-lists.svg",
    text: "MY LATEST SHOPPING LISTS",
    enhancedClientOnly: true,
  },
] as const;
