import React, { useMemo } from "react";
import "twin.macro";
import p from "pluralize";
import { Warning } from "../../../../../../components/rxLibrary/warnings";
import { ShoppingItem } from "../../../useShoppingList";
import { currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits } from "../../../../../../utilities/numbers/currency";
import { formatPurchaseByToDayjs } from "../../../../../../utilities/dates/purchaseBy/formatPurchaseByToDayjs";
import { getMinShipOrderAmount } from "../../../../../../utilities/shipping/shipping";

export function ShoppingListTableHeaderWarnings({
  shoppingItem,
}: {
  shoppingItem: ShoppingItem;
}) {
  const { supplier, supplierOrder } = shoppingItem;
  const { displayName, shippingConfig } = supplier;
  const { items, shippingInfo, increasedBasketWarnings } = supplierOrder;
  const { drugsWithAvailabilityLimitations } = increasedBasketWarnings ?? {};
  const drugsWithAvailabilityLimitationsCount = drugsWithAvailabilityLimitations?.length;

  const msg1 = useMemo(() => {
    if (!shippingInfo) return;

    const { shippable, shippingFee } = shippingInfo;
    if (!shippable) {
      const minShipPrice = getMinShipOrderAmount(shippingConfig);
      return `Does not meet ${minShipPrice} min ship for overnight delivery. Adjust in ${displayName} site`;
    }

    if (!shippingFee) return;
    const fee =
      currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(shippingFee);
    return `Includes ${fee} shipping fee. ${displayName} is the best option for your purchase`;
  }, [displayName, shippingInfo]);

  const msg2 = useMemo(() => {
    if (!drugsWithAvailabilityLimitationsCount) return;

    const itemStr = p("item", drugsWithAvailabilityLimitationsCount);
    const isStr = p("is", drugsWithAvailabilityLimitationsCount);
    return `${drugsWithAvailabilityLimitationsCount} ${itemStr} ${isStr} only available at suppliers w/ shipping minimum - ${displayName} is the best choice`;
  }, [displayName, drugsWithAvailabilityLimitationsCount]);

  const msg3 = useMemo(() => {
    const now = new Date();

    const prescriptionsWithFuturePurchaseBy = items.flatMap((item) => {
      const { prescriptionDetails } = item;

      return prescriptionDetails.filter(({ prescription }) => {
        const { purchaseBy } = prescription;
        const purchaseByDate = formatPurchaseByToDayjs(purchaseBy);

        return !!purchaseByDate?.isAfter(now);
      });
    });

    const prescriptionsCount = prescriptionsWithFuturePurchaseBy.length;
    if (!prescriptionsCount) return;

    const itemsStr = p("item", prescriptionsCount);
    const wasStr = p("was", prescriptionsCount);
    return `${prescriptionsCount} ${itemsStr} marked for a later date ${wasStr} added to meet min. ship`;
  }, [items]);

  const msgs = [msg1, msg2, msg3].filter(Boolean);

  if (msgs.length === 0) return null;
  return (
    <div tw="space-y-1">
      {msgs.map((msg) => (
        <Warning key={msg} type="error">
          {msg}
        </Warning>
      ))}
    </div>
  );
}
