"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.humanLocation = humanLocation;
exports.humanPhoneNumberWithDiallingCode = humanPhoneNumberWithDiallingCode;
exports.initLocation = initLocation;
exports.phoneNumberWithDiallingCode = phoneNumberWithDiallingCode;
exports.setPhoneNumber = setPhoneNumber;
exports.validatePhoneNumber = validatePhoneNumber;
var _immutable = _interopRequireDefault(require("immutable"));
var _index = require("./index");
var _locations = _interopRequireDefault(require("./phone-number/locations"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var locationOptions = _immutable.default.fromJS(_locations.default.map(function (x) {
  return {
    country: x[0],
    diallingCode: x[2],
    isoCode: x[1],
    label: "".concat(x[2], " ").concat(x[1], " ").concat(x[0]),
    value: "".concat(x[2], " ").concat(x[1])
  };
}));
function findLocation(isoCode) {
  return locationOptions.find(function (x) {
    return x.get('isoCode') === isoCode;
  });
}
function initLocation(m, isoCode) {
  var location = findLocation(isoCode) || findLocation('US');
  return (0, _index.registerOptionField)(m, 'location', locationOptions, location.get('value'));
}
function validatePhoneNumber(str) {
  var regExp = /^[0-9]([0-9 -])*[0-9]$/;
  return regExp.test(str);
}
function setPhoneNumber(m, str) {
  return (0, _index.setField)(m, 'phoneNumber', str, validatePhoneNumber);
}
function phoneNumberWithDiallingCode(m) {
  return humanPhoneNumberWithDiallingCode(m).replace(/[\s-]+/g, '');
}
function humanPhoneNumberWithDiallingCode(m) {
  var location = (0, _index.getField)(m, 'location');
  var code = location.get('diallingCode', '');
  var number = (0, _index.getFieldValue)(m, 'phoneNumber', '');
  return code ? "".concat(code, " ").concat(number) : number;
}
function humanLocation(m) {
  var location = (0, _index.getField)(m, 'location');
  return "".concat(location.get('diallingCode'), " ").concat(location.get('country'));
}
