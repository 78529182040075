import React from "react";
import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";
import { PrintWrapper } from "../../components/rxLibrary/print";
import { ExtraWideContainer } from "../../components/containers/ExtraWideContainer";
import { GREY_1, WHITE } from "../../components/rxLibrary/colors";
import { BackButton } from "../../components/rxLibrary/buttons";
import { Supplier } from "../../utilities/types";
import { IS_ELECTRON } from "../../utilities/config";

const Header = tw(PrintWrapper)`relative z-20`.withComponent("header");

const Nav = styled.nav`
  height: 48px;
  position: relative;
  box-shadow: 0 4px 15px 0 #00000050;
  background-color: ${WHITE};
  padding: 0 80px;
`;

const SupplierName = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${GREY_1};
`;

export function ManufacturerStoreNavBar({ supplier }: { supplier?: Supplier }) {
  const { displayName } = supplier ?? {};

  if (IS_ELECTRON) return null;
  return (
    <Header>
      <Nav>
        <ExtraWideContainer tw="h-full flex items-center">
          <Link to="/">
            <BackButton>Back to DaylightRx</BackButton>
          </Link>
          {!!displayName && <SupplierName>/ {displayName}</SupplierName>}
        </ExtraWideContainer>
      </Nav>
    </Header>
  );
}
