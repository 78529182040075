import React, { useCallback, useMemo } from "react";
import "twin.macro";
import { Text } from "../../../../../../components/rxLibrary/typography";
import {
  Dropdown,
  DropdownMenuOptions,
} from "../../../../../../components/rxLibrary/dropdown";
import { MenuButton } from "../../../../../../components/rxLibrary/buttons";

const Warning = Text.withComponent("p");

export function ShoppingListTableRowDropdownActions({
  minShipWarning,
  hasItemAlternatives,
  openModal,
}: {
  minShipWarning?: string;
  hasItemAlternatives: boolean;
  openModal: () => void;
}) {
  const dropdownItems = useMemo((): DropdownMenuOptions => {
    let option;
    if (hasItemAlternatives) {
      option = {
        key: "openSupplierModal",
        label: (
          <>
            <Text block>Change Item</Text>

            {minShipWarning && (
              <Warning block color="red">
                {minShipWarning}
              </Warning>
            )}
          </>
        ),
      };
    } else {
      option = {
        key: "noSupplierAvailable",
        label: "No other supplier available",
        disabled: true,
      };
    }
    return [option];
  }, [minShipWarning, hasItemAlternatives]);

  const handleMenuClick = useCallback(
    ({ key }: { key: string }) => {
      if (key === "openSupplierModal") openModal();
    },
    [openModal]
  );

  return (
    <Dropdown menu={{ items: dropdownItems, onClick: handleMenuClick }}>
      <MenuButton />
    </Dropdown>
  );
}
