import React, { ReactNode, useCallback, useContext, useMemo } from "react";
import "twin.macro";
import PharmaciesContext from "../../../../../../contexts/PharmaciesContext";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import { IconCheck } from "../../../../../../components/rxLibrary/icons";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { formatDateToDayWithTime } from "../../../../../../utilities/dates/formatDateToDayWithTime";
import { ItemInCart } from "../../../../../../utilities/types";
import { PurchaseByButton } from "../../../../../../components/shoppingList/PurchaseByButton/PurchaseByButton";
import { useShoppingUpdater } from "../../../../../../contexts/ShoppingContext/ShoppingContext";
import { getPrescriptionId } from "../../../../../../utilities/prescriptions/getPrescriptionId";

function ProcessedAtLabel({ processedAt }: { processedAt?: string }) {
  const dateFormatted = useMemo(
    () => formatDateToDayWithTime(processedAt),
    [processedAt]
  );

  if (!dateFormatted) return null;
  return (
    <Text color="grey-1" center block>
      {dateFormatted}
    </Text>
  );
}

function ProcessedStatusLabel({
  supplierId,
  suppliersIdList,
}: {
  supplierId?: number;
  suppliersIdList?: number[];
}) {
  const { supplierFullNameById } = useContext(PharmaciesContext);

  const suppliers = useMemo(() => {
    const isMultiSupplier = !!suppliersIdList && suppliersIdList.length > 1;

    let newSuppliers = "Optimized";
    if (isMultiSupplier) {
      newSuppliers = suppliersIdList
        .map((supplierId) => supplierFullNameById[supplierId])
        .join(" & ");
    } else if (supplierId) {
      newSuppliers = supplierFullNameById[supplierId];
    }

    return newSuppliers;
  }, [supplierId, suppliersIdList]);

  return (
    <Text weight="bold" center block>
      Was on {suppliers} List
    </Text>
  );
}

function Label({ children, icon }: { children: ReactNode; icon?: ReactNode }) {
  return (
    <div tw="flex items-center justify-center space-x-1 w-full">
      {!!icon && icon}
      <Text>{children}</Text>
    </div>
  );
}

export function ShoppingListColumnActionsPrescriptionInfo({
  prescription,
  updatePrescriptionStatus,
}: {
  prescription: ItemInCart;
  updatePrescriptionStatus: (prescription: ItemInCart, status: string) => void;
}) {
  const { supplierId, suppliersIdList, status, processedAt, purchaseBy } =
    prescription;

  const { updatePrescriptionPurchaseBy } = useShoppingUpdater();

  const handleListClick = useCallback(() => {
    const newStatus = prescription.processedAt ? "processed" : "add";
    updatePrescriptionStatus(prescription, newStatus);
  }, [prescription, updatePrescriptionStatus]);

  const handleAddClick = useCallback(() => {
    updatePrescriptionStatus(prescription, "list");
  }, [prescription, updatePrescriptionStatus]);

  const handleUpdatePrescriptionPurchaseBy = useCallback(
    (date?: Date) => {
      const id = getPrescriptionId(prescription);
      updatePrescriptionPurchaseBy(id, date);
    },
    [prescription, updatePrescriptionPurchaseBy]
  );

  switch (status) {
    case "list":
      return (
        <>
          <Button
            block
            fullWidth
            variant="text-1"
            icon={<IconCheck />}
            onClick={handleListClick}
          >
            In List
          </Button>
          <PurchaseByButton
            purchaseBy={purchaseBy}
            onChange={handleUpdatePrescriptionPurchaseBy}
          />
          <ProcessedAtLabel processedAt={processedAt} />
        </>
      );
    case "stock":
      return <Label icon={<IconCheck />}>In Stock</Label>;
    case "processed":
      return (
        <>
          <ProcessedStatusLabel
            supplierId={supplierId}
            suppliersIdList={suppliersIdList}
          />
          <ProcessedAtLabel processedAt={processedAt} />
        </>
      );
    case "add":
    default:
      return (
        <>
          <Button block onClick={handleAddClick}>
            Add To List
          </Button>
          {!!processedAt && <Label>In prior list</Label>}
        </>
      );
  }
}
