import { useEvent } from 'rc-util';
import { isSame } from "../../utils/dateUtil";
/**
 * Merge `disabledDate` with `minDate` & `maxDate`.
 */
export default function useDisabledBoundary(generateConfig, locale, disabledDate, minDate, maxDate) {
  var mergedDisabledDate = useEvent(function (date, info) {
    if (disabledDate && disabledDate(date, info)) {
      return true;
    }
    if (minDate && generateConfig.isAfter(minDate, date) && !isSame(generateConfig, locale, minDate, date, info.type)) {
      return true;
    }
    if (maxDate && generateConfig.isAfter(date, maxDate) && !isSame(generateConfig, locale, maxDate, date, info.type)) {
      return true;
    }
    return false;
  });

  // const isInValidBoundary: IsInvalidBoundary<DateType> = (date, type, from) => {
  //   const toBoundaryDate = (boundary?: LimitDate<DateType>) =>
  //     typeof boundary === 'function'
  //       ? boundary({
  //           from,
  //         })
  //       : boundary;

  //   const mergedMinDate = toBoundaryDate(minDate);
  //   const mergedMaxDate = toBoundaryDate(maxDate);

  //   if (
  //     mergedMinDate &&
  //     generateConfig.isAfter(mergedMinDate, date) &&
  //     !isSame(generateConfig, locale, mergedMinDate, date, type)
  //   ) {
  //     return true;
  //   }

  //   if (
  //     mergedMaxDate &&
  //     generateConfig.isAfter(date, mergedMaxDate) &&
  //     !isSame(generateConfig, locale, mergedMaxDate, date, type)
  //   ) {
  //     return true;
  //   }

  //   return false;
  // };

  return mergedDisabledDate;
}