import React, { useEffect, useState } from "react";
import "twin.macro";
import { useHistory } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Pharmacy,
  OfferedDrug,
  CatalogOverride,
  ShoppingSupplierCheck,
  PrescriptionQuantityItem,
} from "../../../utilities/types";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";
import { useAuth0 } from "../../../contexts/AuthenticationContext";
import {
  useShoppingState,
  useShoppingUpdater,
} from "../../../contexts/ShoppingContext/ShoppingContext";
import {useShoppingCartServerUpdater} from "../../../contexts/ShoppingCartServerContext";
import EnhancedShoppingListSearch from "../../../enhanced/EnhancedShoppingListSearch";
import {
  EnhancedStockValidationAvailability,
  RequestEnhanceItemParam,
} from "../../../enhanced/EnhancedClientContext";
import { cleanUpPurchaseByString } from "../../../utilities/dates/purchaseBy/cleanUpPurchaseByString";
import { drugInfoToDrugNameUOMSizeStr } from "../../../utilities/drugInfo/drugInfoToDrugNameUOMSizeStr";
import { SupplierModal } from "./SupplierModal";
import {optimizeCart} from "../../../services/legacy/optimizations";

const MODAL_STYLE = {
  position: "absolute",
  width: "836px",
  top: "80px",
  left: "calc(50% - 418px)",
  right: "40px",
  bottom: "160px",
  border: "1px solid #ccc",
  background: "#fff",
  overflow: "auto",
  WebkitOverflowScrolling: "touch",
  borderRadius: "9px",
  outline: "none",
} as const;

function supplierIdToNameLookupsFor(
  pharmacy: Pharmacy | null
): Record<number, string> {
  const supplierIdsToNames: Record<number, string> = {};
  pharmacy?.suppliers.forEach((s) => {
    supplierIdsToNames[s.id] = s.name;
  });
  return supplierIdsToNames;
}

function supplierNameToIdLookupsFor(
  pharmacy: Pharmacy | null
): Record<string, number> {
  const supplierNamesToIds: Record<string, number> = {};
  pharmacy?.suppliers.forEach((s) => {
    supplierNamesToIds[s.name] = s.id;
  });
  return supplierNamesToIds;
}

function coerceStringifiedNumber(
  value: number | string | undefined
): number | undefined {
  if (typeof value === "number" || value === undefined) {
    return value;
  }
  return parseInt(value) || 0;
}

const makeShoppingList = (
  filteredSuppliersList: ShoppingSupplierCheck[],
  offeredDrugs: OfferedDrug[],
  currentBuyingPharmacy: Pharmacy | null
): RequestEnhanceItemParam[] => {
  const supplierIdsToNames = supplierIdToNameLookupsFor(currentBuyingPharmacy);

  return offeredDrugs
    .filter((offer) => {
      return (
        filteredSuppliersList.find((supplier) => {
          return supplier.id === offer.supplierId;
        })?.check && supplierIdsToNames[offer.supplierId]
      );
    })
    .map((offer) => {
      return {
        ndc: offer.drug.ndc,
        supplier: supplierIdsToNames[offer.supplierId],
        drugNameUOMSizeStr: drugInfoToDrugNameUOMSizeStr(offer.drug),
        price: 0,
        supplierItemNumbers: offer.supplierItemNumbers,
      } as RequestEnhanceItemParam;
    });
};
export function SelectSupplierModal({
  reLoad = false,
  prescriptionData,
  offeredDrugs,
  modalActive,
  selectedSuppliers,
  onClose,
  onOptimizationError,
}: {
  reLoad?: boolean;
  prescriptionData: PrescriptionQuantityItem[];
  offeredDrugs: OfferedDrug[];
  modalActive: boolean;
  selectedSuppliers?: ShoppingSupplierCheck[];
  onClose: (mode: boolean) => void;
  onOptimizationError?: (error?: string) => void;
}) {
  const history = useHistory();
  const { currentBuyingPharmacyId, currentBuyingPharmacy } =
    useBuyingPharmacy();
  const { getAccessTokenSilently } = useAuth0();
  const { clearMutationUndoStack } = useShoppingCartServerUpdater();
  const { addOptimizeCartResponse } = useShoppingState();
  const { setHasPricesChanged } = useShoppingUpdater();
  const [searchModalActive, setSearchModalActive] = useState(false);
  const [supplierModalActive, setSupplierModalActive] = useState(false);
  const [filteredSuppliersList, setFilteredSuppliersList] = useState<
    ShoppingSupplierCheck[]
  >([]);
  const [catalogOverrides, setCatalogOverrides] = useState<CatalogOverride[]>(
    []
  );
  const [catalogOverrideProcessComplete, setCatalogOverrideProcessComplete] =
    useState(false);

  useEffect(() => {
    (async () => {
      if (!catalogOverrideProcessComplete) return;

      const token = await getAccessTokenSilently();
      const allowedSupplierIds = filteredSuppliersList
        .filter((supplier) => supplier.check)
        .map((supplier) => supplier.id);

      try {
        const optimizeCartResponseAll = await optimizeCart(
          currentBuyingPharmacyId,
          token,
          prescriptionData.map((item) => {
            const purchaseBy = cleanUpPurchaseByString(item.purchaseBy);

            return {
              ndc: item.drug.ndc,
              allowManufacturerSubstitution:
                item.allowManufacturerSubstitution || false,
              allowPackSizeSubstitution:
                item.allowPackSizeSubstitution || false,
              rxNumbers: item.rxNumbers,
              ...(!item.useQuantityInput && { numPackages: item.numPackages }),
              ...(item.useQuantityInput && { quantity: item.quantityToBuy }),
              ...(purchaseBy && { purchaseBy }),
            };
          }),
          allowedSupplierIds,
          false,
          catalogOverrides,
          offeredDrugs,
        );

        addOptimizeCartResponse(optimizeCartResponseAll);
        setSearchModalActive(false);
        setHasPricesChanged(false);
        // move from page 2 to 3
        if (!reLoad) {
          history.push("/shoppingListThree");
        }
      } catch (e) {
        console.error(e);
        setSearchModalActive(false);
        setHasPricesChanged(false);
        onOptimizationError && onOptimizationError();
      }
    })();
  }, [
    catalogOverrideProcessComplete,
    currentBuyingPharmacyId,
    prescriptionData,
    offeredDrugs,
    catalogOverrides,
    filteredSuppliersList,
  ]);

  useEffect(() => {
    setSupplierModalActive(modalActive);
  }, [modalActive]);

  return (
    <>
      <Modal
        id="supplierModal"
        open={supplierModalActive}
        onClose={() => onClose && onClose(false)}
      >
        <SupplierModal
          selectedSuppliers={
            filteredSuppliersList.length === 0
              ? selectedSuppliers
              : filteredSuppliersList
          }
          onClose={() => onClose && onClose(false)}
          onContinue={async (suppliersList) => {
            // Only if we go from p2 to p3
            if (!selectedSuppliers) {
              clearMutationUndoStack();
            }
            setSupplierModalActive(false);
            setFilteredSuppliersList(suppliersList);
            setSearchModalActive(true);
          }}
        />
      </Modal>

      <Modal
        id="searchModal"
        open={searchModalActive}
        disableEscapeKeyDown
        onClose={(event: Event, reason: string) => {
          if (reason && reason == "backdropClick") {
            return;
          }
          onClose && onClose(false);
        }}
      >
        <Box sx={MODAL_STYLE}>
          <div style={{ padding: "64px 48px 24px 48px" }}>
            {searchModalActive && (
              <EnhancedShoppingListSearch
                shoppingList={makeShoppingList(
                  filteredSuppliersList,
                  offeredDrugs,
                  currentBuyingPharmacy
                )}
                onComplete={(items, removedSuppliers) => {
                  if (removedSuppliers.length > 0) {
                    const lookups = supplierIdToNameLookupsFor(
                      currentBuyingPharmacy
                    );
                    const newFilteredSuppliersList: ShoppingSupplierCheck[] =
                      [];
                    filteredSuppliersList.forEach((supplier) => {
                      const name = lookups[supplier.id];
                      if (!name) {
                        return;
                      }
                      if (removedSuppliers.indexOf(name) >= 0) {
                        newFilteredSuppliersList.push({
                          id: supplier.id,
                          check: false,
                        });
                        return;
                      }
                      newFilteredSuppliersList.push(supplier);
                    });
                    setFilteredSuppliersList(newFilteredSuppliersList);
                  }
                  if (items.length > 0) {
                    const lookups = supplierNameToIdLookupsFor(
                      currentBuyingPharmacy
                    );
                    const newCatalogOverrides: CatalogOverride[] = items.map(
                      (item) => {
                        return {
                          ndc: item.ndc,
                          supplierId: lookups[item.supplier],
                          price: (item.price as number) || 999999,
                          supplierItemNumber: String(
                            (item.supplierItemNumber as string) ||
                              `${lookups[item.supplier]}_${item.ndc}`
                          ),
                          isAvailable:
                            !!item.price &&
                            item.availability ===
                              EnhancedStockValidationAvailability.AVAILABLE,
                          numPackagesAvailable: coerceStringifiedNumber(
                            item.quantityAvailable
                          ),
                          isRebatedItem: item.isRebatedItem,
                          link: item.deeplink,
                        };
                      }
                    );
                    setCatalogOverrides(newCatalogOverrides);
                  }
                  setCatalogOverrideProcessComplete(true);
                }}
                onAbort={() => {
                  setSearchModalActive(false);
                  setSupplierModalActive(true);
                }}
              />
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}
