import React from "react";
import { styled } from "twin.macro";
import { GREY_4, YELLOW } from "../../../../components/rxLibrary/colors";
import { Text } from "../../../../components/rxLibrary/typography";
import { BUTTON_SHADOW_CSS } from "../../../../components/rxLibrary/buttons";
import { openChat } from "../../../../utilities/chat/openChat";

const AdChatWithUs = styled.button`
  ${BUTTON_SHADOW_CSS}
  background-color: ${GREY_4};
  border-radius: 8px;
  padding: 17px 26px 17px 0;
  display: flex;
  text-align: left;
`;

const CTA = styled.div`
  padding: 5px 20px;
  border-radius: 20px;
  background-color: ${YELLOW};
  display: block;
`;

export function MarketingAd() {
  return (
    <AdChatWithUs tw="flex-1 space-x-3" onClick={openChat}>
      <img src="/assets/pages/home/purchases-ad.svg" tw="ml-[-10px]" />

      <div>
        <Text tw="mb-1" size="medium" weight="bold" block>
          Streamline your purchases
        </Text>

        <Text tw="mb-2" block>
          Connect all your suppliers, including primary, secondaries &
          manufacturers.
        </Text>

        <CTA>
          <Text weight="bold">Interested? Chat with Us</Text>
        </CTA>
      </div>
    </AdChatWithUs>
  );
}
