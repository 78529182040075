import { ReactNode, createContext, useMemo, useState } from "react";
import sortBy from "lodash/sortBy";
import { getManufacturers } from "../services/manufacturers";
import { useRequest } from "../services/request/useRequest";
import { Manufacturer } from "../utilities/types";

type ManufacturersContext = {
  manufacturers?: Manufacturer[];
  manufacturer?: Manufacturer;
  setManufacturerId: (id: Manufacturer["id"]) => void;
};

const ManufacturersContext = createContext<ManufacturersContext>({
  manufacturers: [],
  setManufacturerId: () => {},
});

if (process.env.REACT_APP_USE_CONTEXT_DEVTOOL) {
  ManufacturersContext.displayName = "ManufacturersContext";
}

export default ManufacturersContext;

function formatter(manufacturers: Manufacturer[]) {
  return sortBy(manufacturers, "name");
}

export function ManufacturersContextProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const [manufacturerId, setManufacturerId] = useState<Manufacturer["id"]>();

  const { formattedData: manufacturers } = useRequest({
    dataKey: "manufacturers",
    request: getManufacturers,
    formatter,
  });

  const manufacturer = useMemo(() => {
    const value = manufacturers?.find((m) => m.id === manufacturerId);
    return value ?? manufacturers?.[0];
  }, [manufacturerId, manufacturers]);

  return (
    <ManufacturersContext.Provider
      value={{
        manufacturer,
        manufacturers,
        setManufacturerId,
      }}
    >
      {children}
    </ManufacturersContext.Provider>
  );
}
