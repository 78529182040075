import React, { useState, useContext, useCallback } from "react";
import "twin.macro";
import { useShoppingState } from "../../../../../contexts/ShoppingContext/ShoppingContext";
import EnhancedClientCommandContext from "../../../../../enhanced/EnhancedClientCommandContext";
import { SupplierItemChangeOperation } from "../../../../../utilities/shoppingListTypes";
import { SuppliersModal } from "../../SuppliersModal/SuppliersModal";
import { ShoppingItem } from "../../useShoppingList";
import { ExpandableTable } from "../../../../../components/rxLibrary/tables/ExpandableTable/ExpandableTable";
import { ShoppingListTableRow } from "./ShoppingListTableRow/ShoppingListTableRow";
import { ShoppingListTableHeader } from "./ShoppingListTableHeader/ShoppingListTableHeader";
import {
  SupplierOrderItemRecommendation_All
} from "../../../../../services/types";

export function ShoppingListTable({
  shoppingItems,
  visitedItemsIds,
  isVisitedItem,
  getVisitedItemsCount,
  processCartChangeOperations,
}: {
  shoppingItems: ShoppingItem[];
  visitedItemsIds: Record<string, boolean>;
  isVisitedItem: (item: SupplierOrderItemRecommendation_All) => boolean;
  getVisitedItemsCount: (items: SupplierOrderItemRecommendation_All[]) => number;
  processCartChangeOperations: (
    operations: SupplierItemChangeOperation[]
  ) => void;
}) {
  const { optimizeCartResponse } = useShoppingState();
  const { showSupplierForShopping } = useContext(EnhancedClientCommandContext);

  const [modalData, setModalData] = useState<{
    item: SupplierOrderItemRecommendation_All;
    displayName: string;
  }>();

  const modalCallback = useCallback(
    (operations?: SupplierItemChangeOperation[]) => {
      setModalData(undefined);
      if (operations && operations.length > 0) {
        processCartChangeOperations(operations);
      }
    },
    [processCartChangeOperations]
  );

  const openSupplierPage = useCallback(
    (shoppingItem: ShoppingItem) => {
      const { supplier } = shoppingItem;

      showSupplierForShopping(
        shoppingItem,
        Object.keys(visitedItemsIds),
        optimizeCartResponse,
        supplier.isEcommerceEnabled
      );
    },
    [visitedItemsIds, optimizeCartResponse, showSupplierForShopping]
  );

  return (
    <>
      {shoppingItems.map((shoppingItem) => {
        const { supplier, supplierOrder } = shoppingItem;
        const { drugsWithAvailabilityLimitations } =
          supplierOrder?.increasedBasketWarnings ?? {};
        const { items } = supplierOrder;

        return (
          <ExpandableTable
            blueToggle
            key={supplier.id}
            Row={ShoppingListTableRow}
            items={items}
            itemProps={{
              supplier,
              drugsWithAvailabilityLimitations,
              shoppingItems,
              setModalData,
              isVisitedItem,
              processCartChangeOperations,
            }}
            header={
              <ShoppingListTableHeader
                shoppingItem={shoppingItem}
                openSupplierPage={openSupplierPage}
                getVisitedItemsCount={getVisitedItemsCount}
              />
            }
          />
        );
      })}

      {!!modalData && (
        <SuppliersModal
          isOpen
          data={modalData.item.alternatives}
          suggested={modalData.item}
          supplierName={modalData.displayName}
          callBack={modalCallback}
        />
      )}
    </>
  );
}
