import React from "react";
import "twin.macro";
import type { ManufacturerStoreItem } from "../../Catalog.constants";
import type {
  CatalogItemsTopSellingItem,
  CatalogItemsMostRecentPurchase,
} from "../../../../../../utilities/types";
import { Box } from "../../../../../../components/rxLibrary/box";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { useCatalog } from "../../useCatalog";
import { CatalogItem } from "../CatalogItem/CatalogItem";
import { CatalogItemsSectionFeaturedItems } from "../CatalogItemsSectionFeaturedItems";
import { useCatalogItemsSearchBar } from "./useCatalogItemsSearchBar";
import { CatalogItemsSearchBar } from "./CatalogItemsSearchBar";
import { CatalogItemsBuyItAgain } from "./CatalogItemsBuyItAgain";
import { CatalogItemsTopSellingItems } from "./CatalogItemsTopSellingItems";
import { CatalogItemsAll } from "./CatalogItemsAll";

export function CatalogItemsWithSearch({
  topSellingItems,
  shoppingListItems,
  mostRecentPurchases,
}: {
  shoppingListItems: ManufacturerStoreItem[];
  topSellingItems?: CatalogItemsTopSellingItem[];
  mostRecentPurchases?: CatalogItemsMostRecentPurchase[];
}) {
  const { supplier, itemsByType } = useCatalog();
  const {
    filter,
    searchBarItems,
    isSearchBarOpen,
    setFilter,
    setIsSearchBarOpen,
  } = useCatalogItemsSearchBar(shoppingListItems);

  const hasFeaturedItems = !!itemsByType.featuredItems.length;
  const displayTopSellingItems = (mostRecentPurchases?.length ?? 0) < 10;
  const displaySearchBarItems =
    !!filter && !isSearchBarOpen && !!searchBarItems?.length;

  return (
    <>
      <Box
        bgColor="blue-1"
        tw="flex items-center rounded-t-lg py-[23px] px-[26px] space-x-[19px] "
      >
        <Text color="white" size="medium" block>
          Search {supplier.displayName} Items:
        </Text>

        <CatalogItemsSearchBar
          filter={filter}
          searchBarItems={searchBarItems}
          setFilter={setFilter}
          setIsSearchBarOpen={setIsSearchBarOpen}
        />
      </Box>

      {displaySearchBarItems && (
        <Box
          bgColor="white"
          borderColor="blue-1"
          tw="border rounded-b-lg py-[23px] px-[26px] mb-[14px]"
        >
          {searchBarItems.map(({ item }) => (
            <CatalogItem key={item.ndc} item={item} />
          ))}
        </Box>
      )}

      <div tw="space-y-[14px]">
        {hasFeaturedItems && (
          <Box bgColor="white" tw="rounded-lg px-[26px] pt-[33px] pb-[21px]">
            <CatalogItemsSectionFeaturedItems />
          </Box>
        )}

        <CatalogItemsBuyItAgain
          shoppingListItems={shoppingListItems}
          mostRecentPurchases={mostRecentPurchases}
        />

        {displayTopSellingItems && (
          <CatalogItemsTopSellingItems
            topSellingItems={topSellingItems}
            shoppingListItems={shoppingListItems}
          />
        )}

        <CatalogItemsAll shoppingListItems={shoppingListItems} />
      </div>
    </>
  );
}
