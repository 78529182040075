import React from "react";
import "twin.macro";
import dayjs from "dayjs";
import { Text } from "../../rxLibrary/typography";
import { SortBySelect } from "../../rxLibrary/selects/SortBySelect";
import { Box } from "../../rxLibrary/box";
import { Switch } from "../../rxLibrary/switch";
import { DateRangePicker } from "../../rxLibrary/dateRangePicker";
import {
  MultiSelectCheckboxes,
  MultiSelectCheckboxesSelectedTags,
} from "../../rxLibrary/selects/MultiSelectCheckboxes";
import { useRefillOpportunities } from "../RefillOpportunitiesContext";
import {
  REFILL_OPPORTUNITIES_SORT_OPTIONS,
  getDateRangesPresets,
} from "./RefillOpportunitiesReport.constants";

export function RefillOpportunitiesFilters() {
  const {
    dates,
    sortColumn,
    sortDirection,
    manufacturerFilters,
    manufacturerOptions,
    showOnlyCoveredFills,
    setDates,
    setSortColumn,
    setSortDirection,
    setShowOnlyCoveredFills,
    setManufacturerFilters,
  } = useRefillOpportunities();
  const showManufacturerFilter =
    manufacturerFilters &&
    !!manufacturerOptions &&
    manufacturerOptions.length > 1;
  const showManufacturerTags =
    showManufacturerFilter && manufacturerFilters.length !== 0;

  return (
    <>
      <Box
        tw="rounded-t-lg flex items-center justify-between p-[24px] border"
        bgColor="grey-5"
        borderColor="grey-3"
      >
        <div tw="flex flex-1 justify-between items-center space-x-8">
          {showManufacturerFilter && (
            <div>
              <MultiSelectCheckboxes
                title="Select Manufacturers to Show"
                filters={manufacturerFilters}
                options={manufacturerOptions}
                setFilters={setManufacturerFilters}
              />
            </div>
          )}

          <div>
            <DateRangePicker
              label="Date Range"
              defaultValue={dates}
              onChange={setDates}
              presets={getDateRangesPresets()}
              minDate={dayjs("01/01/2024", "MM/DD/YYYY")}
            />
          </div>

          <div tw="flex items-center space-x-1">
            <Switch
              checked={showOnlyCoveredFills}
              onChange={setShowOnlyCoveredFills}
            />
            <Text size="medium">Show only covered Rxs</Text>
          </div>

          <div>
            <SortBySelect
              value={sortColumn}
              selectMinWidth={170}
              options={REFILL_OPPORTUNITIES_SORT_OPTIONS}
              sortOrder={sortDirection}
              onSortOrderChange={setSortDirection}
              onChange={setSortColumn}
            />
          </div>
        </div>
      </Box>

      {showManufacturerTags && (
        <Box
          tw="flex items-center px-[24px] py-4 my-1"
          bgColor="grey-5"
          borderColor="grey-3"
        >
          <MultiSelectCheckboxesSelectedTags
            filters={manufacturerFilters}
            options={manufacturerOptions}
            setFilters={setManufacturerFilters}
          />
        </Box>
      )}
    </>
  );
}
