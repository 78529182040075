import React, { useMemo } from "react";
import "twin.macro";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import type { ManufacturerAgGridReport } from "../../../utilities/types";
import { FullPageLoader } from "../../../components/loaders/FullPageLoader";
import { useRequest } from "../../../services/request/useRequest";
import { getManufacturerAgGridReport } from "../../../services/report";
import { ManufacturerReportComponent } from "../Manufacturer.constants";
import { ManufacturerReportError } from "./ManufacturerReportError";

function formatter(reportData: ManufacturerAgGridReport) {
  const {
    data,
    query_1_data: exportCsvData = [],
    query_2_data: exportAllCsvData = [],
  } = reportData.response;
  return { data, exportCsvData, exportAllCsvData };
}

export function ManufacturerReportGrid({
  reportId,
  startDate,
  endDate,
  manufacturerId,
  Report,
}: {
  reportId: number;
  startDate: string;
  endDate: string;
  manufacturerId: number;
  Report: ManufacturerReportComponent;
}) {
  const config = useMemo(() => {
    return { reportId, manufacturerId, startDate, endDate };
  }, [reportId, manufacturerId, startDate, endDate]);

  const {
    error,
    isLoading,
    formattedData: reportData,
  } = useRequest({
    dataKey: "manufacturerAgGridReport",
    config,
    request: getManufacturerAgGridReport,
    formatter,
  });

  return (
    <>
      {!!reportData && <Report {...reportData} />}
      {!!error && <ManufacturerReportError />}
      {isLoading && <FullPageLoader />}
    </>
  );
}
