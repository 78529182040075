import React from "react";
import styled from "@emotion/styled/macro";
import {
  RxColor,
  getColorCss,
  getBorderColorCss,
  getBackgroundColorCss,
} from "./colors";

export const Box = styled.div(
  ({
    color,
    bgColor,
    borderColor,
  }: {
    color?: RxColor;
    bgColor?: RxColor;
    borderColor?: RxColor;
  }) => [
    getColorCss(color),
    getBorderColorCss(borderColor),
    getBackgroundColorCss(bgColor),
  ]
);
