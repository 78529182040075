import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { AnonymousUserChatButton } from "./AnonymousUserChatButton";

const CHATLIO_BLACK_LISTED_PATHS = [
  "manufacturer",
  "shoppingListPrint",
  "supplierNavigation",
  "supplierSafetyScreen",
  "admin",
];

export function ChatButton() {
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const isChatHidden = useMemo(() => {
    return CHATLIO_BLACK_LISTED_PATHS.some(
      (blackListed) => path === blackListed
    );
  }, [path]);

  if (isChatHidden) return null;
  return <AnonymousUserChatButton />;
}
