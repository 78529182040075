import React from "react";
import pluralize from "pluralize";
import dayjs from "dayjs";
import "../../../scss/futureRefills.scss";
import {
  SVGType,
  FutureRefillOptionEnum,
  PrescriptionQuantityItem,
} from "../../../utilities/types";
import { formatWithUnit } from "../../../utilities/formatWithUnit";
import Svg from "../../../components/Svg";

function FutureRefillsCalendar({
  futureFillOption,
}: {
  futureFillOption: FutureRefillOptionEnum;
}) {
  const now = dayjs().date();
  const end = dayjs().endOf("month").date();

  switch (futureFillOption) {
    case FutureRefillOptionEnum.SemiMonthly:
      // Render Calendar SVG which has dynamic days towards the end of billing date.
      if (now === 15) {
        // 15th (mid) of month
        return (
          <Svg
            icon={SVGType.Calendar}
            text1={(end - 15).toString()}
            text2="DAYS"
          />
        );
      }
      if (now === end) {
        // last day of month
        return <Svg icon={SVGType.Calendar} text1="16" text2="DAYS" />;
      }
      if (now < 15) {
        // day in 1 - 14
        return (
          <Svg
            icon={SVGType.Calendar}
            text1={(16 - now).toString()}
            text2="DAYS"
          />
        );
      }

      // day in 16 - last day of month
      return (
        <Svg
          icon={SVGType.Calendar}
          text1={(end - now).toString()}
          text2="DAYS"
        />
      );
    case FutureRefillOptionEnum.Next30Days:
      return <Svg icon={SVGType.Calendar} text1="30" text2="DAYS" />;
    case FutureRefillOptionEnum.Next60Days:
      return <Svg icon={SVGType.Calendar} text1="60" text2="DAYS" />;
    case FutureRefillOptionEnum.Next90Days:
      return <Svg icon={SVGType.Calendar} text1="90" text2="DAYS" />;
    default:
      return null;
  }
}

export function FutureRefills({
  prescription,
  futureFillOption,
}: {
  prescription: PrescriptionQuantityItem;
  futureFillOption: FutureRefillOptionEnum;
}) {
  const drug = prescription.drug;
  const futureFills = prescription.futureFills[futureFillOption];
  const { exact, equivalent } = futureFills;

  return (
    <div className="future-refills">
      <div className="future-refills__svg-wrapper">
        <FutureRefillsCalendar futureFillOption={futureFillOption} />
      </div>
      <div className="future-refills--content">
        <div className="future-refills--content-ndc">
          <p className="future-refills--content-ndc--quantity">
            {formatWithUnit(exact.quantity, drug.uom)}
          </p>
          &nbsp;/&nbsp;
          <p className="future-refills--content-ndc--fills">
            {exact.numFills}{" "}
            {pluralize("Refill", exact.numFills)}
          </p>
          &nbsp;
          <span className="text-xs">(NDC)</span>
        </div>
        <div className="future-refills--content-ndc">
          <p className="future-refills--content-ndc--quantity">
            {formatWithUnit(equivalent.quantity, drug.uom)}
          </p>
          &nbsp;/&nbsp;
          <p className="future-refills--content-ndc--fills">
            {equivalent.numFills}{" "}
            {pluralize("Refill", equivalent.numFills)}
          </p>
          &nbsp;
          <span className="text-xs">(Eq)</span>
        </div>
      </div>
    </div>
  );
}
