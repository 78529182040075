import React, { useMemo } from "react";
import "twin.macro";
import uniq from "lodash/uniq";
import { Supplier } from "../../../../../../../../utilities/types";
import { Tag } from "../../../../../../../../components/rxLibrary/tags";
import { OtherSuppliersPopover } from "./OtherSuppliersPopover";
import {
  SupplierOrderItemRecommendation_All
} from "../../../../../../../../services/types";

export function BrandAtOtherSuppliersWarning({
  item,
  supplier,
  openModal,
}: {
  item: SupplierOrderItemRecommendation_All;
  supplier: Supplier;
  openModal: () => void;
}) {
  const otherSuppliers = useMemo(() => {
    if (!supplier.notifyForAlternativeSuppliersForBrand) return;

    const { isRx, isBrand } = item.referenceData.drugInfo;
    if (!isRx || !isBrand) return;

    const suppliers = uniq(
      item.alternatives.map((a) => a.referenceData.catalogInfo.supplierDisplayName)
    );
    const otherSuppliers = suppliers.filter((s) => s !== supplier.displayName);

    if (otherSuppliers.length > 0) return otherSuppliers;
  }, [item, supplier]);

  if (!otherSuppliers) return null;
  return (
    <div tw="flex items-center space-x-1">
      <Tag>Brand Rx</Tag>
      <OtherSuppliersPopover suppliers={otherSuppliers} openModal={openModal} />
    </div>
  );
}
