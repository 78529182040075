import React, {useContext, useEffect, useState} from 'react';
import 'twin.macro';
import EnhancedClientConfigContext from "./EnhancedClientConfigContext";
import { Container } from "../components/containers/Container";
import semver from "semver";
import {AnonymousUserChatButton} from "../components/ChatButton/AnonymousUserChatButton";

const STATUS_NO_UPDATES_AVAILABLE = "No updates available";
export default function EnhancedScreenLockAppOutOfDate({children}: {
    children: React.ReactNode,
}):JSX.Element {
  const { enhancedClientActive, isAppOutOfDate, enhancedClientVersion } = useContext(EnhancedClientConfigContext);
  const [updateStatus, setUpdateStatus] = useState<string | undefined>(undefined);
  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event.data.channel === "daylightrxenhanced" && event.data.sender === "app") {
        console.log("EnhancedScreenLockInactivity received message", event.data);
        if (event.data.msgType === "manualUpdateChecking") {
          setUpdateStatus("Checking for updates...");
        } else if (event.data.msgType === "manualUpdateAvailable") {
          setUpdateStatus("Found updates, downloading...");
        } else if (event.data.msgType === "manualUpdateDownloaded") {
          setUpdateStatus("Downloaded updates, installing...");
        } else if (event.data.msgType === "manualUpdateNotAvailable") {
          setUpdateStatus(STATUS_NO_UPDATES_AVAILABLE);
          setTimeout(() => {
            setUpdateStatus(undefined);
          }, 5000);
        } else if (event.data.msgType === "manualUpdateError") {
          setUpdateStatus("Error updating");
          setTimeout(() => {
            setUpdateStatus(undefined);
          }, 5000);
        }
      }
    };
    window.addEventListener("message", listener);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);
  useEffect(() => {
    if (updateStatus === "Initiating Update") {
      window.postMessage({
        channel: "daylightrxenhanced",
        sender: "web",
        msgType: "initiateManualUpdate",
      });
    }
  }, [updateStatus]);

  if (enhancedClientActive && isAppOutOfDate) {
      return <Container style={{marginTop: "196px"}}>
        <div className="text-lg"><strong>Your current version of DaylightRx is out of date.</strong></div>
        <>
        {!enhancedClientVersion || semver.lte(enhancedClientVersion, "0.3.45") ? (
          <>
            <div className="mt-24 text-16">
              To upgrade:
            </div>
            <div className="mt-12">
              <ol>
                <li className="text-16">
                  1. {" "}
                  <a
                    tw="text-primary"
                    className="app-upgrade-link"
                    rel="noreferrer"
                    target="_blank"
                    href="https://app.daylightrx.com/"
                  >
                    Please log into https://app.daylightrx.com/
                  </a>
                </li>
                <li className="text-16 mt-8">
                  2. Download the current version and run the installer
                </li>
              </ol>
            </div>
            <div className="mt-12 text-16">
              The new DaylightRx icon will be on your desktop.
            </div>
          </>
        ) : (<>
            {updateStatus === undefined && (<div className="mt-24 text-16">
              <a
                tw="text-primary cursor-pointer"
              target="#"
              onClick={() => {
                setUpdateStatus("Initiating Update")
              }}
            >
              Click here
            </a>{" "}
            to Update DaylightRx
          </div>)}
          {updateStatus && (updateStatus === STATUS_NO_UPDATES_AVAILABLE ? (
            <div className="mt-12 text-16">
              <a
                tw="text-primary"
                className="app-upgrade-link"
                rel="noreferrer"
                target="_blank"
                href="https://app.daylightrx.com/"
              >
                Please log into our website to download the most recent
                version.
              </a>
            </div>
          ) : (
            <div className="mt-12 text-16">
              {updateStatus}
            </div>
          ))}
          </>
        )}
        </>

        <div className="mt-12 text-16">
          &quot;Chat With Us&quot; below or email us at support@daylightrx.com
          if you have any questions!
        </div>
        <AnonymousUserChatButton />
      </Container>;
  }

  return children as JSX.Element;
}
