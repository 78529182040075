import { useContext, useEffect, useState } from "react";
import keyBy from "lodash/keyBy";
import { BuyLaterItem } from "../useBuyLaterTableItems";
import { ShoppingItem } from "../../../useShoppingList";
import { Supplier } from "../../../../../../utilities/types";
import BuyingPharmacyContext from "../../../../../../contexts/BuyingPharmacyContext";
import { CatalogDrug_All, SupplierOrder_All } from "../../../../../../services/types";

export type AlternativeWithSupplierData = {
  supplier: Supplier;
  alternative: CatalogDrug_All;
  supplierOrder?: SupplierOrder_All;
};

export function useBuyLaterTableModal(
  modalItem: BuyLaterItem,
  shoppingItems: ShoppingItem[]
) {
  const { getSupplierById } = useContext(BuyingPharmacyContext);

  const [modalData, setModalData] = useState<{
    shoppingItemsAlternatives: AlternativeWithSupplierData[];
    notShoppingItemsAlternatives: AlternativeWithSupplierData[];
  }>({
    shoppingItemsAlternatives: [],
    notShoppingItemsAlternatives: [],
  });

  useEffect(() => {
    const shoppingItemsById = keyBy(shoppingItems, "supplier.id");

    const shoppingItemsAlternatives: AlternativeWithSupplierData[] = [];
    const notShoppingItemsAlternatives: AlternativeWithSupplierData[] = [];
    modalItem.alternatives.map((alternative) => {
      const { supplierId } = alternative.referenceData.catalogInfo;
      const shoppingItem: ShoppingItem | undefined =
        shoppingItemsById[supplierId];

      if (shoppingItem) {
        const { supplier, supplierOrder } = shoppingItem;
        shoppingItemsAlternatives.push({
          supplier,
          supplierOrder,
          alternative,
        });
      } else {
        const supplier = getSupplierById(supplierId);
        if (!supplier) return;
        notShoppingItemsAlternatives.push({ supplier, alternative });
      }
    });
    shoppingItemsAlternatives.sort((a, b) => {
      return a.alternative.referenceData.catalogInfo.price - b.alternative.referenceData.catalogInfo.price;
    });

    setModalData({ shoppingItemsAlternatives, notShoppingItemsAlternatives });
  }, [modalItem, shoppingItems, getSupplierById]);

  return modalData;
}
