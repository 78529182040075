import React, { useCallback, useState } from "react";
import "twin.macro";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { Button } from "../../../../components/rxLibrary/buttons";
import { Title } from "../../../../components/rxLibrary/typography";
import { useStorageState } from "../../../../contexts/StorageContext";
import { useShoppingCartServerUpdater } from "../../../../contexts/ShoppingCartServerContext";

export function ShoppingBarCancelModalBtn() {
  const history = useHistory();
  const { waitButtonMode } = useStorageState();
  const { reverseUndoableMutations, pushBlob, setUseBlob } =
    useShoppingCartServerUpdater();
  const [isOpen, setIsOpen] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleComplete = useCallback(async () => {
    setIsCancelling(true);

    reverseUndoableMutations();

    await pushBlob({
      force: true,
      cb: () => {
        setUseBlob(true);
        history.push("/shoppingList");
      },
    });
  }, [pushBlob, setUseBlob, reverseUndoableMutations]);

  return (
    <>
      <Button variant="text-2" disabled={waitButtonMode} onClick={openModal}>
        Cancel
      </Button>

      {isOpen && (
        <Modal open onCancel={closeModal} footer={null}>
          <div tw="flex flex-col justify-center items-center py-8 space-y-5">
            <Title block tw="mb-2">
              Canceling will mark all your items as {'"'}in list{'"'}
            </Title>

            <Button block onClick={handleComplete} disabled={isCancelling}>
              {isCancelling ? "Canceling..." : 'Cancel & Mark All "In List"'}
            </Button>

            <Button
              block
              variant="text-2"
              onClick={closeModal}
              disabled={isCancelling}
            >
              Stay on This Page
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}
