import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  DropdownOptionType,
  FutureRefillOptionEnum,
} from "../../../utilities/types";
import BuyingPharmacyContext from "../../../contexts/BuyingPharmacyContext";
import { getUiSettings, setUiSettings } from "../../../services/legacy/uiSettings";
import { useAuth0 } from "../../../contexts/AuthenticationContext";
import { MANAGE_COLUMN_LS_KEY } from "../../../utilities/localStorage/constants";
import AppContext from "../../../contexts/AppContext";
import { ManageColumnOptionEnum } from "./ShoppingListTwo.constants";
import { PmsEnum } from "../../../utilities/pms";

function getLocalStorageManageColumnOptions(): DropdownOptionType[] | void {
  const manageColumnStr = localStorage.getItem(MANAGE_COLUMN_LS_KEY);

  if (manageColumnStr) {
    try {
      const manageColumnOptions = JSON.parse(manageColumnStr);
      return manageColumnOptions;
    } catch {
      // do noting
    }
  }
}

export function useManageColumnOptions() {
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useContext(AppContext);
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const [manageColumnOptions, _setManageColumnOptions] =
    useState<DropdownOptionType[]>();
  const {
    id: pharmacyId,
    pms,
    keepsInventory: pharmacyKeepsInventory,
  } = currentBuyingPharmacy ?? {};
  const isPrimeRx = pms === PmsEnum.PrimeRx;
  const isLiberty = pms === PmsEnum.Liberty;
  const isAdmin = !!user?.is_admin;

  const futureRefillOption = useMemo(() => {
    return manageColumnOptions?.find((o) => {
      return o.value === ManageColumnOptionEnum.Refills;
    });
  }, [manageColumnOptions]);

  const inventoryOption = useMemo(() => {
    return manageColumnOptions?.find((o) => {
      return o.value === ManageColumnOptionEnum.Inventory;
    });
  }, [manageColumnOptions]);

  const isShowFutureRefills = !!futureRefillOption?.checked;
  const isShowInventory = !!inventoryOption?.checked;
  const futureFillOptionDataValue = futureRefillOption?.data?.value as
    | FutureRefillOptionEnum
    | undefined;

  const mainTableInitialState = useMemo(() => {
    const hiddenColumns = [];
    if (!isShowInventory) {
      hiddenColumns.push("dispensedQuantity");
    }
    if (!isShowFutureRefills) {
      hiddenColumns.push("futureRefills");
    }

    return { hiddenColumns };
  }, [isShowInventory, isShowFutureRefills]);

  const setManageColumnOptions = useCallback(
    (newOptions: DropdownOptionType[]) => {
      const formattedOptions = newOptions
        .filter((o) => {
          if (isAdmin || !isPrimeRx) return true;
          return o.value !== ManageColumnOptionEnum.Inventory;
        })
        .map((option) => {
          let label = "Upcoming Refills";
          if (option.value === ManageColumnOptionEnum.Inventory) {
            label = isLiberty ? "BOH (Inventory)" : "BOH/EOH (Inventory)";
          }
          return { ...option, label };
        });
      _setManageColumnOptions(formattedOptions);
    },
    [isAdmin, isLiberty, isPrimeRx]
  );

  const updateManageColumnOptions = useCallback(
    async (newManageColumnOptions: DropdownOptionType[]) => {
      if (!pharmacyId) return;

      const token = await getAccessTokenSilently();
      await setUiSettings({
        token,
        pharmacyId,
        uiSettings: {
          settings: { manageColumnOptions: newManageColumnOptions },
        },
      });
      setManageColumnOptions(newManageColumnOptions);
    },
    [pharmacyId, getAccessTokenSilently, setManageColumnOptions]
  );

  useEffect(() => {
    init();

    async function init() {
      if (!pharmacyId) return;

      const token = await getAccessTokenSilently();
      const { data } = await getUiSettings({ token, pharmacyId });
      const manageColumnOptionsBE = data?.settings?.manageColumnOptions;
      if (manageColumnOptionsBE) {
        setManageColumnOptions(manageColumnOptionsBE);
        return;
      }

      const manageColumnOptionsLS = getLocalStorageManageColumnOptions();
      if (manageColumnOptionsLS) {
        setManageColumnOptions(manageColumnOptionsLS);
        await updateManageColumnOptions(manageColumnOptionsLS);
        localStorage.removeItem(MANAGE_COLUMN_LS_KEY);
        return;
      }

      const defaultManageColumnOptions = [
        {
          label: "",
          value: ManageColumnOptionEnum.Inventory,
          checked: !!pharmacyKeepsInventory,
        },
        {
          label: "",
          value: ManageColumnOptionEnum.Refills,
          checked: true,
          data: {
            value: FutureRefillOptionEnum.SemiMonthly,
            label: "15-Day Billing Cycle",
          },
        },
      ];
      setManageColumnOptions(defaultManageColumnOptions);
    }
  }, [
    pharmacyId,
    pharmacyKeepsInventory,
    setManageColumnOptions,
    getAccessTokenSilently,
    updateManageColumnOptions,
  ]);

  return {
    isShowInventory,
    isShowFutureRefills,
    manageColumnOptions,
    mainTableInitialState,
    futureFillOptionDataValue,
    updateManageColumnOptions,
  };
}
