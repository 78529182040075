import React, { useContext } from "react";
import tw, { css, styled } from "twin.macro";
import { Route, Switch, Redirect } from "react-router-dom";
import { printStyleHOC } from "../../components/rxLibrary/print";
import { GREY_4 } from "../../components/rxLibrary/colors";
import { Box } from "../../components/rxLibrary/box";
import { FullPageLoader } from "../../components/loaders/FullPageLoader";
import { RefillOpportunities } from "../../components/RefillOpportunities/RefillOpportunities";
import { useBuyingPharmacy } from "../../contexts/BuyingPharmacyContext";
import { IS_ELECTRON } from "../../utilities/config";
import EnhancedClientEcommerceContext from "../../enhanced/EnhancedClientEcommerceContext";
import { OrderHistory } from "./pages/OrderHistory/OrderHistory";
import { Completed } from "./pages/Completed";
import { Catalog } from "./pages/Catalog/Catalog";
import { AccountSettings } from "./pages/AccountSettings";
import { Invoices } from "./pages/Invoices";
import { useManufacturerStoreSupplier } from "./useManufacturerStoreSupplier";
import { ManufacturerStoreHeader } from "./ManufacturerStoreHeader/ManufacturerStoreHeader";
import { ManufacturerStoreNavBar } from "./ManufacturerStoreNavBar";

const Wrapper = printStyleHOC(
  styled.div(() => [
    tw`min-h-screen pt-[24px] px-[80px] pb-[200px]`,
    css`
      background-color: ${GREY_4};
    `,
  ]),
  css`
    background-color: initial;
    padding: 0;
  `
);

export function ManufacturerStore() {
  const { willNavigateToNewEcommercePage } = useContext(
    EnhancedClientEcommerceContext
  );
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const { supplierId, supplier } = useManufacturerStoreSupplier();

  if (
    !IS_ELECTRON &&
    (!supplierId || (supplier && !supplier.isEcommerceEnabled))
  ) {
    return <Redirect to="/" />;
  }

  if (!supplier || !currentBuyingPharmacy || willNavigateToNewEcommercePage) {
    return (
      <>
        <ManufacturerStoreNavBar supplier={supplier} />
        <FullPageLoader />
      </>
    );
  }

  return (
    <>
      <ManufacturerStoreNavBar supplier={supplier} />
      <ManufacturerStoreHeader supplier={supplier} />

      <Switch>
        <Route path="/manufacturer-store/:supplierId" exact>
          <Wrapper>
            <Catalog supplier={supplier} pharmacy={currentBuyingPharmacy} />
          </Wrapper>
        </Route>

        <Route path="/manufacturer-store/:supplierId/invoices" exact>
          <Wrapper>
            <Invoices supplier={supplier} pharmacy={currentBuyingPharmacy} />
          </Wrapper>
        </Route>

        <Route path="/manufacturer-store/:supplierId/account-settings" exact>
          <Wrapper>
            <AccountSettings
              supplier={supplier}
              pharmacy={currentBuyingPharmacy}
            />
          </Wrapper>
        </Route>

        <Route
          path="/manufacturer-store/:supplierId/completed/:purchaseOrderNumbers"
          exact
        >
          <Wrapper>
            <Completed supplier={supplier} />
          </Wrapper>
        </Route>

        <Route path="/manufacturer-store/:supplierId/order-history" exact>
          <Wrapper>
            <OrderHistory
              pharmacy={currentBuyingPharmacy}
              supplierId={supplier.id}
            />
          </Wrapper>
        </Route>

        <Route
          path="/manufacturer-store/:supplierId/refill-opportunities"
          component={RefillOpportunities}
          exact
        >
          <Box tw="border-t-2" borderColor="grey-4">
            <RefillOpportunities />
          </Box>
        </Route>
      </Switch>
    </>
  );
}
