import React, { useContext, useMemo } from "react";
import { styled } from "twin.macro";
import { uomDescription } from "../../../../utilities/helpers";
import { ItemInCart } from "../../../../utilities/types";
import { ORANGE_2 } from "../../../../components/rxLibrary/colors";
import { formatPurchaseByToDayjs } from "../../../../utilities/dates/purchaseBy/formatPurchaseByToDayjs";
import BuyingPharmacyContext from "../../../../contexts/BuyingPharmacyContext";
import { COLUMNS } from "./PrescriptionTable.columns";
import { SHORT_DATE_FORMAT } from "../../../../utilities/dates/dates.constants";

function getTotalQuantity(rxList?: ItemInCart[]) {
  if (!rxList || rxList.length === 0) return;

  const drugInfo = rxList[0].drug;
  const totalQt = rxList.reduce((acc, item) => {
    return (
      acc +
      (Number(item?.dispensedQuantity) ||
        (item?.packQuantity || 0) *
          item?.drug?.unitQuantity *
          item?.drug?.unitSize)
    );
  }, 0);
  const total = uomDescription(totalQt, drugInfo);
  return total;
}

function getBohUnits(rxList: ItemInCart[], keepsInventory?: boolean) {
  if (!keepsInventory) return;

  const hasPmsItems = rxList.some((item) => item.rxNumber);
  if (!hasPmsItems) return;

  const bohTotal = rxList.reduce((acc, item) => {
    return acc + (item?.boh || 0);
  }, 0);
  if (!bohTotal) return;

  const bohUnits = uomDescription(bohTotal, rxList[0]?.drug);
  return bohUnits
}

export function PrescriptionTableFooter({
  purchaseBy,
  rxList,
}: {
  purchaseBy?: string;
  rxList: ItemInCart[];
}) {
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const { keepsInventory } = currentBuyingPharmacy ?? {};

  const totalQuantity = useMemo(() => {
    return getTotalQuantity(rxList);
  }, [rxList]);

  const dateStr = useMemo(() => {
    const date = formatPurchaseByToDayjs(purchaseBy);
    return date ? `by ${date.format(SHORT_DATE_FORMAT)}` : "Today";
  }, [purchaseBy]);

  const bohStr = useMemo(() => {
    const bohUnits = getBohUnits(rxList, keepsInventory);
    return bohUnits ? ` - ${bohUnits} BOH` : "";
  }, [rxList, keepsInventory]);

  if (!totalQuantity) return null;
  return (
    <tr>
      <FooterTd colSpan={COLUMNS.length}>
        <strong>
          {totalQuantity} Total Needed {dateStr}
          {bohStr}
        </strong>
      </FooterTd>
    </tr>
  );
}

const FooterTd = styled.td`
  background-color: ${ORANGE_2}63;
  text-align: center;
`;
