import React from "react";
import { styled } from "twin.macro";
import { GREY_3 } from "../../colors";
import { Box } from "../../box";

const EmptyCol = styled.div`
  width: 40px;
  min-height: 100px;
  background-color: ${GREY_3};
`;

export function ExpandableTableRow({ children }: { children: JSX.Element }) {
  return (
    <Box tw="flex" bgColor="white">
      <EmptyCol />
      <div tw="flex flex-1 border-t py-[19px] px-[10px]">{children}</div>
    </Box>
  );
}
