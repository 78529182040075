import { useMemo } from "react";
import tw from "twin.macro";

export const SimpleLink = tw.a`underline`;

export function EmailLink({ email }: { email?: string }) {
  const href = useMemo(() => {
    return !!email && `mailto:${email}`;
  }, [email]);

  if (!href) return null;
  return <SimpleLink href={href}>{email}</SimpleLink>;
}
