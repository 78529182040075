import { useCallback, useEffect, useRef, useState } from "react";
import "core-js/actual/set-immediate";
import WindowScroller from "react-virtualized/dist/commonjs/WindowScroller";
import { useShoppingState } from "../../../../contexts/ShoppingContext/ShoppingContext";
import {
  HIDE_SHOPPING_LIST_ROW_ANIMATION_TIME_MS,
  CartItemsSortEnum,
} from "./useShoppingListItems.constants";
import { useVisibleShoppingListItems } from "./useVisibleShoppingListItems";
import { SortDirectionEnum } from "../../../../components/rxLibrary/selects/SortBySelect";

export function useShoppingListItems() {
  const pmsWindowScrollerRef = useRef<WindowScroller>(null);
  const { serverInventoryItemsInCart, prescriptionItemsInCart } =
    useShoppingState();
  const [cartItemsFilters, setCartItemsFilters] = useState<string[]>([]);
  const [cartItemsSorts, setCartItemsSorts] = useState([
    CartItemsSortEnum.DISPENSED,
  ]);
  const [cartItemsSortOrder, setCartItemsSortOrder] = useState(
    SortDirectionEnum.ASC
  );
  const [searchBarFilter, setSearchBarFilter] = useState("");
  const [hideOptimizedInventoryItems, setHideOptimizedInventoryItems] =
    useState(true);
  const [hideOptimizedPmsItems, setHideOptimizedPmsItems] = useState(true);

  const inventoryItemsWithVisibility = useVisibleShoppingListItems({
    sorts: cartItemsSorts,
    filters: cartItemsFilters,
    sortOrder: cartItemsSortOrder,
    isInventory: true,
    prescriptions: serverInventoryItemsInCart,
    searchBarFilter,
    hideOptimizedItems: hideOptimizedInventoryItems,
  });

  const prescriptionItemsWithVisibility = useVisibleShoppingListItems({
    sorts: cartItemsSorts,
    filters: cartItemsFilters,
    sortOrder: cartItemsSortOrder,
    isInventory: false,
    prescriptions: prescriptionItemsInCart,
    searchBarFilter,
    hideOptimizedItems: hideOptimizedPmsItems,
  });

  const setCartItemsSort = useCallback((newSort: CartItemsSortEnum) => {
    setCartItemsSorts((oldSorts) => [newSort, oldSorts[0]]);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [cartItemsFilters, hideOptimizedInventoryItems]);

  useEffect(
    () => {
      const pmsWindowScroller = pmsWindowScrollerRef.current;

      // Update the position of the window scroller after the inventory table height changes
      setImmediate(updateWindowScroller);
      // Update the position of the window scroller after the hide animations are done
      setTimeout(
        updateWindowScroller,
        HIDE_SHOPPING_LIST_ROW_ANIMATION_TIME_MS + 50
      );

      function updateWindowScroller() {
        if (!pmsWindowScroller) return;
        try {
          // Recalculate the position of the window scroller to avoid blank spaces
          pmsWindowScroller.updatePosition();
        } catch {
          // Ignore errors, the window scroller may not be mounted
        }
      }
    },
    // Add all the props that could cause the inventory table height to change
    [
      serverInventoryItemsInCart,
      cartItemsFilters,
      hideOptimizedInventoryItems,
      pmsWindowScrollerRef,
    ]
  );

  return {
    cartItemsSort: cartItemsSorts[0],
    cartItemsFilters,
    cartItemsSortOrder,
    hasCartItemsFilters: cartItemsFilters.length > 0,
    pmsWindowScrollerRef,
    hideOptimizedPmsItems,
    hideOptimizedInventoryItems,
    inventoryItemsWithVisibility,
    prescriptionItemsWithVisibility,
    setCartItemsSort,
    setSearchBarFilter,
    setCartItemsFilters,
    setCartItemsSortOrder,
    setHideOptimizedPmsItems,
    setHideOptimizedInventoryItems,
  };
}
