import React, {Reducer, useEffect, useReducer} from "react";
import "../../scss/base.scss";
import "../../scss/enhSuppSafeScn.scss";

export type EnhancedSupplierSafetyScreenDisplayConfig = {
  supplier: string;
  supplierDisplay: string;
  useCase: string;
}

type ConfigAdj = {
  type: "setConfig",
  config: EnhancedSupplierSafetyScreenDisplayConfig
}

export default function EnhancedSupplierSafetyScreen(): JSX.Element {
  const [displayConfig, updateDisplayConfig] = useReducer<Reducer<EnhancedSupplierSafetyScreenDisplayConfig, ConfigAdj>>((state, update): EnhancedSupplierSafetyScreenDisplayConfig => {
    switch(update.type) {
      case "setConfig":
        return update.config;
    }
    return state;
  }, {
    supplier: "default",
    supplierDisplay: "Default Supplier",
    useCase: "default",
  })

  useEffect(() => {
    document.body.style.backgroundColor = 'rgba(0, 0, 0, 0.4)';
    const root = document.getElementById("root");
    if (root) {
      // root.style.backgroundColor = 'rgba(255,255,255,255)';
      root.style.height = 'auto';
    }
    return () => {
      document.body.style.backgroundColor = 'rgba(255,255,255)';
      const root = document.getElementById("root");
      if (root) {
        root.style.backgroundColor = 'inherit';
        root.style.height = '100%';
      }
    }
  }, [])

  useEffect(() => {
    const messageListenerCallback = async (event: MessageEvent) => {
      if (event.source !== window) {
        return;
      }
      if (event.data.channel === "daylightrxenhanced") {
        if (event.data.msgType === "safetyScreenDisplayConfig_default") {
          const payload = event.data.payload as { supplier: string, supplierDisplay: string };
          updateDisplayConfig({
            type: "setConfig", config: {
              supplier: payload.supplier,
              supplierDisplay: payload.supplierDisplay,
              useCase: "default",
            }
          });
        }
      }
    }
    window.addEventListener("message", messageListenerCallback);

    return () => {
      window.removeEventListener("message", messageListenerCallback);
    }
  }, []);

  return <div className="supplierSafetyScreen">
    <div className="interaction-bar" style={{
      position: "fixed",
      bottom: "0px",
      left: "0px",
      width: "100%",
      height: "128px",
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}>
      <h1 id="title"><span id="supplierDisplay"></span> Safe Mode</h1>
      <h2 id="message"></h2>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "32px",
      }}>
        <button className={"btn btn-white-shade"} id="close" onClick={() => {
          window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "safetyScreenClose",
          });
        }}>Return to DaylightRx
        </button>
        <button className={"btn btn-white-shade"} id="interact" onClick={() => {
          window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "safetyScreenInteract",
          });
        }}>Interact with {displayConfig.supplierDisplay}</button>
      </div>
    </div>
  </div>
}
