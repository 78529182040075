import React from "react";
import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";
import { GREY_5 } from "../../../../components/rxLibrary/colors";
import { Text } from "../../../../components/rxLibrary/typography";
import { BUTTON_SHADOW_CSS } from "../../../../components/rxLibrary/buttons";
import { useNavBarMenuLinks } from "../../../../utilities/routing/useNavBarMenuLinks";
import { SuppliersAndPasswordsCard } from "../SuppliersAndPasswordsCard";
import { Card } from "../Card";
import { MarketingAd } from "./MarketingAd";

const IconLink = styled(Link)`
  ${BUTTON_SHADOW_CSS}
  border-radius: 8px;
  background-color: ${GREY_5};
  column-gap: 18px;
  display: flex;
  padding: 9px 20px;
  align-items: center;
`;

export function ActionCards({
  isManufacturerOnly,
}: {
  isManufacturerOnly: boolean;
}) {
  const links = useNavBarMenuLinks();

  if (!links?.length) return null;

  if (!isManufacturerOnly && links.length === 1) {
    const link = links[0];
    if (link.to === "/supplierAccounts") return <SuppliersAndPasswordsCard />;
  }

  return (
    <Card>
      <div tw="flex flex-col h-full w-full space-y-[10px]">
        {links.map(({ text, to, image }) => {
          const isPreviousShoppingLists = to === "/previousShoppingLists";

          return (
            <IconLink
              key={to}
              to={to}
              tw="flex-1"
              css={isPreviousShoppingLists && tw`pl-[10px]`}
            >
              <img src={image} />
              <Text weight="bold" size="medium">
                <span tw="block">{text}</span>
              </Text>
            </IconLink>
          );
        })}

        {isManufacturerOnly && <MarketingAd />}
      </div>
    </Card>
  );
}
