import React, { ReactNode, useContext } from "react";
import "twin.macro";
import { Tooltip } from "antd";
import type {
  SupplierAccountsSummaryView,
  SupplierAccountRecordView,
} from "../EnhancedSupplierAccount.constants";
import EnhancedClientConfigContext from "../../EnhancedClientConfigContext";
import EnhancedClientCommandContext from "../../EnhancedClientCommandContext";
import { IconBell, IconGear } from "../../../components/rxLibrary/icons";
import { Text } from "../../../components/rxLibrary/typography";
import { Button } from "../../../components/rxLibrary/buttons";
import { Tag } from "../../../components/rxLibrary/tags";
import { PrintWrapper } from "../../../components/rxLibrary/print";
import { Box } from "../../../components/rxLibrary/box";
import { supplierIsDisabledInEnhancedClient } from "../../util";
import { IS_ELECTRON } from "../../../utilities/config";
import { EnhancedSupplierAccountPassword } from "../EnhancedSupplierAccountPassword";
import { EnhancedSupplierAccountUsername } from "../EnhancedSupplierAccountUsername";

const TableHeader = Box.withComponent("thead");

function SupplierWrapper({
  children,
  supplier,
}: {
  children: ReactNode;
  supplier: string;
}) {
  const { showSupplier } = useContext(EnhancedClientCommandContext);

  if (IS_ELECTRON) {
    return (
      <Tooltip title="Click to view supplier site">
        <button
          tw="block w-full h-full text-left hover:opacity-60"
          onClick={() => showSupplier(supplier)}
        >
          {children}
        </button>
      </Tooltip>
    );
  }

  return <>{children}</>;
}

export function EnhancedSupplierAccountSummaryTable({
  tableData,
  togglePassword,
  setCurrentEditedSupplierName,
}: {
  tableData: SupplierAccountsSummaryView[];
  togglePassword: (account: SupplierAccountRecordView) => void;
  setCurrentEditedSupplierName: (supplierName: string) => void;
}) {
  const { enhancedClientVersion } = useContext(EnhancedClientConfigContext);

  return (
    <table tw="w-full">
      <TableHeader
        tw="sticky top-[173px] z-10 text-left shadow-md"
        bgColor="white"
      >
        <tr>
          <th tw="p-4">
            <Text weight="bold">Supplier</Text>
          </th>
          <th tw="p-4 w-[200px]">
            <Text weight="bold">Username</Text>
          </th>
          <th tw="p-4 w-[200px]">
            <Text weight="bold">Password</Text>
          </th>

          <th tw="w-[108px]" />
          <th tw="w-[104px]" />
        </tr>
      </TableHeader>

      <tbody>
        {tableData
          .filter((row) => !row.supplierOfflinePurchasing)
          .map((row) => {
            const {
              supplier,
              loggedIn,
              accounts,
              supplierDisplay,
              autologinDisabled,
            } = row;

            const IsDisabledInEnhancedClient =
              supplierIsDisabledInEnhancedClient(
                supplier,
                enhancedClientVersion
              );

            if (IsDisabledInEnhancedClient) {
              return (
                <tr key={supplier} tw="border">
                  <td tw="p-2 h-0">
                    <Box
                      bgColor="grey-5"
                      tw="px-4 py-1 rounded-md h-full flex items-center"
                    >
                      <div tw="w-full">
                        <Text block color="grey-2" weight="bold" tw="text-left">
                          {supplierDisplay}
                        </Text>
                      </div>
                    </Box>
                  </td>

                  <td tw="p-2" colSpan={4}>
                    <div tw="flex items-center h-[35px]">
                      <Text>
                        {supplierDisplay} temporarily unavailable in DaylightRx
                      </Text>
                    </div>
                  </td>
                </tr>
              );
            }

            return (
              <tr key={supplier} tw="border">
                <td tw="p-2 h-0">
                  <SupplierWrapper supplier={supplier}>
                    <Box
                      bgColor="grey-5"
                      tw="px-4 py-1 rounded-md h-full flex items-center"
                    >
                      <div tw="space-y-2">
                        <Text block color="blue-1" weight="bold">
                          {supplierDisplay}
                        </Text>

                        {autologinDisabled && (
                          <div tw="flex items-center space-x-2">
                            <IconBell color="red" />
                            <Text>
                              Auto-login disabled when more than 1 username is
                              saved.
                            </Text>
                          </div>
                        )}
                      </div>
                    </Box>
                  </SupplierWrapper>
                </td>

                <td tw="py-2 px-0 h-0">
                  <Box
                    bgColor="grey-5"
                    tw="px-4 py-1 rounded-l-md h-full flex items-center"
                  >
                    <div tw="w-full">
                      {accounts.map((account) => (
                        <EnhancedSupplierAccountUsername
                          key={account.id}
                          account={account}
                        />
                      ))}
                    </div>
                  </Box>
                </td>

                <td tw="py-2 px-0 h-0">
                  <Box bgColor="grey-5" tw="py-1 px-4 h-full flex items-center">
                    <div tw="w-full">
                      {accounts.map((account) => (
                        <EnhancedSupplierAccountPassword
                          key={account.id}
                          account={account}
                          togglePassword={togglePassword}
                        />
                      ))}
                    </div>
                  </Box>
                </td>

                <td tw="py-2 px-0 h-0">
                  <Box
                    bgColor="grey-5"
                    tw="py-1 px-4 rounded-r-md h-full flex justify-end items-center"
                  >
                    {loggedIn ? (
                      <Tag bgColor="green-2">Logged In</Tag>
                    ) : (
                      <Tag bgColor="grey-2">Not Logged In</Tag>
                    )}
                  </Box>
                </td>

                <td tw="p-2">
                  <PrintWrapper tw="flex justify-center">
                    <Button
                      icon={<IconGear />}
                      onClick={() => {
                        setCurrentEditedSupplierName(supplier);
                      }}
                    >
                      Settings
                    </Button>
                  </PrintWrapper>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
