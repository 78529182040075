import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { setTag } from "@sentry/react";
import keyBy from "lodash/keyBy";
import { Pharmacy, Supplier } from "../utilities/types";
import PharmaciesContext from "./PharmaciesContext";
import { LAST_BUYING_PHARMACY_KEY } from "../utilities/localStorage/constants";
import { getLocalStorageValue } from "../utilities/localStorage/getLocalStorageValue";
import { setLocalStorageValue } from "../utilities/localStorage/setLocalStorageValue";
import { removeLocalStorageValue } from "../utilities/localStorage/removeLocalStorageValue";

type BuyingPharmacyContext = {
  currentBuyingPharmacy: Pharmacy | null;
  currentBuyingPharmacyId: Pharmacy["id"] | null;
  currentBuyingPharmacyConfig: BuyingPharmacyConfig | null;
  getSupplierById: (id?: number) => Supplier | undefined;
  getSupplierByName: (supplier?: string) => Supplier | undefined;
  setCurrentBuyingPharmacyId: (id: number | null) => void;
};

const BuyingPharmacyContext = createContext<BuyingPharmacyContext>({
  currentBuyingPharmacy: null,
  currentBuyingPharmacyId: null,
  currentBuyingPharmacyConfig: null,
  getSupplierById: () => undefined,
  getSupplierByName: () => undefined,
  setCurrentBuyingPharmacyId: () => {},
});

if (process.env.REACT_APP_USE_CONTEXT_DEVTOOL) {
  BuyingPharmacyContext.displayName = "BuyingPharmacyContext";
}

export default BuyingPharmacyContext;

type BuyingPharmacyConfig = {
  pharmacyId: number;
  pmsIntegrated: boolean;
  siteFlavors?: Record<string, string>;
};

function getLocalStoragePharmacyId() {
  return getLocalStorageValue<number | null>(LAST_BUYING_PHARMACY_KEY, null);
}

export function BuyingPharmacyContextProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const { pharmacies } = useContext(PharmaciesContext);
  const [pharmacyIdRaw, setPharmacyIdRaw] = useState(getLocalStoragePharmacyId);

  const currentBuyingPharmacy = useMemo(() => {
    if (!pharmacies.length) return null;

    let pharmacy;
    if (pharmacyIdRaw) {
      pharmacy = pharmacies.find((p) => p.id === pharmacyIdRaw);
    } else {
      pharmacy = pharmacies.find((p) => p.isDefaultForUser);
    }
    if (!pharmacy) pharmacy = pharmacies[0];

    return pharmacy ?? null;
  }, [pharmacies, pharmacyIdRaw]);
  const currentBuyingPharmacyId = currentBuyingPharmacy?.id ?? null;

  const currentBuyingPharmacyConfig =
    useMemo((): BuyingPharmacyConfig | null => {
      if (currentBuyingPharmacyId === 28) {
        //RidgeEZ
        return {
          pharmacyId: 28,
          pmsIntegrated: false,
          siteFlavors: {
            ANDA: "aspiregpo",
          },
        };
      } else if (
        currentBuyingPharmacyId === 29 ||
        currentBuyingPharmacyId === 30
      ) {
        //Closter, USPharmacyLab
        return {
          pharmacyId: currentBuyingPharmacyId as number,
          pmsIntegrated: false,
        };
      } else if (currentBuyingPharmacyId !== null) {
        return {
          pharmacyId: currentBuyingPharmacyId as number,
          pmsIntegrated: true,
        };
      } else {
        return null;
      }
    }, [currentBuyingPharmacyId]);

  const suppliersById = useMemo(() => {
    return keyBy(currentBuyingPharmacy?.suppliers, "id");
  }, [currentBuyingPharmacy?.suppliers]);

  const suppliersByName = useMemo(() => {
    return keyBy(currentBuyingPharmacy?.suppliers, "name");
  }, [currentBuyingPharmacy?.suppliers]);

  const getSupplierById = useCallback(
    (supplierId?: number): Supplier | undefined => {
      const supplier = supplierId ? suppliersById[supplierId] : undefined;
      return supplier;
    },
    [suppliersById]
  );

  const getSupplierByName = useCallback(
    (supplier?: string): Supplier | undefined => {
      const supplierName = supplier ? suppliersByName[supplier] : undefined;
      return supplierName;
    },
    [suppliersByName]
  );

  const setCurrentBuyingPharmacyId = useCallback((id: number | null) => {
    if (Number.isFinite(id)) {
      setPharmacyIdRaw(id);
      setLocalStorageValue(LAST_BUYING_PHARMACY_KEY, id);
    } else {
      setPharmacyIdRaw(null);
      removeLocalStorageValue(LAST_BUYING_PHARMACY_KEY);
    }
  }, []);

  useEffect(() => {
    if (!currentBuyingPharmacy) return;
    setTag("pharmacy.id", currentBuyingPharmacy.id);
    setTag("pharmacy.name", currentBuyingPharmacy.name);
    setTag("pharmacy.pms", currentBuyingPharmacy.pms);
  }, [currentBuyingPharmacy]);

  useEffect(() => {
    addEventListener("storage", handleLocalStorageUpdate);

    return () => {
      removeEventListener("storage", handleLocalStorageUpdate);
    };

    function handleLocalStorageUpdate(event: StorageEvent) {
      const { key, newValue, oldValue } = event;
      if (key !== LAST_BUYING_PHARMACY_KEY || newValue === oldValue) return;
      const pharmacyId = newValue === null ? null : Number(newValue);
      setCurrentBuyingPharmacyId(pharmacyId);
    }
  }, [setCurrentBuyingPharmacyId]);

  return (
    <BuyingPharmacyContext.Provider
      value={{
        currentBuyingPharmacy,
        currentBuyingPharmacyId,
        currentBuyingPharmacyConfig,
        getSupplierById,
        getSupplierByName,
        setCurrentBuyingPharmacyId,
      }}
    >
      {children}
    </BuyingPharmacyContext.Provider>
  );
}

export function useBuyingPharmacy(): BuyingPharmacyContext {
  const context = useContext(BuyingPharmacyContext);
  if (context === undefined) {
    throw new Error(
      "useBuyingPharmacy must be used within a BuyingPharmacyContextProvider"
    );
  }
  return context;
}
