import React, {
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
  MouseEvent,
  Fragment,
} from "react";
import "twin.macro";
import Typography from "antd/es/typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import cn from "classnames";
import "../scss/base.scss";
import "../scss/enhNav.scss";
const refreshIcon = new URL('svgurl:../assets/icon-refresh.svg', import.meta.url).toString();
import type { Supplier } from "../utilities/types";
import { SuppliersModal } from "../pages/shopping/ShoppingListThree/SuppliersModal/SuppliersModal";
import BuyingPharmacyContext from "../contexts/BuyingPharmacyContext";
import EnhancedClientNavBarCommandContext from "./EnhancedClientNavBarCommandContext";
import { useShoppingState } from "../contexts/ShoppingContext/ShoppingContext";
import { getMinShipOrderAmount } from "../utilities/shipping/shipping";
import { SupplierItemChangeOperation } from "../utilities/shoppingListTypes";
import { useWindowSize } from "../utilities/hooks";
import { shallowLinksOnlyForSupplier } from "../utilities/search";
import { testPayload } from "./test_data/SupplierNavBarTestConfiguration";
import { NavBarDropdown, NavBarDropdownItem } from "./navbar/NavBarDropdown";
import { NavBarHeader } from "./navbar/NavBarHeader";
import EnhancedClientCommandContext, {
  EnhancedCartUpdate,
} from "./EnhancedClientCommandContext";
import { NDCSearchParam } from "./types";
import EnhancedCartOperationsSummaryModal, {
  hasSummarizableOperations,
} from "./EnhancedCartOperationsSummaryModal";
import { SupplierFrameMasterConfig } from "./EnhancedAppConfig";
import EnhancedCartWaitingForOperationsModal from "./EnhancedWaitingForOperationsModal";
import { displayIfWide } from "./util";
import {formatNdc} from "../utilities/ndc/formatNdc";
import {
  CatalogDrug_All,
  SupplierOrder_All,
  SupplierOrderItemRecommendation_All,
  OptimizedCartResponse_All, uniqueCatalogDrugID
} from "../services/types";
import { Text } from "../components/rxLibrary/typography";
import {tryWriteClipboard} from "../utilities/clipboard";
import EnhancedClientConfigContext from "./EnhancedClientConfigContext";

type EnhancedSupplierNavBarDisplayConfigShoppingList = {
  supplier: string;
  supplierDisplay: string;
  useCase: "shoppingList";
  supplierOrder: SupplierOrder_All;
  supplierObj: Supplier;
  optimizeCartResponse: OptimizedCartResponse_All;
  index: number;
  visited: string[];
  latestChange?: SupplierItemChangeOperation | null;
  ndcSearch?: NDCSearchParam;
  isEcommerce?: boolean;
};

export type EnhancedSupplierNavBarDisplayConfig =
  | {
      supplier: string;
      supplierDisplay: string;
      useCase: "default";
    }
  | {
      supplier: string;
      supplierDisplay: string;
      useCase: "ndcSearch";
      search: NDCSearchParam;
      alert?: EnhancedSupplierFrameDisplayConfigAlert;
    }
  | {
      supplier: string;
      supplierDisplay: string;
      useCase: "login";
    }
  | {
      supplier: string;
      supplierDisplay: string;
      useCase: "ecommerceHome";
    }
  | EnhancedSupplierNavBarDisplayConfigShoppingList
  | {
      useCase: "dynamic";
      supplier: string;
      supplierDisplay: string;
      caseName: "problematic-supplier";
    };

export type EnhancedSupplierFrameDisplayConfigAlert =
  { useCase: "loginRequired", alertId: string, supplierName: string }

type ConfigAdj =
  | {
      type: "setConfig";
      config: EnhancedSupplierNavBarDisplayConfig;
    }
  | {
      type: "alert",
      alert: EnhancedSupplierFrameDisplayConfigAlert
    }
  | {
      type: "clearAlert",
      alertId: string
    }
  | {
      type: "loadCurrent";
    }
  | {
      type: "incIndex";
    }
  | {
      type: "decIndex";
    }
  | {
      type: "setIndex";
      index: number;
    }
  | {
      type: "processLatestChange";
    }
  | {
      type: "updateOrder";
      order: SupplierOrder_All;
    }
  | {
      type: "clearNdcSearch";
    };

const addVisit = (
  visited: string[],
  item: SupplierOrderItemRecommendation_All
): string[] => {
  const id = uniqueCatalogDrugID(item);
  return visited.includes(id) ? visited : [...visited, id];
};

function uniqueIDIsInList(
  item: SupplierOrderItemRecommendation_All,
  list: string[]
): boolean {
  return list.includes(uniqueCatalogDrugID(item));
}

function activeItems(displayConfig: EnhancedSupplierNavBarDisplayConfig) {
  const items =
    displayConfig.useCase === "shoppingList"
      ? displayConfig.supplierOrder.items
      : [];
  return items;
}

export default function EnhancedSupplierNavBar(): JSX.Element {
  const size = useWindowSize();
  const { enhancedClientVersion } = useContext(EnhancedClientConfigContext);
  const { ndcSearch, showMain } = useContext(EnhancedClientCommandContext);
  const { setNavBarHeight, requestCartChange, requestShoppingListSwitch } =
    useContext(EnhancedClientNavBarCommandContext);
  const { addOptimizeCartResponse } = useShoppingState();
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const [dotCount, setDotCount] = useState<number>(0);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState<boolean>(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [waitingForOperations, setWaitingForOperations] =
    useState<boolean>(false);
  const [updateModalOperations, setUpdateModalOperations] = useState<
    SupplierItemChangeOperation[] | null
  >(null);
  const [changeSupplierModalShow, setChangeSupplierModalShow] =
    useState<boolean>(false);
  const [changeSupplierModalData, setChangeSupplierModalData] = useState<
    CatalogDrug_All[]
  >([]);
  const [changeSupplierModalSuggested, setChangeSupplierModalSuggested] =
    useState<SupplierOrderItemRecommendation_All>();
  const optionsOpen = Boolean(optionsAnchorEl);
  const handleOptionsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setOptionsAnchorEl(event.currentTarget);
  };
  const handleOptionsClose = () => {
    setOptionsAnchorEl(null);
  };

  useEffect(() => {
    const hideChatlio = setInterval(() => {
      if (window._chatlio && window._chatlio.hide) {
        window._chatlio.hide();
      }
    }, 1000);
    return () => {
      clearInterval(hideChatlio);
    };
  }, []);

  function mergeOrder(
    state: EnhancedSupplierNavBarDisplayConfig & { useCase: "shoppingList" },
    order: SupplierOrder_All
  ): EnhancedSupplierNavBarDisplayConfig & { useCase: "shoppingList" } {
    const items = activeItems(state);
    const index = Math.max(Math.min(state.index, items.length - 1), 0);
    console.log("mergingOrder to index", index, "of", items.length, "items");
    const orderedUniqueIds = state.supplierOrder.items.map((item) =>
      uniqueCatalogDrugID(item)
    );
    order.items.sort((a, b) => {
      const aIndex = orderedUniqueIds.indexOf(uniqueCatalogDrugID(a));
      const bIndex = orderedUniqueIds.indexOf(uniqueCatalogDrugID(b));
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
    const newState = {
      ...state,
      supplierOrder: order,
      index,
    };

    if (activeItems(newState).length > index) {
      return scheduleVisit(newState, index);
    }
    {
      return {
        ...newState,
        index: Math.max(0, index - 1),
      };
    }
  }
  function findNextActiveIndex(
    state: EnhancedSupplierNavBarDisplayConfig & { useCase: "shoppingList" },
    startingIndex: number
  ): number {
    const items = activeItems(state);
    if (startingIndex < items.length) {
      return startingIndex;
    } else if (items.length > 0) {
      return 0;
    } else {
      return -1;
    }
  }

  function scheduleVisit(
    state: EnhancedSupplierNavBarDisplayConfig & { useCase: "shoppingList" },
    index: number
  ): EnhancedSupplierNavBarDisplayConfig & { useCase: "shoppingList" } {
    const item = activeItems(state)[index];
    tryWriteClipboard(item.referenceData.drugInfo.ndc);
    const result: EnhancedSupplierNavBarDisplayConfig & { useCase: "shoppingList" } = {
      ...state,
      ndcSearch: {
        ndc: item.referenceData.drugInfo.ndc,
        supplier: state.supplier,
        deeplink: item.referenceData.catalogInfo.link,
        origlink: item.referenceData.catalogInfo.link,
      },
      visited: addVisit(state.visited, item),
      index: index,
      latestChange: {
        type: "markProcessed",
        item: item,
        markAsVisited: true,
        visitAllForSupplier: state.isEcommerce,
      },
    };

    if (shallowLinksOnlyForSupplier(state.supplier, enhancedClientVersion)) {
      delete result.ndcSearch;
    }
    return result;
  }

  const [displayConfig, updateDisplayConfig] = useReducer<
    Reducer<EnhancedSupplierNavBarDisplayConfig, ConfigAdj>
  >(
    (state, update): EnhancedSupplierNavBarDisplayConfig => {
      console.log("Updating display config", update, "from", state, "");
      switch (update.type) {
        case "setConfig":
          if (update.config.useCase === "shoppingList") {
            let newState = update.config;
            if (
              state.useCase === "shoppingList" &&
              state.supplier === newState.supplier &&
              newState.visited.length > 0
            ) {
              const merged = mergeOrder(state, newState.supplierOrder);
              merged.visited = update.config.visited;
              newState = merged;
            }
            const newIndex = findNextActiveIndex(newState, 0);
            if (newIndex !== -1) {
              return scheduleVisit(newState, newIndex);
            }
          }
          return update.config;
        case "alert":
          if (state.useCase === "ndcSearch") {
            return {
              ...state,
              alert: update.alert
            }
          }
          return state;
        case "clearAlert":
          if (state.useCase === "ndcSearch" && state.alert?.alertId === update.alertId) {
            return {
              ...state,
              alert: undefined
            }
          }
          return state;
        case "updateOrder": {
          if (state.useCase === "shoppingList") {
            const order = update.order;
            return mergeOrder(state, order);
          }
          return state;
        }
        case "processLatestChange": {
          if (state.useCase === "shoppingList") {
            return {
              ...state,
              latestChange: null,
            };
          }
          return state;
        }
        case "loadCurrent": {
          if (
            state.useCase === "shoppingList" &&
            activeItems(state).length > 0
          ) {
            return scheduleVisit(state, state.index);
          }
          return state;
        }
        case "incIndex": {
          if (state.useCase === "shoppingList") {
            const newIndex = findNextActiveIndex(state, state.index + 1);
            console.log("incIndex", state.index, "to", newIndex);
            if (newIndex !== -1) {
              return scheduleVisit(state, newIndex);
            }
          }
          return state;
        }
        case "decIndex": {
          if (state.useCase === "shoppingList") {
            const newIndex = state.index - 1;
            if (newIndex >= 0) {
              const item = activeItems(state)[newIndex];
              tryWriteClipboard(item.referenceData.drugInfo.ndc);
              const result: EnhancedSupplierNavBarDisplayConfig & { useCase: "shoppingList" } = {
                ...state,
                ndcSearch: {
                  ndc: item.referenceData.drugInfo.ndc,
                  supplier: state.supplier,
                  deeplink: item.referenceData.catalogInfo.link,
                  origlink: item.referenceData.catalogInfo.link,
                },
                visited: addVisit(state.visited, item),
                index: newIndex,
                latestChange: {
                  type: "markProcessed",
                  item: item,
                  markAsVisited: true,
                },
              };
              if (shallowLinksOnlyForSupplier(state.supplier, enhancedClientVersion)) {
                delete result.ndcSearch;
              }
              return result;
            } else {
              return {
                ...state,
                index: -1,
              };
            }
          }
          return state;
        }
        case "setIndex": {
          if (state.useCase === "shoppingList") {
            const item = activeItems(state)[update.index];
            tryWriteClipboard(item.referenceData.drugInfo.ndc);
            const result: EnhancedSupplierNavBarDisplayConfig & { useCase: "shoppingList" } = {
              ...state,
              ndcSearch: {
                ndc: item.referenceData.drugInfo.ndc,
                supplier: state.supplier,
                deeplink: item.referenceData.catalogInfo.link,
                origlink: item.referenceData.catalogInfo.link,
              },
              visited: addVisit(state.visited, item),
              index: update.index,
              latestChange: {
                type: "markProcessed",
                item: item,
                markAsVisited: true,
              },
            };
            if (shallowLinksOnlyForSupplier(state.supplier, enhancedClientVersion)) {
              delete result.ndcSearch;
            }
            return result;
          }
          return state;
        }
        case "clearNdcSearch": {
          if (state.useCase === "shoppingList") {
            return {
              ...state,
              ndcSearch: undefined,
            };
          }
          return state;
        }
        default:
          return state;
      }
    },
    {
      useCase: "default",
      supplier: "default",
      supplierDisplay: "Default Supplier",
    }
  );

  const [dataUpdate, setDataUpdate] = useState<EnhancedCartUpdate | null>(null);

  useEffect(() => {
    if (displayConfig.useCase === "shoppingList" && displayConfig.ndcSearch) {
      ndcSearch(displayConfig.ndcSearch);
      updateDisplayConfig({ type: "clearNdcSearch" });
    }
  }, [displayConfig]);

  useEffect(() => {
    if (
      displayConfig.useCase === "shoppingList" &&
      displayConfig.latestChange
    ) {
      requestCartChange(displayConfig.supplier, [displayConfig.latestChange]);
      updateDisplayConfig({ type: "processLatestChange" });
    }
  }, [displayConfig]);

  useEffect(() => {
    if (displayConfig.useCase === "shoppingList") {
      if (displayConfig.index <= 0) {
        setPrevButtonDisabled(true);
      } else {
        setPrevButtonDisabled(false);
      }
      if (displayConfig.index >= activeItems(displayConfig).length - 1) {
        setNextButtonDisabled(true);
      } else {
        setNextButtonDisabled(false);
      }
    }
  }, [displayConfig]);

  function goNext() {
    updateDisplayConfig({ type: "incIndex" });
  }

  function goPrev() {
    updateDisplayConfig({ type: "decIndex" });
  }

  function reloadCurrent() {
    updateDisplayConfig({ type: "loadCurrent" });
  }
  const getSupplierName = (id: number | undefined): string => {
    if (id) {
      const supplier = currentBuyingPharmacy?.suppliers.filter(
        (supplier: Supplier) => {
          return supplier.id === id;
        }
      )[0];

      if (supplier) {
        return supplier.name;
      }
    }
    return "-";
  };

  const getSupplierDisplayName = (id: number | undefined): string => {
    if (id) {
      const supplier = currentBuyingPharmacy?.suppliers.filter(
        (supplier: Supplier) => {
          return supplier.id === id;
        }
      )[0];

      if (supplier) {
        return supplier.displayName;
      }
    }
    return "-";
  };
  function changeActiveItem() {
    if (displayConfig.useCase === "shoppingList") {
      if (displayConfig.index >= 0) {
        const item = activeItems(displayConfig)[displayConfig.index];
        if (item) {
          setChangeSupplierModalShow(true);
          setChangeSupplierModalData(item.alternatives);
          setChangeSupplierModalSuggested(item);
        }
      }
    }
  }

  useEffect(() => {
    if (window.location.href.includes("p4")) {
      window.postMessage({
        channel: "daylightrxenhanced",
        msgType: "navBarDisplayConfig_shopping",
        payload: testPayload,
      });
    } else if (window.location.href.includes("login")) {
      window.postMessage({
        channel: "daylightrxenhanced",
        msgType: "navBarDisplayConfig_login",
        payload: {
          supplier: "KINRAY",
          supplierDisplay: "Kinray",
        },
      });
    } else if (window.location.href.includes("ndc")) {
      window.postMessage({
        channel: "daylightrxenhanced",
        msgType: "navBarDisplayConfig_ndcSearch",
        payload: {
          supplier: "KINRAY",
          supplierDisplay: "Kinray",
          search: {
            supplier: "KINRAY",
            deeplink: "https://www.example.com",
            origlink: "https://www.example.com",
            drug: {
              descriptiveName: "Test Drug",
              manufacturerName: "Test Manufacturer",
            },
            ndc: "00000-0000-00",
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(0,0,0,0)";
    const root = document.getElementById("root");
    if (root) {
      root.style.backgroundColor = "rgba(255,255,255,255)";
      root.style.height = "auto";
    }
    return () => {
      document.body.style.backgroundColor = "rgba(255,255,255)";
      const root = document.getElementById("root");
      if (root) {
        root.style.backgroundColor = "inherit";
        root.style.height = "100%";
      }
    };
  }, []);

  useEffect(() => {
    if (dataUpdate) {
      if (dataUpdate.supplierName === displayConfig.supplier) {
        const order = dataUpdate.currState?.data?.selections.supplierOrders.find((x) => {
          return getSupplierName(x.supplierId) === displayConfig.supplier;
        });
        if (order) {
          updateDisplayConfig({
            type: "updateOrder",
            order: order,
          });
          if (dataUpdate.currState) {
            addOptimizeCartResponse(dataUpdate.currState);
          }
        }
        if (hasSummarizableOperations(dataUpdate.operations)) {
          setWaitingForOperations(false);
          setUpdateModalOperations(dataUpdate.operations);
          setNavBarHeight(displayConfig.supplier, -1);
        }
      }
      setDataUpdate(null);
    }
  }, [dataUpdate, displayConfig]);

  useEffect(() => {
    let handle: NodeJS.Timeout | null = null;
    if (waitingForOperations) {
      handle = setTimeout(() => {
        setWaitingForOperations(false);
      }, 30000);
    }
    return () => {
      if (handle) {
        clearTimeout(handle);
      }
    };
  }, [waitingForOperations]);

  useEffect(() => {
    const messageListenerCallback = async (event: MessageEvent) => {
      if (event.source !== window) {
        return;
      }
      if (event.data.channel === "daylightrxenhanced") {
        if (event.data.msgType === "navBarDisplayConfig_default") {
          const payload = event.data.payload as {
            supplier: string;
            supplierDisplay: string;
          };
          updateDisplayConfig({
            type: "setConfig",
            config: {
              supplier: payload.supplier,
              supplierDisplay: payload.supplierDisplay,
              useCase: "default",
            },
          });
        } else if (event.data.msgType === "navBarDisplayConfig_ndcSearch") {
          const payload = event.data.payload as {
            supplier: string;
            supplierDisplay: string;
            search: NDCSearchParam;
          };
          updateDisplayConfig({
            type: "setConfig",
            config: {
              supplier: payload.supplier,
              supplierDisplay: payload.supplierDisplay,
              useCase: "ndcSearch",
              search: payload.search,
            },
          });
        } else if (event.data.msgType === "navBarDisplayConfig_alert") {
          updateDisplayConfig({
            type: "alert",
            alert: event.data.payload as EnhancedSupplierFrameDisplayConfigAlert
          })
        } else if (event.data.msgType === "navBarDisplayConfig_clearAlert") {
          updateDisplayConfig({
            type: "clearAlert",
            alertId: event.data.payload as string,
          })
        } else if (event.data.msgType === "navBarDisplayConfig_login") {
          const payload = event.data.payload as {
            supplier: string;
            supplierDisplay: string;
          };
          updateDisplayConfig({
            type: "setConfig",
            config: {
              supplier: payload.supplier,
              supplierDisplay: payload.supplierDisplay,
              useCase: "login",
            },
          });
        } else if (event.data.msgType === "navBarDisplayConfig_dynamic") {
          const payload = event.data.payload as {
            supplier: string;
            supplierDisplay: string;
            caseName: "problematic-supplier";
          };
          updateDisplayConfig({
            type: "setConfig",
            config: {
              supplier: payload.supplier,
              supplierDisplay: payload.supplierDisplay,
              useCase: "dynamic",
              caseName: payload.caseName,
            },
          });
        } else if (event.data.msgType === "navBarDisplayConfig_ecommerceHome") {
          const payload = event.data.payload as {
            supplier: string;
            supplierDisplay: string;
          };
          updateDisplayConfig({
            type: "setConfig",
            config: {
              supplier: payload.supplier,
              supplierDisplay: payload.supplierDisplay,
              useCase: "ecommerceHome",
            },
          });
        } else if (event.data.msgType === "navBarDisplayConfig_shopping") {
          const payload = event.data.payload as {
            supplierName: string;
            supplierDisplay: string;
            supplier: Supplier;
            supplierOrder: SupplierOrder_All;
            optimizeCartResponse: OptimizedCartResponse_All;
            visited: string[];
            isEcommerce?: boolean;
          };
          console.log("navBarDisplayConfig_shopping payload", payload);
          addOptimizeCartResponse(payload.optimizeCartResponse);
          updateDisplayConfig({
            type: "setConfig",
            config: {
              supplier: payload.supplierName,
              supplierDisplay: payload.supplierDisplay,
              supplierObj: payload.supplier,
              useCase: "shoppingList",
              supplierOrder: payload.supplierOrder,
              index: -1,
              visited: payload.visited,
              optimizeCartResponse: payload.optimizeCartResponse,
              isEcommerce: payload.isEcommerce,
            },
          });
        } else if (event.data.msgType === "dataUpdate") {
          console.log("dataUpdate", event.data.payload);
          setDataUpdate(event.data.payload);
        }
      }
    };
    window.addEventListener("message", messageListenerCallback);

    return () => {
      window.removeEventListener("message", messageListenerCallback);
    };
  }, []);

  useEffect(() => {
    if (displayConfig.useCase !== "login") {
      setDotCount(0);
      return;
    }
    const dotInterval = setInterval(() => {
      setDotCount((prev) => (prev + 1) % 3);
    }, 1500);
    return () => {
      clearInterval(dotInterval);
    };
  }, [displayConfig]);

  if (displayConfig.useCase === "default") {
    return (
      <div
        className="enhancedSupplierNavigation"
        style={{
          height: SupplierFrameMasterConfig.heightPx + "px",
        }}
      >
        <NavBarHeader displayConfig={displayConfig} />
      </div>
    );
  } else if (displayConfig.useCase === "ndcSearch") {
    return (
      <div
        className="enhancedSupplierNavigation"
        style={{
          height: SupplierFrameMasterConfig.heightPx + "px",
        }}
      >
        <NavBarHeader displayConfig={displayConfig} />
        {displayConfig.alert &&
        displayConfig.alert.useCase === "loginRequired" ? (
          <div className="row">
            <h5 className="alert">
              Please log in to {displayConfig.supplierDisplay} to continue.
            </h5>
          </div>
        ) : (
          displayConfig.search &&
          displayConfig.search.drug && (
            <div className="row">
              <div className="ndcSearch">
                <div
                  tw="align-middle"
                  className="el drugDesc"
                  onClick={() => {
                    ndcSearch(displayConfig.search);
                  }}
                >
                  {displayConfig.search.drug.descriptiveName}{" "}
                </div>
                <div className="el manufacturer">
                  Manufacturer: {displayConfig.search.drug.manufacturerName}
                </div>
                <div className="el">
                  NDC: {formatNdc(displayConfig.search.ndc)}{" "}
                  <Typography.Text
                    copyable={{
                      text: displayConfig.search.ndc,
                      icon: [
                        <Text
                          key="1"
                          weight="bold"
                          color="black"
                          tw="underline"
                        >
                          Copy
                        </Text>,
                        <Text
                          key="2"
                          weight="bold"
                          color="black"
                          tw="underline"
                        >
                          Copied!
                        </Text>,
                      ],
                      tooltips: [null, null],
                    }}
                  />
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  } else if (displayConfig.useCase === "login") {
    return (
      <div
        className="enhancedSupplierNavigation"
        style={{
          height: SupplierFrameMasterConfig.heightPx + "px",
        }}
      >
        <NavBarHeader displayConfig={displayConfig}/>
        <div className="row justify-center">
          <h5 className="login-to">
            Preparing search. Please log in to {displayConfig.supplierDisplay}.
            {".".repeat(dotCount)}
            <span className="color-white">
              {[...Array(2 - dotCount)].map((_v, i) => (
                <Fragment key={i}>.</Fragment>
              ))}
            </span>
          </h5>
        </div>
      </div>
    );
  } else if (displayConfig.useCase === "dynamic") {
    return (
      <div
        className="enhancedSupplierNavigation"
        style={{
          height: SupplierFrameMasterConfig.heightPx + "px",
        }}
      >
        <NavBarHeader displayConfig={displayConfig} />
        <div className="row justify-center pt-8">
          <h3 className="text-xl">
            Please run a manual search to verify the site and{" "}
            <a className="color-primeblue" onClick={showMain}>
              click here to return to DaylightRx auto-search
            </a>
          </h3>
        </div>
      </div>
    );
  } else if (displayConfig.useCase === "ecommerceHome") {
    return (
      <div
        className="enhancedSupplierNavigation"
        style={{
          height: SupplierFrameMasterConfig.ecommerceHeightPx + "px",
        }}
      >
        <NavBarHeader displayConfig={displayConfig} hideHome={true} />
      </div>
    );
  } else if (
    displayConfig.useCase === "shoppingList" &&
    displayConfig.isEcommerce
  ) {
    return (
      <div
        className="enhancedSupplierNavigation"
        style={{
          height: SupplierFrameMasterConfig.ecommerceHeightPx + "px",
        }}
      >
        <NavBarHeader displayConfig={displayConfig} hideHome={true} />
      </div>
    );
  } else {
    const currentItems = activeItems(displayConfig);
    const hasItems = currentItems.length > 0;
    const currentItemHasAlternatives =
      hasItems && currentItems[displayConfig.index].alternatives.length > 0;
    const dropdownOptions: NavBarDropdownItem[] = currentItems.map((item) => {
      return {
        item: item,
        value: uniqueCatalogDrugID(item),
        visited: uniqueIDIsInList(item, displayConfig.visited),
      };
    });

    return (
      <>
        <div
          className="enhancedSupplierNavigation"
          style={{ height: SupplierFrameMasterConfig.heightPx + "px" }}
        >
          <NavBarHeader displayConfig={displayConfig} />
          <MinShipMessage
            hasItems={currentItems.length > 0}
            displayConfig={displayConfig}
          />
          <div className="row">
            {hasItems && (
              <div className="shoppingList">
                <NavBarDropdown
                  tw="flex-1"
                  className="shoppingListDropdown"
                  dropDownItems={dropdownOptions}
                  openTimeMs={1000}
                  callBack={(_value, idx) => {
                    updateDisplayConfig({
                      type: "setIndex",
                      index: idx,
                    });
                  }}
                  onOpen={() => {
                    setNavBarHeight(
                      displayConfig.supplier,
                      SupplierFrameMasterConfig.expandedHeightPx
                    );
                  }}
                  onClose={() => {
                    setNavBarHeight(
                      displayConfig.supplier,
                      SupplierFrameMasterConfig.heightPx
                    );
                  }}
                  defaultValue={
                    displayConfig.index >= 0
                      ? uniqueCatalogDrugID(currentItems[displayConfig.index])
                      : ""
                  }
                />
                <button
                  id="refresh-button"
                  className="btn-white-shade btn-sm"
                  onClick={reloadCurrent}
                >
                  <img
                    src={refreshIcon}
                    className="hover-pointer refreshIcon"
                    alt="refresh"
                  />
                </button>
                <button
                  id="options-button"
                  className="btn-white-shade btn-sm"
                  onClick={(e) => {
                    setNavBarHeight(
                      displayConfig.supplier,
                      SupplierFrameMasterConfig.expandedHeightPx
                    );
                    handleOptionsClick(e);
                  }}
                  aria-controls={optionsOpen ? "options-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={optionsOpen ? "true" : undefined}
                >
                  Options
                </button>
                <Menu
                  id="options-menu"
                  anchorEl={optionsAnchorEl}
                  open={optionsOpen}
                  onClose={() => {
                    setNavBarHeight(
                      displayConfig.supplier,
                      SupplierFrameMasterConfig.heightPx
                    );
                    handleOptionsClose();
                  }}
                  MenuListProps={{
                    "aria-labelledby": "options-button",
                  }}
                  transitionDuration={1000}
                >
                  {currentItemHasAlternatives ? (
                    <MenuItem
                      onClick={() => {
                        setNavBarHeight(displayConfig.supplier, -1);
                        changeActiveItem();
                        handleOptionsClose();
                      }}
                    >
                      Change item
                    </MenuItem>
                  ) : (
                    <MenuItem disabled>No other supplier available</MenuItem>
                  )}
                </Menu>
                <button
                  className={cn(
                    "btn-white-shade btn-sm",
                    prevButtonDisabled && "btn-disabled"
                  )}
                  onClick={() => {
                    if (!prevButtonDisabled) {
                      goPrev();
                    }
                  }}
                >
                  &lt; Prev
                </button>
                <div>
                  {displayConfig.index + 1} of {dropdownOptions.length}{" "}
                  {displayIfWide("Items", 1440, size.width)}
                </div>
                <button
                  className={cn(
                    "btn-white-shade btn-sm",
                    nextButtonDisabled && "btn-disabled"
                  )}
                  onClick={() => {
                    if (!nextButtonDisabled) {
                      goNext();
                    }
                  }}
                >
                  Next &gt;
                </button>
              </div>
            )}
            {activeItems(displayConfig).length === 0 && (
              <div className="shoppingList">
                <div tw="font-bold">
                  No more items in your {displayConfig.supplierDisplay} list.
                  Return to all shopping lists.
                </div>
              </div>
            )}
          </div>
        </div>
        <SuppliersModal
          isOpen={changeSupplierModalShow}
          data={changeSupplierModalData}
          suggested={changeSupplierModalSuggested}
          supplierName={
            changeSupplierModalSuggested
              ? getSupplierDisplayName(changeSupplierModalSuggested.supplierId)
              : ""
          }
          callBack={(operations) => {
            setChangeSupplierModalShow(false);
            setNavBarHeight(
              displayConfig.supplier,
              SupplierFrameMasterConfig.heightPx
            );
            if (operations && operations.length > 0) {
              if (hasSummarizableOperations(operations)) {
                setWaitingForOperations(true);
                setNavBarHeight(
                  displayConfig.supplier,
                  SupplierFrameMasterConfig.expandedHeightPx
                );
              }
              requestCartChange(displayConfig.supplier, operations);
            }
          }}
        />
        <EnhancedCartWaitingForOperationsModal
          activateModal={waitingForOperations}
          onClose={() => {}}
        />
        <EnhancedCartOperationsSummaryModal
          activateModal={hasSummarizableOperations(updateModalOperations)}
          operations={updateModalOperations || []}
          onClose={() => {
            setNavBarHeight(
              displayConfig.supplier,
              SupplierFrameMasterConfig.heightPx
            );
            setUpdateModalOperations(null);
          }}
          onChangeList={(supplierName) => {
            setNavBarHeight(
              displayConfig.supplier,
              SupplierFrameMasterConfig.heightPx
            );
            setUpdateModalOperations(null);
            showMain();
            requestShoppingListSwitch(supplierName);
          }}
        />
      </>
    );
  }
}

function MinShipMessage({
  hasItems,
  displayConfig,
}: {
  hasItems: boolean;
  displayConfig: EnhancedSupplierNavBarDisplayConfigShoppingList;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { supplierObj, supplierDisplay, supplierOrder } = displayConfig;
  const { shippingInfo, increasedBasketWarnings } = supplierOrder;
  const { shippingConfig } = supplierObj;
  const drugs = increasedBasketWarnings?.drugsWithAvailabilityLimitations;
  const shouldDisplaySeeWhy = !!drugs?.length;

  const minShipOrderAmount = useMemo(() => {
    if (hasItems && !shippingInfo?.shippable) return getMinShipOrderAmount(shippingConfig);
    return undefined;
  }, [hasItems, shippingInfo, shippingConfig]);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  if (!minShipOrderAmount) return <div tw="h-[21px]" />;
  return (
    <div>
      <span className="attention">
        NOTE: {minShipOrderAmount} min ship for overnight delivery not met within
        DaylightRx list. Adjust in {supplierDisplay} site
      </span>

      {shouldDisplaySeeWhy && (
        <button onClick={openModal} tw="text-blue-600 underline font-bold ml-1">
          See why
        </button>
      )}

      {isModalOpen && (
        <div tw="fixed top-0 left-0 w-full h-full p-4 z-50 bg-white flex flex-col items-center justify-center">
          <p>
            {drugs
              ?.map((drug) => {
                return `${drug.referenceData.drugInfo.name} ${drug.referenceData.drugInfo.strength}${drug.referenceData.drugInfo.strengthUnit}`;
              })
              .join(" & ")}{" "}
            only available at suppliers with shipping minimums.
          </p>
          <button className="btn" tw="underline font-bold" onClick={closeModal}>
            Close
          </button>
        </div>
      )}
    </div>
  );
}
