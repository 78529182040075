import tw, { css, styled } from "twin.macro";
import { BLACK, BLUE_2, RxColor, getColorCss } from "../colors";

type TitleVariants = "h1" | "h2";

export const Title = styled.span(
  ({
    color,
    variant,
    block = false,
    center = false,
  }: {
    color?: RxColor;
    block?: boolean;
    center?: boolean;
    variant?: TitleVariants;
  }) => [
    getCss(variant),
    !!block && tw`block`,
    !!center && tw`text-center`,
    !!color && getColorCss(color),
  ]
);

function getCss(variant?: TitleVariants) {
  switch (variant) {
    case "h1":
      return H1_CSS;
    case "h2":
      return H2_CSS;
    default:
      return H1_CSS;
  }
}

const H1_CSS = css`
  font-family: "Archivo Narrow Variable", sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${BLACK};
`;

const H2_CSS = css`
  font-family: "Archivo Narrow Variable", sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${BLUE_2};
`;
