import React, { ComponentProps } from "react";
import { styled } from "twin.macro";
import { Select as BaseSelect } from "antd";
import { Global, css } from "@emotion/react";
import { DEFAULT_TEXT_CSS, getTextCss } from "../typography";
import { BLACK } from "../colors";

const RX_SELECT_POPOVER_CLASS_NAME = "rx-select-popover";

export type SelectProps = Omit<
  ComponentProps<typeof BaseSelect>,
  "popupClassName" | "popupMatchSelectWidth"
>;

export type SelectOption = NonNullable<SelectProps["options"]>[number]

export function Select(props: SelectProps) {
  return (
    <>
      <StyledSelect
        optionFilterProp="label"
        showSearch
        {...props}
        popupClassName={RX_SELECT_POPOVER_CLASS_NAME}
        popupMatchSelectWidth={false}
      />
      <Global styles={GLOBAL_CSS} />
    </>
  );
}

const GLOBAL_CSS = css`
  .${RX_SELECT_POPOVER_CLASS_NAME} {
    border: 1px solid ${BLACK};

    .ant-select-item-option-content {
      ${DEFAULT_TEXT_CSS}
    }
  }
`;

const StyledSelect = styled(BaseSelect)`
  .ant-select-selection-item {
    ${getTextCss({ size: "medium" })}
  }
`;
