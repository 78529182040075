import React, {useEffect, useState, useContext, useMemo, useCallback} from "react";
import tw from "twin.macro";
import dayjs from "dayjs";
import Typography from "antd/es/typography";
const NDCImg = new URL('svgurl:../../../../assets/ndc.svg', import.meta.url).toString();
const LowerImg = new URL(
  "svgurl:../../../../assets/lower.svg",
  import.meta.url
).toString();
import {
  Supplier,
  TaggedItem,
  ListCategoryEnum,
  ShippingConfig,
  HistoricalOrderingInfo,
} from "../../../../utilities/types";
import { EnhancedStockValidationAvailability } from "../../../../enhanced/EnhancedClientContext";
import { Container } from "../../../../components/containers/Container";
import HorizontalCarousel from "../../../../components/HorizontalCarousel/HorizontalCarousel";
import { NdcOffering, NdcOfferingSet } from "../../../../enhanced/result_types";
import AccordionPanel from "../../../../components/AccordionPanel";
import BuyingPharmacyContext from "../../../../contexts/BuyingPharmacyContext";
import DispensedItem from "../../../../components/DispensedItem";
import { ExpirationDate } from "../../../../components/ExpirationDate";
import { formatNdc } from "../../../../utilities/ndc/formatNdc";
import { getDrugMeasureDisplay } from "../../../../utilities/drugInfo/getDrugMeasureDisplay";
import { dayjsWithDuration } from "../../../../utilities/dates/dayjsWithDuration";
import {
  currencyWithCentsAtAllTimes,
  currencyWithFractionOfPenny,
  currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits,
  currencyWithCentsUnlessGreaterThanOrEqualToOneThousand
} from "../../../../utilities/numbers/currency";
import { formatDrugDispenseQuantity } from "../../../../utilities/drugInfo/formatDrugDispenseQuantity";
import {
  formatDrugNameWithDetails
} from "../../../../utilities/drugInfo/formatDrugNameWithDetails";
import { Text } from "../../../../components/rxLibrary/typography";
import { IconDown } from "../../../../components/rxLibrary/icons";
import { Image } from "../../../../components/Image";
import EnhancedClientConfigContext from "../../../../enhanced/EnhancedClientConfigContext";
import EnhancedClientCommandContext from "../../../../enhanced/EnhancedClientCommandContext";
import { tryWriteClipboard } from "../../../../utilities/clipboard";
import { shallowLinksOnlyForSupplier } from "../../../../utilities/search";
import { ndcOfferingCostPerDose } from "../NDCResults.utils";
import { useHandleNdcLinkClick } from "./useHandleNdcLinkClick";
import { useObtainEnhancedClientDeepLink } from "./useObtainEnhancedClientDeepLink";
import { useDetermineEcommerceByOffering } from "./useDetermineEcommerce";
import { ItemDispensation } from "./ItemDispensation";
import { ItemProjection } from "./ItemProjection";

const LowerLabel = tw.div`text-sm font-light whitespace-pre-line text-left`;

const FeatureStyles = {
  alt: tw`bg-[#7e7e7e]`,
  primeblue: tw`bg-primeblue`,
};

const ButtonStyles = {
  alt: tw`hover:bg-gradient-to-t from-[#6f7073] to-[#95979c] hover:text-white`,
  primeblue: tw`hover:bg-gradient-to-t from-[#4670e3] to-[#3d57cf] hover:text-white`,
};

function PriceOrAvailabilityText({
  result,
}: {
  result: NdcOffering;
}) {
  const priceDisplay =
    currencyWithCentsAtAllTimes(result.price) +
    `${result.priceNote === "(R)" ? "w/ Rebate" : ""}`;

  if (
    result.supplierData &&
    result.supplierData.availability !==
      EnhancedStockValidationAvailability.AVAILABLE
  ) {
    switch (result.supplierData.availability) {
      case EnhancedStockValidationAvailability.UNAVAILABLE:
        return <>Not in Stock</>;
      case EnhancedStockValidationAvailability.BACKORDERED:
        return (
          <>
            <span>Back Order</span>
          </>
        );
      case EnhancedStockValidationAvailability.PENDING:
        return (
          <>
            <span>{priceDisplay} Unverified</span>
          </>
        );
    }
  }
  if (result.price === 0) {
    return <span>See Website</span>;
  }
  return (
    <span>{priceDisplay}</span>
  );
}

const convertFrom24To12Format = (time24: string) => {
  const t = time24.match(/([0-9]{1,2}):([0-9]{2})/);
  const [sHours, minutes] = t && t.slice(1) || [];
  const period = +sHours < 12 ? 'AM' : 'PM';
  const hours = +sHours % 12 || 12;

  if (minutes === "00") {
    return `${hours}${period}`;
  } else {
    return `${hours}:${minutes}${period}`;
  }
}

function MinOrder(item: string | ShippingConfig): JSX.Element {
  let arr = [];
  let fee = 0;
  let minOrder = 0;
  let minGenericOrder = 0 ;
  let minGenericOrderText = '';
  let long = false;

  if (typeof item === 'string'){
    arr = item.split("|");
    fee = arr[0] && Number(arr[0]) || 0;
    minOrder = arr[1] && Number(arr[1]) || 0;

    // If it's the IPC supplier, we would show not only the amount but also some texts for the minship
    if (arr[2]?.length > 15) minGenericOrderText = arr[2];

    minGenericOrder = arr[2] && Number(arr[2]) || 0;
  } else {
    fee = item.fee || 0;
    minOrder = item.minOrder || 0;
    minGenericOrder = item.minGenericOrder || 0;
    long = true;
  }

  return (
    <>
      {minGenericOrder === 0  && fee === 0  && minOrder === 0  ? (long ? <p>No min. Order</p> : <p>{minGenericOrderText || 'None'}</p>) : (long && <p>Min. Order:</p>)}
      {minGenericOrder > 0 && <p>{currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(minGenericOrder)} generics</p>}
      {minOrder > 0 && <p>{currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(minOrder)}</p>}
      {(minGenericOrder > 0 || minOrder > 0) && fee > 0  && <p> or </p>}
      {fee > 0 && <p>{currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(fee)} fee</p>}
    </>
   )
}

function CutOffTime(item: string): JSX.Element {
  const items = item.split("|");

  return (
    <>
    {items.map((item, index) => {
          return <p key={index}>{item}</p>
        })}
    </>
   )
}

function FeaturedShipping({
  featured
}: {
  featured: NdcOffering;
}): JSX.Element {
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const [shippingData, setShippingData] = useState<ShippingConfig | null>(null);

  let supplierName;
  useEffect(() => {
    if (currentBuyingPharmacy) {
      supplierName = featured.item.catalogInfo.supplierDisplayName;
      const d = getShippingConfig(supplierName, currentBuyingPharmacy?.suppliers);

      setShippingData(d)
    }
  }, [currentBuyingPharmacy]);

  return (
    <>
      {shippingData  && <div tw="border-t border-white p-[10px] text-white w-[100%]">
        <p tw="font-bold">{featured.item.catalogInfo.supplierDisplayName} Info</p>
        <div tw="mt-[8px]">
          {MinOrder(shippingData)}
        </div>
        <>{!!shippingData?.cutoffTimes && shippingData.cutoffTimes !== "" && <>
          <p tw="mt-[16px]">Cutoff time:</p>
          {CutOffTime(shippingData.cutoffTimes.toString())}
        </>
          }
        </>
      </div>}
     </>
  )
}

function SupplierShippingTbl({
  featured,
  alternatives
}: {
  featured: NdcOffering;
  alternatives: NdcOffering[];
}): JSX.Element {
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const [shippingData, setShippingData] = useState<string[][] | null>(null);
  const [cellWidth, setCellWidth] = useState<number[] | null>(null);

  const calculateWidth = (featured: NdcOffering ) => {
    const width: number[] = []
    const carousel = document.querySelector(`#carousel${featured.item.drugInfo.ndc}`);
    if(carousel) {
      carousel.querySelectorAll('.catalog-link, .catalog-link-shallow').forEach((s) => {
        const elem = s as HTMLElement;
        // 16 is the padding
        width.push(elem?.offsetWidth + 16);
      })
    }
    return width;
  }

  const adjustTblInfo = () => {
    const height: number[] = [];
    const carousel = document.querySelector(`#carousel${featured.item.drugInfo.ndc}`);
    const info = document.querySelector(`#shippingTbl${featured.item.drugInfo.ndc}`);
    if(carousel) {
      carousel.querySelectorAll('.tbl-row').forEach((s) => {
        const elem = s as HTMLElement;
        // 18 is the padding
        height.push(elem?.offsetHeight);
      })
    }
    if(info) {
      info.querySelectorAll('.tbl-info').forEach((s, index) => {
        const elem = s as HTMLElement;
        // 18 is the padding
        elem.style.height = `${height[index]}px`;
        elem.style.minHeight = `${height[index]}px`;
      })
    }
  }

  type CustomShippingConfig = ShippingConfig & Record<'supplierName', string | undefined>;
  const data: CustomShippingConfig[]  = []
  let supplierName;
  useEffect(() => {
    if (currentBuyingPharmacy) {
      supplierName = featured.item.catalogInfo.supplierDisplayName;
      data.push({...(getShippingConfig(supplierName, currentBuyingPharmacy?.suppliers)), supplierName });

      alternatives.forEach(item => {
        supplierName = item.item.catalogInfo.supplierDisplayName;
        data.push({...(getShippingConfig(supplierName, currentBuyingPharmacy?.suppliers)), supplierName });
      })

      const fee: string[] =[];
      const cutoffTimes: string[] =[];
      const notes: string[] =[];

      data.forEach((obj) => {
        let feeTxt = "";
        for (const [key, value] of Object.entries(obj)) {
          switch(key) {
            case "fee" :
            case "minOrder":
            case "minGenericOrder":
              feeTxt += obj.supplierName === 'IPC' ? '$200 Generics or $5000 Brand|' : `${value}|`
              break;
            case "cutoffTimes":
              cutoffTimes.push((value || '').toString());
              break;
            case "notes":
              notes.push((value || '').toString());
              break;
          }
        }
        fee.push(feeTxt);
      });

      setCellWidth(calculateWidth(featured));

      const arr: string [][] = [];
      arr.push(fee);
      arr.push(cutoffTimes);
      arr.push(notes);

      setShippingData(arr);
      setTimeout(adjustTblInfo, 50);
    }
  }, []);


  return (
    <div tw=" mt-[8px]">
    {shippingData && cellWidth && shippingData.map((items: string[], index) => {
        return (
          <div key={index} tw="flex flex-col" className="tbl-row">
            <div
              tw="flex"
              className="items"
              css={[
                index === 0 && tw`min-h-[81px]`,
                index === 1 && tw`min-h-[42px]`,
                index === 2 && tw`min-h-[62px]`,
              ]}
            >
              {items.map((item: string, keyIndex) => {
                return (
                  <div
                    key={keyIndex}
                    tw="min-w-[96px] bg-[#E6EDF8] p-[2px 0] border-b border-white"
                    style={{
                      width: `${cellWidth[keyIndex]}px`,
                      maxWidth: `${cellWidth[keyIndex]}px`,
                    }}
                  >
                    <div
                      css={[
                        keyIndex === items.length - 1
                          ? tw`border-[#E6EDF8]`
                          : tw`border-white`,
                        tw`border-r-2 py-[9px] px-[8px] min-h-full text-[13px] `,
                      ]}
                    >
                      {index === 0 && MinOrder(item)}
                      {index === 1 && CutOffTime(item)}
                      {index === 2 && <p>{item}</p>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  )
}

function SupplierElement({
  result,
  bold,
}: {
  result: NdcOffering;
  bold?: boolean;
}): JSX.Element {
  const { enhancedClientActive, enhancedClientVersion } = useContext(EnhancedClientConfigContext);
  const { showSupplier } = useContext(EnhancedClientCommandContext);
  const handleLinkClick = useHandleNdcLinkClick(result);
  const isEcommerce = useDetermineEcommerceByOffering(result);
  const deepLink = useObtainEnhancedClientDeepLink(result);
  const isShallow = shallowLinksOnlyForSupplier(result.item.catalogInfo.supplier, enhancedClientVersion);

  const onClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    tryWriteClipboard(result.item.drugInfo.ndc)
    if (isShallow && enhancedClientActive) {
      e.preventDefault();
      showSupplier(result.item.catalogInfo.supplier);
    } else {
      handleLinkClick(e);
    }
  }, [enhancedClientActive, showSupplier, isShallow, handleLinkClick]);
  return (
    <div
      key={`${result.item.catalogInfo.supplier}:${result.item.drugInfo.ndc}`}
      tw="flex items-start flex-col pr-[8px] pl-[8px] min-w-[96px]"
    >
      <a
        href={isEcommerce ? deepLink : result.item.catalogInfo.link}
        css={[tw`text-[16px]  hover:underline`, bold && tw`font-bold`]}
        target={`tab_${result.item.catalogInfo.supplier}`}
        className={isShallow ? `catalog-link-shallow group` : `catalog-link group`}
        data-supplier={result.item.catalogInfo.supplier}
        data-supplier-id={result.item.catalogInfo.supplierId}
        data-drug-descriptive-name={formatDrugNameWithDetails(result.item.drugInfo)}
        data-drug-manufacturer-name={result.item.drugInfo.manufacturer}
        data-is-ecommerce={isEcommerce}
        data-ndc={result.item.drugInfo.ndc}
        data-deeplink={deepLink}
        onClick={onClick}
      >
        <span tw="whitespace-nowrap">
          {result.item.catalogInfo.supplierDisplayName}
        </span>

        <LowerLabel css={[bold && tw`font-bold`]}>
          <PriceOrAvailabilityText result={result} />
        </LowerLabel>

        <div className={`text-sm ${bold ? "font-500" : "font-300"}`}>
          <ExpirationDate textOnly catalogInfo={result.item.catalogInfo} />
        </div>
      </a>
    </div>
  );
}

function AllSuppliers({
  featured,
  alternatives,
  shippingPanel,
}: {
  featured: NdcOffering;
  alternatives: NdcOffering[];
  shippingPanel: boolean;
}): JSX.Element | null {

  useEffect(() => {
    const s = document.querySelector(`#suppliersPanel${featured.item.drugInfo.ndc}`) as HTMLElement;
    const sp = document.querySelector(`#tag${featured.item.drugInfo.ndc}`) as HTMLElement;
    const si = document.querySelector(`#tagInfo${featured.item.drugInfo.ndc}`) as HTMLElement;
    if (s && sp && si) {
      if (shippingPanel) {
        const h = si?.offsetHeight;
        sp.style.paddingBottom =  `${s?.offsetHeight - (h - 11)}px`;
      } else {
        sp.style.paddingBottom = "0";
      }
    }
  }, [shippingPanel]);


  return (
    <div tw="w-[100%] max-w-[445px] border-t border-[#cfcfcf] pt-[8px] min-h-[53px]">
      <HorizontalCarousel id={`carousel${featured.item.drugInfo.ndc}`}>
        <div tw="flex">
          <SupplierElement result={featured} bold />
          {alternatives.map((item) => {
            return (
              <SupplierElement
                key={`${item.item.catalogInfo.supplier}:${item.item.drugInfo.ndc}`}
                result={item}
              />
            );
          })}

        </div>
          <AccordionPanel open={shippingPanel}>
            {(shippingPanel ? (
                <SupplierShippingTbl featured={featured} alternatives ={alternatives} />
            ) : null)}
          </AccordionPanel>
      </HorizontalCarousel>
    </div>
  );
}

function DrugDescription({
  item,
  children,
  shippingPanel,
}: {
  item: TaggedItem;
  children: React.ReactNode;
  shippingPanel: boolean;
}) {
  const { drugInfo, historicalOrderingInfo } = item;

  return (
    <div tw="flex flex-row h-[100%]">
      <div tw="flex flex-col justify-between h-[100%]">
        <Image
          src={drugInfo.imageURI}
          tw="h-[52px] w-[70px] object-contain"
        />

        <AccordionPanel open={shippingPanel}>
          <div tw="flex flex-col" id={`shippingTbl${drugInfo.ndc}`}>
            <div
              tw="text-[13px] text-right max-w-[56px] min-w-[56px] pt-[10px]"
              className="tbl-info"
            >
              <p>Overnight</p>
              <p>Min.</p>
              <p>Order</p>
            </div>
            <div
              tw="text-[13px] text-right max-w-[56px] min-w-[56px] pt-[10px]"
              className="tbl-info"
            >
              <p>Cutoff</p>
            </div>
            <div
              tw="text-[13px] text-right max-w-[56px] min-w-[56px] pt-[10px]"
              className="tbl-info"
            >
              <p>Notes</p>
            </div>
          </div>
        </AccordionPanel>
      </div>
      <div tw="ml-4 flex flex-col justify-between w-[100%]">
        <div>
          <DispensedItem
            tw="font-medium text-lg"
            ndc={false}
            drug={drugInfo}
            showQuantityDescription
          />

          <div tw="font-medium text-lg" title={drugInfo.manufacturer}>
            Manufacturer: {drugInfo.manufacturer}
          </div>
          <p tw="text-lg font-light">
            NDC: {formatNdc(drugInfo.ndc)}{" "}
            <Typography.Text
              copyable={{
                text: drugInfo.ndc,
                icon: [
                  <Text key="1" weight="bold" color="black" tw="underline">
                    Copy
                  </Text>,
                  <Text key="2" weight="bold" color="black" tw="underline">
                    Copied!
                  </Text>,
                ],
                tooltips: [null, null],
              }}
            />
          </p>
        </div>

        <HistoricalOrdering order={historicalOrderingInfo} />
        {children}
      </div>
    </div>
  );
}

const FeaturedPanel = ({
  featuredResult,
  isBelowTheFold,
  shippingPanelCallback,
  shippingPanel
}: {
  featuredResult: NdcOffering;
  isBelowTheFold?: boolean;
  shippingPanelCallback: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  shippingPanel: boolean;
}): JSX.Element => {
  const { enhancedClientActive, enhancedClientVersion } = useContext(EnhancedClientConfigContext);
  const { showSupplier } = useContext(EnhancedClientCommandContext);
  const handleLinkClick = useHandleNdcLinkClick(featuredResult);

  const featuredItem = featuredResult.item;
  const featuredDrugInfo = featuredItem.drugInfo;
  const quantityDesc = formatDrugDispenseQuantity(featuredDrugInfo);
  const ndc = featuredDrugInfo.ndc;

  const bgStyle = isBelowTheFold ? FeatureStyles.alt : FeatureStyles.primeblue;
  const btnStyle = isBelowTheFold ? ButtonStyles.alt : ButtonStyles.primeblue;

  const cta =
    featuredResult.supplierData &&
    featuredResult.supplierData.availability ===
      EnhancedStockValidationAvailability.BACKORDERED
      ? "Back Order"
      : `Get at ${featuredItem.catalogInfo.supplierDisplayName}`;
  const isEcommerce = useDetermineEcommerceByOffering(featuredResult);
  const deepLink = useObtainEnhancedClientDeepLink(featuredResult);
  const isShallow = shallowLinksOnlyForSupplier(featuredItem.catalogInfo.supplier, enhancedClientVersion);

  const onClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    tryWriteClipboard(ndc);
    if (isShallow && enhancedClientActive) {
      e.preventDefault();
      showSupplier(featuredResult.item.catalogInfo.supplier);
    } else {
      handleLinkClick(e);
    }
  }, [enhancedClientActive, showSupplier, isShallow, handleLinkClick]);

  return (
    <div
      tw="p-[24px] flex text-white items-center rounded-tr-lg rounded-br-lg flex-col self-stretch w-[220px]"
      css={bgStyle}
    >
      <div tw="flex justify-between mb-[24px]" css={{ width: "100%" }}>
        <div tw="flex flex-col max-w-[78px]">
          <p tw="text-[22px] font-medium">
            <sup style={{ top: "-4px" }}>$</sup>
            {currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(
              featuredResult.price,
              true
            )}
          </p>
          {featuredResult.priceNote === "(R)" && (
            <p tw="text-[13px] font-medium">w/ Rebate</p>
          )}
          {featuredResult.supplierData &&
            featuredResult.supplierData.availability ===
              EnhancedStockValidationAvailability.UNAVAILABLE && (
              <p tw="text-[13px] font-medium">Not in Stock</p>
            )}
          {featuredResult.supplierData &&
            featuredResult.supplierData.availability ===
              EnhancedStockValidationAvailability.PENDING && (
              <p tw="text-[13px] font-medium">Unverified</p>
            )}
          <LowerLabel>{quantityDesc}</LowerLabel>
        </div>
        <div tw="flex flex-col items-center max-w-[78px]">
          <p tw="text-[22px] font-medium">
            <sup style={{ top: "-4px" }}>$</sup>
            {currencyWithFractionOfPenny(
              ndcOfferingCostPerDose(featuredResult),
              true
            )}
          </p>
          <LowerLabel>
            Per {getDrugMeasureDisplay(featuredResult.item.drugInfo)}
          </LowerLabel>
        </div>
      </div>

      {/* 7443 */}
      {/* {featuredItem.catalogInfo && <div className="flex justify-start strech mb-12"><ExpirationDate textOnly catalogInfo={featuredItem.catalogInfo} /></div>} */}

      <a
        href={isEcommerce ? deepLink : featuredItem.catalogInfo.link}
        tw="w-[100%] rounded border-white border text-white py-2 px-2 text-base font-bold text-center"
        css={btnStyle}
        className={isShallow ? `catalog-link-shallow` : `catalog-link`}
        data-supplier={featuredItem.catalogInfo.supplier}
        data-supplier-id={featuredItem.catalogInfo.supplierId}
        data-drug-descriptive-name={formatDrugNameWithDetails(
          featuredItem.drugInfo
        )}
        data-drug-manufacturer-name={featuredItem.drugInfo.manufacturer}
        data-is-ecommerce={isEcommerce}
        data-ndc={ndc}
        data-deeplink={deepLink}
        onClick={onClick}
      >
        {cta}
      </a>

      <button
        onClick={() => shippingPanelCallback(!shippingPanel)}
        tw="flex items-center mt-[12px] hover:text-white"
      >
        {!shippingPanel && (
          <>
            <span>Shipping Info</span>
            <IconDown tw="ml-2" />
          </>
        )}
        {shippingPanel && (
          <>
            <span>Collapse</span>
            <IconDown tw="ml-2 rotate-180" />
          </>
        )}
      </button>

      {/* Shipping Info */}
      <AccordionPanel open={shippingPanel}>
        <FeaturedShipping featured={featuredResult} />
      </AccordionPanel>
    </div>
  );
};

const quantityLabel = (item: TaggedItem, match: TaggedItem | undefined) => {
  if (!match) return "quantity";

  const itemCount = item.drugInfo.unitQuantity * item.drugInfo.unitSize;
  const matchCount = match.drugInfo.unitQuantity * match.drugInfo.unitSize;

  return `${itemCount > matchCount ? "larger" : "smaller"} quantity`;
};

function quantityManufacturerLabel(item: TaggedItem, match?: TaggedItem) {
  return `other manufacturer ${quantityLabel(item, match)}`;
}

function Savings({
  ndcOffering,
  mainNdcOffering,
}: {
  ndcOffering: NdcOffering;
  mainNdcOffering?: NdcOffering;
}) {
  const savings = useMemo(() => {
    if (!mainNdcOffering) return 0;

    const mainItemCost = ndcOfferingCostPerDose(mainNdcOffering);
    const featuredItemCost = ndcOfferingCostPerDose(ndcOffering);
    const percentage =
      ((mainItemCost - featuredItemCost) / mainItemCost) * 100;

    if (percentage < 0) return 0;
    if (percentage <= 0.5) return percentage;
    if (percentage < 1) return 1;

    const savingsPercentage = Math.round(percentage);
    return savingsPercentage;
  }, [ndcOffering, mainNdcOffering]);

  const displayLessThan = savings > 0 && savings <= 0.5;

  return (
    <div tw="flex flex-col items-center justify-center">
      {displayLessThan && <p tw="leading-none text-[9px]">Less than</p>}
      <p tw="leading-none">
        {displayLessThan ? 1 : savings}
        <sup tw="text-[13px] font-light" style={{ top: "-2px" }}>
          %
        </sup>
      </p>
    </div>
  );
}

function TagPanel({
  category,
  ndcOffering,
  isBelowTheFold,
  mainNdcOffering,
}: {
  ndcOffering: NdcOffering;
  category?: ListCategoryEnum;
  isBelowTheFold?: boolean;
  mainNdcOffering?: NdcOffering;
}) {
  const differentManufacturer =
    category === ListCategoryEnum.DIFFERENT_MANUFACTURERS;
  const differentQuantity = category === ListCategoryEnum.DIFFERENT_QUANTITIES;
  const differentManufacturerQuantity =
    category === ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY;
  const alternatePackaging = ndcOffering.item.alternatePackaging;

  return (
    <div tw="p-[24px] flex justify-between items-start flex-col self-stretch w-[220px] border-l border-dashed border-black">
      {!isBelowTheFold && (
        <div>
          {/* NDC Match */}
          {category === ListCategoryEnum.NDC_MATCH && (
            <div tw="flex flex-row items-center">
              <img src={NDCImg} alt="NDC Match" tw="w-[56px] h-[56px]" />
              <span
                tw="uppercase text-primeblue text-base font-bold ml-[8px] leading-5"
                style={{ wordSpacing: "100vw" }}
              >
                NDC Match
              </span>
            </div>
          )}

          {/* Suggested NDC */}
          {category === ListCategoryEnum.SUGGESTED_NDC && (
            <div tw="flex flex-row items-center">
              <img src={NDCImg} alt="NDC Match" tw="w-[56px] h-[56px]" />
              {ndcOffering.item.suggestedReason === "order_frequency" && (
                <span tw="uppercase text-primeblue text-base font-bold ml-[8px] leading-5">
                  {ListCategoryEnum.LAST_PURCHASED}
                </span>
              )}
              {ndcOffering.item.suggestedReason === "prescription_frequency" && (
                <span
                  tw="uppercase text-primeblue text-base font-bold ml-[8px] leading-5"
                  style={{ wordSpacing: "100vw" }}
                >
                  {ListCategoryEnum.LAST_DISPENSED}
                </span>
              )}
              {ndcOffering.item.suggestedReason !== "prescription_frequency" && ndcOffering.item.suggestedReason !== "order_frequency" &&
                <span
                tw="uppercase text-primeblue text-base font-bold ml-[8px] leading-5"
              >
                {ListCategoryEnum.SUGGESTED_NDC}
              </span>}
            </div>
          )}

          {/* NOT NDC */}
          {category !== ListCategoryEnum.NDC_MATCH &&
            category !== ListCategoryEnum.SUGGESTED_NDC && (
              <div tw="flex flex-row items-center">
                <div
                  tw="bg-no-repeat bg-center bg-auto flex items-center justify-center text-xl text-white w-[56px] min-w-[56px] h-[56px]"
                  style={{ backgroundImage: `url(${LowerImg})` }}
                >
                  <Savings
                    ndcOffering={ndcOffering}
                    mainNdcOffering={mainNdcOffering}
                  />
                </div>

                <span tw="uppercase text-primeblue text-base font-bold ml-[8px]">
                  Lower Unit Price
                </span>
              </div>
            )}
        </div>
      )}

      {/* both sections */}
      {category !== ListCategoryEnum.NDC_MATCH &&
        category !== ListCategoryEnum.SUGGESTED_NDC ? (
          <p tw="text-[13px] text-primeblue uppercase mt-[8px] min-h-[40px]" id={`tagInfo${ndcOffering.item.drugInfo.ndc}`}>
            {differentQuantity && (
              <span>
                {quantityLabel(ndcOffering.item, mainNdcOffering?.item)} (
                {ndcOffering.item.drugInfo.unitSize *
                  ndcOffering.item.drugInfo.unitQuantity}
                )
              </span>
            )}
            {differentManufacturerQuantity && (
              <span>
                {quantityManufacturerLabel(
                  ndcOffering.item,
                  mainNdcOffering?.item
                )}{" "}
                (
                {ndcOffering.item.drugInfo.unitSize *
                  ndcOffering.item.drugInfo.unitQuantity}
                )
              </span>
            )}
            {differentManufacturer && <span>{category}</span>}{" "}
            {alternatePackaging && <span>{ListCategoryEnum.ALTERNATIVE_PACKAGING}</span>}
          </p>
        ) : <p tw="text-[13px] text-primeblue uppercase mt-[8px] min-h-[40px]" id={`tagInfo${ndcOffering.item.drugInfo.ndc}`}></p>}
      <div tw="mt-[24px] border-t border-[#cfcfcf] pt-[8px] w-[100%] min-h-[53px]" id={`tag${ndcOffering.item.drugInfo.ndc}`}>
        {category === ListCategoryEnum.NDC_MATCH ||
        category === ListCategoryEnum.SUGGESTED_NDC ? (
          <div>
            <ItemProjection item={ndcOffering.item} />
          </div>
        ) : differentManufacturerQuantity ? (
          <div tw="flex flex-col">
            <div>
              <ItemDispensation item={ndcOffering.item} />
            </div>
            <div>
              <ItemProjection item={ndcOffering.item} />
            </div>
          </div>
        ) : (
          <>
            {differentManufacturer && <ItemDispensation item={ndcOffering.item} />}
            {differentQuantity && <ItemProjection item={ndcOffering.item} />}
          </>
        )}
      </div>
    </div>
  );
}

function HistoricalOrdering({ order }: { order?: HistoricalOrderingInfo }) {
  const text = useMemo(() => {
    if (!order) return null;

    const { lastInvoicePrice, lastOrdered, lastSupplier } = order;

    let dateStr = "";
    const date = dayjs(lastOrdered);
    if (date.isSame(new Date(), "day") || date.isAfter(new Date(), "day")) {
      dateStr = "today";
    } else if (date.isSame(dayjs().subtract(1, "day"), "day")) {
      dateStr = "yesterday";
    } else {
      dateStr = dayjsWithDuration
        .duration(date.diff(new Date()))
        .humanize(true);
    }

    let priceStr;
    if (Number.isFinite(lastInvoicePrice)) {
      const price =
        currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(lastInvoicePrice);
      priceStr = ` for ${price}`
    }

    return (
      <>
        {`Last invoiced at ${lastSupplier} ${dateStr}${priceStr}`}
      </>
    );
  }, [order]);

  return <p tw="font-medium text-base mt-[12px] leading-[24px]">{text}</p>;
}

const timeRange = (item: string[]): string => {
  let time = convertFrom24To12Format(item[0]);
  if (item[1] && item[0] !== item[1]) {
    time = time + '; ' + convertFrom24To12Format(item[1]);
  }

  return time
}
const dayRange = (items: string[][]): string => {
  const dayOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  let time = timeRange(items[0]);
  const mainTime = JSON.stringify(items[0]);
  items.forEach((item, index) => {
    if (JSON.stringify(item) !== mainTime) {
      const tmp = timeRange(item);
      if (tmp.indexOf(";") > 0) {
        time += `|(${dayOfWeek[index]} ${tmp})`
      } else {
        time += `|(${tmp} on ${dayOfWeek[index]})`
      }
    }
  });

  return time
}
const getCutOffTime = (items: string[][]): string => {
  // check if we have one time
  let time = JSON.stringify(items[0]);
  let equal = true;

  items.forEach(item => {
    if (JSON.stringify(item) !== time) {
      equal =  false;
    }
  });

  if (equal) {
    time = timeRange(items[0])
  } else {
    time = dayRange(items);
  }

  return time;
}
const getShippingConfig = (name: string, suppliers: Supplier[]) : ShippingConfig => {
  const defaultConfig: ShippingConfig = {
    "fee": 0,
    "minOrder": 0,
    "minGenericOrder": 0,
    "cutoffTimes": "",
    "notes": "",
    "version" :0,
  };

  const supplier = suppliers.find(supplier => supplier.displayName === name);
  let shippingData = supplier && supplier.shippingConfig || defaultConfig;

  // Normalization
  shippingData = {...defaultConfig, ...shippingData};

  if (Array.isArray(shippingData.notes) && shippingData.notes[0]) {
    shippingData.notes = shippingData.notes[0].toString()
  }
  if (Array.isArray(shippingData.cutoffTimes)) {
    shippingData.cutoffTimes = getCutOffTime(shippingData.cutoffTimes);
  }

  return shippingData;
}

export default function NDCResultCard({
  ndcOfferings,
  isBelowTheFold,
  totalCardCount,
  mainNdcOffering,
}: {
  ndcOfferings: NdcOfferingSet;
  totalCardCount: number;
  isBelowTheFold?: boolean;
  mainNdcOffering?: NdcOffering;
}) {
  const ndcOffering = ndcOfferings.featured;
  const [openPanel, setOpenPanel] = useState<boolean>(false);

  if (
    !ndcOffering ||
    (ndcOffering.supplierData &&
      (ndcOffering.supplierData.availability ===
        EnhancedStockValidationAvailability.UNAVAILABLE ||
        ndcOffering.supplierData.availability ===
          EnhancedStockValidationAvailability.PENDING))
  ) {
    if (
      ndcOfferings.category === ListCategoryEnum.NDC_MATCH ||
        ndcOfferings.category === ListCategoryEnum.SUGGESTED_NDC
    ) {
      if (!ndcOffering) {
        return (
          <Container tw="text-center pt-12 text-lg">
            Unfortunately none of your catalogs currently carry{" "}
            <span tw="font-medium">
              {formatNdc(ndcOfferings.ndc)}
            </span>
            .{totalCardCount > 1 && " Please see other options below."}
          </Container>
        );
      } else {
        return (
          <Container tw="text-center pt-12 text-lg">
            Unfortunately none of your catalogs currently carry this NDC.
            {totalCardCount > 1 && " Please see other options below."}
          </Container>
        );
      }
    } else {
      return null;
    }
  }

  return (
    <div tw="rounded-lg border border-[#dadada] shadow-card bg-white mb-4">
      <div tw="flex justify-end items-center">
        <div tw="p-[24px] flex justify-between self-stretch flex-col w-[580px]">
          <DrugDescription item={ndcOffering.item} shippingPanel={openPanel}>

            <div id={`suppliersPanel${ndcOffering.item.drugInfo.ndc}`}>
              <AllSuppliers
                featured={ndcOffering}
                shippingPanel={openPanel}
                alternatives={ndcOfferings.alternatives}
              />

            </div>
          </DrugDescription>
        </div>
        <TagPanel
          ndcOffering={ndcOffering}
          category={ndcOfferings.category}
          isBelowTheFold={isBelowTheFold}
          mainNdcOffering={mainNdcOffering}
        />
        <FeaturedPanel
          featuredResult={ndcOffering}
          isBelowTheFold={isBelowTheFold}
          shippingPanel={openPanel}
          shippingPanelCallback={setOpenPanel}
        />
      </div>
    </div>
  );
}
