import React from "react";
import "twin.macro";
import { NavBar } from "../../components/NavBar/NavBar";
import { PerformanceHeader } from "./PerformanceHeader/PerformanceHeader";
import { PerformanceContextProvider } from "./usePerformance";
import { PerformanceReport } from "./PerformanceReport";

export function Performance() {
  return (
    <>
      <NavBar />

      <PerformanceContextProvider>
        <PerformanceHeader />
        <PerformanceReport />
      </PerformanceContextProvider>
    </>
  );
}
