import React, { ButtonHTMLAttributes } from "react";
import { css, styled } from "twin.macro";
import {
  RxColor,
  GREY_1,
  GREY_2,
  GREY_3,
  WHITE,
  getBackgroundColorCss,
} from "./colors";
import { IconX } from "./icons";
import { Text } from "./typography";

const ButtonTag = styled.button`
  height: 28px;
  padding: 0 16px;
  border-radius: 20px;
  background-color: ${GREY_3};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    opacity: 0.45;
    height: 10px;
    margin-right: 8px;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }

  &:active {
    background-color: ${GREY_2};
  }
`;

export function ClosableTag({
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <ButtonTag {...props}>
      <IconX />
      <Text>{children}</Text>
    </ButtonTag>
  );
}

export const Tag = styled.span(
  ({ bgColor = "green-3" }: { bgColor?: RxColor }) => {
    return [
      css`
        height: 22px;
        border-radius: 3px;
        border: solid 1px ${WHITE};
        padding: 0 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: Roboto;
        font-size: 10px;
        font-weight: normal;
        font-stretch: condensed;
        font-style: normal;
        line-height: 2.2;
        letter-spacing: normal;
        text-transform: uppercase;
        cursor: default;
        color: ${GREY_1};
      `,
      getBackgroundColorCss(bgColor),
    ];
  }
);
