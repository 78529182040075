import React, { useCallback, useEffect, useMemo, useState } from "react";
import "twin.macro";
import pluralize from "pluralize";
import { Button } from "../../../../components/rxLibrary/buttons";
import { IconRefresh } from "../../../../components/rxLibrary/icons";
import { Text, Title } from "../../../../components/rxLibrary/typography";
import { dayjsWithDuration } from "../../../../utilities/dates/dayjsWithDuration";

export function ShoppingListTablePmsHeader({
  loading,
  pmsName,
  pmsRefreshTimeStamp,
  prescriptionItemsInCartCount,
  visiblePrescriptionItemsCount,
  getPmsData,
}: {
  loading: boolean;
  pmsName: string;
  pmsRefreshTimeStamp?: Date;
  prescriptionItemsInCartCount: number;
  visiblePrescriptionItemsCount: number;
  getPmsData: () => void;
}) {
  const refreshTimeStr = useRefreshTimeStr({
    loading,
    pmsRefreshTimeStamp,
  });

  const handleRefreshClick = useCallback(() => {
    getPmsData();
  }, [getPmsData]);

  const message = useMemo(() => {
    const filteredPrescriptionItemsCount =
      prescriptionItemsInCartCount - visiblePrescriptionItemsCount;
    const itemsPlural = pluralize("Item", visiblePrescriptionItemsCount);

    let newMessage = `${pmsName} ${itemsPlural}: ${visiblePrescriptionItemsCount}`;
    if (
      !loading &&
      prescriptionItemsInCartCount > 0 &&
      filteredPrescriptionItemsCount > 0
    ) {
      newMessage += ` (+${filteredPrescriptionItemsCount} filtered)`;
    }

    return newMessage;
  }, [
    pmsName,
    loading,
    prescriptionItemsInCartCount,
    visiblePrescriptionItemsCount,
  ]);

  return (
    <>
      <Title variant="h2">{message}</Title>

      <Button
        variant="secondary"
        icon={<IconRefresh />}
        onClick={handleRefreshClick}
      >
        Refresh
      </Button>

      {!!refreshTimeStr && <Text size="medium">{refreshTimeStr}</Text>}
    </>
  );
}

function useRefreshTimeStr({
  loading,
  pmsRefreshTimeStamp,
}: {
  loading: boolean;
  pmsRefreshTimeStamp?: Date;
}) {
  const [refreshTimeStr, setRefreshTimeStr] = useState<string>();

  useEffect(() => {
    updateRefreshTimeStr();

    if (loading || !pmsRefreshTimeStamp) return;
    const intervalId: NodeJS.Timeout = setInterval(updateRefreshTimeStr, 60000);

    return () => {
      clearInterval(intervalId);
    };

    function updateRefreshTimeStr() {
      if (loading || !pmsRefreshTimeStamp) {
        setRefreshTimeStr(undefined);
        return;
      }

      const minutes = dayjsWithDuration(pmsRefreshTimeStamp).diff(
        new Date(),
        "minutes"
      );
      const timeStr = dayjsWithDuration.duration(minutes, "minutes").humanize();
      const newRefreshTimeStr = `Refreshed ${timeStr} ago`;

      setRefreshTimeStr(newRefreshTimeStr);
    }
  }, [loading, pmsRefreshTimeStamp]);

  return refreshTimeStr;
}
