"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.incremental = incremental;
exports.random = random;
function random() {
  return (+new Date() + Math.floor(Math.random() * 10000000)).toString(36);
}
var start = 1;
function incremental() {
  return start++;
}
