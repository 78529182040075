"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasSkippedQuickAuth = hasSkippedQuickAuth;
exports.skipQuickAuth = skipQuickAuth;
var _data_utils = require("./utils/data_utils");
var _dataFns = (0, _data_utils.dataFns)(['quickAuth']),
  tget = _dataFns.tget,
  tset = _dataFns.tset;
function skipQuickAuth(m, b) {
  return tset(m, 'skipped', b);
}
function hasSkippedQuickAuth(m) {
  return tget(m, 'skipped', false);
}
