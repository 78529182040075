import React, { useMemo } from "react";
import { styled } from "twin.macro";
import dayjs from "dayjs";
import { formatPrescriberName } from "../../../../utilities/helpers";
import { ItemInCart, PrescriptionQuantityItem } from "../../../../utilities/types";
import { Table } from "../../../../components/Table";
import { formatPurchaseByToDayjs } from "../../../../utilities/dates/purchaseBy/formatPurchaseByToDayjs";
import { COLUMNS } from "./PrescriptionTable.columns";
import { PrescriptionTableFooter } from "./PrescriptionTableFooter";
import { SHORT_DATE_FORMAT } from "../../../../utilities/dates/dates.constants";

function formatData(rxList: ItemInCart[]) {
  if (!rxList || rxList.length === 0) return [];

  const data = rxList.map((item) => {
    const purchaseByDate = formatPurchaseByToDayjs(item.purchaseBy);
    const purchaseByStr = purchaseByDate
      ? purchaseByDate.format(SHORT_DATE_FORMAT)
      : "TODAY";

    const dateFilled = item?.rxNumber
      ? dayjs(item?.fillDate).format(SHORT_DATE_FORMAT)
      : "-";

    const prescriber = item?.prescriber
      ? formatPrescriberName(item.prescriber)
      : "-";

    return {
      identifier: item,
      dateFilled,
      prescriber,
      dispensedQty: item,
      purchaseBy: purchaseByStr,
    };
  });

  return data;
}

export function PrescriptionTable({
  prescription,
}: {
  prescription: PrescriptionQuantityItem;
}) {
  const { rxList, purchaseBy } = prescription;
  const data = useMemo(() => formatData(rxList), [rxList]);

  return (
    <TableWrapper>
      <Table
        showFooter
        data={data}
        columns={COLUMNS}
        isStickyHeader={false}
        footer={
          <PrescriptionTableFooter
            purchaseBy={purchaseBy}
            rxList={rxList}
          />
        }
      />
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;

  thead th {
    font-weight: normal;
    text-align: start;
  }
`;
