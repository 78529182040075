import React from "react";
import "twin.macro";
import { Text } from "../../../rxLibrary/typography";

export function AcquisitionCostError() {
  return (
    <Text center block>
      Error Loading Refill Items
    </Text>
  );
}
