import React, { useCallback } from "react";
import "twin.macro";
import {
  CalendarFooterNotInStockToday,
  PurchaseByButton,
} from "../../../../../components/shoppingList/PurchaseByButton/PurchaseByButton";
import { DrugDetailsCol } from "../../../../../components/shoppingList/table/columns/DrugDetailsCol";
import { ManufacturerCol } from "../table/columns/ManufacturerCol";
import { QuantityDescriptionCol } from "../../../../../components/shoppingList/table/columns/QuantityDescriptionCol";
import { BuyLaterItem } from "./useBuyLaterTableItems";
import { BuyingForCol } from "../table/columns/BuyingForCol";

export function BuyLaterTableRow({
  item,
  itemProps,
}: {
  item: BuyLaterItem;
  itemProps?: {
    onPurchaseByChange: (v: { item: BuyLaterItem; date?: Date }) => void;
  };
}) {
  const { onPurchaseByChange } = itemProps ?? {};
  const { purchaseBy, alternatives, prescriptionDetails } = item;
  const { drugInfo: drug } = item.referenceData;
  const isUnavailable = alternatives.length === 0;

  const handlePurchaseByChange = useCallback(
    (date?: Date) => {
      onPurchaseByChange && onPurchaseByChange({ date, item });
    },
    [item, onPurchaseByChange]
  );

  return (
    <>
      <DrugDetailsCol drug={drug} />

      <div tw="px-[10px] border-r w-[205px]">
        <ManufacturerCol drug={drug} />
      </div>

      <div tw="px-[10px] border-r w-[130px]">
        <BuyingForCol prescriptionDetails={prescriptionDetails} />
      </div>

      <div tw="px-[10px] border-r flex-1">
        <QuantityDescriptionCol drug={drug} />
      </div>

      <div tw="px-[10px] w-[170px]">
        <PurchaseByButton
          purchaseBy={purchaseBy}
          disableToday={isUnavailable}
          onChange={handlePurchaseByChange}
          calendarFooter={
            <CalendarFooterNotInStockToday hide={!isUnavailable} />
          }
        />
      </div>
    </>
  );
}
