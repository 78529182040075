import React, { FC, useCallback, useState } from "react";
import tw, { css, styled } from "twin.macro";
import { BLUE_1, GREY_1, GREY_2, WHITE } from "../../colors";
import { ExpandableTableRow } from "./ExpandableTableRow";

const ButtonHeader = styled.button(({ isOpen }: { isOpen: boolean }) => [
  tw`flex items-stretch w-full`,
  css`
    background-color: ${WHITE};
  `,
  // Shadow
  css`
    box-shadow: 0 6px 6px 0 #0000003b;
  `,
  // hack to display the shadow over the next component when it button is open
  isOpen && tw`relative`,
]);

const ToggleIndicator = styled.div(
  ({ isOpen, bgBlue }: { isOpen: boolean; bgBlue?: boolean }) => {
    const bgColorOpen = bgBlue ? BLUE_1 : GREY_2;
    const bgColorClosed = bgBlue ? BLUE_1 : GREY_1;

    return [
      css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        min-height: 100px;
        font-size: 28px;
        color: ${WHITE};
      `,
      css`
        background-color: ${isOpen ? bgColorOpen : bgColorClosed};
      `,
    ];
  }
);

export function ExpandableTable<T extends { id: string }, P>({
  Row,
  items,
  header,
  itemProps,
  blueToggle = false,
  RowWrapper = ExpandableTableRow,
}: {
  Row: FC<{ item: T; itemProps?: P }>;
  items: T[];
  header: JSX.Element;
  itemProps?: P;
  blueToggle?: boolean;
  RowWrapper?: FC<{ children: JSX.Element }>;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div>
      <ButtonHeader isOpen={isOpen} onClick={toggleOpen}>
        <ToggleIndicator isOpen={isOpen} bgBlue={blueToggle}>
          {isOpen ? "-" : "+"}
        </ToggleIndicator>
        <div tw="text-left flex-1">{header}</div>
      </ButtonHeader>

      {isOpen && (
        <div>
          {items.map((item) => (
            <RowWrapper key={item.id}>
              <Row item={item} itemProps={itemProps} />
            </RowWrapper>
          ))}
        </div>
      )}
    </div>
  );
}
