import React, { useMemo } from "react";
import { DrugInfo } from "../../../../../utilities/types";
import { formatDrugDispenseQuantity } from "../../../../../utilities/drugInfo/formatDrugDispenseQuantity";
import { Text } from "../../../../../components/rxLibrary/typography";

export function ShoppingListColumnPackageInfo({
  drug,
  noManufacturerPreference,
}: {
  drug: DrugInfo;
  noManufacturerPreference?: boolean;
}) {
  const quantityDescription = useMemo(() => {
    return formatDrugDispenseQuantity(drug);
  }, [drug]);

  return (
    <>
      <Text weight="bold" block>
        {quantityDescription}
      </Text>

      <Text block>
        {noManufacturerPreference ? "Any Manufacturer" : drug.manufacturer}
      </Text>
    </>
  );
}
