import React from "react";
import { styled } from "twin.macro";
import { GREY_3, WHITE } from "../../../components/rxLibrary/colors";

export const Card = styled.div`
  width: 374px;
  height: 402px;
  padding: 24px;
  border-radius: 9px;
  box-shadow: 0 0 10px 0 #0000001e;
  border: solid 1px ${GREY_3};
  background-color: ${WHITE};
`;
