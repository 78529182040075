import React, { useCallback } from "react";
import { styled } from "twin.macro";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { BLUE_1, GREY_2 } from "./colors";
import { DEFAULT_TEXT_CSS, Text } from "./typography";

export type DateRangePickerValue = [Dayjs, Dayjs];

type DateRangePickerProps = Omit<
  RangePickerProps,
  "onChange" | "allowClear" | "value"
> & {
  label: string;
  value?: DateRangePickerValue;
  onChange: (v: DateRangePickerValue) => void;
};

const RangePicker = styled(DatePicker.RangePicker)`
  min-height: 38px;
  border-radius: 8px;
  border-color: ${GREY_2};

  :hover {
    border-color: ${BLUE_1};
  }

  .ant-picker-input {
    input {
      ${DEFAULT_TEXT_CSS}
    }
  }
`;

export function DateRangePicker({
  label,
  style,
  className,
  onChange,
  ...props
}: DateRangePickerProps) {
  const handleChange: NonNullable<RangePickerProps["onChange"]> = useCallback(
    (dates) => {
      if (!dates || !dates[0] || !dates[1]) return;
      onChange([dayjs(dates[0].toDate()), dayjs(dates[1].toDate())]);
    },
    [onChange]
  );

  return (
    <div style={style} className={className} tw="flex items-center space-x-2">
      <Text size="medium">{label}</Text>

      <RangePicker {...props} allowClear={false} onChange={handleChange} />
    </div>
  );
}
