import React, {useContext, } from "react";
import EnhancedClientContext from "../enhanced/EnhancedClientContext";

export default function EnhancedOverlayScreen(): JSX.Element {
    const {enhancedOverlayShown, requestClearEnhancedOverlay} = useContext(EnhancedClientContext);

    if (!enhancedOverlayShown) {
        return <></>;
    }

    return (
        <div onClick={requestClearEnhancedOverlay} style={{position: "fixed", zIndex: 9999, top: 0, left: 0, height: "100%", width: "100%", backgroundColor: "rgba(0.5, 0.5, 0.5, 0.75)"}} />
    )
}
