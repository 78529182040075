import React, { useCallback, useState } from "react";
import "twin.macro";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { WideContainer } from "../../../components/containers/WideContainer";
import { Box } from "../../../components/rxLibrary/box";
import { BackButton, Button } from "../../../components/rxLibrary/buttons";
import { Text } from "../../../components/rxLibrary/typography";
import {
  SupplierAccountRecordView,
  SupplierAccountsSummaryView,
} from "../EnhancedSupplierAccount.constants";
import { EnhancedSupplierAccountSettingsEditAccount } from "./EnhancedSupplierAccountSettingsEditAccount";
import { EnhancedSupplierAccountSettingsCreateAccount } from "./EnhancedSupplierAccountSettingsCreateAccount";
import { EnhancedSupplierAccountSettingsViewAccount } from "./EnhancedSupplierAccountSettingsViewAccount";

export function EnhancedSupplierAccountSettings({
  workstationName,
  supplierAccount,
  togglePassword,
  returnToSummary,
}: {
  supplierAccount: SupplierAccountsSummaryView;
  workstationName?: string;
  togglePassword: (row: SupplierAccountRecordView) => void;
  returnToSummary: () => void;
}) {
  const [editingAccountId, setEditingAccountId] = useState<string>();
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);

  const displayCreateAccount =
    !supplierAccount.accounts.length || isCreatingAccount;

  const setEditMode = useCallback((accountId: string) => {
    setIsCreatingAccount(false);
    setEditingAccountId(accountId);
  }, []);

  const setViewMode = useCallback(() => {
    setIsCreatingAccount(false);
    setEditingAccountId(undefined);
  }, []);

  const setCreateMode = useCallback(() => {
    setIsCreatingAccount(true);
    setEditingAccountId(undefined);
  }, []);

  return (
    <div tw="mb-[120px]">
      <Box tw="shadow-lg" bgColor="white">
        <WideContainer tw="flex items-center py-8">
          <BackButton onClick={returnToSummary}>Supplier Management</BackButton>
        </WideContainer>
      </Box>

      <WideContainer tw="mt-20">
        <Box tw="px-4 border-b" borderColor="grey-3">
          <Text block tw="mb-8" size={30}>
            {supplierAccount.supplierDisplay}
          </Text>

          {!!workstationName && (
            <Text tw="mb-6" block size="large">
              <Text color="red" size="large">
                Note:{" "}
              </Text>
              This password is saved locally on workstation {workstationName}
            </Text>
          )}
        </Box>

        <Box tw="p-4">
          <table>
            <thead>
              <tr tw="text-left">
                <th tw="w-[230px]">
                  <Text color="grey-1">Username</Text>
                </th>
                <th tw="w-[230px]">
                  <Text color="grey-1">Password</Text>
                </th>
                <th />
              </tr>
            </thead>

            <tbody>
              {supplierAccount.accounts.map((account) => {
                if (editingAccountId === account.id) {
                  return (
                    <EnhancedSupplierAccountSettingsEditAccount
                      key={account.id}
                      account={account}
                      setViewMode={setViewMode}
                    />
                  );
                }

                return (
                  <EnhancedSupplierAccountSettingsViewAccount
                    key={account.id}
                    account={account}
                    setEditMode={setEditMode}
                    togglePassword={togglePassword}
                  />
                );
              })}

              {displayCreateAccount && (
                <EnhancedSupplierAccountSettingsCreateAccount
                  supplierAccount={supplierAccount}
                  setViewMode={setViewMode}
                  returnToSummary={returnToSummary}
                />
              )}
            </tbody>
          </table>

          {!displayCreateAccount && (
            <Button
              variant="text-2"
              icon={<PlusOutlined rev={undefined} />}
              onClick={setCreateMode}
            >
              Add Another Login
            </Button>
          )}
        </Box>
      </WideContainer>
    </div>
  );
}
