import React, { ReactNode, useState } from "react";
import tw, { css } from "twin.macro";
import dayjs, { Dayjs } from "dayjs";
import isString from "lodash/isString";
import {
  Button,
  BackButton,
  IconButton,
  MenuButton,
  NoteButton,
  ShoppingListButton,
} from "../../../components/rxLibrary/buttons";
import { Text, Title } from "../../../components/rxLibrary/typography";
import { WideContainer } from "../../../components/containers/WideContainer";
import { COLORS, RxColor } from "../../../components/rxLibrary/colors";
import * as icons from "../../../components/rxLibrary/icons";
import { ClosableTag, Tag } from "../../../components/rxLibrary/tags";
import { Switch } from "../../../components/rxLibrary/switch";
import { Checkbox } from "../../../components/rxLibrary/checkbox";
import {
  Dropdown,
  DropdownMenuOptions,
} from "../../../components/rxLibrary/dropdown";
import {
  Popover,
  useIsPopoverOpen,
} from "../../../components/rxLibrary/popover";
import { Col, InnerRow, Row } from "../../../components/rxLibrary/grid";
import { Select } from "../../../components/rxLibrary/selects/select";
import {
  SimpleTable,
  ExpandableTable,
} from "../../../components/rxLibrary/tables";
import { Warning } from "../../../components/rxLibrary/warnings";
import { QtySelect } from "../../../components/rxLibrary/selects/QtySelect";
import {
  SortBySelect,
  SortDirectionEnum,
} from "../../../components/rxLibrary/selects/SortBySelect";
import { Box } from "../../../components/rxLibrary/box";
import { DateRangePicker } from "../../../components/rxLibrary/dateRangePicker";
import { Pagination } from "../../../components/rxLibrary/pagination/Pagination";
import {
  MultiSelectCheckboxes,
  MultiSelectCheckboxesSelectedTags,
} from "../../../components/rxLibrary/selects/MultiSelectCheckboxes";

const { IconCheck, IconX } = icons;

export function RxLibrary() {
  return (
    <WideContainer tw="pb-[200px]">
      <Title block tw="mb-4 border-b-2">
        Rx Components
      </Title>

      <a
        tw="underline"
        href="https://app.zeplin.io/project/6191c505b9c2f322fa3714af/screen/644f9b678f5e62262fba6eb6"
      >
        Zeplin - Rx Components
      </a>

      <Colors />
      <Icons />
      <Buttons />
      <Typography />
      <Tags />
      <Warnings />
      <Switches />
      <Checkboxes />
      <Dropdowns />
      <Popovers />
      <Selects />
      <DateRangePickers />
      <Grids />
      <Tables />
      <Paginations />
      <Boxes />
    </WideContainer>
  );
}

function Colors() {
  return (
    <>
      <VariantTitle>Colors</VariantTitle>

      <Variants>
        <div tw="grid grid-cols-12 gap-4">
          {Object.entries(COLORS).map(([name, color]) => {
            if (!isString(color)) return null;
            return (
              <div key={name} tw="text-center">
                <div
                  tw="w-20 h-20 rounded-lg"
                  style={{ backgroundColor: color as string }}
                />
                <Text block>{color}</Text>
                <Text block weight="bold">
                  {name}
                </Text>
              </div>
            );
          })}
        </div>
      </Variants>
    </>
  );
}

function Icons() {
  return (
    <>
      <VariantTitle>Icons</VariantTitle>
      <Text tw="mb-4" weight="bold" block>
        Note: css color attribute could be changed used to change the icon color
      </Text>

      <Variants>
        <div tw="grid grid-cols-12 gap-4" style={{ color: COLORS["blue-1"] }}>
          {Object.entries(icons).map(([name, Icon], i) => (
            <div key={i} tw="flex flex-col items-center space-y-2">
              <Icon />
              <Text>{name}</Text>
            </div>
          ))}
        </div>
      </Variants>
    </>
  );
}

function Buttons() {
  return (
    <>
      <VariantTitle>Buttons</VariantTitle>

      <Variants>
        <VariantSubtitle>Variants</VariantSubtitle>

        <Variants>
          <VariantSubtitle>Primary (default)</VariantSubtitle>

          <Variants tw="space-x-2">
            <Button>Primary (default)</Button>
            <Button icon={<IconCheck />}>Primary icon</Button>
            <Button disabled>Primary disabled</Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Secondary</VariantSubtitle>

          <Variants tw="space-x-2">
            <Button variant="secondary">Secondary</Button>
            <Button variant="secondary" icon={<IconCheck />}>
              Secondary icon
            </Button>
            <Button variant="secondary" disabled>
              Secondary disabled
            </Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Tertiary</VariantSubtitle>

          <Variants tw="space-x-2">
            <Button variant="tertiary">Tertiary</Button>
            <Button variant="tertiary" icon={<IconCheck />}>
              Tertiary icon
            </Button>
            <Button variant="tertiary" disabled>
              Tertiary disabled
            </Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Button Text 1</VariantSubtitle>

          <Variants tw="space-x-2">
            <Button variant="text-1">text-1</Button>
            <Button variant="text-1" icon={<IconCheck />}>
              Text-1 icon
            </Button>
            <Button variant="text-1" disabled>
              Text-1 disabled
            </Button>
          </Variants>
        </Variants>

        <Variants tw="space-x-2">
          <VariantSubtitle>Button Text 2</VariantSubtitle>

          <Variants>
            <Button variant="text-2">text-2</Button>
            <Button variant="text-2" icon={<IconCheck />}>
              Text-2 icon
            </Button>
            <Button variant="text-2" disabled>
              Text-2 disabled
            </Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Large</VariantSubtitle>

          <Variants>
            <Button variant="large">large</Button>
            <Button variant="large" icon={<IconCheck />}>
              large icon
            </Button>
            <Button variant="large" disabled>
              large disabled
            </Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Shopping List Button</VariantSubtitle>

          <Variants>
            <ShoppingListButton>Shopping List Button</ShoppingListButton>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Modifiers</VariantSubtitle>

          <Variants>
            <Variants>
              <VariantSubtitle>Inline</VariantSubtitle>

              <Variants>
                <Button>Primary</Button>
                <Button variant="secondary">Secondary</Button>
                <Button variant="tertiary">Tertiary</Button>
                <Button variant="text-1">Text-1</Button>
                <Button variant="text-2">Text-2</Button>
              </Variants>
            </Variants>

            <Variants>
              <VariantSubtitle>Block</VariantSubtitle>

              <Variants>
                <Button block>Primary block</Button>
                <Button variant="secondary" block>
                  Secondary block
                </Button>
                <Button variant="tertiary" block>
                  Tertiary block
                </Button>
                <Button variant="text-1" block>
                  Text-1 block
                </Button>
                <Button variant="text-2" block>
                  Text-2 block
                </Button>
              </Variants>
            </Variants>

            <Variants>
              <VariantSubtitle>Full Width</VariantSubtitle>

              <Variants>
                <Button fullWidth>Primary fullWidth</Button>
                <Button variant="secondary" fullWidth>
                  Secondary fullWidth
                </Button>
                <Button variant="tertiary" fullWidth>
                  Tertiary fullWidth
                </Button>
                <Button variant="text-1" fullWidth>
                  Text-1 fullWidth
                </Button>
                <Button variant="text-2" fullWidth>
                  Text-2 fullWidth
                </Button>
              </Variants>
            </Variants>
          </Variants>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Icon Button</VariantSubtitle>
        <Text block>
          <ul>
            <li>Could use any internal icon as content</li>
          </ul>
        </Text>

        <Variants>
          <Variants>
            <VariantSubtitle>Example</VariantSubtitle>

            <Variants>
              <IconButton>
                <IconCheck />
              </IconButton>
            </Variants>
          </Variants>

          <Variants>
            <VariantSubtitle>Predefined Common Icon Buttons</VariantSubtitle>

            <Variants tw="flex space-x-2">
              <div tw="flex flex-col space-y-2 items-center">
                <MenuButton />
                <Text>MenuButton</Text>
              </div>
              <div tw="flex flex-col space-y-2 items-center">
                <NoteButton />
                <Text>NoteButton</Text>
              </div>
            </Variants>
          </Variants>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Back Button</VariantSubtitle>
        <Text block>
          Button commonly used in the navigation headers to go back to the previous page/section
        </Text>

        <Variants>
          <BackButton>Back Button</BackButton>
        </Variants>
      </Variants>
    </>
  );
}

function Typography() {
  return (
    <>
      <VariantTitle>Typography</VariantTitle>
      <Text block>
        <ul>
          <li>
            The rendered tag could be changed using .withComponent(...).{" "}
            <a
              tw="underline"
              href="https://emotion.sh/docs/styled#change-the-rendered-tag-using-withcomponent"
            >
              Link doc
            </a>
          </li>
        </ul>
      </Text>

      <Variants>
        <VariantSubtitle>Title</VariantSubtitle>

        <Variants>
          <VariantSubtitle>Variants</VariantSubtitle>

          <Variants tw="flex flex-col space-y-2">
            <Title>H1 (default)</Title>
            <Title variant="h2">H2</Title>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Modifiers</VariantSubtitle>

          <Variants>
            <VariantSubtitle>Inline (default)</VariantSubtitle>

            <Variants>
              <Title>h1 inline</Title>
              <Title variant="h2">h2 inline</Title>
            </Variants>
          </Variants>

          <Variants>
            <VariantSubtitle>Block</VariantSubtitle>

            <Variants>
              <Title block>h1 block</Title>
              <Title variant="h2" block>
                h2 block
              </Title>
            </Variants>
          </Variants>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Text</VariantSubtitle>

        <Variants>
          <VariantSubtitle>Modifiers</VariantSubtitle>
          <Variants>
            <VariantSubtitle>Inline (default)</VariantSubtitle>

            <Variants>
              <Text>inline text light</Text>
              <Text weight="bold">inline text bold</Text>
            </Variants>
          </Variants>

          <Variants>
            <VariantSubtitle>Block</VariantSubtitle>

            <Variants>
              <Text block>block light</Text>
              <Text block weight="bold">
                block bold
              </Text>
            </Variants>
          </Variants>

          <Variants>
            <VariantSubtitle>Center</VariantSubtitle>

            <Variants>
              <Text block center>
                block center
              </Text>
              <Text block center weight="bold">
                block center
              </Text>
            </Variants>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Weights</VariantSubtitle>

          <SimpleTable>
            <thead>
              <tr>
                <th>weight</th>
                <th>light</th>
                <th>bold</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>color</td>
                <td>
                  {Object.keys(COLORS).map((color) => (
                    <Text key={color} block color={color as RxColor}>
                      {color}
                    </Text>
                  ))}
                </td>
                <td>
                  {Object.keys(COLORS).map((color) => (
                    <Text
                      key={color}
                      block
                      color={color as RxColor}
                      weight="bold"
                    >
                      {color}
                    </Text>
                  ))}
                </td>
              </tr>
              <tr>
                <td>size</td>
                <td>
                  <Text block size={12}>
                    size: any integer number (example: 12)
                  </Text>
                  <Text block>small: 14px (default)</Text>
                  <Text block size="medium">
                    medium: 16px
                  </Text>
                  <Text block size="large">
                    large: 18px
                  </Text>
                  <Text block size="x-large">
                    x-large: 24px
                  </Text>
                </td>
                <td>
                  <Text block weight="bold" size={12}>
                    size: any integer number (example: 12)
                  </Text>
                  <Text block weight="bold">
                    small: 14px (default)
                  </Text>
                  <Text block weight="bold" size="medium">
                    medium: 16px
                  </Text>
                  <Text block weight="bold" size="large">
                    large: 18px
                  </Text>
                  <Text block weight="bold" size="x-large">
                    x-large: 24px
                  </Text>
                </td>
              </tr>
            </tbody>
          </SimpleTable>
        </Variants>
      </Variants>
    </>
  );
}

function Tags() {
  return (
    <>
      <VariantTitle>Tags</VariantTitle>

      <Variants>
        <VariantSubtitle>Tag</VariantSubtitle>

        <Variants>
          <Tag>Tag</Tag>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Closable Tag</VariantSubtitle>

        <Variants>
          <ClosableTag>Closable Tag</ClosableTag>
        </Variants>
      </Variants>
    </>
  );
}

function Warnings() {
  return (
    <>
      <VariantTitle>Warnings</VariantTitle>

      <Variants>
        <VariantSubtitle>Types</VariantSubtitle>

        <Variants tw="space-y-2">
          <Warning>type: info (default)</Warning>
          <Warning type="error">type: error</Warning>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Icon</VariantSubtitle>

        <Variants tw="space-y-2">
          <Warning>icon: bell icon (default)</Warning>
          <Warning type="error">icon: bell icon (default)</Warning>
          <Warning Icon={IconCheck}>icon: custom, example: check icon</Warning>
          <Warning type="error" Icon={IconCheck}>
            icon: custom, example: check icon
          </Warning>
        </Variants>
      </Variants>
    </>
  );
}

function Switches() {
  return (
    <>
      <VariantTitle link="https://ant.design/components/switch">
        Switch
      </VariantTitle>

      <Variants tw="flex space-x-2">
        <div tw="flex flex-col items-center">
          <Switch defaultChecked />
          <Text block>Checked</Text>
        </div>
        <div tw="flex flex-col items-center">
          <Switch />
          <Text block>Unchecked</Text>
        </div>
      </Variants>
    </>
  );
}

function Checkboxes() {
  return (
    <>
      <VariantTitle link="https://ant.design/components/checkbox">
        Checkbox
      </VariantTitle>

      <Variants tw="flex space-x-2">
        <div tw="flex flex-col items-center">
          <Checkbox defaultChecked />
          <Text block>Checked</Text>
        </div>
        <div tw="flex flex-col items-center">
          <Checkbox />
          <Text block>Unchecked</Text>
        </div>
        <div tw="flex flex-col items-center">
          <Checkbox defaultChecked disabled />
          <Text block>Checked disabled</Text>
        </div>
        <div tw="flex flex-col items-center">
          <Checkbox disabled />
          <Text block>Unchecked disabled</Text>
        </div>
      </Variants>
    </>
  );
}

function Dropdowns() {
  const items: DropdownMenuOptions = [
    {
      key: "add",
      label: "Remove from Shopping List",
    },
    {
      key: "list",
      label: "Add to Shopping List",
    },
    {
      key: "stock",
      label: "In Stock",
    },
    {
      key: "processed",
      label: "Mark: Optimized",
    },
    {
      key: "delete",
      label: "Delete Item",
      icon: <IconX />,
    },
  ];

  return (
    <>
      <VariantTitle link="https://ant.design/components/dropdown">
        Dropdown
      </VariantTitle>

      <Variants>
        <Dropdown
          menu={{
            items,
            onClick: (value) => console.log("dropdown option clicked:", value),
          }}
        >
          <Button>Dropdown</Button>
        </Dropdown>
      </Variants>
    </>
  );
}

function Popovers() {
  const { isPopoverOpen, closePopover, handleOpenPopoverChange } =
    useIsPopoverOpen();

  return (
    <>
      <VariantTitle link="https://ant.design/components/popover">
        Popover
      </VariantTitle>

      <Variants>
        <Popover
          open={isPopoverOpen}
          onOpenChange={handleOpenPopoverChange}
          content={() => (
            <div tw="flex flex-col space-y-2 p-4">
              <Title>Popover Title</Title>
              <Text>Popover content</Text>
              <Button icon={<IconX />} onClick={closePopover}>
                Close
              </Button>
            </div>
          )}
        >
          <Button>Popover</Button>
        </Popover>
      </Variants>
    </>
  );
}

function Selects() {
  const options = new Array(10)
    .fill(0)
    .map((_, i) => ({ label: `Item ${i}`, value: i }));
  const [value, setValue] = useState(options[0]);
  const [sortDirection, setSortDirection] = useState(SortDirectionEnum.ASC);
  const [multiSelectValues, setMultiSelectValues] = useState([
    options[0].value,
  ]);

  return (
    <>
      <VariantTitle link="https://ant.design/components/select">
        Select
      </VariantTitle>

      <Variants>
        <VariantSubtitle>Select</VariantSubtitle>
        <Variants>
          <Select placeholder="Select" allowClear options={options} />
        </Variants>

        <VariantSubtitle>Vertical</VariantSubtitle>
        <Variants>
          <QtySelect options={options} vertical />
        </Variants>

        <VariantSubtitle>Qty Select</VariantSubtitle>
        <Variants>
          <VariantSubtitle>Options 1 to 120 (default)</VariantSubtitle>
          <Variants>
            <QtySelect />
          </Variants>

          <VariantSubtitle>
            Custom Options - Custom defined options, value should be a number,
            label could be anything
          </VariantSubtitle>

          <Variants>
            <QtySelect options={options} />
          </Variants>

          <VariantSubtitle>
            Quantities Config - Build the options using multiple modifiers like:
            bundleSize: number, includeZero: boolean, zeroLabel: string,
            multiplesOfBundleSize: boolean, directDealLabel: boolean
          </VariantSubtitle>

          <Variants>
            <QtySelect
              quantitiesConfig={{
                bundleSize: 10,
                includeZero: true,
                zeroLabel: "None",
                multiplesOfBundleSize: true,
                directDealLabel: true,
              }}
            />
          </Variants>
        </Variants>

        <VariantSubtitle>Sort By Select</VariantSubtitle>
        <Variants>
          <SortBySelect
            options={options}
            selectMinWidth={120}
            value={value}
            sortOrder={sortDirection}
            onSortOrderChange={setSortDirection}
            onChange={setValue}
          />
        </Variants>

        <VariantSubtitle>Multi Select Checkboxes</VariantSubtitle>
        <Variants>
          <MultiSelectCheckboxes
            title="Select Filters to Apply"
            filters={multiSelectValues}
            setFilters={setMultiSelectValues}
            options={options}
          />

          <div tw="mt-2">
            <MultiSelectCheckboxesSelectedTags
              filters={multiSelectValues}
              setFilters={setMultiSelectValues}
              options={options}
            />
          </div>
        </Variants>
      </Variants>
    </>
  );
}

function DateRangePickers() {
  const [dates, setDates] = useState((): [Dayjs, Dayjs] => {
    return [dayjs(), dayjs().add(3, "days")];
  });

  return (
    <>
      <VariantTitle link="https://ant.design/components/date-picker#rangepicker">
        Date Range Picker
      </VariantTitle>

      <Variants>
        <DateRangePicker
          label="Date Range"
          defaultValue={dates}
          onChange={setDates}
          presets={[
            {
              label: "Last 90 Days",
              value: [dayjs().subtract(91, "day"), dayjs().subtract(1, "day")],
            },
            {
              label: "Last 60 Days",
              value: [dayjs().subtract(61, "day"), dayjs().subtract(1, "day")],
            },
            {
              label: "Last 30 Days",
              value: [dayjs().subtract(31, "day"), dayjs().subtract(1, "day")],
            },
            {
              label: "Now to end-of-week",
              value: [dayjs(), dayjs().endOf("week")],
            },
            {
              label: "Next 7 Days",
              value: [dayjs(), dayjs().add(7, "day")],
            },
            {
              label: "Next 30 Days",
              value: [dayjs(), dayjs().add(30, "day")],
            },
          ]}
        />
      </Variants>
    </>
  );
}

function Grids() {
  return (
    <>
      <VariantTitle link="https://ant.design/components/grid">
        Grid
      </VariantTitle>

      <Variants>
        <Row>
          <Col span={12}>
            <Text>col-12</Text>
          </Col>
          <Col span={6}>
            <Text>col-6</Text>
          </Col>
          <Col span={3}>
            <Text>col-3</Text>
          </Col>
          <Col span={2}>
            <Text>col-2</Text>
          </Col>
          <Col span={1}>
            <Text>col-1</Text>
          </Col>
        </Row>

        <Row>
          <Col tw="w-[200px]">
            <Text>200px</Text>
          </Col>
          <Col tw="flex-1">
            <Text>flex-1</Text>
          </Col>
          <Col tw="flex-1">
            <Text>flex-1</Text>
          </Col>
        </Row>

        <Row>
          <Col tw="w-[200px]" noBorder>
            <Text>200px</Text>
          </Col>
          <Col tw="flex-1" noBorder>
            <Text>flex-1</Text>
          </Col>
          <Col tw="flex-1" noBorder>
            <Text>flex-1</Text>
          </Col>
        </Row>

        <Row>
          <Col tw="w-[200px]" noBorder>
            <Text>200px</Text>
          </Col>

          <InnerRow tw="flex-1">
            <Col tw="flex-1">
              <Text>flex-1</Text>
            </Col>
            <Col tw="flex-1">
              <Text>flex-1</Text>
            </Col>
          </InnerRow>
        </Row>
      </Variants>
    </>
  );
}

function Tables() {
  const row = {
    drugName: "Zilxi 1.5%",
    fillDate: "Aug 20",
    rxPatient: "123456, Ale S",
    manufacturer: "Any",
    purchaseByText: "Today",
    prescriberName: "Dr Science",
    dispenseQuantity: "60 Units",
  };
  const items = [row, row, row, row, row].map((r, i) => {
    return { ...r, id: i.toString() };
  });

  type Item = typeof items[number];

  function ExpandableHeader({ variant }: { variant: string }) {
    return (
      <div tw="flex">
        <div tw="flex-1 p-5">
          <Text weight="bold" block>
            Variant
          </Text>
        </div>
        <div tw="flex-1 p-5">
          <Text weight="bold" block>
            {variant}
          </Text>
        </div>
      </div>
    );
  }

  function ExpandableTableRow({
    item,
    itemProps,
  }: {
    item: Item;
    itemProps?: { variant: string };
  }) {
    const { variant } = itemProps as NonNullable<typeof itemProps>;

    return (
      <>
        {Object.entries(item).map(([key, value], i) => (
          <div key={i} tw="flex-1 space-y-1 p-5">
            <Text weight="bold" block>
              {key}
            </Text>
            <Text block>{value}</Text>
          </div>
        ))}
        <div tw="flex-1 space-y-1 p-5">
          <Text weight="bold" block>
            variant
          </Text>
          <Text block>{variant}</Text>
        </div>
      </>
    );
  }

  return (
    <>
      <VariantTitle>Tables</VariantTitle>

      <Variants>
        <VariantSubtitle>Simple Table</VariantSubtitle>

        <Variants>
          <SimpleTable>
            <thead>
              <tr>
                <th>Item</th>
                <th>Manufacturer</th>
                <th>Dispensed Quantity</th>
                <th>Needed</th>
                <th>Rx # , Patient</th>
                <th>Date Filled</th>
                <th>Prescriber</th>
              </tr>
            </thead>

            <tbody>
              {items.map((row) => (
                <tr key={row.id}>
                  <td>{row.drugName}</td>
                  <td>{row.manufacturer}</td>
                  <td>{row.dispenseQuantity}</td>
                  <td>
                    <Text weight="bold">{row.purchaseByText}</Text>
                  </td>
                  <td>
                    <Text weight="bold">{row.rxPatient}</Text>
                  </td>
                  <td>{row.fillDate}</td>
                  <td>{row.prescriberName}</td>
                </tr>
              ))}
            </tbody>
          </SimpleTable>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Expandable Table</VariantSubtitle>

        <Variants>
          <VariantSubtitle>Variants</VariantSubtitle>

          <Variants>
            <VariantSubtitle>Variant: (default)</VariantSubtitle>
            <Variants>
              <ExpandableTable
                items={items}
                itemProps={{ variant: "(default)" }}
                header={<ExpandableHeader variant="(default)" />}
                Row={ExpandableTableRow}
              />
            </Variants>

            <VariantSubtitle>Variant: blueToggle</VariantSubtitle>
            <Variants>
              <ExpandableTable
                blueToggle
                items={items}
                itemProps={{ variant: "blueToggle" }}
                header={<ExpandableHeader variant="blueToggle" />}
                Row={ExpandableTableRow}
              />
            </Variants>
          </Variants>
        </Variants>
      </Variants>
    </>
  );
}

function Paginations() {
  return (
    <>
      <VariantTitle>Pagination</VariantTitle>

      <Variants>
        <VariantSubtitle>Pagination</VariantSubtitle>
        <Variants>
          <Pagination
            pagination={{
              currentPage: 1,
              pageSize: 10,
              totalPageCount: 5,
              totalRows: 85,
            }}
            updatePagination={() => {}}
          />
        </Variants>

        <VariantSubtitle>Pagination without total rows</VariantSubtitle>
        <Variants>
          <Pagination
            pagination={{ currentPage: 1, pageSize: 10, totalPageCount: 5 }}
            updatePagination={() => {}}
          />
        </Variants>

        <VariantSubtitle>
          Pagination with custom page size options
        </VariantSubtitle>
        <Variants>
          <Pagination
            pagination={{ currentPage: 1, pageSize: 10, totalPageCount: 5 }}
            updatePagination={() => {}}
            sizeOptions={[1, 2, 3]}
          />
        </Variants>

        <VariantSubtitle>Pagination without page size options</VariantSubtitle>
        <Variants>
          <Pagination
            pagination={{ currentPage: 1, pageSize: 10, totalPageCount: 5 }}
            updatePagination={() => {}}
            sizeOptions={null}
          />
        </Variants>
      </Variants>
    </>
  );
}

function Boxes() {
  return (
    <>
      <VariantTitle>Box</VariantTitle>
      <VariantSubtitle>
        Inspired in the MUI Box component, the rx library Box is a helper
        component designed to easily use the rx library colors.
      </VariantSubtitle>

      <Variants>
        <VariantSubtitle>Box (color, bgColor, borderColor)</VariantSubtitle>

        <Box
          color="blue-1"
          bgColor="white"
          borderColor="orange-1"
          style={{ borderWidth: 1, padding: "10px" }}
        >
          <span>Text Color</span>
        </Box>
      </Variants>
    </>
  );
}

// ------------ Rx Library ------------
const Variants = tw.div`mb-4 px-4`;

function VariantTitle({
  children,
  link,
}: {
  children: ReactNode;
  link?: string;
}) {
  return (
    <div
      css={css`
        border-bottom: 1px solid #e5e5e5;
        border-top: 1px solid #e5e5e5;
        margin: 20px 0;
        padding: 8px 0;
        display: flex;
        align-items: baseline;
        column-gap: 10px;
      `}
    >
      <Title variant="h2">{children}</Title>
      {!!link && (
        <>
          {"|"}
          <a tw="underline" href={link}>
            {link}
          </a>
        </>
      )}
    </div>
  );
}

function VariantSubtitle({ children }: { children: ReactNode }) {
  return (
    <div
      css={css`
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 15px;
      `}
    >
      <Text weight="bold">{children}</Text>
    </div>
  );
}
