"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLocationFromUrl = getLocationFromUrl;
exports.getOriginFromUrl = getOriginFromUrl;
function getLocationFromUrl(href) {
  var match = href.match(/^(https?:|chrome-extension:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
  return match && {
    href: href,
    protocol: match[1],
    host: match[2],
    hostname: match[3],
    port: match[4],
    pathname: match[5],
    search: match[6],
    hash: match[7]
  };
}
function getOriginFromUrl(url) {
  if (!url) {
    return undefined;
  }
  var parsed = getLocationFromUrl(url);
  if (!parsed) {
    return null;
  }
  var origin = parsed.protocol + '//' + parsed.hostname;
  if (parsed.port) {
    origin += ':' + parsed.port;
  }
  return origin;
}
