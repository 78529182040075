import React, { useCallback, useEffect } from "react";
import tw, { styled } from "twin.macro";
import { useFormContext, useWatch } from "react-hook-form";
import { IconMagnify, IconX } from "./rxLibrary/icons";
import { IconButton } from "./rxLibrary/buttons";
import { getTextCss } from "./rxLibrary/typography";
import { GREY_2, WHITE } from "./rxLibrary/colors";

const IconButtonStyled = styled(IconButton)(
  ({ clickable }: { clickable?: boolean }) => [
    tw`absolute right-0 mr-4`,
    !clickable && tw`pointer-events-none`,
  ]
);

const Input = styled.input`
  ${getTextCss({ size: "medium" })}
  padding-left: 25px;
  padding-right: 25px;
  border: solid 1px ${GREY_2};
  border-radius: 500px;
  height: 40px;
  background-color: ${WHITE};
  width: 100%;

  &::placeholder {
    ${getTextCss({ color: "grey-2", size: "medium" })}
  }
`;

const MASK = "#####-####-##" as const;
const MASK_NUMBERS_COUNT = MASK.replaceAll(/-/gi, "").length;
const MASK_HYPHENS_COUNT = MASK.length - MASK_NUMBERS_COUNT;

function getHyphenAttributes(value: string) {
  const valueNoHyphens = value.replaceAll(/-/gi, "");
  const valueHyphensCount = value.length - valueNoHyphens.length;
  const valueNoHyphensIsInt = !isNaN(parseInt(valueNoHyphens));
  return { valueNoHyphens, valueHyphensCount, valueNoHyphensIsInt };
}

// Try to format value with ndc format using hyphens
function maskNdcValue(value: string): string {
  if (value.length <= 5) return value;

  if (value.length === MASK.length + 1) {
    const { valueNoHyphens, valueHyphensCount, valueNoHyphensIsInt } =
      getHyphenAttributes(value);

    if (valueNoHyphensIsInt && valueHyphensCount === MASK_HYPHENS_COUNT) {
      return valueNoHyphens;
    }
  }

  if (value.length >= MASK.length) return value;

  const { valueNoHyphens, valueHyphensCount, valueNoHyphensIsInt } =
    getHyphenAttributes(value);

  if (
    valueNoHyphensIsInt &&
    valueHyphensCount <= MASK_HYPHENS_COUNT &&
    valueNoHyphens.length <= MASK_NUMBERS_COUNT
  ) {
    const formattedNdc = [
      valueNoHyphens.substring(0, 5),
      valueNoHyphens.substring(5, 9),
      valueNoHyphens.substring(9, 11),
    ]
      .filter(Boolean)
      .join("-");

    return formattedNdc;
  }

  return value;
}

export function NDCInput({
  name,
  required = false,
  disabled = false,
  autoFocus,
}: {
  name: string;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
}) {
  const { reset, getValues, setValue, register } = useFormContext();
  const inputControl = register(name, { required });
  const value = useWatch({ name });

  const handleReset = useCallback(() => {
    reset({ ...getValues(), [name]: "" });
  }, [name, getValues]);

  useEffect(() => {
    if (!value) return;
    const newValue = maskNdcValue(value);
    if (newValue !== value) setValue(name, newValue);
  }, [value, name, setValue]);

  return (
    <div tw="relative flex items-center w-full">
      <Input
        {...inputControl}
        type="text"
        placeholder="Search Drug Name / NDC / UPC / Rx #"
        disabled={disabled}
        autoFocus={autoFocus}
      />

      <IconButtonStyled
        type="button"
        clickable={!!value || undefined}
        disabled={disabled}
        onClick={handleReset}
      >
        {value ? (
          <IconX color="grey-2" style={{ height: 13 }} />
        ) : (
          <IconMagnify color="grey-2" />
        )}
      </IconButtonStyled>
    </div>
  );
}
