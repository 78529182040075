import React from "react";
import { SVGType } from '../utilities/types';
import { BLACK } from './rxLibrary/colors';

interface ISvgProps {
  icon: SVGType;
  width?: number | string;
  height?: number | string;
  textX?: number | string;
  textY?: number | string;
  text1?: string;
  text2?: string;
}

/** @deprecated use rx library icons */
const Svg = (props: ISvgProps) => {
  const { icon, width = 48, height = 48, textX = "50%", textY = "50%", text1 = '', text2 } = props;

  const Calendar = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0 1)" fill="none" fillRule="evenodd">
        <path
          d="M7 3v41a5 5 0 0 0 5 5h25a5 5 0 0 0 5-5V3"
          fill="#8C8C8C"
          opacity=".665"
        />
        <path d="M7 10v31a5 5 0 0 0 5 5h24a5 5 0 0 0 5-5V10" fill="#FFF" />
        <path fill="#FFA604" d="M7 3h34v7H7z" />
        {text2 ? (
          <text
            x={textX}
            y={textY}
            fontFamily="'Archivo Narrow Variable', sans-serif"
            fontSize="11"
            fontWeight="bold"
            fontStretch="normal"
            fontStyle="normal"
            fill={BLACK}
            dominantBaseline="middle"
            textAnchor="middle"
          >
            <tspan x="23.783" y="20">
              {text1}
            </tspan>
            <tspan x="24.599" y="35">
              {text2}
            </tspan>
          </text>
        ) : (
          <text
            x={textX}
            y={textY}
            fontFamily="'Archivo Narrow Variable', sans-serif"
            fontSize="11"
            fontWeight="bold"
            fontStretch="normal"
            fontStyle="normal"
            fill={BLACK}
            dominantBaseline="middle"
            textAnchor="middle"
          >
            <tspan y="30">{text1}</tspan>
          </text>
        )}
        <rect
          stroke="#FFF"
          fill="#D3D3D3"
          x="13.5"
          y="-.5"
          width="4"
          height="6"
          rx="1"
        />
        <rect
          stroke="#FFF"
          fill="#D3D3D3"
          x="33.5"
          y="-.5"
          width="4"
          height="6"
          rx="1"
        />
      </g>
    </svg>
  );

  const Savings = (
    <svg width={width} height={height} viewBox="0 0 43 42" xmlns="http://www.w3.org/2000/svg">
      <g fill="#F60" fillRule="nonzero">
        <path d="M42.115 29.392c-.361-.85-1.298-1.376-2.447-1.376-.4 0-.828.066-1.298.198l-8.71 2.766-.122.367c-.492 1.44-1.947 2.303-3.897 2.303 0 0-8.25-.917-11.403-1.272a.61.61 0 0 1-.397-.718c.081-.326.436-.53.784-.454l10.21 1.163c1.89.246 3.252-.39 3.503-1.598.21-1.016-.446-2.24-2.11-2.617-2.601-.592-4.625-1.456-6.416-2.225-2.235-.954-4.165-1.779-6.576-1.779-2.067 0-4.426 1.175-7.42 2.663L0 29.519l4.347 7.467s2.92-1.445 3.978-1.445c.9 0 2.23.65 4.508 1.809 2.546 1.292 5.713 2.9 7.812 2.9 2.535 0 6.758-1.92 16.953-6.556l2.705-1.23c1.569-.707 2.294-1.944 1.812-3.072zM26.163 24.15c6.655 0 12.074-5.415 12.074-12.075C38.237 5.415 32.822 0 26.162 0c-6.656 0-12.074 5.414-12.074 12.075.002 6.66 5.418 12.075 12.075 12.075zm1.579-10.19c-.308-.317-.823-.604-1.543-.859-.722-.254-1.283-.49-1.683-.707-1.333-.715-2-1.77-2-3.174 0-.953.287-1.73.869-2.342.576-.612 1.36-.975 2.35-1.09V3.917h1.373v1.888c.994.142 1.765.565 2.308 1.265.544.702.815 1.612.815 2.734h-2.077c0-.723-.16-1.289-.487-1.707-.324-.412-.763-.618-1.314-.618-.552 0-.978.149-1.289.446-.308.298-.462.723-.462 1.278 0 .499.152.896.457 1.198.304.3.826.584 1.559.853.732.27 1.309.517 1.728.746.416.226.766.492 1.056.786.285.294.506.634.66 1.016.157.384.232.83.232 1.348 0 .967-.295 1.749-.89 2.351-.592.6-1.415.956-2.476 1.064v1.674h-1.364v-1.666c-1.132-.125-2.004-.532-2.612-1.219-.61-.686-.914-1.595-.914-2.728h2.084c0 .721.18 1.278.545 1.673.364.395.877.593 1.54.593.657 0 1.147-.157 1.49-.471.343-.314.513-.733.513-1.254-.004-.486-.158-.89-.468-1.207z"/>
      </g>
    </svg>
  );
  
  switch (icon) {
    case SVGType.Calendar:
      return Calendar;
    case SVGType.Savings:
      return Savings;
    default:
      return null;
  }
}

export default Svg;