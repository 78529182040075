"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setVcode = setVcode;
var _index = require("./index");
function setVcode(m, str) {
  return (0, _index.setField)(m, 'vcode', str.replace(/[\s-]+/g, ''));
}
