import React from "react";
import { Tag } from "../../../../../../components/rxLibrary/tags";
import { ItemInCart } from "../../../../../../utilities/types";

/*
isRx | isNarrowTherapeuticIndex | isBrand	| Result
-----|--------------------------|---------|-------------------------
F	   | F	                      | F       | null
T	   | F	                      | F       | null
F	   | T	                      | F       | Narrow Therapeutic
T	   | T	                      | F       | Narrow Therapeutic
F	   | F	                      | T       | Brand OTC
T	   | F	                      | T       | Brand RX
F	   | T	                      | T       | Brand & Narrow Thrp
T	   | T	                      | T       | Brand & Narrow Thrp
*/
export function PrescriptionTag({
  isInventory,
  prescription,
}: {
  isInventory: boolean;
  prescription: ItemInCart;
}) {
  const { drug, noManufacturerPreference, dawCode } = prescription;

  if (dawCode && !isInventory) {
    return <Tag>DAW {dawCode}</Tag>;
  }

  if (noManufacturerPreference) {
    return null;
  }

  const { isNarrowTherapeuticIndex, isBrand, isRx } = drug;
  if (isBrand) {
    if (isNarrowTherapeuticIndex) {
      return <Tag>Brand & Narrow Thrp</Tag>;
    }

    if (isRx) {
      return <Tag>Brand Rx</Tag>;
    }

    return <Tag>Brand OTC</Tag>;
  }

  if (isNarrowTherapeuticIndex) {
    return <Tag>Narrow Therapeutic</Tag>;
  }

  return null;
}
