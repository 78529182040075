import { useCallback } from "react";
import { useShoppingState } from "../../../contexts/ShoppingContext/ShoppingContext";
import { DrugInfo, ItemInCart } from "../../types";
import { getPrescriptionId } from "../getPrescriptionId";

export function useGetDrugFamilyPrescriptionsInShoppingList() {
  const { serverInventoryItemsInCart, prescriptionItemsInCart } = useShoppingState();

  const getDrugFamilyPrescriptionsInShoppingList = useCallback(
    ({
      drug,
      prescriptionToExclude,
      excludePmsPrescriptions = false,
    }: {
      drug: DrugInfo;
      prescriptionToExclude?: ItemInCart;
      excludePmsPrescriptions?: boolean;
    }) => {
      const prescriptions = excludePmsPrescriptions
        ? serverInventoryItemsInCart
        : [...serverInventoryItemsInCart, ...prescriptionItemsInCart];

      const prescriptionsInList = prescriptions.filter((item) => {
        return item.status === "list";
      });

      const idToExclude =
        prescriptionToExclude && getPrescriptionId(prescriptionToExclude);
      const { genericProductIdentifier } = drug;

      const drugFamilyPrescriptions = prescriptionsInList.filter((item) => {
        if (idToExclude) {
          const itemId = getPrescriptionId(item);
          if (itemId === idToExclude) return false;
        }

        return item.drug.genericProductIdentifier === genericProductIdentifier;
      });

      return drugFamilyPrescriptions;
    },
    [serverInventoryItemsInCart, prescriptionItemsInCart]
  );

  return getDrugFamilyPrescriptionsInShoppingList;
}
