import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { PrescriptionQuantityItem, OfferedDrug } from "../utilities/types";
import { BlobIntervalsProps } from "../utilities/types";

type StorageStateContextType = {
  prescriptionData: PrescriptionQuantityItem[];
  offeredDrugs: OfferedDrug[];
  waitButtonMode: boolean;
  blobIntervals: BlobIntervalsProps[];
};

type StorageUpdaterContextType = {
  setPrescriptionData: Dispatch<SetStateAction<PrescriptionQuantityItem[]>>;
  setOfferedDrugs: Dispatch<SetStateAction<OfferedDrug[]>>;
  setWaitButtonMode: (mode: boolean) => void;
  addInterval: (obj: BlobIntervalsProps) => void;
  resetInterval: () => void;
};

const StorageStateContext = createContext<StorageStateContextType | undefined>(
  undefined
);

const StorageUpdaterContext = createContext<
  StorageUpdaterContextType | undefined
>(undefined);

if (process.env.REACT_APP_USE_CONTEXT_DEVTOOL) {
  StorageStateContext.displayName = "StorageStateContext";
  StorageUpdaterContext.displayName = "StorageUpdaterContext";
}

export const StorageContextProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const [prescriptionData, setPrescriptionData] = useState<
    PrescriptionQuantityItem[]
  >([]);
  const [offeredDrugs, setOfferedDrugs] = useState<OfferedDrug[]>([]);
  const [waitButtonMode, setWaitButtonMode] = useState(false);
  const [blobIntervals, setBlobIntervals] = useState<BlobIntervalsProps[]>([]);

  const resetInterval = useCallback(() => {
    setBlobIntervals((prevState: BlobIntervalsProps[]) => {
      prevState.forEach((obj: BlobIntervalsProps) => {
        window.clearInterval(obj.intervalId);
      });

      // reset
      return [];
    });
  }, [setBlobIntervals]);

  const addInterval = useCallback(
    (obj: BlobIntervalsProps) => {
      setBlobIntervals((prev: BlobIntervalsProps[]) => [...prev, obj]);
    },
    [setBlobIntervals]
  );

  return (
    <StorageStateContext.Provider
      value={{
        offeredDrugs,
        blobIntervals,
        waitButtonMode,
        prescriptionData,
      }}
    >
      <StorageUpdaterContext.Provider
        value={{
          addInterval,
          resetInterval,
          setOfferedDrugs,
          setWaitButtonMode,
          setPrescriptionData,
        }}
      >
        {children}
      </StorageUpdaterContext.Provider>
    </StorageStateContext.Provider>
  );
};

export const useStorageState = (): StorageStateContextType => {
  const context = useContext(StorageStateContext);
  if (context === undefined) {
    throw new Error(
      "useStorageState must be used within a StorageContextProvider"
    );
  }
  return context;
};

export const useStorageUpdater = (): StorageUpdaterContextType => {
  const context = useContext(StorageUpdaterContext);
  if (context === undefined) {
    throw new Error(
      "useStorageUpdater must be used within a StorageContextProvider"
    );
  }
  return context;
};
