import React from "react";
import { CatalogInfo } from "../utilities/types";
import { formatDrugExpirationDate } from "../utilities/dates/formatDrugExpirationDate";

export function ExpirationDate({
  catalogInfo,
  textOnly,
}: {
  catalogInfo?: CatalogInfo;
  textOnly?: boolean;
}) {
  const expirationDate = formatDrugExpirationDate(catalogInfo);

  if (!expirationDate) return null;
  return (
    <span className="color-green">
      {textOnly ? `Exp: ${expirationDate}` : `(Exp: ${expirationDate})`}
    </span>
  );
}
