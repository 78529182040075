import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import "twin.macro";
import {Input} from "antd";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
const SORT_ICON = new URL('svgurl:../../../assets/icon-sort.svg', import.meta.url).toString();
import "../../../scss/base.scss";
import "../../../scss/buttons.scss";
import "../../../scss/shoppingTables.scss";
import "../../../scss/loader.scss";
import {Button} from "../../../components/rxLibrary/buttons";
import {Dropdown} from "../../../components/rxLibrary/dropdown";
import BuyingPharmacyContext from "../../../contexts/BuyingPharmacyContext";
import {
  useShoppingState,
  useShoppingUpdater,
} from "../../../contexts/ShoppingContext/ShoppingContext";
import {WideContainer} from "../../../components/containers/WideContainer";
import EnhancedClientCommandContext
  from "../../../enhanced/EnhancedClientCommandContext";
import {SupplierItemChangeOperation} from "../../../utilities/shoppingListTypes";
import {BuyingOptions} from "./BuyingOptions/BuyingOptions";
import {useStorageUpdater} from "../../../contexts/StorageContext";
import {ShoppingBar} from "./ShoppingBar/ShoppingBar";
import {
  useShoppingCartServerUpdater
} from "../../../contexts/ShoppingCartServerContext";
import {useP4EnhancedCart} from "./useP4EnhancedCart";
import {useShoppingList} from "./useShoppingList";
import {UnusedSuppliers} from "./UnusedSuppliers";
import {UnavailableTable} from "./tables/UnavailableTable/UnavailableTable";
import {BuyLaterTable} from "./tables/BuyLaterTable/BuyLaterTable";
import {useRxList} from "./tables/RxListTable/useRxList";
import {RxListTable} from "./tables/RxListTable/RxListTable";
import {
  RX_LIST_TABLE_FILTER_OPTIONS
} from "./tables/RxListTable/RxListTable.constants";
import {ShoppingListTable} from "./tables/ShoppingListTable/ShoppingListTable";
import {useShoppingListPrintBtnProps} from "./useShoppingListPrintBtnProps";
import {ShoppingListThreeTabPanel} from "./ShoppingListThreeTabPanel";
import {IconPrint} from "../../../components/rxLibrary/icons";
import {
  SupplierOrderItemRecommendation_All
} from "../../../services/types";
import {
  PreviousOptimizationWarningBar
} from "./ShoppingBar/PreviousOptimizationWarningBar";

export function ShoppingListThree() {
  console.log("Rendering ShoppingListThree");
  const { useCase, optimizeCartResponse, lookupOptimizedCartOp, isVisitedItem, getVisitedItemsCount, updateItemPurchaseDetailValue_undoable } =
    useShoppingState();
  const {
    addSupplierToOptimizeCart,
    updateOptimizeCartResponse,
    updateOptimizeCartQty,
    updateOptimizeCartQtyAlt,
    setHasPricesChanged,
    removeVisitedItem,
    addVisitedItems,
  } = useShoppingUpdater();
  const { setUseBlob, pushBlob } = useShoppingCartServerUpdater();
  const { setWaitButtonMode } = useStorageUpdater();
  const { shoppingItems, unusedSuppliers } = useShoppingList();
  const { rxList, rxListFilter, setRxListFilter, setRxListSortOrder } =
    useRxList(shoppingItems);
  const printBtnProps = useShoppingListPrintBtnProps();
  const hasShoppingItems = shoppingItems.length > 0;

  const {
    showSupplierForShopping,
    addEnhancedShoppingListSwitchListener,
    removeEnhancedShoppingListSwitchListener,
  } = useContext(EnhancedClientCommandContext);
  const { getSupplierById, getSupplierByName } = useContext(
    BuyingPharmacyContext
  );
  const [tabValue, setTabValue] = useState(0);

  useP4EnhancedCart(processCartChangeOperations);

  const visitedItemsIds = useMemo(() => {
    return optimizeCartResponse.data?.selections.visitedItemsIds || {};
  }, [optimizeCartResponse]);

  const updateStatusesOnItemRxs = (
    item: SupplierOrderItemRecommendation_All,
    status: string,
    pushToServer: boolean
  ) => {
    item.rxNumbers.forEach((rx) => {
      updateItemPurchaseDetailValue_undoable(rx, "status", status);
    });

    if (pushToServer) {
      console.log("Force Pushing Blob!");
      pushBlob({ force: true });
    }
  };

  const updateSuppliersOnItemRxs = (
    item: SupplierOrderItemRecommendation_All,
    supplierId: number,
    pushToServer: boolean
  ) => {
    item.rxNumbers.forEach((rx) => {
      updateItemPurchaseDetailValue_undoable(rx, "supplierId", supplierId);
    });

    if (pushToServer) {
      console.log("Force Pushing Blob!");
      pushBlob({ force: true });
    }
  };

  const goToShoppingListTab = useCallback(() => {
    setTabValue(0);
  }, []);

  const handleSortByMenuClick = useCallback(
    ({ key }: { key: string }) => {
      setRxListSortOrder(key);
    },
    [setRxListSortOrder]
  );

  // Prevent Blob push/update
  useEffect(() => {
    setUseBlob(false);
  }, []);

  const calledOnce = useRef(false);
  useEffect(() => {
    if (calledOnce.current) {
      return;
    }
    if (hasShoppingItems) {
      setWaitButtonMode(true);
      setTimeout(async () => {
        pushBlob({
          force: true,
          cb: () => {
            setWaitButtonMode(false);
            calledOnce.current = true;
          },
        });
      }, 0);
    }
  }, [hasShoppingItems, pushBlob, setWaitButtonMode]);

  useEffect(() => {
    const shopListener = (supplier: string) => {
      const sli = shoppingItems.find((sl) => sl.supplier.name === supplier);
      if (sli) {
        const supplierObj = getSupplierByName(supplier);
        showSupplierForShopping(
          sli,
          Object.keys(visitedItemsIds),
          optimizeCartResponse,
          !!supplierObj?.isEcommerceEnabled
        );
      }
    };
    addEnhancedShoppingListSwitchListener(shopListener);

    return () => {
      removeEnhancedShoppingListSwitchListener(shopListener);
    };
  }, [shoppingItems, visitedItemsIds, optimizeCartResponse]);

  function processCartChangeOperations(
    operations: SupplierItemChangeOperation[]
  ) {
    operations.forEach((op) => {
      if (op.type === "addToSupplierOrders") {
        const supplierOrderItem = op.newItem;
        const supplierId = supplierOrderItem.referenceData.catalogInfo.supplierId;
        const supplier = getSupplierById(supplierId);
        if (!supplier) return;

        const newSupplierOrder = {
          supplierId,
          shippingCost: 0,
          buyingCost:
            supplierOrderItem.referenceData.catalogInfo.price * supplierOrderItem.numPackages,
          items: [],
        };

        addSupplierToOptimizeCart(supplier, newSupplierOrder);
        setHasPricesChanged(true);
      } else if (op.type === "changeQty") {
        setHasPricesChanged(true);
        const oldSupplier = getSupplierById(op.oldItem.referenceData.catalogInfo.supplierId);
        updateOptimizeCartQty(op.quantity, op.oldItem, oldSupplier);
        updateItemPurchaseDetailValue_undoable(
          op.oldItem.rxNumbers[0],
          "packQuantity",
          op.quantity
        );
      } else if (op.type === "changeQtyAlt") {
        setHasPricesChanged(true);
        const oldSupplier = getSupplierById(op.oldItem.referenceData.catalogInfo.supplierId);
        updateOptimizeCartQtyAlt(
          op.quantity,
          op.oldItem,
          op.newItem,
          oldSupplier
        );
      } else if (op.type === "changeSupplierItem") {
        setHasPricesChanged(true);
        const oldSupplier = getSupplierById(op.oldItem.referenceData.catalogInfo.supplierId);
        const newSupplier = getSupplierById(op.newItem.referenceData.catalogInfo.supplierId);
        updateOptimizeCartResponse(
          oldSupplier,
          op.oldItem,
          newSupplier,
          op.newItem
        );
        removeVisitedItem(op.oldItem);
        updateStatusesOnItemRxs(op.oldItem, "processed", true);
        if (newSupplier) {
          updateSuppliersOnItemRxs(op.oldItem, newSupplier.id, true);
        }
      } else if (op.type === "markProcessed") {
        if (op.markAsVisited) {
          if (op.visitAllForSupplier) {
            shoppingItems.forEach((si) => {
              if (si.supplier.id === op.item.referenceData.catalogInfo.supplierId) {
                addVisitedItems(si.supplierOrder.items);
              }
            });
          } else {
            addVisitedItems([op.item]);
          }
        }
        updateStatusesOnItemRxs(op.item, "processed", true);
      }
    });
  }

  return (
    <div tw="bg-[#f4f4f4] pb-8 min-h-[100vh]">
      {optimizeCartResponse.data && useCase === "previouslyCreated" &&
        <PreviousOptimizationWarningBar
          createdBy={optimizeCartResponse.data.createdBy.full_name}
          createdAt={new Date(optimizeCartResponse.data.createdAt)} />
      }
      <ShoppingBar useCase={useCase} />

      <div style={{ minHeight: "calc(100% - 140px)" }}>
        <div tw="bg-white">
          <WideContainer
            tw="h-[120px] flex items-center"
            className="buying-options-container"
          >
            {(lookupOptimizedCartOp?.processed && (
              <BuyingOptions />
            ))}
          </WideContainer>
        </div>

        <WideContainer tw="pt-[46px]" className="tbl-tab-container">
          {hasShoppingItems && (
            <div tw="flex justify-between items-center pb-[19px]">
              <Tabs
                value={tabValue}
                onChange={(event, newTabValue) => {
                  setTabValue(newTabValue);
                }}
              >
                <Tab label="Shopping List by Supplier" disableRipple />
                <Tab label="Rx List" disableRipple />
              </Tabs>

              {tabValue === 0 && (
                <button
                  {...printBtnProps}
                  tw="items-center"
                  className="print flex print-shopping-list"
                >
                  <IconPrint tw="mr-1" color="blue-1" />
                  Print Shopping List
                </button>
              )}

              {tabValue === 1 && (
                <div className="flex items-center">
                  <div className="flex items-center mr-8">
                    <label htmlFor="search" className="mr-6 font-500">
                      Search Rx Number
                    </label>

                    <Input
                      tw="w-[76px]"
                      type="number"
                      size="large"
                      placeholder="0000000"
                      value={rxListFilter}
                      onChange={(e) => {
                        const filter = e.target.value
                          .replace(/\D/g, "")
                          .substring(0, 7);
                        setRxListFilter(filter);
                      }}
                    />
                  </div>

                  <Dropdown
                    disabled={rxList.length <= 1}
                    menu={{
                      items: RX_LIST_TABLE_FILTER_OPTIONS,
                      onClick: handleSortByMenuClick,
                    }}
                  >
                    <Button variant="text-1" block>
                      <span tw="flex space-x-1">
                        <img src={SORT_ICON} />
                        <span>Sort By</span>
                      </span>
                    </Button>
                  </Dropdown>
                </div>
              )}
            </div>
          )}

          <ShoppingListThreeTabPanel value={tabValue} index={0}>
            <div tw="space-y-4">
              {hasShoppingItems && (
                <ShoppingListTable
                  shoppingItems={shoppingItems}
                  visitedItemsIds={visitedItemsIds}
                  isVisitedItem={isVisitedItem}
                  getVisitedItemsCount={getVisitedItemsCount}
                  processCartChangeOperations={processCartChangeOperations}
                />
              )}

              <UnavailableTable />

              <BuyLaterTable shoppingItems={shoppingItems} />

              {hasShoppingItems && (
                <UnusedSuppliers suppliers={unusedSuppliers} />
              )}
            </div>
          </ShoppingListThreeTabPanel>

          <ShoppingListThreeTabPanel value={tabValue} index={1}>
            <RxListTable
              rxList={rxList}
              shoppingItems={shoppingItems}
              goToShoppingListTab={goToShoppingListTab}
            />
          </ShoppingListThreeTabPanel>
        </WideContainer>
      </div>
    </div>
  );
}
