import React, { useState } from "react";
import { styled } from "twin.macro";
import { Modal } from "antd";
import ArrowRightOutlined from "@ant-design/icons/ArrowRightOutlined";
import Svg from "../../../../../../components/Svg";
import { Title, Text } from "../../../../../../components/rxLibrary/typography";
import { SimpleTable } from "../../../../../../components/rxLibrary/tables";
import { GREY_2, GREY_4 } from "../../../../../../components/rxLibrary/colors";
import { SavingsTableDataType, SVGType } from "../../../../../../utilities/types";
import { formatNdc } from "../../../../../../utilities/ndc/formatNdc";
import { currencyWithCentsUnlessGreaterThanOrEqualToOneThousand } from "../../../../../../utilities/numbers/currency";
import { formatDrugDispenseQuantity } from "../../../../../../utilities/drugInfo/formatDrugDispenseQuantity";

const SAVINGS_REASON_LABEL: Record<string, string> = {
  supplier_change: "Supplier Change",
  ndc_change: "NDC Change",
  supplier_and_ndc_change: "NDC + Supplier Change",
};

const Badge = styled.div`
  border-radius: 18px;
  white-space: nowrap;
  padding: 6px;
  text-align: center;
  background-color: ${GREY_4};
`;

export function SavingsModal({
  onClose,
  savingsTableData,
  savingsGrandTotal,
  displayedSavingsTotal,
  minShipManagementAndShippingTotal,
  hasPricesChanged,
}: {
  onClose: () => void;
  savingsTableData: SavingsTableDataType;
  savingsGrandTotal: number;
  displayedSavingsTotal: number;
  minShipManagementAndShippingTotal: number;
  hasPricesChanged: boolean;
}) {
  const [showOverlay, setShowOverlay] = useState(hasPricesChanged);

  const savingsTotal = currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(
    displayedSavingsTotal
  );
  const hasMinShipFee = minShipManagementAndShippingTotal > 0;

  return (
    <Modal open centered onCancel={onClose} footer={null} width={840}>
      <Title tw="mb-2" block>
        Your Savings Details
      </Title>

      <div tw="flex mb-2">
        <div tw="flex-1">
          <Text block size="medium">
            Your savings are calculated comparing the original item{"'"}s
            pricing at your primary supplier(s) to DaylightRx{"'"}s suggested
            item.
          </Text>
        </div>

        <div tw="flex-1 flex justify-center">
          <div tw="flex space-x-2">
            <Svg icon={SVGType.Savings} />

            <div>
              <Text size="medium" weight="bold" block center>
                {currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(
                  savingsGrandTotal
                )}
              </Text>
              <Text size="medium" block center>
                SAVINGS
              </Text>
            </div>
          </div>
        </div>
      </div>

      <SimpleTable>
        <thead>
          <tr>
            <th />
            <th>Original Item</th>
            <th />
            <th>Suggested Item</th>
            <th />
            <th />
          </tr>
        </thead>

        <tbody>
          {savingsTableData.map((item, i) => {
            const {
              itemDesc,
              originalItem,
              suggestedItem,
              savings,
              savingsReason,
            } = item;
            const savingsReasonLabel = SAVINGS_REASON_LABEL[savingsReason];

            return (
              <tr key={i}>
                <td tw="flex-1">
                  <Text block>{itemDesc.drug.name}</Text>
                  <Text block>
                    {itemDesc.numPackages} x{" "}
                    {formatDrugDispenseQuantity(itemDesc.drug)}
                  </Text>
                </td>

                <td tw="w-[130px]">
                  <Text block>
                    {formatNdc(originalItem.comparison.originalNDC || "0")}
                  </Text>

                  <Text block>
                    <Text weight="bold">
                      {currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(
                        originalItem.comparison.originalCost || 0
                      )}{" "}
                    </Text>
                    At {originalItem.supplierName}
                  </Text>
                </td>

                <td tw="w-[35px]">
                  <ArrowRightOutlined
                    style={{ color: GREY_2 }}
                    rev={undefined}
                  />
                </td>

                <td tw="w-[130px]">
                  <Text block>{formatNdc(suggestedItem.ndc)}</Text>
                  <Text block>
                    <Text weight="bold">
                      {currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(
                        suggestedItem.price
                      )}{" "}
                    </Text>
                    At {suggestedItem.supplierName}
                  </Text>
                </td>

                <td tw="w-[130px]">
                  <div tw="flex flex-col items-center">
                    <Text weight="bold">SAVINGS</Text>

                    <div className="flex space-x-2">
                      <Svg icon={SVGType.Savings} width={20} height={18} />

                      <Text weight="bold">
                        {currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(
                          savings
                        )}
                      </Text>
                    </div>
                  </div>
                </td>

                <td tw="w-[150px]">
                  <Badge>{savingsReasonLabel}</Badge>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={4} />
            {hasMinShipFee ? (
              <td colSpan={2}>
                <Text size="medium" weight="bold" block center>
                  {savingsTotal}{" "}
                  {hasMinShipFee &&
                    `+ ${currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(
                      minShipManagementAndShippingTotal
                    )} in Min Ship Mgmt Fees`}
                </Text>
              </td>
            ) : (
              <>
                <td>
                  <Text size="medium" weight="bold" block center>
                    {savingsTotal}
                  </Text>
                </td>
                <td />
              </>
            )}
          </tr>
        </tbody>
      </SimpleTable>

      {showOverlay && (
        <Text size="medium" tw="mt-2" center block>
          The original proposed purchase has been modified.
          <br />
          <br />
          <a
            href="javascript:;"
            onClick={() => setShowOverlay(false)}
            className="underline hover-underline"
          >
            Click here
          </a>{" "}
          to see the savings from the original purchase proposal.
        </Text>
      )}
    </Modal>
  );
}
