import React, { useMemo } from "react";
import "twin.macro";
import { formatPurchaseByToDayjs } from "../../../../../../../utilities/dates/purchaseBy/formatPurchaseByToDayjs";
import { SHORT_ORDINAL_DATE_FORMAT } from "../../../../../../../utilities/dates/dates.constants";
import { PrescriptionDetail } from "../../../../../../../utilities/types";
import { Text } from "../../../../../../../components/rxLibrary/typography";
import { Warning } from "../../../../../../../components/rxLibrary/warnings";
import {
  Popover,
  useIsPopoverOpen,
} from "../../../../../../../components/rxLibrary/popover";

const MESSAGE = "Added to today's list to meet min ship.";

export function PurchaseByDrugWarning({
  prescriptionDetails,
}: {
  prescriptionDetails: PrescriptionDetail[];
}) {
  const { isPopoverOpen, handleOpenPopoverChange } = useIsPopoverOpen();

  const [text, popoverTexts] = useMemo(() => {
    const data = prescriptionDetails.reduce<
      { displayName: string; purchaseByStr: string }[]
    >((acc, { prescription }) => {
      const { purchaseBy, rxNumber } = prescription;
      const purchaseByDate = formatPurchaseByToDayjs(purchaseBy);
      if (purchaseByDate) {
        const displayName = rxNumber ?? "Inventory";
        const purchaseByStr = purchaseByDate.format(SHORT_ORDINAL_DATE_FORMAT);
        acc.push({ displayName, purchaseByStr });
      }

      return acc;
    }, []);

    if (data.length === 0) return [];
    if (data.length === 1) {
      const date = data[0].purchaseByStr;
      return [`Added to today's list to meet min. ship. Was ${date}`];
    }

    const popupTexts = data.map((i) => {
      return `${i.displayName} was ${i.purchaseByStr}`;
    });

    return [MESSAGE, popupTexts];
  }, [prescriptionDetails]);

  if (!text) return null;
  return (
    <Warning>
      {text}
      {!!popoverTexts && (
        <Popover
          open={isPopoverOpen}
          onOpenChange={handleOpenPopoverChange}
          content={
            <div tw="p-4">
              <Text weight="bold" block>{MESSAGE}</Text>
              <ul>
                {popoverTexts.map((text) => (
                  <li key={text}>{text}</li>
                ))}
              </ul>
            </div>
          }
        >
          {" "}
          <span tw="underline">Details</span>
        </Popover>
      )}
    </Warning>
  );
}
