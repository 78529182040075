import React, { useCallback, useEffect, useMemo, useState } from "react";
import "twin.macro";
import { MenuButton } from "../../../../../../components/rxLibrary/buttons";
import {
  Dropdown,
  DropdownMenuOptions,
} from "../../../../../../components/rxLibrary/dropdown";
import { IconNote, IconX } from "../../../../../../components/rxLibrary/icons";
import { ItemInCart } from "../../../../../../utilities/types";
import { GREY_2 } from "../../../../../../components/rxLibrary/colors";
import { useShoppingUpdater } from "../../../../../../contexts/ShoppingContext/ShoppingContext";
import { usePrescriptionNote } from "../../../../../../components/PrescriptionNote/usePrescriptionNote";
import { isInventoryId } from "../../../../../../utilities/prescriptions/isInventoryId";
import { getPrescriptionId } from "../../../../../../utilities/prescriptions/getPrescriptionId";

const BORDER_TOP_STYLE = { borderTop: `1px solid ${GREY_2}`, borderRadius: 0 };

export function ShoppingListColumnActionsDropdown({
  prescription,
}: {
  prescription: ItemInCart;
}) {
  const { status, processedAt } = prescription;
  const [menuOptions, setMenuOptions] = useState<DropdownMenuOptions>([]);
  const id = useMemo(() => getPrescriptionId(prescription), [prescription]);
  const isInventory = useMemo(() => isInventoryId(id), [id]);

  const {
    deletePrescription,
    updatePrescriptionNote,
    updatePrescriptionStatus,
  } = useShoppingUpdater();

  const {
    hasPrescriptionNote,
    prescriptionNotePopover,
    openPrescriptionNotePopover,
  } = usePrescriptionNote({
    prescription,
    updatePrescriptionNote,
  });

  const handleMenuClick = useCallback(
    ({ key }: { key: string }) => {
      if (key === "note") {
        openPrescriptionNotePopover();
      } else if (key === "delete") {
        deletePrescription(id);
      } else {
        updatePrescriptionStatus(prescription, key);
      }
    },
    [id, prescription, deletePrescription, updatePrescriptionStatus]
  );

  useEffect(() => {
    const newMenuOptions = [
      {
        key: "add",
        label: "Remove from Shopping List",
      },
      {
        key: "list",
        label: processedAt ? "Re-Add to Shopping List" : "Add to Shopping List",
      },
      {
        key: "stock",
        label: "In Stock",
      },
      {
        key: "processed",
        label: "Mark: Optimized",
      },
      {
        style: BORDER_TOP_STYLE,
        key: "delete",
        label: "Delete Item",
        icon: <IconX />,
      },
      {
        style: BORDER_TOP_STYLE,
        key: "note",
        label: hasPrescriptionNote ? "Edit Note" : "Add Note",
        icon: <IconNote />,
      },
    ].filter(({ key }) => {
      if (key === status) return false;
      if (key === "add") return status !== "processed";
      if (key === "delete") return isInventory;
      return true;
    });

    setMenuOptions(newMenuOptions);
  }, [status, processedAt, isInventory, hasPrescriptionNote]);

  return (
    <>
      <Dropdown menu={{ items: menuOptions, onClick: handleMenuClick }}>
        <MenuButton />
      </Dropdown>

      {prescriptionNotePopover}
    </>
  );
}
