import { useMemo } from "react";
import "twin.macro";
import p from "pluralize";
import { Box } from "../../../../../components/rxLibrary/box";
import { Text } from "../../../../../components/rxLibrary/typography";
import { useCatalog } from "../useCatalog";
import { CatalogItem } from "./CatalogItem/CatalogItem";
import { CatalogItemsSection } from "./CatalogItemsSection";
import { CatalogItemsSectionFeaturedItems } from "./CatalogItemsSectionFeaturedItems";

export function CatalogItemsWithCategories() {
  const { supplier, itemsByType } = useCatalog();

  const supplierName = supplier.displayName;
  const { featuredC2Items, featuredItems, otherC2Items, otherItems } =
    itemsByType;
  const displayOtherItemsTitle =
    !!featuredC2Items.length || !!featuredItems.length;

  const featuredC2ItemsTitle = useMemo(() => {
    return `C2 ${p("item", featuredC2Items.length)} on your shopping list`;
  }, [featuredC2Items.length]);

  const otherItemsTitle = useMemo(() => {
    return `Other ${supplierName} ${p("item", otherItems.length)}`;
  }, [supplierName, otherItems.length]);

  const otherC2ItemsTitle = useMemo(() => {
    const msg = `C2 ${supplierName} ${p("item", otherC2Items.length)}`;
    return featuredC2Items.length ? `Other ${msg}` : msg;
  }, [supplierName, otherC2Items.length, featuredC2Items.length]);

  return (
    <Box bgColor="white" tw="rounded-lg">
      <div tw="py-[40px] px-[26px]">
        <CatalogItemsSectionFeaturedItems />

        {!!featuredC2Items.length && (
          <CatalogItemsSection>
            <div tw="mb-[15px]">
              <Text size={21} block>
                {featuredC2ItemsTitle}
              </Text>
              <Text color="grey-1" block>
                C2s not yet available for purchase directly via DaylightRx.
              </Text>
            </div>

            {featuredC2Items.map((item) => (
              <CatalogItem key={item.ndc} item={item} />
            ))}
          </CatalogItemsSection>
        )}

        {!!otherItems.length && (
          <CatalogItemsSection>
            {displayOtherItemsTitle && (
              <Text tw="mb-[15px]" size={21} block>
                {otherItemsTitle}
              </Text>
            )}

            {otherItems.map((item) => (
              <CatalogItem key={item.ndc} item={item} />
            ))}
          </CatalogItemsSection>
        )}

        {!!otherC2Items.length && (
          <CatalogItemsSection>
            <div tw="mb-[15px]">
              <Text size={21} block>
                {otherC2ItemsTitle}
              </Text>
              <Text color="grey-1" block>
                Not yet available for purchase via DaylightRx
              </Text>
            </div>

            {otherC2Items.map((item) => (
              <CatalogItem key={item.ndc} item={item} />
            ))}
          </CatalogItemsSection>
        )}
      </div>
    </Box>
  );
}
